<div class="flutter_idea">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-sm-7 col-md-5">
        <div>
          <img
            src="../../assets/img/servicesdev/flutter/rocket.gif"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="col-sm-5 col-md-7">
        <div class="mb-3 mb-md-0">
          <h2>
            {{ heading }}
          </h2>
          <p>
            {{ description }}
          </p>
        </div>
      </div>
    </div>
    <br />
    <div class="row gy-3 gy-md-0">
      <div class="col-12 col-lg" *ngFor="let item of steps">
        <div class="point_content">
          <div class="circle_wrap">
            <div class="circle">{{ item.count }}</div>
            <div class="line"></div>
          </div>

          <h5 class="my-1 my-md-3">{{ item.heading }}</h5>
          <p>{{ item.desc }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
