<section class="spacing">
    <div class="container-md">

        <h2>{{firsttitle}} <span>{{semititle}}</span> </h2>
        <p class="my-3">{{desc}}</p>


        <div class="row my-md-5 gy-3 gy-md-0" *ngFor="let item of portfolio">

            <div class="col-md-6">
                <div class="portfolio-img">
                    <img src={{item.bgimg}} alt="" srcset="">

                    <a href={{item.casestudy}} *ngIf="item.casestudy">
                        <div class="textwrap">
                            <img src="../../assets/img/servicesdev/arrow.svg" alt="" srcset="">
                            <p>Read Full <br> Case Study</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="contentwrap">

                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <h4>{{item.heading}}</h4>
                            <h6 class="text-start">{{item.smalltitle}}</h6>
                        </div>
                        <div class="techicon">
                            <img src={{item.icon}} alt="" srcset="">
                        </div>

                    </div>

                    <p>{{item.desc}}</p>


                    <div class="d-flex justify-content-between detailwrap">

                        <div>
                            <p>{{item.specialist}}</p>
                            <span>{{item.heading1}}</span>
                        </div>

                        <div>
                            <p>{{item.download}}</p>
                            <span>{{item.heading2}}</span>
                        </div>

                        <div>
                            <p>{{item.rating}}</p>
                            <span>{{item.heading3}}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>


</section>