<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container head">
                <h1 class="fw-bold"> TERMS AND <span style="color: #F7993B;"> CONDITIONS </span></h1>
            </div>
        </div>
    </div>

</div>


<section class="pp-sec1">
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <ol>
                    <li> Pro Web initiates project work upon receiving an initial down payment. Unless explicitly
                        stated otherwise in written agreements, clients are billed the remaining balance upon project
                        completion. Live deployment of websites occurs once all payments are successfully processed.
                        Annual payments are required for hosting and domain name registration fees, if applicable.</li>

                    <li> Additional services are available at extra charges, either on a fee-for-service basis or an
                        hourly rate. Clients requesting supplementary services will have the corresponding fees added to
                        their invoice, and charges will be applied accordingly.</li>


                    <li> Project completion delays may arise under the following circumstances:</li>


                    <ul>
                        <li>Payment delays</li>
                        <li>Client-requested alterations to design, structure, or content</li>
                        <li>Purchase of additional project-related services by the client</li>
                        <li>Delays in receiving client-provided materials
                        </li>
                        <li>Time frame estimates are approximate and subject to change at Pro Web's discretion. Pro
                            Web reserves the right to suspend a project at its discretion.</li>
                    </ul>

                    <li>All our services come with a 100% satisfaction guarantee. We strive for client satisfaction and
                        continue working until clients are content with their solutions. However, Pro Web is not liable
                        for meeting unreasonable expectations and retains the discretion to determine the reasonableness
                        of requests. For instance, if additional services are deemed necessary for full satisfaction, it
                        becomes the client's responsibility.</li>

                    <li> Approved services or project elements are considered 100% satisfactory. Any further work on
                        these services will be invoiced accordingly.
                    </li>

                    <li>Unless explicitly stated otherwise, project statuses are marked as "completed" after a 3-month
                        period from the initial payment date. No further work will be conducted, and no services will be
                        provided. This exclusion does not apply to hosting and domain name registration services, which
                        continue independently based on client payments.</li>

                    <li>If a client is dissatisfied with Pro Web Services, they may request a refund for any unused
                        funds. Pro Web is open to refund requests following a review process.
                    </li>

                </ol>

                <h2 class="heading">ABOUT CREDIT CARDS DATA PROCESSING
                </h2>

                <p>For security purposes, Pro Web consistently implements the following measures:</p>


                <ul>
                    <li>Implements stringent measures to prevent unauthorized access, maintains data accuracy, and
                        ensures the proper use of information. This is achieved through suitable physical, electronic,
                        and managerial procedures designed to safeguard and secure both online and offline information.
                    </li>

                    <li>Engages reputable third-party entities to handle credit card information and employ secure
                        encryptions for payment gateways on client websites. These external services typically incur
                        transaction fees and/or monthly charges, for which the client is accountable. The majority of
                        these service providers offer diverse fee plans tailored to the specific business needs of the
                        client, depending on the volume of online transactions.
                    </li>
                </ul>

                <h2 class="heading">ABOUT THIS SITE</h2>
                <ul>
                    <li>The prices displayed on this site are subject to change without prior notice. They serve as
                        informational references, and Pro Web is not obligated to adhere to these prices for your
                        specific project. For a complimentary quote tailored to your project, please contact us at
                        +971-528050084(add CTA) or submit a quote request on our website.</li>
                    <li>Promotional offers cannot be combined.
                    </li>
                    <li>All images featured on this website are purely representative.</li>
                </ul>

                <h2 class="heading">ABOUT PRO WEB</h2>

                <ul>
                    <li>Pro Web assumes no responsibility or liability for the content featured on the websites it
                        designs and/or hosts. Pro Web lacks control over the information, materials, or products present
                        on these websites. It does not act as a consultant for its clients, and clients bear full
                        responsibility for the content on their websites.</li>

                    <li>Due to the inherent nature of web design and programming, websites may appear differently on
                        various computers. Factors such as screen resolution, operating system, and browsing software
                        can contribute to these variations. Clients should be mindful of these potential differences,
                        and Pro Web is not held accountable for such variations.
                    </li>
                </ul>



            </div>
        </div>
    </div>
</section>