import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlertService } from '../_alert';
import { Router } from "@angular/router";
import { LoadingService } from '../utils/services/loading.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

  isMobile = true;
  isDesktop = true;
  reviews: any[] = [];

  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    if (width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
    }
  }

  model: any = {};
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  // Meta Title
  title = 'Become a Part of Our Team - Careers | Pro Web';
  filedata: any;
  fd = new FormData();

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private titleService: Title,
    private metaTagService: Meta,
    private alertService: AlertService,
    private router: Router,
    private loadingService: LoadingService,

  ) { }

  apiurl = environment.apiUrl;

  careerForm: any;
  submitted = false;

  isDisabled = false;
  errorcallback = false;
  successcallback = false;
  country_code = '';
  showLoader = false;
  showReview: boolean = false


  // public careerJobsList: any = '';
  public activeJobsList: any = '';

  careerJobsList = [
    {
      title: "Marketing Manager",
      profile_id: "",
      experience: "Minimum 8 years",
      skills: "Critical Thinking, Knowledge of business environment, Analytical thinking, Investment Management, Digital Advertising, Content Marketing, Market Research",
      location: "Dubai",
      slug: "marketing-manager"
    },
    {
      title: "Customer Support Executive",
      profile_id: "",
      experience: "Minimum 3 years",
      skills: "Empathy, Communication, Problem-Solving, Multi-Channel Support, Patience, Technical Proficiency, Customer Relationship Management (CRM)",
      location: "Dubai",
      slug: "customer-support-executive"
    }
  ]
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000)

    // Meta Title & skills
    
    // Meta Title & Description
    this.titleService.setTitle("Become a Part of Our Team - Careers | Pro Web");

    this.metaTagService.updateTag(
      { name: "keywords", content: "careers at pro web, career openings at pro web, career development at pro web, join pro web" }
    );

    this.metaTagService.updateTag(
      { name: 'description', content: "Join our team at Pro Web and embark on a career filled with innovation and client-centric success. Explore our exciting opportunities." }
    );


    // Meta Title & skills

    this.addBodyClass();
    /* Generate code according to resolution open here */

    if (window.screen.width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    }
    else {
      this.isMobile = false;
      this.isDesktop = true;
    }

    /* Generate code according to resolution close here */

    this.addBodyClass();

    // this.http.get(this.apiurl + 'career_jobs').subscribe(data => {

    //   let ClientsData: any = data;

    //   this.careerJobsList = ClientsData;
    // });

    // this.http.get(this.apiurl + 'jobs_dropdown_list').subscribe(data => {

    //   let ClientsNewData: any = data;

    //   this.activeJobsList = ClientsNewData;
    // });


    this.careerForm = this.formBuilder.group({
      name: [],
      email: [],
      phone_number: [],
      interested_in: [],
      linkedin_url: [],
      country_code: ['91'],
      resume: [],
      filedata: [],
      // name: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      // country_code: ['91'],
      // phone_number: ['', Validators.required],
      // interested_in: ['', Validators.required],
      // resume: ['', Validators.required]
    });
  }

  getFiles(event: any) {
    //  console.log(event.target.files);
    //  this.selectedFile = <File>event.target.files;
    //  this.fd.append('file', this.selectedFile, this.selectedFile.name);
    //  this.model.append('file', event.target.files);
    this.filedata = event.target.files;
  }

  get f() { return this.careerForm.controls; }

  onSubmit(event: any) {

    this.isDisabled = true;
    this.submitted = true;

    // stop here if form is invalid
    // if (this.careerForm.invalid) {
    // 	console.log('error');
    //     return;
    // }

    this.showLoader = true;

    const formData = new FormData(event.target);

    this.http.post(this.apiurl + 'careers', formData).subscribe(data => {

      this.showLoader = false;

      let ClientsData: any = data;

      if (ClientsData.success == true) {
        this.alertService.clear();
        this.alertService.success(ClientsData.message);
        this.submitted = false;
        this.careerForm.reset();
        this.isDisabled = false;
        this.router.navigate(['/thankyou']);
      } else {
        this.alertService.clear();
        this.alertService.error(ClientsData.message);
        this.isDisabled = false;
      }
    });
  }

  telInputObject(obj: any) {
    console.log(obj);
    obj.setCountry('in');
  }
  countryChange(country: any) {
    console.log(country);
    // this.rcbCode = country.dialCode;
    // document.getElementById('country_code').value = country.dialCode;
    console.log(this.careerForm.controls);
    this.careerForm.controls.country_code.setValue(country.dialCode);
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  addBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.add('career-page');
  }


  benifits = [
    {
      name: "Best in Industry Salaries (we run market surveys regularly)",
    },
    {
      name: "Hybrid Work culture",
    },
    {
      name: "Equal Opportunities",
    },
    {
      name: "ESIC , PF Benefits",
    },
    // {
    //   name: "TDS Benefits",
    // },
    {
      name: "UAE, Singapore , Europe , US Based Projects",
    },
    {
      name: "Opportunities of onsite visa & Accommodation"
    },
    {
      name: "Yearly Evaluation and Appraisal Mechanism"
    }
  ]

  arrowDown() {
    this.showReview = !this.showReview;
  }


  carouselConfig1 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 5000,
    arrows: false,
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };


  review = [
    {
      img: "assets/img/team-reviews/Neeraj-Bhawsar.png",
      name: "Neeraj Bhawsar",
      namefirst: 'N',
      designation: "Technical Team Lead",
      feedback: "I have been working with Pro Web for more than 3 years. It is a very great and career uplifting experience and I am sure that other people will also be able to repeat my experience. It's a growing organization with a number of big clients and a great platform to grow your career & learn new technologies specially for the freshers.",
    },
    {
      img: "assets/img/team-reviews/Neelam-Manker.png",
      name: "Neelam Manker",
      namefirst: 'N',
      designation: "SEO Analyst",
      feedback: "Good place to work! I have been working at Pro Web (more than a year). It is the best place for learning new technologies. I highly recommend!",
    },
    {
      img: "assets/img/team-reviews/Saurabh-Jain.png",
      name: "Saurabh Jain",
      namefirst: 'S',
      designation: "Graphic Designer",
      feedback: "friendly environment",
    },
    {
      img: "assets/img/team-reviews/Appeaksha-Awatramani.png",
      name: "Appeaksha Awatramani",
      namefirst: 'A',
      designation: "Web Tester",
      feedback: "Great workplace and a company with good business acumen and vision !",
    },
    {
      img: "assets/img/team-reviews/Jayshri-Gupta.png",
      name: "Jayshri Gupta",
      namefirst: 'J',
      designation: "Marketing Content Writer",
      feedback: "The office ambience is great.",
    },
    {
      img: "assets/img/team-reviews/Anshul-Nema.png",
      name: "Anshul Nema",
      namefirst: 'A',
      designation: "Digital Marketing Executive",
      feedback: "Good place to work! I have recently joined Pro Web as a digital marketing executive. Employees of Pro Web are talented and helpful. I highly recommend!",
    },
    {
      img: "assets/img/team-reviews/Ankita-Jain.png",
      name: "Ankita Jain",
      namefirst: 'A',
      designation: "Sr. Marketing Executive",
      feedback: "I am working in this company full time for more than 3 years,having a good work experience.",
    },
    {
      img: "assets/img/team-reviews/Neelesh-Tiwari.png",
      name: "Neelesh Tiwari",
      namefirst: 'N',
      designation: "Sr. PHP Developer",
      feedback: "Good company to work. I have enhanced my skills and worked on so many technologies.Team Lead is very good,i have learned many things from him.good place to learn new technologies and build career.",
    },
    {
      img: "assets/img/team-reviews/Shruti-Shrivastava.png",
      name: "Shruti Shrivastava",
      namefirst: 'S',
      designation: "Content Writer",
      feedback: "Pro Web is the best company in India providing affordable web design & development solutions. The professional support that i have received frm them is really commendable!",
    },
    {
      img: "assets/img/team-reviews/Hitesh-Chaurasia.png",
      name: "Hitesh Chaurasia",
      namefirst: 'H',
      designation: "Web Developer",
      feedback: "It's a great place to work, everyday you're going to learn something new which is going to help a lot in boosting up your skills. Beside that whole staff have very supportive and friendly nature.",
    },
    {
      img: "assets/img/team-reviews/Varsha-Suryawanshi.png",
      name: "Varsha Suryawanshi",
      namefirst: 'V',
      designation: "Graphic Designer",
      feedback: "Excellent platform to initiate your career and enhance your skills.",
    },
    {
      img: "assets/img/team-reviews/Ankit-Richhariya.png",
      name: "Ankit Richhariya",
      namefirst: 'A',
      designation: "Sr. PHP Developer",
      feedback: "I had a great time when I was working in Pro Web. I have enhanced my skills and worked on so many technologies. So it was good to work their.",
    },
    {
      img: "assets/img/team-reviews/Anamika-Sethi.png",
      name: "Anamika Sethi",
      namefirst: 'A',
      designation: "Sr. SEO Executive",
      feedback: "My experience was very good with Pro Web, delivered what they promised, highly recommend.",
    },
    {
      img: "assets/img/team-reviews/Pranati-Khare.png",
      name: "Pranati Khare",
      namefirst: 'P',
      designation: "UI/UX Designer Intern",
      feedback: "Quite good environment,seniors are very friendly and of helping nature,fortunate to be a part of Pro Web.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Gurpreet Walia",
      namefirst: 'G',
      designation: "Business Analyst",
      feedback: "I have been working in Pro Web from past 2 years and it ia great place to work. I have learnt a lot from this company",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Shaleen Singh",
      namefirst: 'S',
      designation: "Business Analyst",
      feedback: "Its nice place to work in, having great working culture & environment. Seniors & colleagues are very supportive and helpful",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Khushi Dwivedi",
      namefirst: 'K',
      designation: "Business Analyst",
      feedback: "It's been fantastic working here always great and enjoying. Good work environment and culture. Teammates are supportive and they are very helpful in nature. If you are a fresher there is a lot to learn. Everything is good and great. I enjoy working. A fun place to do the kind of work you want.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Rahul Jain",
      namefirst: 'R',
      designation: "Business Analyst",
      feedback: "I have recently joined the company. excellent work place supportive teammates i am happy to join this company.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Pratik Verma",
      namefirst: 'P',
      designation: "Business Analyst",
      feedback: "It's my pleasure to get a chance to be a part of such a great organization .RPA technology is really a great place to work and grow your skills ,the environment of office is very pleasant and motivated , Colleagues and seniors are very helpful and always motivate you for the upcoming challenges.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Divya Nagwanshi",
      namefirst: 'D',
      designation: "Business Analyst",
      feedback: "I am proud to be a part of  Pro Web. The company does a great job of creating a fun environment that also motivates people to be their best. I am thankful and  I can say that I really do enjoy going to work everyday.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Srishti Jain",
      namefirst: 'S',
      designation: "Business Analyst",
      feedback: "Working environment is good & Friendly Team members.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Manoj Shivankar",
      namefirst: 'M',
      designation: "Business Analyst",
      feedback: "RPA is truly a great company to work with. I enjoy the work culture as you'll get lot of opportunities to enhance your skills. I have learnt too many things from my seniors, managers who are great mentors as well as good friends too. Office timing is good and ambiance is also nice.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Meet Kumar",
      namefirst: 'M',
      designation: "Business Analyst",
      feedback: "Pro Web provides a good platform for programmer or developer which hasn't experience in any tech company...",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Mausam Bhalekar",
      namefirst: 'M',
      designation: "Business Analyst",
      feedback: "I feel very happy working at Pro Web. The culture is very positive and transparent. There are lots of opportunities for growth at Pro Web. They really care about their employees and provide as much support as possible. If you can maintain a steady workflow, this is probably the best place for you to work. not only the managers super helpful but so are the other employees. The job hours are so perfect and I feel grateful for working here.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Vijay Kanathe",
      namefirst: 'V',
      designation: "Business Analyst",
      feedback: "Pro Web is a great company to work for. It is actually a place that care about their employees and provides fun & enthusiastic work environment too. Every employee here is given the freedom to forge their own path & generate new ideas that help contribute to the success of the company. I would really give big stars for the culture that I got here to enhance my skills & talent.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Avni Mishra",
      namefirst: 'A',
      designation: "Business Analyst",
      feedback: "I feel so happy working at Pro Web. They really care about their employees and provide as much support as possible. The timing is very perfect and I feel grateful for working here.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "enthoharsh",
      namefirst: 'E',
      designation: "Business Analyst",
      feedback: "it's great and lovely atmosphere in the office with many people and also very helpful for the individual and fresher to work on and progress in the requirements the is essential.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "enthoharsh",
      namefirst: 'E',
      designation: "Business Analyst",
      feedback: "it's great and lovely atmosphere in the office with many people and also very helpful for the individual and fresher to work on and progress in the requirements the is essential.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Sanjana Choudhary",
      namefirst: 'S',
      designation: "Business Analyst",
      feedback: "Being a part of RPA Team as a content writer is has been a great experience by far! Remarkable colleagues and environment makes it a more fulfilling experience!",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Arshad Ansari",
      namefirst: 'A',
      designation: "Business Analyst",
      feedback: "Nice work environment, amazing colleagues, supportive teammates & management. Excellent career growth and lots of learning opportunities. Beautiful ambience, good leadership and lots of fun.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Manali Nagar",
      namefirst: 'A',
      designation: "Business Analyst",
      feedback: "I have recently joined the company & enjoying  being a part of it. I am getting the opportunity to learn new things.Work environment is good and company provides ample opportunities for every one working in it.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Aishita Shrivastava",
      namefirst: 'A',
      designation: "Business Analyst",
      feedback: "It has been a great Experience working here as a Graphic Designer so far, learning new things everyday, Workplace Culture is Diverse, Great Place to Work!!",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Devendra Jaiswal",
      namefirst: 'D',
      designation: "Business Analyst",
      feedback: "I have been working in RPA for the past 2.5 years. I have been enjoying a friendly work environment, helpful colleagues and amazing team leader.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Manisha Sharma",
      namefirst: 'M',
      designation: "Business Analyst",
      feedback: "The working environment this office is good and safe as well. All the seniors and other employees are always ready to corporate us.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Bhavna Yadav",
      namefirst: 'B',
      designation: "Business Analyst",
      feedback: "Nice work environment, pleasant people, amazing colleagues, supportive seniors, teammates & management",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Suraj kant Rai",
      namefirst: 'S',
      designation: "Business Analyst",
      feedback: "Good office culture, supportive team , full of opportunity and learning",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Devendra Arya",
      namefirst: 'D',
      designation: "Business Analyst",
      feedback: "Work environment is so good and great place to learn and grow, colleagues and seniors are very helpful ",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Somya Jain",
      namefirst: 'S',
      designation: "Business Analyst",
      feedback: "It's been a month since i have been working on this company and now i can say that this company and this working culture  is really great.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Rahul Sharma",
      namefirst: 'R',
      designation: "Business Analyst",
      feedback: "This is a bets place to work. I personally suggest to join the company. Everything about company is great.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Shrishti Kushwaha",
      namefirst: 'S',
      designation: "Business Analyst",
      feedback: "Good place to learn,grow and develop.Employees and staff are helping and supportive.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Aayushi Shrivastava",
      namefirst: 'A',
      designation: "Business Analyst",
      feedback: "A great place to work.. in which you trust the people you work for , have pride in what you do and enjoy the people you work with :-)",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Aditya Jain",
      namefirst: 'A',
      designation: "Business Analyst",
      feedback: "Excellent platform to initiate your career and enhance your skills.I am Having A Great Experience Here.☺️",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Sushma Masane",
      namefirst: 'S',
      designation: "Business Analyst",
      feedback: "Good environment and you can learn  and develop your skills",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Vandana Chouhan",
      namefirst: 'V',
      designation: "Business Analyst",
      feedback: "Great Working Environment, nice to work with team Pro Web",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Muskaan Choudhary",
      namefirst: 'M',
      designation: "Business Analyst",
      feedback: "I feel grateful to join with RPA!! The environment is really great and I just joined so it's really good experience for me..all office members are so welcoming in nature and never make you feel like an outsider...along with this environment is so professional and definitely a good opportunity to learn something new every single day !",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Rishee Shrivastava",
      namefirst: 'R',
      designation: "Business Analyst",
      feedback: "I have been fortunate to be a part of Pro Web. for the past four years. They empowers employees to reach their full potential by combining interest, skills, and career trajectory with right opportunities. The environment is “work hard, relax more” where self-motivated and solution driven candidates can make a difference.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Ziyad Shaikh",
      namefirst: 'Z',
      designation: "Business Analyst",
      feedback: "I joined Pro Web in the beginning of my second year of engineering. I started as an intern and now I am working part time here. I manage college and work together and it wouldn't be possible without the support and cooperation that …",
    },
  ];



  // Team section open here

  teamTestimonials = [
    {
      img: "assets/img/team-reviews/Neeraj-Bhawsar.png",
      name: "Ivo Moravec",
      namefirst: 'I',
      feedback: "I have been using services of Pro Web since 2016. Rohit and his team always managed to deliver what was promised at reasonable costs, including aftersales service or updating of the web reflecting latest google changes. They helped me with custom design & development of my real estate website for uk market and although it started in 2016, cooperation still lasts.",
    },
    {
      img: "assets/img/team-reviews/Neelam-Manker.png",
      name: "Harvey Classic",
      namefirst: 'H',
      feedback: "Our experience with Rohit and his team has exceeded our expectations. The scope of work offered was flexible and tailored to suit our specific needs. Other IT companies said it would not be possible but Pro Web have made it work. Arshad and Manoj responded quickly to update the project based on feedback and all communication was handled seamlessly by Shrishti. We are glad to have found Pro Web and look forward to working with them on further projects with our other businesses in the future. Thank you for your dedication and commitment to the project!",
    },
    {
      img: "assets/img/team-reviews/Saurabh-Jain.png",
      name: "GTC Control Solutions",
      namefirst: 'G',
      feedback: "Rohit & the team at Pro Web have been outstanding to work with.  They are attentiveness and do a great job at communicating.  They resolve every issue that we approach them with and have helped us get online and running faster.  We will continue to work with them!",
    },
    {
      img: "assets/img/team-reviews/Appeaksha-Awatramani.png",
      name: "Aditya Jagtap",
      namefirst: 'A',
      feedback: "Had a great experience working with Rohit Sadani and his team. They were very responsive and did a great job with our company website. They were also open to quick changes and available when required.",
    },
    {
      img: "assets/img/team-reviews/Jayshri-Gupta.png",
      name: "Cyrus Graesslin",
      namefirst: 'C',
      feedback: "After almost a decade of failed attempts at building a respectable website for my company, Shakespeare and Co. Restaurants, I had all but given up hope. Amongst the issues I previously faced included a lethargic pace of development, not adhering to mutually agreed timelines, moderate to severe resistance to certain ideas we wanted to realize, and ultimately, failure to execute on our vision.",
    },
    {
      img: "assets/img/team-reviews/Anshul-Nema.png",
      name: "The Travel Hub ",
      namefirst: 'T',
      feedback: "I have been working with Pro Web to design and develop my travel website. It has a great deal of functionality that required a custom build. They have been extremely professional from the outset and their flexibility in being able to work solutions for complex problems has been amazing. Above all else, their level of communication is first class, I have been able to speak with Rohit and his team daily throughout the process. I cant recommend this team highly enough!",
    },
    {
      img: "assets/img/team-reviews/Ankita-Jain.png",
      name: "lana sharaiha",
      namefirst: 'L',
      feedback: "Working with Pro Web team has been a very pleasant experience! Rohit, Ravi, Aakansha, Megha and every person I worked with at Pro Web was knowledgeable, creative, prompt and personable. Their dedication and passion for what they do is exemplified by their unrivaled customer support and attentiveness to the specific needs to our business. We look forward to a long lasting business relationship with the team at Pro Web.",
    },
    {
      img: "assets/img/team-reviews/Neelesh-Tiwari.png",
      name: "Fiza Jabeen",
      namefirst: 'F',
      feedback: "We have worked with pro web for designing our company profile and logo. Our experience with the team has been very pleasant. Pro web team is very prompt and adaptive at the same time. Respects time lines and understands the brand needs.",
    },
    {
      img: "assets/img/team-reviews/Shruti-Shrivastava.png",
      name: "Cherie Ellis",
      namefirst: 'C',
      feedback: "I have been working with Rohit & his team for the past six weeks, their dedication, understanding & easy revisions have made building my companies website a joy.",
    },
    {
      img: "assets/img/team-reviews/Hitesh-Chaurasia.png",
      name: "Girish Gullapudi",
      namefirst: 'G',
      feedback: "Have worked with Proweb team on couple of projects for Balance Spa, Nandos KSA that are part of Landmark Hospitality group.",
    },
    {
      img: "assets/img/team-reviews/Varsha-Suryawanshi.png",
      name: "Talib Sadiq Janwari",
      namefirst: 'T',
      feedback: "We have now had 2 sites built with Unisys and can confirm that the team is extremely efficient, always available and make the process to building a site considerably stress free when comparing to past developers we have engaged with. We highly recommend to use their services, we genuinely cannot point at any dissatisfaction.",
    },
    {
      img: "assets/img/team-reviews/Ankit-Richhariya.png",
      name: "Joy Coco",
      namefirst: 'J',
      feedback: "Pro Web team is proactive and result-focused. They were dedicated throughout the web development, and rendered top-notch solution to our company. I am glad that I chose Rohit & Alex for our Digital Marketing & Website Support.",
    },
    {
      img: "assets/img/team-reviews/Anamika-Sethi.png",
      name: "Rushikesh patil",
      namefirst: 'R',
      feedback: "Rohit & Alex are awesome!! We were little apprehensive to shift to them from our existing shopify agency but seeing their passion & proactive approach in the proposal stage helped us in making that shift decision to hire them...we have been working with them from last 8months on our shopify store and they are by far the best agency and we are very happy to have them as our technical partners…thank you guys!!",
    },
    {
      img: "assets/img/team-reviews/Pranati-Khare.png",
      name: "Addnan Anwar",
      namefirst: 'A',
      feedback: "Amazing company to deal with had a great experience working with Rohit. They fully understood our requirements and delivered and amazing website. We are looking forward to working on more projects and getting the same high quality of service.",
    },
    {
      img: "assets/img/team-reviews/Gurpreet-Walia.png",
      name: "Ghady FELFLEH",
      namefirst: 'G',
      feedback: "My overall experience is good. Rohit specifically is nice person to work with. He was replying to my queries at any time of the day even on weekends. the team was also knowledgeable and could assist in all technical requirements.",
    },

  ];


  // Vacnacy block open here

  vacblocks = [
    {
      status: "apply-inactive",
      title: "Wordpress Developer",
      ctc: "1.3 to 2.3 Lacs",
      experience: "Minimum 1 Year",
      skills: "Wordpress, WooCommerce, PHP, Mysql, Jquery, Ajax",
    },
    {
      status: "apply-inactive",
      title: "UI/UX Designer",
      ctc: "1.5 to 2.5 Lac",
      experience: "Minimum 1-2 Year",
      skills: "Protoyping, UX Research, Adobe XD, Photoshop, Illustrator, Sketch, Invision Studio"
    },
    {
      status: "apply-inactive",
      title: "UI/Front-End Developer",
      ctc: "1.5 to 2.5 Lac",
      experience: "Minimum 1-2 Year",
      skills: "Angular, React, jQuery, JavaScript, CSS, SCSS, Git, Cross-Browser Development"
    },
    {
      status: "apply-inactive",
      title: "Content Writer",
      ctc: "1.5 to 2.0 Lac",
      experience: "Minimum 0-1 Year",
      skills: "Excellent Writing Skills, Web Content, SEO Content and SMO content, UX Writing",
    },
    {
      status: "apply-inactive",
      title: "PHP Developer",
      ctc: "1.2 to 2.3 Lac",
      experience: "Minimum 1.5-2 Year",
      skills: "PHP7, Mysqli, Ajax, jQuery, Javascript framework, CMS, Laravel, Git",
    },
    {
      status: "apply-active",
      title: "Ecommerce Developer",
      ctc: "1.3 to 2.3 Lac",
      experience: "Minimum 1.5 - 2 Year",
      skills: "Opencart, Shopify, Prestashop, Magento, PHP7, Mysqli, Ajax, jQuery",
    },
    {
      status: "apply-inactive",
      title: "Graphic Designer",
      ctc: "1.0 to 2.0 Lac",
      experience: "Minimum 0 - 1 Year",
      skills: "Adobe Photoshop, Adobe Illustrator, CorelDraw, Adobe InDesign, Sketch",
    },
    {
      status: "apply-inactive",
      title: "Web/App Tester",
      ctc: "1.0 to 2.0 Lac",
      experience: "Minimum 1-1.5 Year",
      skills: "Manual Testing, Automation Testing, Knowledge about Ecommerce, SDLC knowledge",
    },
    {
      status: "apply-active",
      title: "Hybrid App Developer",
      ctc: "2.5 to 3.5 Lac",
      experience: "Minimum 1.5-2 Year",
      skills: "Ionic, React Native, Flutter, Angular, Node, SCSS, NPM, Git, Firebase, API Integration",
    },
    {
      status: "apply-inactive",
      title: "Business Development Executive",
      ctc: "1.5 to 2.5 Lac",
      experience: "Minimum  0-1  Year",
      skills: "Convincing and marketing skills, Idea of techniques for business growth, Technical proposal, Financial Proposal, Scope of work",
    },
    {
      status: "apply-active",
      title: "Customer Support Executive",
      ctc: "1.0 to 1.5 Lac",
      experience: "Minimum 0-1 Year",
      skills: "Good Communication Skills, Cool-tempered, Understand customer requirements, Mail drafting, Computer efficiency",
    },
    {
      status: "apply-inactive",
      title: "SEO Executive ( Off Page )",
      ctc: "1.0 to 1.8 Lac",
      experience: "Minimum 0-1 Year",
      skills: "Blogging, Planning & strategizing, Business listing, Keywords ranking, Audits, Back linking",
    },
    {
      status: "apply-inactive",
      title: "SEO Executive ( On page )",
      ctc: "1.0 to 1.8 Lac",
      experience: "Minimum 0-1 Year",
      skills: "Technical SEO, Website audit, Semantic SEO, Goggle webmaster tools, Tracking website performance",
    },
    {
      status: "apply-inactive",
      title: "Google Adwords Executive",
      ctc: "1.0 to 1.8 Lac",
      experience: "Minimum 0-1 Year",
      skills: "Analytical, Analyze current PPC campaigns, Statistical Analysis, Creating Google ads campaign, Analyzing websites performance",
    },
    {
      status: "apply-inactive",
      title: "Social Media Analyst",
      ctc: "1.0 to 1.8 Lac",
      experience: "Minimum 0-1 Year",
      skills: "Content Creating Skills, Social Media Strategy & Planning, Latest SMM Trends, Creative Mindset, Creating an engaging advertisement content, Creating Ad campaigns",
    },
    {
      status: "apply-active",
      title: "Web/App Developer Intern",
      ctc: "2k to 6k(Stipend)",
      experience: "Minimum 0-3 Month",
      skills: "Basic knowledge of any back-end & front-end framework",
    },
    {
      status: "apply-inactive",
      title: "UI  Developer Intern",
      ctc: "2k to 6k(Stipend)",
      experience: "Minimum 0-3 Month",
      skills: "Basic knowledge of HTML , CSS ,JavaScript or any front-end framework",
    },
    {
      status: "apply-inactive",
      title: "Digital Marketing Intern",
      ctc: "2k to 6k(Stipend)",
      experience: "Minimum 0-3 Month",
      skills: "Basic knowledge of SEO(Off-Page & On-Page), SMO, PPC",
    }
    // {
    //   status: "apply-active",
    //   title: "",
    //   ctc: "",
    //   experience: "",
    //   skills: "",
    // }

  ];

  // Vacnacy block close here

  selectjob(job: any, el: HTMLElement) {
    // this.careerForm.controls.interested_in=job;
    this.careerForm.controls.interested_in.setValue(job);

    el.scrollIntoView({ behavior: "smooth" });
  }
}