import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-steps-choose',
  templateUrl: './steps-choose.component.html',
  styleUrls: ['./steps-choose.component.css'],
})
export class StepsChooseComponent {
  @Input() heading: any;
  @Input() description: any;
  @Input() steps: any;
}
