import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-web-hosting-service',
  templateUrl: './web-hosting-service.component.html',
  styleUrls: ['./web-hosting-service.component.css'],
})
export class WebHostingServiceComponent {
  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle('Web Hosting Company in Dubai | Pro Web');
    this.metaTagService.updateTag({
      name: 'description',
      content:
        "Pro Web sets the standard for web hosting in Dubai, offering reliable services with high performance, 99% uptime, and expert support. Elevate your website's effectiveness through our web hosting solutions.",
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: 'web hosting servies, web hosting services in dubai',
    });
  }

  faq = [
    {
      title: 'What is web hosting and how does it work?',
      des: "<p> Web hosting is a service that stores website files on a server, making it accessible on the internet. When users enter a domain, the hosting server delivers the site's content to their browsers, facilitating online presence and interaction.</p>",
      isActive: false,
    },
    {
      title: 'What is a dedicated server and what are the advantages of it ?',
      des: '<p>A dedicated server is an exclusive machine used solely by one user, offering full access to hardware resources. Its advantages include optimal performance, complete software control, enhanced security, and tailored server protection measures.</p>',
      isActive: false,
    },
    {
      title: 'What are the most common applications for dedicated server ?',
      des: '<p> Common applications for dedicated servers include website hosting, database management, email hosting, application hosting, gaming servers, and large-scale data storage and processing.</p>',
      isActive: false,
    },
    {
      title: 'How do I choose the right web hosting plan for my needs?',
      des: '<p>We are committed to guiding you through the process of selecting the perfect web hosting plan. We assess your website requirements, including traffic volume, performance needs, budget, and technical expertise. Based on this information, we recommend the most suitable hosting plan that meets your specific needs.</p>',
      isActive: false,
    },
    {
      title: 'How long does it take to setup a website on dedicated server ?',
      des: '<p>The time required to configure a dedicated server typically ranges from 3 to 7 days, depending on the complexity of the website and database. In some cases, it can be completed within 24 hours. This timeframe can be analyzed after reviewing the code structure and tech stack used to develop the website or application.</p>',
      isActive: false,
    },
    {
      title:
        'Can I host multiple domains or websites on a single hosting account?',
      des: '<p> Yes, many of our hosting plans allow you to host multiple domains or websites on a single account. We can help you set up additional domains and manage them efficiently. </p>',
      isActive: false,
    },
    {
      title: 'Can I upgrade or downgrade my hosting plan as my website grows?',
      des: '<p>Yes, we offer flexibility to upgrade or downgrade your hosting plan based on your evolving needs. Whether your website experiences increased traffic or requires additional resources, we ensure seamless scalability to accommodate growth.</p>',
      isActive: false,
    },
    {
      title: 'What uptime guarantee do you provide?',
      des: '<p>We guarantee a high uptime percentage of 99.9%, ensuring your website remains accessible to visitors at all times. Our reliable infrastructure and proactive monitoring minimize downtime and ensure optimal performance.</p>',
      isActive: false,
    },
    {
      title:
        'What additional features or services do you offer with your hosting plans?',
      des: '<p>In addition to basic hosting services, we offer various value-added features such as domain registration, website builders, email hosting, SSL certificates, marketing tools, and e-commerce solutions to enhance your online presence.</p>',
      isActive: false,
    },
    {
      title:
        'What level of customer support do you offer, and how can I contact you for assistance? ',
      des: '<p>We offer dedicated customer support via email, live chat, and phone. Our team is here to assist you with any technical issues or questions you may have regarding your hosting service.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }

  logo = [
    '/assets/img/servicesdev/web-hosting/industrial_control_care.webp',
    '/assets/img/servicesdev/web-hosting/life_smoothies_logo.webp',
    '/assets/img/servicesdev/web-hosting/avea_contracting_logo.svg',
    '/assets/img/servicesdev/ecom-dev/babboe_logo.webp',
  ];

  logo2 = [
    '/assets/img/servicesdev/web-hosting/industrial_control_care.webp',
    '/assets/img/servicesdev/web-hosting/life_smoothies_logo.webp',
    '/assets/img/servicesdev/web-hosting/avea_contracting_logo.svg',
  ];

  cardsdata = [
    {
      cardimg: 'cloud-hosting/fast-loading.png',
      cardheading: 'Fast loading websites anywhere',
      cardspara:
        "Our global network ensures your website loads quickly for users no matter where they are. By edge-caching full pages close to the user's location, we significantly reduce latency and enhance user experience. Our CDN seamlessly integrates with our StackCache WordPress module—no additional plugins needed—as well as any other software that sets public cache control headers. This means it works effortlessly with any website platform.",
    },
    {
      cardimg: 'cloud-hosting/web-giants.png',
      cardheading: 'The same network as the web giants',
      cardspara:
        'Our CDN utilizes the same premier internet exchange points (neutral data centers) as industry leaders like AWS, Google Cloud, and Microsoft Azure. This ensures that your business or project benefits from top-tier, enterprise-level connectivity and performance, regardless of your size or industry. By leveraging these high-quality infrastructure components, we ensure your website remains fast, reliable, and resilient.',
    },
    {
      cardimg: 'cloud-hosting/reliability.png',
      cardheading: 'Reliability and uptime',
      cardspara:
        "Boost the reliability and uptime of your website, no matter where your users are located. By serving your site from data centers close to your users, we minimize network 'hops'. This means the internet service provider only needs to route the user to our nearest Point of Presence (POP), reducing potential issues and ensuring a smoother, more reliable connection. This results in a consistently better user experience.",
    },
  ];

  taglinearr = [
    'Free SSL Included  ',
    '99.9% Uptime Guarantee',
    'Dedicated Support Available',
    'SSD Drives Included',
    '99.9% Uptime Guarantee',
    'Dedicated Support Available',
    'Free SSL Included  ',
    '99.9% Uptime Guarantee',
    'Dedicated Support Available',
    'SSD Drives Included',
    '99.9% Uptime Guarantee',
    'Dedicated Support Available',
  ];

  cardsItems = [
    {
      title: 'Dedicated Servers',
      desc: "Supercharge your digital agency or eCommerce venture with our fully managed dedicated server hosting. Built on the cutting-edge architecture, our services offer hassle-free server management, allowing you to focus on website development and online store management. Experience peace of mind as we handle routine maintenance, robust security measures, and proactive issue resolution, enabling you to scale confidently and stand out in today's competitive market. Elevate your digital presence with our dedicated hosting.",
      img: 'assets/img/servicesdev/globe.webp',
      btnname: 'Explore Dedicated Hosting Packages',
      btnurl: '/web-hosting-packages',
    },
    {
      title: 'Cloud Based Servers',
      desc: "Empower your digital agency or ecommerce venture with our fully managed cloud hosting. Focus on developing client websites and managing your online store, while our robust hosting, based on leading cloud providers' architecture, streamlines website and email management with 24/7/365 support. Sit back and relax as our dedicated engineers ensure top-notch server protection, optimization, and vigilant monitoring, allowing you to scale with confidence and efficiency in the dynamic digital landscape.",
      img: 'assets/img/servicesdev/globe.webp',
      btnname: 'Explore more About Cloud Hosting',
      btnurl: '/cloud-hosting-company/dubai',
    },
  ];

  dataItems = [
    {
      heading: ' Free SSL Certificate',
      description:
        'Pro Web is commited to prioritizing the security of your website. Upgrade to a secure online environment effortlessly with our free SSL certificate ensuring peace of mind for you and trust from your visitors.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Free Site Migration',
      description:
        'Pro Web offers a seamless and complimentary website migration to our top-tier server, ensuring a hassle-free transition with zero data loss. Make the switch effortlessly and experience the quality service.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Weekly Backups',
      description:
        'Elevate your peace of mind with Pro Web, your trusted Dubai-based web hosting partner. Our intelligent features include data restoration and weekly backups without any  constraints.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Custom Firewall',
      description:
        'Our all-in-one solution uses smart technology to detect and halt attacks, and ensures information security through robust encryption, acting as a powerful shield for businesses to operate safely online.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'CPanel Mastery',
      description:
        'Effortlessly oversee and expand your website portfolio using cPanel, featuring user-friendly controls and one-click installations for a wide array of popular web applications and frameworks.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Easy to Use',
      description:
        'Effortlessly oversee and expand your website portfolio using cPanel, featuring user-friendly controls and one-click installations for a wide array of popular web applications and frameworks.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Prioritized Local Support',
      description:
        'Embark on your online journey with confidence as Pro Web is technical experts offer dedicated support at every step. Experience seamless assistance with our on-call support, ensuring reliable help whenever you need it.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Bandwidth Brilliance',
      description:
        'Revitalize your website is performance with advanced bandwidth solutions. From increased efficiency to enhanced user experiences, Pro Web is bandwidth offerings are designed to propel your website is capabilities forward.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Disk Space Upgrade',
      description:
        'If your disk space is running low, simply get in touch with the company to arrange an upgrade that aligns with your specific requirements, ensuring your hosting resources are adeptly tailored to the evolving demands.',
      bgimg: 'hosting-card.png',
    },
  ];

  cards = [
    {
      icon: '../../assets/img/servicesdev/flag.png',
      heading: 'Superior Performance',
      description:
        'Optimize resources for unparalleled performance and reliability with a dedicated server, providing exclusive control and surpassing shared hosting or virtual private servers in consistent and dependable operation.',
    },

    {
      icon: '../../assets/img/servicesdev/notebook.png',
      heading: 'Cost-Effective',
      description:
        ' While dedicated servers may have a higher upfront cost than shared hosting or VPS, they often prove more cost-effective in the long run, especially for high-traffic or resource-intensive applications.',
    },

    {
      icon: '../../assets/img/servicesdev/lock.png',
      heading: 'Expert Support',
      description:
        "Rely on Pro Web's seasoned technical support for seamless server operation. Our expert support team is readily available to address any issues that may arise, ensuring uninterrupted functionality and responsive assistance.",
    },

    {
      icon: '../../assets/img/servicesdev/search.png',
      heading: 'Scalability',
      description:
        'Navigate your growing business landscape effortlessly with the scalability and reliability of a dedicated server. Easily adjust resources in real-time to meet changing demands, ensuring your server adapts seamlessly to the evolving needs of your expanding enterprise.',
    },

    {
      icon: '../../assets/img/servicesdev/hands.png',
      heading: 'Customization Options',
      description:
        'Leverage the extensive flexibility of dedicated servers, allowing seamless customization to align with your distinct needs. Install tailored software, fine-tune security settings, and adjust resource allocation as required to create a server environment precisely tailored to your specifications.',
    },

    {
      icon: '../../assets/img/servicesdev/setting.png',
      heading: 'Managed Services',
      description:
        'Opt for a dedicated server with Pro Web, where all server administration tasks, from software updates and security patches to backups, are expertly handled. This service not only saves you time and effort but guarantees that your server remains consistently up-to-date and secure.',
    },
  ];

  iconsdata = [
    {
      icon: 'assets/img/servicesdev/grad-one.png',
      name: 'Host In Dubai, UAE Data Center',
    },
    { icon: 'assets/img/servicesdev/grad-two.png', name: '20x Faster Speed' },
    {
      icon: 'assets/img/servicesdev/grad-three.png',
      name: 'Dedicated Support',
    },
    {
      icon: 'assets/img/servicesdev/firewall-icon.png',
      name: 'Firewall Protection',
    },
    {
      icon: 'assets/img/servicesdev/country-data.png',
      name: 'In Country Data Compliance',
    },
  ];

  data = [
    'ICC.png',
    'avea.png',
    'life-smoothy.png',
    'aashima.png',
    'consultancy-solution.png',
  ];

  datamobile = [
    'ICC.png',
    'avea.png',
    'aashima.png',
    'consultancy-solution.png',
  ];

  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
}
