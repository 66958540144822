<app-servicebanner
  heading="Unlock Sales Potential with Ecommerce Development Mastery"
  paragraph="Pro Web stands out as a leading E-Commerce development expert. Our top-tier web agency specializes in creating tailored solutions, ensuring a seamless and distinctive user experience across all devices."
  bannerimg="assets/img/servicesdev/ecombg.webp"
  mobileImg="E-Commerce-image.webp"
  [cards]="logo"
  [cards2]="logo2"
  [tagline]="taglinearr"
></app-servicebanner>

<!-- breadcrumbs -->

<nav aria-label="breadcrumb" class="ps-2 ps-md-5 d-none d-md-block">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/">Home</a></li>
    <li class="breadcrumb-item"><a href="/">Services</a></li>
    <li class="breadcrumb-item active" aria-current="page">
      E-Commerce Development
    </li>
  </ol>
</nav>

<div #scrollTarget>
  <app-technology-stack
    firsttitle="Diverse Ecommerce Options"
    semititle="to Fit Your Business Goals"
    description="To cater to the diverse needs of businesses in Dubai, Pro Web offers a variety of top E-Commerce platforms, including Magento, WooCommerce, and Shopify. Each platform boasts its own unique features and benefits, allowing you to choose the one that best aligns with your specific requirements and goals.
Whether you’re a small business looking to establish an online presence or a large enterprise seeking a robust and scalable E-Commerce solution, Pro Web has the expertise and resources to bring your vision to life."
    [cards]="cardsItems"
  ></app-technology-stack>
</div>

<!-- the proweb way section -->
<section class="spacing">
  <div class="proweb-padding">
    <div class="row justify-content-center processWrap">
      <h2 class="my-3">The <span>Pro Web Way</span></h2>

      <div class="d-none d-md-flex p-3">
        <div class="row px-5">
          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/analysis-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />
                  <img
                    src="../../assets/img/servicesdev/process-way/analysis-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Analysis and Planning</h5>
                <ul>
                  <li>Research on market trends and target audiences</li>
                  <li>Precise planning for required technologies & tools</li>
                  <li>Definition of the website layout & modules</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/design-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/design-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Design & Development</h5>
                <ul>
                  <li>
                    Wireframes & UI creation, based on client requirements
                  </li>
                  <li>Online market trends guide development</li>
                  <li>Focus on exceeding customer expectations</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/quality-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/quality-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Quality Analysis</h5>
                <ul>
                  <li>Continuous testing by a dedicated QA team</li>
                  <li>Guarantee of error-free applications</li>
                  <li>Ensure reliability and user-friendliness</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/deploy-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/deploy-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Deployment</h5>
                <ul>
                  <li>Launch after resolving technical issues</li>
                  <li>Transition from development to live environment</li>
                  <li>Active monitoring and optimization</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-block d-md-none my-4">
        <ngx-slick-carousel
          class="carousel owl-carousel branflex processSlider"
          [config]="carouselConfig"
        >
          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/analysis-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Analysis and Planning</h5>
              <ul>
                <li>Research on market trends and target audiences</li>
                <li>Precise planning for required technologies & tools</li>
                <li>Definition of the website layout & modules</li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/design-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Design & Development</h5>
              <ul>
                <li>Wireframes & UI creation, based on client requirements</li>
                <li>Online market trends guide development</li>
                <li>Focus on exceeding customer expectations</li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/quality-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Quality Analysis</h5>
              <ul>
                <li>Continuous testing by a dedicated QA team</li>
                <li>Guarantee of error-free applications</li>
                <li>Ensure reliability and user-friendliness</li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/deploy-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Deployment</h5>
              <ul>
                <li>Launch after resolving technical issues</li>
                <li>Transition from development to live environment</li>
                <li>Active monitoring and optimization</li>
              </ul>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</section>

<app-engagement-modal></app-engagement-modal>

<app-portfolio
  firsttitle="E-Commerce"
  semititle="Portfolio(Theme Based)"
  desc="Explore our project portfolio - a showcase of innovation and excellence"
  [portfolio]="portfolioItems"
></app-portfolio>

<app-portfolio
  firsttitle="E-Commerce"
  semititle="Portfolio(Custom)"
  desc="Explore our project portfolio - a showcase of innovation and excellence"
  [portfolio]="customportfolioItems"
></app-portfolio>

<app-customized-solutions [htmlStr]="ecomStr"></app-customized-solutions>

<app-testimonial-service
  profile="Saif-A-Khan.webp"
  clientname="Mr. Saif A Khan"
  designation="Digital Retail and Customer Applications Manager - Audi Volkswagen Middle East"
  location="UAE"
  flag="uk-flag.svg"
  clientcomment="Rohit has been very hands on and proactive with stake holder management, he is very approachable and always ensures clarity in user’s requirements. Rohit and his team has been supporting us in our pilot projects using Shopify platform and have delivered some amazing results."
  rating="Rated 4.9 out of 5.0"
  [clientimg]="data"
  [clientmobile]="datamobile"
  clienttarget="audi_logo.webp"
></app-testimonial-service>

<app-why-choose-proweb
  heading="Why Choose Pro Web For E-Commerce Development ?"
  desc="We excel in E-Commerce Development, delivering top-tier solutions with expertise and reliability."
  [obj]="cards"
></app-why-choose-proweb>

<app-check-out
  checkoutImg="../../assets/img/servicesdev/checkout_mainimg.png"
  alt="check out"
  checkoutText="Check Out Our"
  checkoutName="Web Maintenance Packages"
  checkoutUrl="/website-maintenance-packages"
></app-check-out>

<app-bottom-bar
  text="Let’s Work Together to Build Your Dream E-Commerce Website!"
></app-bottom-bar>

<section class="spacing">
  <div class="container-md">
    <div class="row justify-content-center justify-content-md-start">
      <h2>Frequently <span>Asked Questions </span></h2>
      <p class="my-3">
        Our industry expertise and research capabilities drive our leadership in
        developing advanced engagement models.
      </p>

      <div class="row my-4">
        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(0, 4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i + 4)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
