<section class="spacing">
  <div class="container">
    <h2 class="head">
      {{ heading }}
      <img
        *ngIf="currentRoute == '/web-hosting-company/dubai'"
        src="/assets/img/servicesdev/hearticonhome.svg"
        width="40px"
        alt=""
        srcset=""
      />
      <span> {{ subheading }}</span>
    </h2>
    <p class="my-3" *ngIf="currentRoute == '/hire-dedicated-developers/dubai'">
      Revolutionize your software development process with Pro Web's unique
      approach to hiring dedicated developers. Tap into our pool of skilled
      individuals for your project needs. Embrace outsourcing to maximize cost
      savings and entrust us with essential tasks. Choose from our selection of
      talented developers, tailored to your specific requirements. Whether you
      need an independent developer or extra re sources, our Dedicated Developer
      Hiring service ensures your projects receive expert attention. At Pro Web,
      we specialize in matching you with the right individual to drive your
      business success. Gain more than just a developer – gain a committed
      partner for your project's triumph.
    </p>

    <div class="features-wrap">
      <div class="row gy-3 gy-md-5">
        <div class="col-md-4" *ngFor="let item of data">
          <div class="card">
            <img
              src="assets/img/servicesdev/{{ item.bgimg }}"
              alt=""
              srcset=""
            />
            <div class="content">
              <h6>
                <img
                  src="assets/img/servicesdev/diomond-icon.svg"
                  *ngIf="
                    currentRoute == '/web-hosting-company/dubai' ||
                    currentRoute == '/cloud-hosting-company/dubai' ||
                    currentRoute == '/machine-learning/dubai' ||
                    currentRoute == '/ai-development/dubai'
                  "
                  class="diamond-icon"
                  width="30"
                  alt=""
                  srcset=""
                />
                {{ item.heading }}
              </h6>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
