<footer *ngIf="!hideFooter" class="footer-sec">
    <img src="assets/img/webp/homepage/proweb-globe-icon.webp" alt="pro web" class="proweb-globe" />
    <!-- <img src="assets/img/webp/homepage/skyline-image.webp" alt="skyline" class="skyline-image" /> -->
    <div class="footer-grad-bg">
        <div class="container-md">
            <section class="footer-partner-sec">
                <div class="container">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <h4 class="partner-slidehead">Our Valuable Partners</h4>
                            <section class="team-area ptb-30">
                                <div class="container">
                                    <div class="row m-0">
                                        <ngx-slick-carousel class="carousel owl-carousel  branflex"
                                            [config]="carouselConfig">
                                            <div ngxSlickItem *ngFor="let partner of partners"
                                                class="slider-inlinefixe">
                                                <div class="industries-image">
                                                    <img src="{{partner.Img}}" width="" height="" alt={{partner.Img}}>
                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <div class="row">
                <div class="col-md-12">
                    <div class="formbot-row">
                        <div class="formbot-left">
                            <div class="row px-0">
                                <div class="col-md-5">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Pro Web </h4>
                                        <ul class="packageList">
                                            <li>
                                                <span class="servicename"> <a routerLink="/about-us" routerLinkActive="active" class="nav-link"> About Us </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/portfolio" routerLinkActive="active" class="nav-link"> Our Work </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/case-study" routerLinkActive="active" class="nav-link"> Case Studies</a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/careers" routerLinkActive="active" class="nav-link"> Career </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/blog" routerLinkActive="active" class="nav-link"> Blog </a></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- dekstop location -->
                                <div class="col-md-7 desktoplocation" *ngIf="isDesktop">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Location </h4>
                                        <div style="line-height: 30px;">
                                            <img src="assets/img/locationoffice.svg" style="filter: invert(1);" alt="uae-flag">
                                            <span class="address">
                                                Office #1606, SIT Tower,
                                                DSO,
                                                Dubai, UAE
                                            </span>
                                        </div>
                                        <div style="line-height: 30px;">
                                            <a href="https://api.whatsapp.com/send/?phone=971527732125&text&type=phone_number&app_absent=0" target="_blank" class="text-white">
                                                <img src="assets/img/whatsupicon.svg" width="20" alt="call">
                                                +971 52 773 2125
                                                <!-- +971 5265 49225 , +971 5280 50084 -->
                                            </a>
                                            <!-- <a href="tel:+971528050084" class="text-white">
                                                <img src="assets/img/footermobileicon.svg" style="filter: invert(1);"
                                                    alt="call">
                                                +971 5280 50084
                                            </a> -->
                                           
                                        </div>
                                        <div style="line-height: 30px;">
                                            <a href="mailto:info@prowebtechnos.com" class="text-white">
                                                <img src="assets/img/Emailicon.svg" alt="email" width="20">
                                                info&#64;prowebtechnos.com
                                            </a>
                                        </div>
                                        <div style="line-height: 30px;">
                                            <a href="mailto:contact@prowebtechnos.com" class="text-white">
                                                <img src="assets/img/Emailicon.svg" alt="email" width="20">
                                                contact&#64;prowebtechnos.com
                                            </a>
                                        </div>
                                        <div style="line-height: 30px;">
                                            <span href="" class="text-white">
                                                <img src="assets/img/skype_logo1.svg" alt="skype" width="18">
                                                prowebtechnos
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- mobile package -->
                                <div class="col-md-5 mobilepackage" *ngIf="isMobile">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Packages </h4>
                                        <ul class="packageList">
                                            <li>
                                                <span class="servicename"> <a routerLink="/seo-packages"
                                                        routerLinkActive="active" class="nav-link"> SEO Packages
                                                    </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/ppc-packages"
                                                        routerLinkActive="active" class="nav-link"> PPC Packages
                                                    </a></span>
                                            </li>

                                            <li><span class="servicename"><a routerLink="/smm-packages"
                                                        routerLinkActive="active" class="nav-link"> SMM
                                                        Packages </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/website-maintenance-packages"
                                                        routerLinkActive="active" class="nav-link"> Web Maintenance
                                                        Packages </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/web-hosting-packages"
                                                        routerLinkActive="active" class="nav-link"> Web Hosting Packages
                                                    </a></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-4">
                                <!-- desktop package -->
                                <div class="col-md-5 desktoppackage" *ngIf="isDesktop">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Packages </h4>
                                        <ul class="packageList">
                                            <li>
                                                <span class="servicename"> <a routerLink="/seo-packages"
                                                        routerLinkActive="active" class="nav-link"> SEO Packages
                                                    </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/ppc-packages"
                                                        routerLinkActive="active" class="nav-link"> PPC Packages
                                                    </a></span>
                                            </li>

                                            <li><span class="servicename"><a routerLink="/smm-packages"
                                                        routerLinkActive="active" class="nav-link"> SMM
                                                        Packages </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/website-maintenance-packages"
                                                        routerLinkActive="active" class="nav-link"> Web Maintenance
                                                        Packages </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/web-hosting-packages"
                                                        routerLinkActive="active" class="nav-link"> Web Hosting Packages
                                                    </a></span>
                                            </li>
                                        </ul>
                                    </div>


                                    <h4 class="service_location mt-0 mt-md-4">
                                        Connect With Us
                                    </h4>


                                    <div class="foo-social-bar mt-4">
                                        <div class="">
                                            <ul class="mb-3">
                                                <li class="me-1 fbhover">
                                                    <a href="https://www.facebook.com/proweb.ae/" class="foo-fb"
                                                        target="_blank">
                                                         <img src="assets/img/webp/footer/facebook.webp" alt="" srcset="">
                                                    </a>
                                                </li>
                                                <li class="me-1 instahover">
                                                    <a href="https://www.instagram.com/proweb.ae/" class="foo-insta"
                                                        target="_blank">
                                                        <img src="assets/img/webp/footer/instagram.webp" alt="" srcset="">
                                                    </a>
                                                </li>
                                                <li class="me-1 linkedin">
                                                    <a href="https://www.linkedin.com/company/prowebdubai/?viewAsMember=true"
                                                        class="foo-linkedin" target="_blank">
                                                        <img src="assets/img/webp/footer/linkedin.webp" alt="" srcset="">
                                                    </a>
                                                </li>

                                                <li class="me-1 blackhover">
                                                    <a href="https://twitter.com/proweb_uae" target="_blank">
                                                        <img src="assets/img/webp/footer/new_twitter.webp"
                                                            alt="" srcset="">
                                                    </a>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>


                                </div>

                                <!-- mobile location -->
                                <div class="col-md-7 mobileplocation" *ngIf="isMobile">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Location </h4>
                                        <div style="line-height: 30px;">
                                            <img src="assets/img/locationoffice.svg" style="filter: invert(1);"
                                                alt="uae-flag">
                                            <span class="address">
                                                Office #1606, SIT Tower,
                                                DSO,
                                                Dubai, UAE
                                            </span>
                                        </div>
                                        <div style="line-height: 30px;">
                                            <a href="tel:+971528050084" class="text-white">
                                                <img src="assets/img/footermobileicon.svg" style="filter: invert(1);"
                                                    alt="call">
                                                +971 5280 50084
                                            </a> 
                                            <br>
                                            <a href="https://wa.me/971526549225" target="_blank" class="text-white ms-1 md:ms-2">
                                                <img src="assets/img/whatsupicon.svg" width="18" alt="call" >
                                                <!-- +971 5265 49225 -->
                                                +971 5265 49225 , +971 5280 50084
                                            </a>
                                        </div>
                                        <div style="line-height: 30px;  margin-left: 3px;">
                                            <a href="mailto:info@prowebtechnos.com" class="text-white">
                                                <img src="assets/img/Emailicon.svg" alt="email" width="20">
                                                info&#64;prowebtechnos.com
                                            </a>
                                        </div>
                                        <div style="line-height: 30px;  margin-left: 3px;">
                                            <a href="mailto:contact@prowebtechnos.com" class="text-white">
                                                <img src="assets/img/Emailicon.svg" alt="email" width="20">
                                                contact&#64;prowebtechnos.com
                                            </a>
                                        </div>
                                        <div style="line-height: 30px;  margin-left: 3px;">
                                            <span href="" class="text-white">
                                                <img src="assets/img/skype_logo1.svg" alt="skype" width="15">
                                                prowebtechnos
                                            </span>
                                        </div>
                                    </div>

                                    <h4 class="service_location mt-4">
                                        Connect With Us
                                    </h4>


                                    <div class="foo-social-bar mt-4">
                                        <div class="">                                                                                                                                  
                                            <ul class="mb-3">
                                                <li>
                                                    <a href="https://www.facebook.com/proweb.ae/" aria-label="fb" class="foo-fb"
                                                        target="_blank">
                                                        <img src="assets/img/webp/footer/facebook.webp" alt="" srcset="">                                                    
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/proweb.ae/" aria-label="insta" class="foo-insta"
                                                        target="_blank">
                                                        <img src="assets/img/webp/footer/instagram.webp" alt="" srcset="">
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/prowebdubai/?viewAsMember=true" aria-label="linkedin"
                                                        class="foo-linkedin" target="_blank">
                                                        <img src="assets/img/webp/footer/linkedin.webp" alt="" srcset="">
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="https://twitter.com/proweb_uae" aria-label="twitter" target="_blank">
                                                        <img src="assets/img/webp/footer/new_twitter.webp"
                                                            alt="" srcset="">
                                                    </a>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-md-7">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Find Us On Google Map </h4>
                                        <div>
                                            <iframe class="map-wrapper" title="google_map"
                                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14450.111666476441!2d55.39063!3d25.117837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f58297ffde587%3A0x557dd64882fcdefd!2sPro%20Web!5e0!3m2!1sen!2sin!4v1699275516159!5m2!1sen!2sin"
                                                width="380" height="260" style="border:0;" allowfullscreen=""
                                                loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                                                style="border-radius: 10px; overflow: hidden;"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-md-flex justify-content-between formwrap main">

                            <div class="form">
                                <div class="formhead">
                                    <div class="mt-2">
                                        <h4 class="form_head">Request Callback</h4>

                                        <p class="subhead mb-2">PLEASE PROVIDE YOUR DETAILS BELOW</p>
                                    </div>
                                    <app-alert></app-alert>
                                    <form [formGroup]="footerForm" (ngSubmit)="onSubmit($event)" #footerFormNew="ngForm"
                                        class="foocon-form mb-4" enctype="multipart/form-data" id="footerForm">
                                        <div class="formLoader" *ngIf="showLoader">Please Wait...</div>
                                        <div class="mb-2">
                                            <input type="text" class="inpstyle" placeholder="Name*" name="name"
                                                formControlName="name"
                                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">

                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                <div *ngIf="f.name.errors.required">Please, enter your name</div>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-2 countrycontact">
                                            <select name="country_code" formControlName="country_code"
                                                class="ctry-select">
                                                <option *ngFor="let country of countries"
                                                    [attr.data-countryCode]="country.country_code"
                                                    value="{{country.phone_code}}">+{{ country.phone_code }}</option>
                                            </select>
                                            <div class="contactfield">
                                                <input type="number" name="phone_number" formControlName="phone_number"
                                                    (keypress)="numberOnly($event)"
                                                    [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"
                                                    (countryChange)="countryChange($event)" class="inpstyle contactinp"
                                                    placeholder="Contact No.*">
                                                <div *ngIf="submitted && f.phone_number.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.phone_number.errors.required">Please, enter Phone
                                                        number</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <input type="email" name="email" formControlName="email"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                class="inpstyle" placeholder="Email*">

                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Please, enter a valid email</div>
                                                <div *ngIf="f.email.errors.email">Please, enter a valid email</div>
                                            </div>
                                        </div>
                                        <div>
                                            <textarea name="message" formControlName="message"
                                                [ngClass]="{ 'is-invalid': submitted && f.message.errors }" rows="5"
                                                cols="45" class="inpstyle"
                                                placeholder="I would like to discuss about..."></textarea>

                                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                                <div *ngIf="f.message.errors.required">Message is required</div>
                                            </div>
                                        </div>
                                        <button class="btn btn-primary d-block w-100 mt-3">Request Now</button>
                                    </form>


                                    <p class="foo-form-infotxt py-3"><span>We only need your e-mail address in case we
                                            can't reach you so we can still get in touch.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="made_with mb-3">
                <p>Crafted with <img src="assets/img/webp/footer/heart.webp" class="mx-1" alt="" srcset="">  in UAE and proudly serving</p>
                <ul>
                    <li class="px-2">India</li>
                    <li class="px-2">Singapore </li>
                    <li class="px-2">Canada</li>
                    <li class="px-2">USA</li>
                    <li class="px-2">Europe </li>
                    <li class="px-2">Australia</li>
                </ul>
            </div>
        </div>
        <div class="row footer-copyright">
            <div class="container mb-4 mb-md-0">
                <div class="d-md-flex px-2 justify-content-between">
                    <p class="footer-copyright my-2"> Copyright © 2024 Unisys Technologies L.L.C. All Rights Reserved.
                    </p>
                    <p class="footer-copyright my-2">
                        <a href="/privacy-policy" class="px-2 text-white">Privacy Policy </a>
                        |
                        <a href="/terms-and-conditions" class="px-2 text-white"> Terms & Conditions </a>
                        |
                        <a href="/sitemap" class="px-2 text-white">Sitemap</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="go-top"></div>
<footer class="sticky-bottom p-2 d-lg-none " >
    <div class="sticky-bottom " style="display: flex; justify-content: flex-end; flex-direction: column; align-items: end; padding-bottom: 18px;">
        <div class="d-flex justify-content-center align-middle rounded-circle "  style="background-color: #2D6FA6; width: fit-content; height:auto; padding: 13px; margin-bottom: 8px;">
             <a target="_blank" aria-label="telephone" href="tel:+971527732125">
                <img src="assets/img/webp/homepage/telephone-header.webp" alt="telephone" srcset="" style="width: 20px; height:20px;" />
            </a>
        </div>
        <!-- +971 5280 50084 -->
        <a target="_blank" aria-label="telephone" href="https://api.whatsapp.com/send/?phone=971527732125&text&type=phone_number&app_absent=0">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 44px; height:44px;">
                <circle cx="18" cy="18" r="18" fill="#4CAF50"/>
                <g clip-path="url(#clip0_1084_194)">
                <path d="M6.1755 18.0006V18.0001C6.1755 11.4791 11.4775 6.17573 17.9968 6.17573H18.0029C24.5221 6.17573 29.8242 11.4806 29.8242 18.0001C29.8242 24.5195 24.5221 29.8244 18.0029 29.8244L18.0021 29.8244C15.6891 29.8293 13.4264 29.1496 11.4989 27.8709L11.3638 27.7812L11.2093 27.8306L7.16974 29.122L8.47333 25.236L8.52882 25.0706L8.42607 24.9296C6.95965 22.9173 6.17145 20.4906 6.1755 18.0006Z" fill="#4CAF50" stroke="white" stroke-width="0.675676"/>
                <path d="M25.08 23.0123C24.7865 23.8409 23.622 24.528 22.6931 24.7287C22.0577 24.864 21.2276 24.972 18.4333 23.8135C14.8592 22.3328 12.5575 18.7009 12.3781 18.4652C12.2063 18.2296 10.9338 16.5421 10.9338 14.7968C10.9338 13.0515 11.8202 12.2017 12.1774 11.8368C12.4708 11.5373 12.9558 11.4005 13.421 11.4005C13.5715 11.4005 13.7068 11.4081 13.8284 11.4142C14.1857 11.4294 14.3651 11.4507 14.6007 12.0147C14.8941 12.7216 15.6087 14.4669 15.6938 14.6463C15.7805 14.8257 15.8671 15.0689 15.7455 15.3046C15.6315 15.5478 15.5311 15.6557 15.3517 15.8625C15.1724 16.0693 15.0021 16.2274 14.8227 16.4493C14.6585 16.6424 14.473 16.8492 14.6798 17.2064C14.8865 17.5561 15.6011 18.7221 16.6531 19.6586C18.0107 20.8672 19.1114 21.2534 19.5051 21.4176C19.7985 21.5392 20.1482 21.5103 20.3626 21.2823C20.6347 20.9889 20.9707 20.5024 21.3127 20.0235C21.556 19.6799 21.8631 19.6373 22.1854 19.759C22.5137 19.873 24.2514 20.7319 24.6087 20.9098C24.9659 21.0892 25.2016 21.1743 25.2882 21.3248C25.3734 21.4753 25.3734 22.1823 25.08 23.0123Z" fill="#FAFAFA"/>
                </g>
                <defs>
                    <clipPath id="clip0_1084_194">
                        <rect width="24.3243" height="24.3243" fill="white" transform="translate(5.83765 5.83789)"/>
                    </clipPath>
                </defs>
            </svg>
        </a>
    </div>
</footer>