import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';


@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.css']
})

export class BlogComponent implements OnInit {
	// Meta Title
	title = 'Blog - Pro Web';

	blogList: any = [];
	blogCategory: any = [];
	pressRelease: any = [];
	featuredBlogs: any = [];
	featuredBlog: any = [];

	currentPage = 1;
	pageSize = 6;
	totalPages: any;

	currentTab = 1;

	constructor(
		private http: HttpClient,
		private titleService: Title,
		private metaTagService: Meta,
		private loadingService: LoadingService,

	) { }

	blogUrl = environment.blogUrl

	ngOnInit() {
		this.loadingService.setLoadingState(true);

		setTimeout(() => {
			this.loadingService.setLoadingState(false);
		}, 2000)

		this.titleService.setTitle("Blog - Stay Informed on Design, Development & Marketing | Pro Web");
		this.metaTagService.updateTag({ name: 'description', content: "Discover Pro Web's insights! Latest blogs on web design & development, digital marketing, and industry trends. Explore new possibilities and elevate your online presence." });
		this.metaTagService.updateTag({ name: "keywords", content: "pro web blogs, pro web's latest blogs" });

		// blog list
		this.http.get(this.blogUrl + 'blog_list').subscribe(data => {
			let ClientsData: any = data;
			this.blogList = ClientsData.response;
		});

		// blog categories
		this.http.get(this.blogUrl + 'blog_categories').subscribe(data => {
			let category: any = data;
			this.blogCategory = category.response;
		});

		// blog categories
		this.http.get(this.blogUrl + 'latest_press_release').subscribe(data => {
			let clientData: any = data;
			this.pressRelease = clientData.response;
		});

		// blog categories
		this.http.get(this.blogUrl + 'featured_blog_list').subscribe(data => {
			let clientData: any = data;
			this.featuredBlogs = clientData.response;
		});

		// featured blog
		this.http.get(this.blogUrl + 'featured_blog').subscribe(data => {
			let clientData: any = data;
			this.featuredBlog = clientData.response;
		});
	}

	getPaginatedData(pageNumber: any, pageSize: any) {
		const startIndex = (pageNumber - 1) * pageSize;
		const endIndex = startIndex + pageSize;
		const pageData = this.blogList.slice(startIndex, endIndex);
		this.totalPages = Math.ceil(this.blogList.length / pageSize);
		return pageData;
	}

	changePage(page: any, el: HTMLElement) {
		this.currentPage = page;
		el.scrollIntoView({behavior: 'smooth'});
	}

	getPageNumbers(): number[] {
		const pageNumbers: number[] = [];
		for (let i = 1; i <= this.totalPages; i++) {
			pageNumbers.push(i);
		}
		return pageNumbers;
	}
}
