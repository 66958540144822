import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';


@Component({
  selector: 'app-smm-package',
  templateUrl: './smm-package.component.html',
  styleUrls: ['./smm-package.component.css']
})
export class SmmPackageComponent implements OnInit {

  @ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;

  goToBottom() {
    // window.scrollTo(0, 6000);
    this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });

  }

  title = 'Cost-Effective SMM Packages for Digital Presence | Pro Web Dubai';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private loadingService: LoadingService,

  ) { }

  logoarr = ['/assets/img/packages/avea-logo.svg', '/assets/img/packages/uaedriving-logo.svg', '/assets/img/packages/prepme-logo.svg', '/assets/img/packages/carrytune-logo.svg'];
  logoarr2 = ['/assets/img/packages/avea-mobile.png', '/assets/img/packages/prepme-mobile.png', '/assets/img/packages/carry-tune-mobile.png'];


  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000)
    this.titleService.setTitle("Budget Friendly SMM Packages in Dubai | Pro Web");

    this.metaTagService.updateTag(
      { name: "keywords", content: "social media marketing packages" }
    );

    this.metaTagService.updateTag(
      { name: 'description', content: "Unlock success with Pro Web's SMM Packages in the UAE. Boost your digital presence, manage reputation, and grow sustainably without budget constraints." }
    );

  }

  // seo package click function

  package: any = 1;

  seo(a: any) {
    this.package = a;
  }

}
