import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-nodejs',
  templateUrl: './nodejs.component.html',
  styleUrls: ['./nodejs.component.css'],
})
export class NodejsComponent {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle(
      'Node JS Development Company in Dubai | Pro Web'
    );
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Pro Web offers top-notch Node.js development services in Dubai, creating scalable and high-performance web applications tailored to your business needs. Our expert team ensures seamless integration and exceptional user experiences. ',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'node js , node js development, node js development services, node js development company',
    });
  }

  cardsItems = [
    {
      heading: 'Custom Node.js Web Application Development',
      description:
        'Discover our expertise in crafting tailored Node.js web applications. With a focus on scalable architecture and seamless integration, we deliver robust solutions aligned with your strategic goals. Experience efficiency, growth, and user satisfaction through our bespoke development services.',
    },

    {
      heading: 'API Development & Integration',
      description:
        'Pro Web excels in creating custom APIs that enhance connectivity and functionality across your digital ecosystem. Our experts develop secure, scalable APIs tailored to your needs, ensuring seamless integration with third-party services and internal systems. Revolutionize your digital infrastructure with Pro Web’s cutting-edge API solutions.',
    },

    {
      heading: 'Node.js Migration And Upgradation Services',
      description:
        'Optimize your infrastructure with our expert Node.js migration and upgrade services. We specialize in seamless transitions and advancements, ensuring your applications remain efficient, cutting-edge, and scalable. Trust us to optimize your technology for enhanced performance and scalability.',
    },
  ];

  data3 = [
    'flutter/brands/upside.svg',
    'flutter/brands/uae-driving.svg',
    'flutter/brands/smart-door.svg',
    'flutter/brands/vista.svg',
  ];

  data = [
    {
      count: '1',
      heading: 'Project Kickoff and Planning',
      desc: 'We begin by thoroughly understanding your project goals, business requirements, and necessary functionalities. Collaboratively, we outline a detailed roadmap, set clear timelines, and define key milestones. This strategic planning phase ensures alignment, sets expectations, and lays a strong foundation for successful project execution.',
    },
    {
      count: '2',
      heading: 'Backend Architecture and Design',
      desc: 'Our experts craft a scalable backend architecture tailored to your project requirements. We emphasize robust data models and efficient server-side logic, ensuring optimal performance and reliability. This approach guarantees a solid, adaptable system designed to meet current needs and future growth. Continuous monitoring and refinement further enhance stability.',
    },
    {
      count: '3',
      heading: 'Node.js Development and Integration',
      desc: 'Our skilled developers leverage Node.js to write clean, efficient code and implement robust server-side logic. We specialize in integrating external services and ensuring seamless connectivity with other components. Adhering to best coding practices, we deliver high-performance applications tailored to your business needs.',
    },
    {
      count: '4',
      heading: 'Testing and Optimization',
      desc: 'Our rigorous testing process validates the functionality, performance, and security of your Node.js application. We meticulously address any identified issues and optimize the application for peak performance. This ensures that the final product is robust, efficient, and ready to meet your business needs, providing a seamless user experience.',
    },
    {
      count: '5',
      heading: 'Deployment',
      desc: 'We deploy your Node.js application with precision, ensuring a smooth transition to the live environment. Post-deployment, we conduct continuous monitoring to maintain performance, address any issues promptly, and ensure a reliable user experience. Our proactive approach guarantees the application remains robust and efficient over time.',
    },
  ];

  iconsdata = [
    { icon: 'assets/img/servicesdev/icon-one.png', name: 'Enhanced Security' },
    {
      icon: 'assets/img/servicesdev/icon-two.png',
      name: 'Lasting Customer Loyalty',
    },
    {
      icon: 'assets/img/servicesdev/icon-three.png',
      name: 'Two-Tier Authentication',
    },
    {
      icon: 'assets/img/servicesdev/icon-four.png',
      name: 'Session Out Feature',
    },
    {
      icon: 'assets/img/servicesdev/icon-five.png',
      name: 'Nullified Frauds & Risk Factors',
    },
  ];

  data2 = [
    'bpm_logo.png',
    'qatw_logo.png',
    'gtc_logo.png',
    'classic_logo.png',
    'alliance_logo.png',
  ];

  datamobile = [
    'bpm_logo.png',
    'qatw_logo.png',
    'classic_logo.png',
    'alliance_logo.png',
  ];

  faq = [
    {
      title:
        'What is Node.js development, and why should I use it for my project?',
      des: "<p> Node.js development involves building server-side applications using Node.js, a JavaScript runtime known for its efficiency and scalability. It's ideal for projects requiring real-time data, high performance, and fast development cycles. Using Node.js ensures responsive, scalable, and high-performing web applications.</p>",
      isActive: false,
    },
    {
      title: 'What Node.js development services does Pro Web offer?',
      des: '<p>Pro Web offers a comprehensive range of Node.js development services, including custom application development, API development and integration, real-time chat applications, migration and upgrades, performance optimization, and ongoing maintenance and support to ensure robust and scalable solutions.</p>',
      isActive: false,
    },
    {
      title: 'How does Pro Web ensure the quality of Node.js applications?',
      des: '<p>Pro Web ensures the quality of Node.js applications through industry best practices, including rigorous automated and manual testing, comprehensive code reviews, and continuous integration/continuous deployment (CI/CD) pipelines. Our commitment to quality assurance guarantees reliable, high-performance applications.</p>',
      isActive: false,
    },
    {
      title: 'Can Pro Web develop real-time applications using Node.js?',
      des: '<p> Yes, Pro Web can develop real-time applications using Node.js. Node.js is ideal for real-time applications like chat apps, live-streaming services, and collaborative tools due to its event-driven architecture and non-blocking I/O. Our team has extensive experience in building robust, scalable real-time solutions.</p>',
      isActive: false,
    },
    {
      title:
        'What kind of post-launch support does Pro Web offer for Node.js applications?',
      des: '<p>Pro Web offers comprehensive post-launch support for Node.js applications, including performance monitoring, regular updates, security patches, bug fixes, and feature enhancements. Our dedicated support team ensures your application remains efficient, secure, and up-to-date, providing ongoing reliability and performance.</p>',
      isActive: false,
    },
    {
      title:
        'Can Pro Web integrate third-party services and APIs with Node.js applications?',
      des: '<p>Yes, Pro Web excels in integrating third-party services and APIs with Node.js applications. Our experienced team ensures seamless integration to enhance functionality, improve performance, and provide a cohesive user experience, tailored to meet your specific business needs.</p>',
      isActive: false,
    },
    {
      title: 'What industries does Pro Web serve with Node.js development?',
      des: '<p>Pro Web serves diverse industries with Node.js development, including e-commerce, healthcare, finance, education, real estate, and entertainment. Our versatile Node.js solutions are tailored to meet the unique demands of various sectors, ensuring scalable, high-performance applications that drive success.</p>',
      isActive: false,
    },
    {
      title:
        'Can Pro Web help with the maintenance and scaling of existing Node.js applications?',
      des: '<p>Yes, Pro Web can assist with the maintenance and scaling of existing Node.js applications. We offer comprehensive maintenance services to ensure smooth operation and implement scalability solutions to handle increasing user loads and data growth, ensuring your application remains efficient and robust.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }

  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
}
