import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from "@angular/router";
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertService } from '../_alert';
import { Platform } from '@angular/cdk/platform';

@Injectable({
	providedIn: 'root'
})
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	isDesktop: boolean | undefined;
	isMobile: boolean = false;

	hideFooter: boolean = false;

	countries: any = [];

	constructor(
		private platform: Platform,
		private formBuilder: FormBuilder,
		private http: HttpClient,
		private alertService: AlertService,
		private router: Router
	) {
		this.isMobile = this.platform.ANDROID || this.platform.IOS;
		if (!this.isMobile) {
			this.isDesktop = true;
		}
	}

	apiurl = environment.apiUrl;
	blogUrl = environment.blogUrl

	footerForm: any = FormGroup;
	submitted = false;
	isDisabled = false;
	country_code = '';
	showLoader = false;

	ngOnInit() {
		this.footerForm = this.formBuilder.group({
			name: ['', Validators.required],
			country_code: ['91'],
			phone_number: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			message: ['', Validators.required]
		});

		this.http.get(this.blogUrl + 'countries').subscribe(async response => {
			let data: any = response;
			this.countries = data.response;
			// console.log("this.countries", this.countries)
		});

		this.http.get('https://ipapi.co/json').subscribe(async data => {
			let ClientsData: any = data;
			var countryCode = ClientsData.country_calling_code.replace('+', '');
			this.footerForm.controls.country_code.setValue(countryCode);
		});

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.hideFooter = event.url === '/thankyou'; // Adjust path as needed
			}
		});

	}

	get f() { return this.footerForm.controls; }

	onSubmit(event: any) {
		this.submitted = true;
		if (this.footerForm.invalid) {
			return;
		}
		this.showLoader = true;
		const formData = new FormData(event.target);
		this.http.post(this.blogUrl + 'save_callback_enquiry', formData).subscribe(data => {
			this.isDisabled = false;
			this.showLoader = false;
			let ClientsData: any = data;
			if (ClientsData.success == true) {
				this.alertService.clear();
				this.alertService.success(ClientsData.message);
				this.submitted = false;
				this.footerForm.reset();
				this.router.navigate(['/thankyou']);
			} else {
				this.alertService.clear();
				this.alertService.error(ClientsData.message);
			}
		});
	}

	countryChange(country: any) {
		this.footerForm.controls.country_code.setValue(country.dialCode);
	}

	numberOnly(event: any): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	carouselConfig = {
		autoplay: true,
		autoplaySpeed: 0,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		speed: 5000,
		arrows: false,
		dots: false,
		pauseOnHover: false,
		responsive: [

			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1900,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1
				}
			}
		]
	};

	partners = [
		{
			Img: 'assets/img/webp/homepage/google-adwords-certified-png 2.webp',
		},
		{
			Img: 'assets/img/webp/homepage/shopify.webp',
		},
		{
			Img: 'assets/img/webp/homepage/authorized-partner.webp',
		},
		{
			Img: 'assets/img/webp/homepage/AWS_PartnerNetwork 1.webp',
		},
		{
			Img: 'assets/img/webp/homepage/google-adwords-certified-png 2.webp',
		},
		{
			Img: 'assets/img/webp/homepage/shopify.webp',
		},
		{
			Img: 'assets/img/webp/homepage/authorized-partner.webp',
		},
		{
			Img: 'assets/img/webp/homepage/AWS_PartnerNetwork 1.webp',
		}
	]
}