<!-- Start Page Title -->
<div class="page-title-area">
    <div class="d-table d-none">
        <div class="d-table-cell">
            <div class="container">
                <h2>Blog Details</h2>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-80">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-8">
                <div class="blog-details-box" *ngFor="let blog of blogs">
                    <div class="blog-details">
                        <div class="article-img">
                            <img src="{{ blog.image }}" alt="{{ blog.title }}">
                            <!-- <div class="date">{{ blog.post_date }} {{ blog.post_month }} <br> {{ blog.post_year }}</div> -->
                        </div>
                        <div class="article-content">
                            <ul class="category">
                                <li *ngFor="let tag of blog.tags"><a href="javascript:void(0)">{{ tag }}</a></li>
                            </ul>
                            <div class="datemobile">

                                <div>
                                    <p>Posted By : Admin</p>
                                </div>
                                <div>
                                    <p> Date : {{ blog.post_date }} {{ blog.post_month }} {{ blog.post_year }}</p>
                                </div>
                            </div>

                            <h1 class="main-heading">{{ blog.title }}</h1>
                            <div class="blog-fulltxt" [innerHTML]="blog.description"></div>
                        </div>
                    </div>
                    <!-- <div class="post-controls-buttons">
                        <div class="controls-left">
                            <a (click)="getblog(blog.next)" style="cursor:pointer;"> Prev Post</a>
                        </div>
                        <div class="controls-right">
                            <a (click)="getblog(blog.previous)" style="cursor:pointer;">Next Post</a>
                        </div>
                    </div> -->
                    <!-- <div class="post-comments">
                        <h3>Comments</h3>
                        <disqus [identifier]="pageId" [url]="sharehref"></disqus>
                    </div> -->
                </div> 
            </div>
            <div class="col-lg-4 col-md-4">
                <app-blog-sidebar></app-blog-sidebar>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->