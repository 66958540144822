import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-cloud-hosting',
  templateUrl: './cloud-hosting.component.html',
  styleUrls: ['./cloud-hosting.component.css'],
})
export class CloudHostingComponent {
  // selectedPackage: any;
  @ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;
  @Input() selectedPackage: any;
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle('Cloud Hosting Company in Dubai | Pro Web');
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Pro Web offers tailored cloud hosting solutions for professional websites. Experience scalability, reliability, and security with our robust infrastructure and expert support. Elevate your online presence today!',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: 'cloud hosting, cloud hosting services',
    });
  }

  logo = [
    '/assets/img/servicesdev/life_smoothy.png',
    '/assets/img/servicesdev/ICC.png',
    '/assets/img/servicesdev/avea.png',
    '/assets/img/servicesdev/ai-aasima.png',
  ];

  taglinearr = [
    'Faster Migration',
    'Programming Language Setup',
    'Fully Managed Server',
    'Uptime Monitoring',
    'Faster Migration',
    'Programming Language Setup',
    'Fully Managed Server',
    'Uptime Monitoring',
  ];

  cardsItems = [
    {
      title: 'Microsoft Azure',
      desc: "Azure seamlessly integrates cloud capabilities into your current network infrastructure through the robust Azure platform. As an esteemed Microsoft subsidiary, Azure offers comprehensive computing and network solutions through its Infrastructure as a Service (IaaS) model. By consolidating all your Microsoft accounts, Azure provides a unified ecosystem for streamlined management. Leveraging Microsoft's state-of-the-art data centers ensures the utmost safety and security for your data, reaffirming your content's protection and reliability.",
      img: 'assets/img/servicesdev/globe.webp',
      btnname: 'Explore More',
      btnurl: '/cloud-hosting-company/dubai',
    },
    {
      title: 'Google Cloud',
      desc: 'Google Cloud presents a dynamic solution to enhance your network infrastructure with advanced cloud features. Embracing Google Cloud grants access to a progressive suite of tools, empowering your organization with scalability and flexibility for the ever-evolving digital landscape. Whether leveraging advanced analytics, machine learning, or storage solutions, it stands out as a comprehensive platform meeting diverse computing and networking needs.',
      img: 'assets/img/servicesdev/globe.webp',
      btnname: 'Explore More',
      btnurl: '/cloud-hosting-company/dubai',
    },

    {
      title: 'Amazon Web Servers',
      desc: 'Amazon Web Services (AWS) offers a comprehensive suite of on-demand cloud solutions and APIs, delivering a diverse range of fundamental technical and distributed computing tools. It hosts data on specialized data farms strategically distributed worldwide, ensuring a globally distributed service. AWS encompasses Virtual Machines, Cloud Storage, Database Services, Networking, Disaster Recovery, and Security Services, providing a versatile and scalable infrastructure for businesses and developers to meet their computing needs.',
      img: 'assets/img/servicesdev/globe.webp',
      btnname: 'Explore More',
      btnurl: 'cloud-hosting-company/dubai',
    },

    {
      title: 'Digital Ocean',
      desc: 'Digital Ocean emerges as a dynamic solution for infusing an existing network infrastructure with advanced cloud capabilities. Digital Ocean provides a streamlined approach to computing and networking solutions. It simplifies management by offering a user-friendly environment. The platform prioritizes data safety and security, ensuring the protection and reliability of your content. Embracing Digital Ocean empowers your organization with scalability, simplicity, and a range of tools to navigate the evolving digital landscape effectively.',
      img: 'assets/img/servicesdev/globe.webp',
      btnname: 'Explore More',
      btnurl: '/cloud-hosting-company/dubai',
    },
  ];

  dataItems = [
    {
      heading: 'Cloud Efficient Infrastructure',
      description:
        'Cloud services provide a cost-effective solution for businesses enabling resource allocation and decreased operational expenses. This scalable approach not only simplifies financial management but also offers enhanced flexibility to seamlessly adjust with business requirements.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Advance Security',
      description:
        'Cloud services enhance security through advanced measures like encryption & access controls, safeguarding sensitive business data. This robust security infrastructure not only mitigates risks but also fosters a secure digital environment, instilling confidence in the protection of critical information.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Downtime & Recovery',
      description:
        'Cloud services provide resilient infrastructure, minimizing downtime with features like redundant systems and automated failover mechanisms. In addition, cloud platforms offer robust disaster recovery and backup solutions, fortifying business continuity and safeguarding critical data.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Easy Migration',
      description:
        'Seamless migration from the current infrastructure is effortlessly facilitated by cloud services. This streamlined process ensures a smooth transition, allowing businesses to efficiently move their data to the cloud with minimal disruptions. Migration tools provided by cloud platforms simplify the onboarding process, enabling organizations to harness all benefits.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Worldwide Data Centers',
      description:
        'Cloud services boast a global network of data centers, ensuring widespread availability. This expansive infrastructure guarantees optimal performance, low latency, and redundancy. With a worldwide presence, cloud platforms offer flexibility to deploy applications in diverse regions, accommodating a broad user base..',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Improved Productivity',
      description:
        "Boosting productivity, cloud services revolutionize work processes by simplifying workflows. With the flexibility and scalability inherent in cloud solutions, businesses can adapt to evolving needs, fostering an efficient work environment. The result is a significant enhancement in overall productivity, allowing organizations to thrive in today's competitive landscape.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Cost Effective Customization',
      description:
        'Cloud services offer enhanced customization at cost-effective rates, allowing businesses to tailor solutions to their specific needs without breaking the budget. This flexibility ensures that organizations can adapt and scale resources according to their unique requirements.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Load Balancing',
      description:
        'Cloud services optimize resource utilization and ensure seamless performance. Cloud platforms employ sophisticated load-balancing algorithms that dynamically adjust to changing workloads, maintaining optimal efficiency even during peak demand. This strategic distribution of workloads enhances system stability.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Continuous Support',
      description:
        'Continuous support is a cornerstone of cloud services, ensuring that businesses receive ongoing assistance and maintenance for their digital infrastructure. Cloud providers offer around-the-clock support, addressing issues promptly. This commitment to continuous support encompasses troubleshooting, updates, and security enhancements.',
      bgimg: 'hosting-card.png',
    },
  ];

  cards = [
    {
      icon: '../../assets/img/servicesdev/cloud-hosting/icon_one.png',
      heading: 'Simplified Deployment',
      description:
        'Streamline your cloud hosting journey with the support of our seasoned engineers. Navigate effortlessly through our intuitive management panel, granting you seamless control and comprehensive oversight of your deployment process.',
    },

    {
      icon: '../../assets/img/servicesdev/cloud-hosting/icon_two.png',
      heading: 'Cost Efficiency',
      description:
        'Opting cloud servers may involve an initial investment higher than shared hosting or VPS, they frequently prove to be more cost-effective in the long haul, especially for applications with high traffic or demanding resource needs.',
    },

    {
      icon: '../../assets/img/servicesdev/cloud-hosting/icon_three.png',
      heading: 'Security Monitoring & Remediation',
      description:
        'Rest easy knowing that our expert team swiftly responds to any security breaches, promptly eliminating any malware detected and restoring your website to its pristine condition.',
    },

    {
      icon: '../../assets/img/servicesdev/cloud-hosting/icon_four.png',
      heading: 'Reliability Through Redundancy',
      description:
        'Our infrastructure is fortified with redundancy measures to mitigate the risk of downtime. With multiple servers and robust data backups in place, your services remain operational even in the face of unforeseen events.',
    },

    {
      icon: '../../assets/img/servicesdev/cloud-hosting/icon_five.png',
      heading: 'Tailored Flexibility',
      description:
        'Our flexible approach ensures that your hosting environment precisely matches your unique specifications, providing a level of adaptability that enhances the performance and efficiency of your applications.',
    },

    {
      icon: '../../assets/img/servicesdev/cloud-hosting/icon_six.png',
      heading: 'Multiple OS Supported',
      description:
        'Leverage cloud hosting versatility with support for a variety of operating systems, including Windows, Linux, and more. Select the operating system that best aligns with your application or business requirements for a tailored and optimized server environment.',
    },
  ];

  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  data = [
    'UAE-Driving-review.png',
    'discounter-review.png',
    'neo_reality_review.png',
    'classic-review.png',
    'life-smoothy-review.png',
  ];

  datamobile = [
    'UAE-Driving-review.png',
    'discounter-review.png',
    'classic-review.png',
    'life-smoothy-review.png',
  ];

  faq = [
    {
      title: 'What is cloud hosting?',
      des: '<p> Cloud hosting is a type of web hosting service that utilizes virtual servers to host websites or applications. It offers scalability, reliability, and flexibility by utilizing resources from a network of physical servers, allowing for efficient resource management and high availability.</p>',
      isActive: false,
    },
    {
      title: 'What kind of companies use cloud hosting?',
      des: '<p> Various companies across industries, including e-commerce, technology firms, media, healthcare, and finance, utilize cloud hosting. Its benefits, such as scalability and cost-effectiveness, cater to diverse needs, from small businesses to multinational corporations.</p>',
      isActive: false,
    },
    {
      title: 'Why is cloud hosting better than dedicated server hosting?',
      des: '<p>Cloud hosting offers scalability, flexibility, and cost-effectiveness, as resources are distributed across multiple servers. In contrast, dedicated server hosting provides exclusive access to a single server, limiting scalability and potentially leading to underutilization and higher costs.</p>',
      isActive: false,
    },
    {
      title:
        'What types of websites or applications are best suited for cloud hosting?',
      des: '<p>Cloud hosting is suitable for a wide range of websites and applications, including blogs, e-commerce sites, SaaS applications, enterprise applications, and more. It is particularly beneficial for sites or applications with fluctuating traffic or resource demands.</p>',
      isActive: false,
    },
    {
      title: "What security measures does Pro Web's cloud hosting offer?",
      des: '<p>Pro Web implements various security measures, including firewalls, data encryption, DDoS protection, regular security audits, and compliance certifications. Users also have control over access permissions and can implement additional security measures as needed.</p>',
      isActive: false,
    },
    {
      title:
        'Does Pro Web facilitate smooth website or application migrations to their cloud hosting?',
      des: '<p> Yes, Pro Web simplifies website and application migration to cloud hosting with seamless tools and support, ensuring a smooth transition for your online presence. Our flexible setup works with different platforms, making sure your website or app transitions easily.</p>',
      isActive: false,
    },
    {
      title:
        'What happens if I exceed the resources allocated in my hosting plan?',
      des: '<p>If you exceed the resources allocated in your hosting plan, Pro Web will typically offer options to upgrade your plan or add additional resources on a pay-as-you-go basis to accommodate your growing needs.</p>',
      isActive: false,
    },
    {
      title:
        "Is Pro Web's cloud hosting environment compatible with popular web development frameworks and technologies?",
      des: "<p> Yes, Pro Web's cloud hosting environment supports a wide range of web development frameworks, programming languages, and technologies, including PHP, Python, Node.js, and more.</p>",
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }

  onSelectPackage(event: any) {
    this.selectedPackage = event;
    this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
