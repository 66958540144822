<section>
  <!-- banner section -->
  <app-anim-banner
    heading="Hire Flutter App Developers for Excellence Across the UAE"
    description="  Leverage the versatility of Flutter, an innovative framework
  transcending traditional app development, to harness its expressive,
  flexible nature. With a single codebase, unlock boundless possibilities
  for creating dynamic and efficient applications."
    image="flutter/flutter-anim.png"
  ></app-anim-banner>

  <!-- popular brands -->
  <app-brands [brands]="data3"></app-brands>

  <!-- services -->
  <app-services-offer
    [cards]="cardsItems"
    firsttitle="Flutter App Development"
    semititle="Services We Offer"
    description="Pro Web boasts a team of versatile experts committed to delivering cutting-edge solutions. From startups to enterprises, we create cross-platform apps that drive business growth globally. Our skilled developers specialize in robust, scalable apps with rich user experiences. As an esteemed Flutter development company we tailor platform-independent digital solutions to your unique business requirements."
  ></app-services-offer>

  <!-- empower business -->
  <div class="learn_business">
    <div class="container">
      <div class="row align-items-center gy-3">
        <div class="col-md-6">
          <div>
            <h2>Discover the Potential of Flutter for Your Business</h2>
            <p>
              With Flutter's cross-platform capabilities, your app can reach a
              wider audience across iOS, Android, and web platforms, maximizing
              your market reach and customer engagement. Benefit from Flutter's
              fast development cycles and hot reload feature, allowing for rapid
              iteration and quicker time to market your applications.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="empower_wrapper">
            <div>
              <img
                src="../../assets/img/servicesdev/flutter/steps_img.svg"
                alt=""
                srcset=""
              />
            </div>

            <div class="emplower_gif">
              <img
                src="../../assets/img/servicesdev/flutter/Studio.gif"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- engagement model -->
  <div class="engagement">
    <app-engagement-modal></app-engagement-modal>
  </div>

  <!-- flutter app idea -->

  <app-steps-choose
    heading="The Flutter App Development Process: Turning Your Ideas into Reality"
    description="  As a leading UAE-based Flutter app design and development company,
  we specialize in delivering end-to-end IT services. Through
  rigorous technical implementation and creative ideation, we create
  cross-platform applications that resonate with your vision and
  captivate your audience"
    [steps]="data"
  ></app-steps-choose>

  <!-- why choose -->

  <div class="why-choose">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6">
          <div>
            <h2>Why Choose Pro Web as Your Flutter App Development Company?</h2>
            <p>
              Being a prominent Flutter development firm, we acknowledge the
              importance of designing visually captivating brand identities that
              synchronize seamlessly with your business objectives. Each iOS,
              Android, and web app, crafted by our skilled Flutter developers,
              mirrors the essence of your brand impeccably.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mt-3 mt-md-0">
            <img
              src="../../assets/img/servicesdev/flutter/why-choose2.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- review -->
  <app-testimonial-service
    profile="Gianluca.svg"
    clientname="Mr. Gianluca de Arcangelis"
    designation="Chartered Engineer, MIPowerE"
    location="England, United Kingdom"
    flag="UK_flag.svg"
    clientcomment="Very Patient and Skillful to Support with Software and App development"
    rating="Rated 4.9 out of 5.0"
    [clientimg]="data2"
    [clientmobile]="datamobile"
    clienttarget="ttt-review.png"
  ></app-testimonial-service>

  <!-- contact -->
  <app-bottom-bar
    text="Let's build something extraordinary together – reach out now!"
  ></app-bottom-bar>

  <!-- faq's -->
  <section class="spacing">
    <div class="container-md">
      <div class="row justify-content-center justify-content-md-start">
        <h2>Frequently <span>Asked Questions </span></h2>
        <p class="my-3">
          Discover insights to deepen your grasp of Flutter fundamentals
        </p>

        <div class="row my-4">
          <div class="col-sm-6">
            <div
              class="faq-list"
              *ngFor="let item of faq.slice(0, 4); let i = index"
            >
              <div
                class="faq-title d-flex justify-content-between"
                (click)="toggleAccordian($event, i)"
              >
                <h6>{{ item.title }}</h6>
                <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

                <h4 *ngIf="item.isActive == false">+</h4>
                <h4 *ngIf="item.isActive == true">-</h4>
              </div>
              <div class="faq-des" *ngIf="item.isActive">
                <div [innerHTML]="item.des"></div>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div
              class="faq-list"
              *ngFor="let item of faq.slice(4); let i = index"
            >
              <div
                class="faq-title d-flex justify-content-between"
                (click)="toggleAccordian($event, i + 4)"
              >
                <h6>{{ item.title }}</h6>
                <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

                <h4 *ngIf="item.isActive == false">+</h4>
                <h4 *ngIf="item.isActive == true">-</h4>
              </div>
              <div class="faq-des" *ngIf="item.isActive">
                <div [innerHTML]="item.des"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
