import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgwWowService } from 'ngx-wow';
import { PreloaderService } from '../app/preloader.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	providers: [
		Location, {
			provide: LocationStrategy,
			useClass: PathLocationStrategy
		}
	]
})

export class AppComponent implements OnInit {
	isFooterVisible: boolean = false;
	location: any;

	constructor( 
		private wowService: NgwWowService,
		private preloader: PreloaderService
	){
		this.wowService.init();
	}

  	ngOnInit() {
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.isFooterVisible = true;
		}, 3000);
		
		// this.preloader.hide();
		// this.preloader.show();
	}
}
