import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-service-tab',
  templateUrl: './service-tab.component.html',
  styleUrls: ['./service-tab.component.css']
})
export class ServiceTabComponent {
  @Input() selectedPackage: any;
  @ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;
  isExpanded: boolean = false;
  text = "Click here to view More";
  icon = "../../assets/img/servicesdev/top-bottom.svg";

  toggleHeight() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded == true) {
      this.text = "Click here to view less";
      this.icon = "../../assets/img/servicesdev/top.svg";
    }
    else {
      this.text = "Click here to view more";
      this.icon = "../../assets/img/servicesdev/top-bottom.svg";
    }
    this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  onPackageChange(package_name: any) {
    this.selectedPackage = package_name
  }

}
