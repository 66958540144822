<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container head">
                <h1 class="fw-bold"> PRIVACY
                    <span style="color: #F7993B;"> POLICY</span>
                </h1>
            </div>
        </div>
    </div>
</div>
<section class="pp-sec1">
    <div class="container">
        <div class="row">
            <div class="col-md-12 pt-3">
                <p>
                    This Privacy Policy governs the manner in which Pro Web – Unisys collects, uses, maintains and
                    discloses information collected from users (each, a “User”) of the prowebtechnos website (“Site”).
                    This privacy policy applies to the Site and all products and services offered by Pro Web.
                </p>
                <h2 class="heading">PERSONAL IDENTIFICATION INFORMATION</h2>
                <p>
                    We may gather personal identification information from Users in several ways, including but not
                    limited to, when Users visit our site, fill out a form, or engage in other activities, services,
                    features, or resources made available on our Site. The information requested may include, among
                    other details, the User's name, email address, and phone number. It is important to note that Users
                    have the option to explore our Site anonymously.
                </p>
                <p>
                    We will only collect personal identification information from Users if they choose to voluntarily
                    submit such information to us. Users reserve the right to refuse the provision of personally
                    identifiable information, except in cases where it may hinder their participation in certain
                    Site-related activities.
                </p>
                <p>
                    Our commitment is to handle all collected information with the utmost care and in compliance with
                    applicable privacy laws. For more details on how we handle and protect your personal information,
                    please refer to our Privacy Policy.
                </p>
                <h2 class="heading">NON-PERSONAL IDENTIFICATION INFORMATION</h2>
                <p>
                    Our Site may collect non-personal identification information from Users each time they engage with
                    our platform. This type of information may encompass details such as the browser name, the type of
                    computer, and technical specifics regarding Users' methods of connection to our Site. This can
                    include information about the operating system and Internet service providers used, along with
                    similar details related to the interaction with our platform.
                </p>
                <h2 class="heading">WEB BROWSER COOKIES</h2>
                <p>
                    To improve the User experience, our Site may utilize "cookies." These are small files that a User's
                    web browser places on their hard drive for record-keeping and, at times, to monitor information
                    about them. Users have the option to configure their web browser settings to decline cookies or
                    receive alerts when cookies are being sent. It's important to note that opting to reject cookies may
                    impact the functionality of certain parts of the site.
                </p>
                <h2 class="heading">HOW WE USE COLLECTED INFORMATION</h2>
                <p>Pro Web may gather and utilize Users' personal information for the following purposes:</p>
                <ul>
                    <li>
                        <b>Improving Customer Service:</b>
                        The information provided enables us to respond more
                        efficiently to
                        customer service requests and support needs.
                    </li>
                    <br>
                    <li>
                        <b>Enhancing Our Site:</b>
                        Feedback from Users is valuable and helps us enhance our products and
                        services for an improved user experience.
                    </li>
                    <br>
                    <li>
                        <b>Sending Periodic Emails:</b>
                        If Users provide their email address, we may use it to respond
                        to
                        inquiries, questions, and other requests. Additionally, Users who opt-in to our mailing list may
                        receive emails containing company news, updates, or related product and service information.
                        Unsubscribe instructions are provided at the bottom of each email, allowing Users to opt out of
                        future communications at any time.
                    </li>
                </ul>
                <h2 class="heading">HOW WE PROTECT YOUR INFORMATION</h2>
                <p>
                    We adopt appropriate data collection, storage and processing practices and security measures to
                    protect against unauthorized access, alteration, disclosure or destruction of your personal
                    information, username, password, transaction information and data stored on our Site.
                </p>
                <h2 class="heading">
                    SHARING YOUR PERSONAL INFORMATION
                </h2>
                <p>
                    We do not sell, trade, or rent Users personal identification information to others. We may share
                    generic aggregated demographic information not linked to any personal identification information
                    regarding visitors and users with our business partners, trusted affiliates and advertisers for the
                    purposes outlined above.We may use third party service providers to help us operate our business and
                    the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may
                    share your information with these third parties for those limited purposes provided that you have
                    given us your permission.
                </p>
                <h2 class="heading">THIRD PARTY WEBSITES</h2>
                <p>
                    Users may find advertising or other content on our Site that link to the sites and services of our
                    partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the
                    content or links that appear on these sites and are not responsible for the practices employed by
                    websites linked to or from our Site. In addition, these sites or services, including their content
                    and links, may be constantly changing. These sites and services may have their own privacy policies
                    and customer service policies. Browsing and interaction on any other website, including websites
                    which have a link to our Site, is subject to that website’s own terms and policies.
                </p>
                <h2 class="heading">
                    CHANGES TO THIS PRIVACY POLICY
                </h2>
                <p>
                    Pro Web has the discretion to update this privacy policy at any time. When we do, we will revise the
                    updated date at the bottom of this page. We encourage Users to frequently check this page for any
                    changes to stay informed about how we are helping to protect the personal information we collect.
                    You acknowledge and agree that it is your responsibility to review this privacy policy periodically
                    and become aware of modifications.
                </p>
                <h2 class="heading">
                    YOUR ACCEPTANCE OF THESE TERMS
                </h2>
                <p>
                    By using this Site, you signify your acceptance of this policy. If you do not agree to this policy,
                    please do not use our Site. Your continued use of the Site following the posting of changes to this
                    policy will be deemed your acceptance of those changes.
                </p>
                <h2 class="heading">CONTACTING US</h2>
                <p>
                    If you have any questions about this Privacy Policy, the practices of this site, or your dealings
                    with this site, please contact us at:
                    <a href="#"> prowebtechnos.</a>
                    Address : Office #1606, SIT
                    Tower, DSO,
                    Dubai, UAE or email at
                    <a href="mailto:info@prowebtechnos.com"> info&#64;prowebtechnos.com</a>
                </p>
                <!-- <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings
                    with this site, please contact us at: <a href="/">prowebtechnos</a> <b> P.O BOX 15627, AJMAN (U.A.E)
                        OFFICE NO : E13306C
                        FZE </b> <a href="mailto:info@prowebtechnos.com"> info@prowebtechnos.com </a> This document was
                    last updated on September 1, 2018

                </p> -->
            </div>
        </div>
    </div>
</section>
