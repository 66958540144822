import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgwWowModule } from 'ngx-wow';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { IconsModule } from './icons/icons.module';
import { FunfactComponent } from './funfact/funfact.component';
import { PartnerComponent } from './partner/partner.component';
import { FeaturesComponent } from './features/features.component';
import { FeaturesDetailsComponent } from './features-details/features-details.component';
import { ServicesComponent } from './services/services.component';
import { BlogComponent } from './blog/blog.component';
import { CategoryBlogsComponent } from './category-blogs/category-blogs.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { PricingComponent } from './pricing/pricing.component';
import { FaqComponent } from './faq/faq.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { DevelopersComponent } from './developers/developers.component';
import { CareersComponent } from './careers/careers.component';
import { CareersDetailsComponent } from './careers/careers-details/careers.component';
import { ServicebannerComponent } from './servicebanner/servicebanner.component';
import { TechnologyStackComponent } from './technology-stack/technology-stack.component';
import { DevelopmentProcessComponent } from './development-process/development-process.component';
import { EngagementModalComponent } from './engagement-modal/engagement-modal.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { CustomizedSolutionsComponent } from './customized-solutions/customized-solutions.component';
import { WhyChooseProwebComponent } from './why-choose-proweb/why-choose-proweb.component';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { MobileAppComponent } from './mobile-app-development-company-in-dubai/mobile-app.component';
import { EcommerceDevelopmentCompanyInDubaiComponent } from './ecommerce-development-company-in-dubai/ecommerce-development-company-in-dubai.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { WebHostingServiceComponent } from './web-hosting-service/web-hosting-service.component';
import { CloudHostingComponent } from './cloud-hosting/cloud-hosting.component';
import { BlockchainServiceComponent } from './blockchain-service/blockchain-service.component';
import { SeoServiceComponent } from './seo-service/seo-service.component';
import { MachineLearningServiceComponent } from './machine-learning-service/machine-learning-service.component';
import { FlutterComponent } from './flutter/flutter.component';
import { DedicatedHostingComponent } from './dedicated-hosting/dedicated-hosting.component';
import { DedicatedMapComponent } from './dedicated-map/dedicated-map.component';
import { ServiceTabComponent } from './service-tab/service-tab.component';
import { TestimonialServiceComponent } from './testimonial-service/testimonial-service.component';
import { DeployementApproachComponent } from './deployement-approach/deployement-approach.component';
import { HostingTechnologiesComponent } from './hosting-technologies/hosting-technologies.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { ServicesOfferComponent } from './services-offer/services-offer.component';
import { BrandsComponent } from './brands/brands.component';
import { AnimBannerComponent } from './anim-banner/anim-banner.component';
import { StepsChooseComponent } from './steps-choose/steps-choose.component';
// json-ld nmp
// typed js npm
import { NgxTypedJsModule } from 'ngx-typed-js';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { ScrollProgressBarComponent } from './scroll-progress-bar/scroll-progress-bar.component';
import { AlertModule } from './_alert';
import { PlatformsScrollarComponent } from './platforms-scrollar/platforms-scrollar.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServicesComponent } from './terms-of-services/terms-of-services.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
//Discuss comment plugin module
import { SitemapComponent } from './sitemap/sitemap.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SeoComponent } from './seo-packages/seo-packages.component';
import { SmmPackageComponent } from './smm-packages/smm-package.component';
import { WebMaintenancePackageComponent } from './web-maintenance-packages/web-maintenance-package.component';
import { PpcPackageComponent } from './ppc-packages/ppc-package.component';
import { WebHostingPackageComponent } from './web-hosting-packages/web-hosting-package.component';
import { BlogSidebarComponent } from './blog-sidebar/blog-sidebar.component';
import { PackagesbannerComponent } from './packagesbanner/packagesbanner.component';
import { DedicatedTeamComponent } from './dedicated-team/dedicated-team.component';
import { FormComponent } from './form/form.component';
import { ArtificialIntelligenceComponent } from './artificial-intelligence/artificial-intelligence.component';
import { ReactNativeComponent } from './react-native/react-native.component';
import { NativeComponent } from './native/native.component';
import { QualityAssuranceComponent } from './quality-assurance/quality-assurance.component';
import { NodejsComponent } from './nodejs/nodejs.component';
import { VaptComponent } from './vapt/vapt.component';
import { PpcServiceComponent } from './ppc-service/ppc-service.component';
import { PpcServiceSliderComponent } from './ppc-service-slider/ppc-service-slider.component';
import { SmmServiceComponent } from './smm-service/smm-service.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FunfactComponent,
    PartnerComponent,
    FeaturesComponent,
    FeaturesDetailsComponent,
    ServicesComponent,
    BlogComponent,
    CategoryBlogsComponent,
    BlogDetailsComponent,
    PricingComponent,
    FaqComponent,
    ComingSoonComponent,
    NotFoundComponent,
    DevelopersComponent,
    CareersComponent,
    CareersDetailsComponent,
    WebDevelopmentComponent,
    PlatformsScrollarComponent,
    PrivacyPolicyComponent,
    TermsOfServicesComponent,
    RefundPolicyComponent,
    ThankyouComponent,
    SitemapComponent,
    SeoComponent,
    SmmPackageComponent,
    WebMaintenancePackageComponent,
    PpcPackageComponent,
    WebHostingPackageComponent,
    ServicebannerComponent,
    TechnologyStackComponent,
    EngagementModalComponent,
    PortfolioComponent,
    WhyChooseProwebComponent,
    BottomBarComponent,
    DevelopmentProcessComponent,
    MobileAppComponent,
    CustomizedSolutionsComponent,
    EcommerceDevelopmentCompanyInDubaiComponent,
    ScrollProgressBarComponent,
    WebDesignComponent,
    BlockchainServiceComponent,
    BlogSidebarComponent,
    PackagesbannerComponent,
    WebHostingServiceComponent,
    CloudHostingComponent,
    DedicatedHostingComponent,
    DedicatedMapComponent,
    ServiceTabComponent,
    TestimonialServiceComponent,
    DeployementApproachComponent,
    HostingTechnologiesComponent,
    SeoServiceComponent,
    FormComponent,
    DedicatedTeamComponent,
    CheckOutComponent,
    MachineLearningServiceComponent,
    FlutterComponent,
    ServicesOfferComponent,
    BrandsComponent,
    AnimBannerComponent,
    StepsChooseComponent,
    ArtificialIntelligenceComponent,
    ReactNativeComponent,
    NativeComponent,
    QualityAssuranceComponent,
    NodejsComponent,
    VaptComponent,
    PpcServiceComponent,
    PpcServiceSliderComponent,
    SmmServiceComponent
  ],
  imports: [
    NgxTypedJsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    IconsModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    NgwWowModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    AlertModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LfIp-sUAAAAAFlmzXoJEVwPh4pBWPwhYyK0qgKZ',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
