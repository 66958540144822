<div class="bannerwrap_container">
  <div class="banner-content_wrap">
    <div class="bigcircle"></div>
    <div class="smallcircle"></div>
    <h1>{{ heading }}</h1>
    <p>{{ paragraph }}</p>

    <div class="my-md-2 d-flex align-items-center">
      <div class="google">
        <img
          src="/assets/img/servicesdev/web-design/google-icons.webp"
          width="100%"
          alt="google-icon"
          srcset=""
        />
      </div>
      <span>🏆 Award-Winning Agency in 2021, 2022 & 2023</span>
    </div>
    <h5
      *ngIf="
        currentRoute != '/web-hosting-company/dubai' &&
        currentRoute != '/cloud-hosting-company/dubai' &&
        currentRoute != '/machine-learning/dubai' &&
        currentRoute != '/ai-development/dubai'
      "
    >
      Popular Brands Who
      <img
        src="/assets/img/awardslogo/hearticonhome.png"
        width="25px"
        alt="heart"
        srcset=""
      />
      Pro Web
    </h5>
    <h5 *ngIf="currentRoute == '/web-hosting-company/dubai'" class="trusted">
      Trusted for Dedicated Server Hosting and Premium Email Services by
      Startups & SME's
    </h5>

    <h5
      *ngIf="currentRoute == '/cloud-hosting-company/dubai'"
      class="trusted text-capitalize"
    >
      Trusted for deploying clouds, earning acclaim from
      <span style="color: #f7993b">enterprises and corporations</span> for
      seamless deployment expertise.
    </h5>

    <div
      class="trusted-logo py-1 py-md-3"
      *ngIf="
        currentRoute != '/cloud-hosting-company/dubai' &&
        currentRoute != '/machine-learning/dubai' &&
        currentRoute != '/ai-development/dubai'
      "
    >
      <table>
        <tr>
          <td *ngFor="let logoimg of cards">
            <img src="{{ logoimg }}" class="py-2" alt="book my pet" srcset="" />
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="currentRoute == '/cloud-hosting-company/dubai'">
      <div class="d-flex align-items-center">
        <div class="head_css">
          <p class="fw-bold heading text-center logo_txt">
            Hosting Platforms :
          </p>
          <p class="fw-bold heading text-center logo_txt">Our Clients :</p>
        </div>
        <div class="hosting-img">
          <img src="/assets/img/servicesdev/azure-logo.png" alt="" srcset="" />
        </div>
        <div class="hosting-img">
          <img src="/assets/img/servicesdev/aws-logo.png" alt="" srcset="" />
        </div>
        <div class="hosting-img">
          <img
            src="/assets/img/servicesdev/discountr-logo.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="hosting-img">
          <img
            src="/assets/img/servicesdev/digital-logo.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="hosting-img">
          <img src="/assets/img/servicesdev/zoho-logo.png" alt="" srcset="" />
        </div>
      </div>
    </div>

    <div></div>

    <div class="tagline">
      <div class="d-flex justify-content-between align-items-center">
        <div class="marquee_slider">
          <div class="track_slider">
            <div class="content">
              <span
                class="taglist"
                *ngFor="let tagimg of tagline"
                class="text-white mb-0"
              >
                <b class="arrowicon"> > </b> {{ tagimg }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/web-design-company/dubai'"
  >
    <div class="anim1">
      <svg
        class="svgwave"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 301 301"
        style="width: auto; height: auto; overflow: visible"
      >
        <path
          id="wavepath"
          xmlns="http://www.w3.org/2000/svg"
          d="M19.7119 130.183C19.7119 130.183 341.212 -256.816 669.712 454.684C822.792 786.24 1116.54 597.184 1246.71 454.684C1353.13 338.184 1571.51 198.984 1936.71 462.184"
          stroke="#EBB655"
          fill="transparent"
          stroke-width="50"
        />
        <text
          text-anchor="left"
          style="font-family: Arial; font-size: 22px; fill: white"
        >
          <textPath
            style="fill-opacity: 1"
            href="#wavepath"
            side="left"
            startOffset="10%"
          >
            <animate
              attributeName="startOffset"
              from="0%"
              to="22%"
              begin="0s"
              dur="10s"
              repeatCount="indefinite"
            ></animate>
            React.js &nbsp;
            <tspan style="fill: #fff">Angular &nbsp;</tspan>
            Next.js &nbsp;
            <tspan style="fill: #fff">Vue.js &nbsp;</tspan>
            Nuxt.js &nbsp;
            <tspan style="fill: #fff">HTML &nbsp;</tspan>
            CSS &nbsp;
            <tspan style="fill: #fff">Bootstrap &nbsp;</tspan>
            Material-UI &nbsp;
            <tspan style="fill: #fff">jQuery &nbsp;</tspan>
            javascript &nbsp;
            <tspan style="fill: #fff">Angular &nbsp;</tspan>
            Next.js &nbsp;
            <tspan style="fill: #fff">Vue.js &nbsp;</tspan>
            Nuxt.js &nbsp;
            <tspan style="fill: #fff">HTML &nbsp;</tspan>
          </textPath>
        </text>
      </svg>
    </div>
    <div class="anim2">
      <svg
        class="svgwave"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 301 301"
        style="width: auto; height: auto; overflow: visible"
      >
        <path
          id="wavepath"
          xmlns="http://www.w3.org/2000/svg"
          d="M19.7119 130.183C19.7119 130.183 341.212 -256.816 669.712 454.684C822.792 786.24 1116.54 597.184 1246.71 454.684C1353.13 338.184 1571.51 198.984 1936.71 462.184"
          stroke="#EBB655"
          fill="transparent"
          stroke-width="50"
        />
        <text
          text-anchor="left"
          style="font-family: Arial; font-size: 22px; fill: white"
        >
          <textPath
            style="fill-opacity: 1"
            href="#wavepath"
            side="left"
            startOffset="0%"
          >
            <animate
              attributeName="startOffset"
              from="10%"
              to="0%"
              begin="0s"
              dur="8s"
              repeatCount="indefinite"
            ></animate>
            React.js &nbsp;
            <tspan style="fill: #fff">Angular &nbsp;</tspan>
            Next.js &nbsp;
            <tspan style="fill: #fff">Vue.js &nbsp;</tspan>
            Nuxt.js &nbsp;
            <tspan style="fill: #fff">HTML &nbsp;</tspan>
            CSS &nbsp;
            <tspan style="fill: #fff">Bootstrap &nbsp;</tspan>
            Material-UI &nbsp;
            <tspan style="fill: #fff">jQuery &nbsp;</tspan>
            javascript &nbsp;
            <tspan style="fill: #fff">Angular &nbsp;</tspan>
            Next.js &nbsp;
            <tspan style="fill: #fff">Vue.js &nbsp;</tspan>
            Nuxt.js &nbsp;
            <tspan style="fill: #fff">HTML &nbsp;</tspan>
          </textPath>
        </text>
      </svg>
    </div>

    <img
      src="/assets/img/banner-animation/webdesign2.png"
      class="tower rigth0"
      alt="cloud"
    />
    <img
      src="/assets/img/banner-animation/webdesign.png"
      class="macbook"
      alt="cloud"
    />
    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/web-development-company/dubai'"
  >
    <div class="anim1">
      <svg
        class="svgwave"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 301 301"
        style="width: auto; height: auto; overflow: visible"
      >
        <path
          id="wavepath"
          xmlns="http://www.w3.org/2000/svg"
          d="M19.7119 130.183C19.7119 130.183 341.212 -256.816 669.712 454.684C822.792 786.24 1116.54 597.184 1246.71 454.684C1353.13 338.184 1571.51 198.984 1936.71 462.184"
          stroke="#EBB655"
          fill="transparent"
          stroke-width="50"
        />
        <text
          text-anchor="left"
          style="font-family: Arial; font-size: 22px; fill: white"
        >
          <textPath
            style="fill-opacity: 1"
            href="#wavepath"
            side="left"
            startOffset="10%"
          >
            <animate
              attributeName="startOffset"
              from="0%"
              to="22%"
              begin="0s"
              dur="10s"
              repeatCount="indefinite"
            ></animate>
            Node.js &nbsp;
            <tspan style="fill: #fff">Express.js &nbsp;</tspan>
            PHP &nbsp;
            <tspan style="fill: #fff">Laravel &nbsp;</tspan>
            CodeIgniter &nbsp;
            <tspan style="fill: #fff">Java &nbsp;</tspan>
            Python &nbsp;
            <tspan style="fill: #fff">CodeIgniter &nbsp;</tspan>
            Java &nbsp;
            <tspan style="fill: #fff">Node.js &nbsp;</tspan>
            Express.js &nbsp;
            <tspan style="fill: #fff">PHP &nbsp;</tspan>
            Laravel &nbsp;
            <tspan style="fill: #fff">CodeIgniter &nbsp;</tspan>
            Java &nbsp;
            <tspan style="fill: #fff">Python</tspan>
          </textPath>
        </text>
      </svg>
    </div>
    <div class="anim2">
      <svg
        class="svgwave"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 301 301"
        style="width: auto; height: auto; overflow: visible"
      >
        <path
          id="wavepath"
          xmlns="http://www.w3.org/2000/svg"
          d="M19.7119 130.183C19.7119 130.183 341.212 -256.816 669.712 454.684C822.792 786.24 1116.54 597.184 1246.71 454.684C1353.13 338.184 1571.51 198.984 1936.71 462.184"
          stroke="#EBB655"
          fill="transparent"
          stroke-width="50"
        />
        <text
          text-anchor="left"
          style="font-family: Arial; font-size: 22px; fill: white"
        >
          <textPath
            style="fill-opacity: 1"
            href="#wavepath"
            side="left"
            startOffset="0%"
          >
            <animate
              attributeName="startOffset"
              from="10%"
              to="0%"
              begin="0s"
              dur="8s"
              repeatCount="indefinite"
            ></animate>
            Node.js &nbsp;
            <tspan style="fill: #fff">Express.js &nbsp;</tspan>
            PHP &nbsp;
            <tspan style="fill: #fff">Laravel &nbsp;</tspan>
            CodeIgniter &nbsp;
            <tspan style="fill: #fff">Java &nbsp;</tspan>
            Python &nbsp;
            <tspan style="fill: #fff">CodeIgniter &nbsp;</tspan>
            Java &nbsp;
            <tspan style="fill: #fff">Node.js &nbsp;</tspan>
            Express.js &nbsp;
            <tspan style="fill: #fff">PHP &nbsp;</tspan>
            Laravel &nbsp;
            <tspan style="fill: #fff">CodeIgniter &nbsp;</tspan>
            Java &nbsp;
            <tspan style="fill: #fff">Python</tspan>
          </textPath>
        </text>
      </svg>
    </div>

    <img
      src="/assets/img/banner-animation/tower.png"
      class="tower"
      alt="cloud"
    />
    <img
      src="/assets/img/banner-animation/macbook.png"
      class="macbook"
      alt="cloud"
    />
    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>
  <div
    class="banner-animation"
    *ngIf="currentRoute === '/ecommerce-development-company/dubai'"
  >
    <div class="anim1">
      <svg
        class="svgwave"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 301 301"
        style="width: auto; height: auto; overflow: visible"
      >
        <path
          id="wavepath"
          xmlns="http://www.w3.org/2000/svg"
          d="M19.7119 130.183C19.7119 130.183 341.212 -256.816 669.712 454.684C822.792 786.24 1116.54 597.184 1246.71 454.684C1353.13 338.184 1571.51 198.984 1936.71 462.184"
          stroke="#EBB655"
          fill="transparent"
          stroke-width="50"
        />
        <text
          text-anchor="left"
          style="font-family: Arial; font-size: 22px; fill: white"
        >
          <textPath
            style="fill-opacity: 1"
            href="#wavepath"
            side="left"
            startOffset="10%"
          >
            <animate
              attributeName="startOffset"
              from="0%"
              to="22%"
              begin="0s"
              dur="10s"
              repeatCount="indefinite"
            ></animate>
            Magento &nbsp;
            <tspan style="fill: #fff">Opencart &nbsp;</tspan>
            WordPress &nbsp;
            <tspan style="fill: #fff">Shopify &nbsp;</tspan>
            PrestaShop &nbsp;
            <tspan style="fill: #fff">Magento &nbsp;</tspan>
            Magento &nbsp;
            <tspan style="fill: #fff">Opencart &nbsp;</tspan>
            WordPress &nbsp;
            <tspan style="fill: #fff">Shopify &nbsp;</tspan>
            PrestaShop &nbsp;
            <tspan style="fill: #fff">Magento &nbsp;</tspan>
            Magento &nbsp;
            <tspan style="fill: #fff">Opencart &nbsp;</tspan>
            WordPress &nbsp;
            <tspan style="fill: #fff">Shopify &nbsp;</tspan>
            PrestaShop &nbsp;
            <tspan style="fill: #fff">Magento &nbsp;</tspan>
          </textPath>
        </text>
      </svg>
    </div>
    <div class="anim2">
      <svg
        class="svgwave"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 301 301"
        style="width: auto; height: auto; overflow: visible"
      >
        <path
          id="wavepath"
          xmlns="http://www.w3.org/2000/svg"
          d="M19.7119 130.183C19.7119 130.183 341.212 -256.816 669.712 454.684C822.792 786.24 1116.54 597.184 1246.71 454.684C1353.13 338.184 1571.51 198.984 1936.71 462.184"
          stroke="#EBB655"
          fill="transparent"
          stroke-width="50"
        />
        <text
          text-anchor="left"
          style="font-family: Arial; font-size: 22px; fill: white"
        >
          <textPath
            style="fill-opacity: 1"
            href="#wavepath"
            side="left"
            startOffset="0%"
          >
            <animate
              attributeName="startOffset"
              from="10%"
              to="0%"
              begin="0s"
              dur="8s"
              repeatCount="indefinite"
            ></animate>
            Magento &nbsp;
            <tspan style="fill: #fff">Opencart &nbsp;</tspan>
            WordPress &nbsp;
            <tspan style="fill: #fff">Shopify &nbsp;</tspan>
            PrestaShop &nbsp;
            <tspan style="fill: #fff">Magento &nbsp;</tspan>
            Magento &nbsp;
            <tspan style="fill: #fff">Opencart &nbsp;</tspan>
            WordPress &nbsp;
            <tspan style="fill: #fff">Shopify &nbsp;</tspan>
            PrestaShop &nbsp;
            <tspan style="fill: #fff">Magento &nbsp;</tspan>
            Magento &nbsp;
            <tspan style="fill: #fff">Opencart &nbsp;</tspan>
            WordPress &nbsp;
            <tspan style="fill: #fff">Shopify &nbsp;</tspan>
            PrestaShop &nbsp;
            <tspan style="fill: #fff">Magento &nbsp;</tspan>
          </textPath>
        </text>
      </svg>
    </div>

    <img
      src="/assets/img/banner-animation/ecommerce2.png"
      class="tower left0"
      alt="cloud"
    />
    <img
      src="/assets/img/banner-animation/ecommerce.png"
      class="macbook"
      alt="cloud"
    />
    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/mobile-app-development-company/dubai'"
  >
    <div class="anim1">
      <svg
        class="svgwave"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 301 301"
        style="width: auto; height: auto; overflow: visible"
      >
        <path
          id="wavepath"
          xmlns="http://www.w3.org/2000/svg"
          d="M19.7119 130.183C19.7119 130.183 341.212 -256.816 669.712 454.684C822.792 786.24 1116.54 597.184 1246.71 454.684C1353.13 338.184 1571.51 198.984 1936.71 462.184"
          stroke="#EBB655"
          fill="transparent"
          stroke-width="50"
        />
        <text
          text-anchor="left"
          style="font-family: Arial; font-size: 22px; fill: white"
        >
          <textPath
            style="fill-opacity: 1"
            href="#wavepath"
            side="left"
            startOffset="10%"
          >
            <animate
              attributeName="startOffset"
              from="0%"
              to="22%"
              begin="0s"
              dur="10s"
              repeatCount="indefinite"
            ></animate>
            Flutter &nbsp;
            <tspan style="fill: #fff">Kotlin &nbsp;</tspan>
            Swift &nbsp;
            <tspan style="fill: #fff">React Native &nbsp;</tspan>
            Android &nbsp;
            <tspan style="fill: #fff">IOS &nbsp;</tspan>
            Ionic &nbsp;
            <tspan style="fill: #fff">Swift &nbsp;</tspan>
            Flutter &nbsp;
            <tspan style="fill: #fff">Kotlin &nbsp;</tspan>
            Swift &nbsp;
            <tspan style="fill: #fff">React Native &nbsp;</tspan>
            Android &nbsp;
            <tspan style="fill: #fff">IOS &nbsp;</tspan>
            Ionic &nbsp;
            <tspan style="fill: #fff">Flutter &nbsp;</tspan>
          </textPath>
        </text>
      </svg>
    </div>
    <div class="anim2">
      <svg
        class="svgwave"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 301 301"
        style="width: auto; height: auto; overflow: visible"
      >
        <path
          id="wavepath"
          xmlns="http://www.w3.org/2000/svg"
          d="M19.7119 130.183C19.7119 130.183 341.212 -256.816 669.712 454.684C822.792 786.24 1116.54 597.184 1246.71 454.684C1353.13 338.184 1571.51 198.984 1936.71 462.184"
          stroke="#EBB655"
          fill="transparent"
          stroke-width="50"
        />
        <text
          text-anchor="left"
          style="font-family: Arial; font-size: 22px; fill: white"
        >
          <textPath
            style="fill-opacity: 1"
            href="#wavepath"
            side="left"
            startOffset="0%"
          >
            <animate
              attributeName="startOffset"
              from="10%"
              to="0%"
              begin="0s"
              dur="8s"
              repeatCount="indefinite"
            ></animate>
            Flutter &nbsp;
            <tspan style="fill: #fff">Kotlin &nbsp;</tspan>
            Swift &nbsp;
            <tspan style="fill: #fff">React Native &nbsp;</tspan>
            Android &nbsp;
            <tspan style="fill: #fff">IOS &nbsp;</tspan>
            Ionic &nbsp;
            <tspan style="fill: #fff">Swift &nbsp;</tspan>
            Flutter &nbsp;
            <tspan style="fill: #fff">Kotlin &nbsp;</tspan>
            Swift &nbsp;
            <tspan style="fill: #fff">React Native &nbsp;</tspan>
            Android &nbsp;
            <tspan style="fill: #fff">IOS &nbsp;</tspan>
            Ionic &nbsp;
            <tspan style="fill: #fff">Flutter &nbsp;</tspan>
          </textPath>
        </text>
      </svg>
    </div>

    <img
      src="/assets/img/banner-animation/mobileapp2.png"
      class="tower right-5"
      alt="cloud"
    />
    <img
      src="/assets/img/banner-animation/mobileapp.png"
      class="macbook right-15"
      alt="cloud"
    />
    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/web-hosting-company/dubai'"
  >
    <img
      src="/assets/img/banner-animation/webhosting.png"
      style="max-height: 100%; z-index: 9"
      class="macbook right-15"
      alt="cloud"
    />
    <div class="marquee2">
      <div class="track2">
        <img src="/assets/img/banner-animation/hosting-page.svg" alt="cloud" />
        <img src="/assets/img/banner-animation/hosting-page.svg" alt="cloud" />
        <img src="/assets/img/banner-animation/hosting-page.svg" alt="cloud" />
        <img src="/assets/img/banner-animation/hosting-page.svg" alt="cloud" />
      </div>
    </div>
    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/cloud-hosting-company/dubai'"
  >
    <img
      src="/assets/img/banner-animation/webhosting.png"
      style="max-height: 100%; z-index: 9"
      class="macbook right-15"
      alt="cloud"
    />
    <div class="marquee2">
      <div class="track2">
        <img src="/assets/img/banner-animation/cloud-plan.svg" alt="cloud" />
        <img src="/assets/img/banner-animation/cloud-plan.svg" alt="cloud" />
        <img src="/assets/img/banner-animation/cloud-plan.svg" alt="cloud" />
        <img src="/assets/img/banner-animation/cloud-plan.svg" alt="cloud" />
      </div>
    </div>
    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/blockchain-development-company/dubai'"
  >
    <img
      src="/assets/img/banner-animation/blockchain.png"
      style="max-height: 100%; z-index: 9"
      class="macbook right-15"
      alt="cloud"
    />
    <div class="marquee2">
      <div class="track2">
        <img
          src="/assets/img/banner-animation/blockchain-plane.svg"
          alt="plane"
        />
        <img
          src="/assets/img/banner-animation/blockchain-plane.svg"
          alt="plane"
        />
        <img
          src="/assets/img/banner-animation/blockchain-plane.svg"
          alt="plane"
        />
        <img
          src="/assets/img/banner-animation/blockchain-plane.svg"
          alt="plane"
        />
      </div>
    </div>

    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/software-quality-assurance/dubai'"
  >
    <img
      src="/assets/img/banner-animation/quality-assurance.png"
      style="max-height: 100%; z-index: 9"
      class="macbook right-15"
      alt="cloud"
    />
    <!-- <div class="marquee2">
    <div class="track2">
      <img
        src="/assets/img/banner-animation/blockchain-plane.svg"
        alt="plane"
      />
      <img
        src="/assets/img/banner-animation/blockchain-plane.svg"
        alt="plane"
      />
      <img
        src="/assets/img/banner-animation/blockchain-plane.svg"
        alt="plane"
      />
      <img
        src="/assets/img/banner-animation/blockchain-plane.svg"
        alt="plane"
      />
    </div>
  </div> -->

    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>

  <div *ngIf="currentRoute === '/hire-dedicated-developers/dubai'">
    <div>
      <img
        src="../../assets/img/servicesdev/dedicated-team/dedicated-banner.svg"
        width="100%"
        alt=""
        srcset=""
      />
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/machine-learning/dubai'"
  >
    <img
      src="/assets/img/servicesdev/machine-learning/machine_learning_img.png"
      style="max-height: 100%; z-index: 9"
      class="macbook right-15"
      alt="cloud"
    />

    <div class="marquee2">
      <div class="track2">
        <img
          src="/assets/img/banner-animation/machine-learning-plane.svg"
          alt="plane"
        />
        <img
          src="/assets/img/banner-animation/machine-learning-plane.svg"
          alt="plane"
        />
        <img
          src="/assets/img/banner-animation/machine-learning-plane.svg"
          alt="plane"
        />
        <img
          src="/assets/img/banner-animation/machine-learning-plane.svg"
          alt="plane"
        />
      </div>
    </div>

    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>

  <div
    class="banner-animation"
    *ngIf="currentRoute === '/ai-development/dubai'"
  >
    <img
      src="/assets/img/servicesdev/artificial-intelligence/ai-img.png"
      style="max-height: 100%; z-index: 9"
      class="macbook right-15"
      alt="cloud"
    />

    <div class="marquee2">
      <div class="track2">
        <img src="/assets/img/banner-animation/ai-plane.svg" alt="plane" />
        <img src="/assets/img/banner-animation/ai-plane.svg" alt="plane" />
        <img src="/assets/img/banner-animation/ai-plane.svg" alt="plane" />
        <img src="/assets/img/banner-animation/ai-plane.svg" alt="plane" />
      </div>
    </div>

    <div class="marquee">
      <div class="track">
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
        <img src="/assets/img/banner-animation/cloudnew.png" alt="cloud" />
      </div>
    </div>
  </div>
</div>

<div class="mobilebanner">
  <div class="mobilecontent">
    <div class="px-3">
      <h2>{{ heading }}</h2>
      <p class="my-2">{{ paragraph }}</p>
    </div>

    <h5
      class="banner-text"
      *ngIf="
        currentRoute != '/web-hosting-company/dubai' &&
        currentRoute != '/cloud-hosting-company/dubai'
      "
    >
      Popular Brands Who ️<img
        src="/assets/img/awardslogo/hearticonhome.png"
        width="20px"
        alt="heart"
        srcset=""
      />
      Pro Web
    </h5>
    <h5 *ngIf="currentRoute == '/web-hosting-company/dubai'" class="trusted">
      Trusted for Dedicated Server Hosting and Premium Email Services by
      Startups & SME's
    </h5>

    <h5
      *ngIf="currentRoute == '/cloud-hosting-company/dubai'"
      class="trusted px-2"
    >
      Trusted for Advanced Cloud Hosting Solutions and Reliable Performance by
      Enterprises and Corporations.
    </h5>

    <div
      class="trusted-logo pt-0 pb-3"
      *ngIf="currentRoute != '/cloud-hosting-company/dubai'"
    >
      <table class="mx-3">
        <tr>
          <td *ngFor="let logoimg of cards2">
            <img src="{{ logoimg }}" alt="logo" srcset="" />
          </td>
        </tr>
      </table>
    </div>

    <div class="my-md-2 text-center">
      <span>🏆 Award-Winning Agency in 2021, 2022 & 2023</span>
      <div class="google m-auto">
        <img
          src="/assets/img/servicesdev/web-design/google-icons.webp"
          width="100%"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <div *ngIf="currentRoute == '/cloud-hosting-company/dubai'">
      <!-- <div class="px-2">
                <p class="fw-bold mb-0 text-start">Hosting Platform :</p>
                <p class="fw-bold mb-0 text-start">Our Clients :</p>
            </div> -->
      <div
        class="d-flex align-items-center justify-content-center justify-content-lg-start my-3"
      >
        <div class="hosting-img">
          <img src="/assets/img/servicesdev/azure-logo.png" alt="" srcset="" />
        </div>
        <div class="hosting-img">
          <img src="/assets/img/servicesdev/aws-logo.png" alt="" srcset="" />
        </div>
        <div class="hosting-img">
          <img
            src="/assets/img/servicesdev/discountr-logo.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="hosting-img">
          <img
            src="/assets/img/servicesdev/digital-logo.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="hosting-img">
          <img src="/assets/img/servicesdev/zoho-logo.png" alt="" srcset="" />
        </div>
      </div>
    </div>

    <div class="mainImg">
      <img
        src="../../assets/img/servicesdev/{{ mobileImg }} "
        alt=""
        srcset=""
      />
    </div>

    <div class="tagline">
      <div class="d-flex justify-content-between align-items-center">
        <div class="marquee_slider">
          <div class="track_slider">
            <div class="content">
              <span
                class="taglist"
                *ngFor="let tagimg of tagline"
                class="text-white mb-0"
              >
                <b class="arrowicon"> > </b> {{ tagimg }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
