<section class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-content">
                    <div class="crpa-logodiv">
                        <img src="./assets/img/rpa-logo-white.svg" alt="rpa logo" />
                    </div>
                    <h1>Under Construction</h1>
                    <p>Our website is currently undergoing scheduled maintenance. We Should be back shortly. Thank you for your patience.</p>

                    <!-- <form>
                        <input type="email" name="email" id="email" class="email-input" placeholder="Enter your email" required>
                        <button type="submit" class="submit-btn">Notify Us</button>
                    </form> -->

                    <div id="timer">
                        <div id="days"></div>
                        <div id="hours"></div>
                        <div id="minutes"></div>
                        <div id="seconds"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ul class="social-list">
        <li class="list-heading">Follow us for update:</li>
        <li><a target="_blank" href="mailto:admin&#64;rpatechnologies.in" class="email-icon"></a></li>
        <li><a target="_blank" href="https://www.facebook.com/rpatechnos/" class="facebook"></a></li>
        <li><a target="_blank" href="https://twitter.com/Rpa_technologie" class="twitter"></a></li>
        <li><a target="_blank" href="https://www.linkedin.com/company/rpatechnologiespvt.ltd" class="linkedin"></a></li>
    </ul>
</section>