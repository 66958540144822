<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <h2>Refund Policy</h2>
                <br/>
                <p>Because your satisfaction is our biggest asset</p>
            </div>
        </div>
    </div>
    
    <div class="shape1"><img src="assets/img/shape1.png" alt="shape"></div>
    <div class="shape2 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.svg" alt="shape"></div>
    <div class="shape4"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape5"><img src="assets/img/shape5.png" alt="shape"></div>
    <div class="shape6 rotateme"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape7"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
</div>


<section class="rfp-sec1">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h5 class="top-headtxt">Effective Date: March 1, 2020</h5><br/>
<h5>Cancellation Policy</h5>
<p>In case you wish to cancel your orders placed at rpatechnologies.in, you could do so within 24 hrs of the orders placed. The cancellation would not be done once the work has already been started.</p>
<h5>Refund Policy</h5>
<p>Pro Web operates as a business and we expect clients to understand and respect our policies. Our goal as a web design and internet marketing company is to bring the highest value per dollar spent by our clients, and in order to help us in that pursuit, we need to cover expenses and fuel our operations. Therefore, we require payments to be made on-time in respect to the services offered to our clients.</p>
<p>We understand that sometimes &ldquo;life happens&rdquo; and you may need a refund on fees paid for services to Pro Web. We try to be fair, and create a policy that is a win-win for both parties. We will work with you to make you satisfied with our work. However, if a refund is requested and granted, all property created by Pro Web including graphics, content, and code, will remain the property of Pro Web.</p>
<p>Requests for the final portion of payment due on all projects are not made until the project receives final approval from the client. Final payment is due within 5 business days once the invoice is received.</p>
<p>By signing up for our services you acknowledge that you have read and understand this Agreement and agree to be bound by its terms and conditions.</p>
<h4>General Refund Policy Guidelines</h4>
<h5>Web Design &amp; Development:</h5>
<p>Web design and development projects require extensive resources, consumes our time and incurs internal expenses. Therefore once a payment or deposit is made, it is non-refundable. Payments for custom design projects (i.e. custom logo designs and branding projects, full website designs, individual page mockups and smaller design components within a website, etc.) and custom development projects (i.e. php programming, WordPress CMS custom development, WordPress Plugin custom development, etc.) are made to us in increments as a courtesy to the client as well as discounted for those who agree to pay in full, but if a project gets cancelled, delayed or postponed by the client, then all monies paid will be retained by Pro Web and if applicable, an additional cost will be charged to the client for all work completed beyond what was already paid for shall be paid by the client. All projects that are completed as defined as the scope of the work are non-refundable.&nbsp;</p>
<h5>Website Maintenance:&nbsp;</h5>
<p>When adding new text and/or content to a page the client is responsible for providing all text (in digital format) and images/photos. Unused hours do not roll over to subsequent months. No refunds are provided for unused hours. There are absolutely no refunds for any fees related to website maintenance services. Our annual support plans are dependent on the scope and size of a website and are quoted on an annual basis. Especially for websites that we did not initially build, website maintenance and upkeep require extensive upfront resources and time to learn the setup, framework and specifics of a new client's website, thus we require annual maintenance to be a paid in full yearly fee, unless determined otherwise.</p>
<h5>Web Hosting:&nbsp;</h5>
<p>All hosting payments whether present or past months are non-refundable. Web hosting accounts are set up from the date of your order and are allotted a limited amount of server resources depending on the plan you purchased.</p>
<p>If client opens a hosting account but does not use it, payment will still be due. Hosting billing cycles continue until a cancellation notice is received in writing from the client in writing.</p>
<p>Unless otherwise provided, you agree that until and unless you notify Pro Web of your desire to cancel the web hosting services, you will be billed on an automatically recurring basis to prevent any disruption to your Services, using your credit card or other billing information on file with us.</p>
<p>Any invoice that is outstanding for more than fourteen (14) days may result in the suspension or termination of web hosting services. Access to the account will not be restored until payment has been received. If you fail to pay the fees as specified herein, Pro Web may suspend or terminate your account and pursue the collection costs incurred by Pro Web, including without limitation, any arbitration and legal fees, and reasonable attorneys&rsquo; fees. Pro Web will not activate new orders or activate new packages for customers who have an outstanding balance on their account.</p>
<p>Pro Web is not responsible for transferring websites to and from the RPA Technologies Pvt. Ltd's web servers and reserves the right to charge for any web hosting transfer requests.</p>
<h5>Seo &amp; Digital Marketing:&nbsp;</h5>
<p>Customer understands, acknowledges, and agrees that Pro Web has no control over the policies of search engines or directories with respect to the type of sites and/or content that they accept now or in the future. Customer&rsquo;s website may be excluded from any search engine or directory at any time at the sole discretion of the search engine or directory entity. Some search engines and directories may take as long as 90 days, and in some cases longer, after commencement of plan to list and/or update customer&rsquo;s website. Occasionally, search engines and directories will drop listings for no apparent or predictable reason. Pro Web is not responsible for changes made to customer&rsquo;s website by other parties that adversely affect the search engine or directory rankings of customer&rsquo;s web site. SEO and marketing professional services require extensive resources, strategy, and consumes our time and incurs internal expenses. Therefore payments made for SEO and marketing services, are non-refundable.</p>
<h5>Setup fees:&nbsp;</h5>
<p>Setup fees are applied directly to expenses incurred by Pro Web in the setup phase of any SEO or internet marketing campaigns and are non-refundable.</p>
<h5>Domain Names:</h5>
<p>All sales of domain names are final once a domain name has been transferred to the client's account. Domain names sales require a payment in full before transferring the purchased domain name.</p>
<h5>Chargebacks:</h5>
<p>We will as the client for credit card payment info so that we can charge the client's card for services provided or we will send an invoice so that you can pay from your end. Claiming that you didn&rsquo;t provide your card info or that you are not aware of such charges is illegal and fraudulent.</p>
<p>If we receive a chargeback or payment dispute (i.e. PayPal Dispute) from a credit card company or bank, your service and/or project will be suspended without notice. A $50 (3500 INR Or 46 Euro) chargeback fee (issued to recover fees passed on to us by the credit company), plus any outstanding balances accrued as a result of the chargeback(s) must be paid in full before service is restored, files delivered, or any further work is done. Instead of issuing a chargeback, please <a href="https://rpatechnologies.in/contact">contact us</a> to address any billing issues. Requesting a chargeback or opening a PayPal dispute for a valid charge from us is fraud, and is never an appropriate or legal means of obtaining a refund. Please read and make sure you fully understand our refund policy prior to making a payment.</p>
<h5>How Refunds Are Processed:</h5>
<p>If you have paid using your credit card - the money will be transferred back to your credit card account within 30 business days after approval. If you paid using a check or money order, your refund will be returned to you by check.</p>
<h5>Domain Name Renewals:</h5>
<p>At no point will rpatechnologies.in automatically renew a domain name on behalf of a customer. Customers will receive notification of a domain name's pending expiration prior to the expiration date. It is the customer's responsibility to respond to the notification and inform us if you want your domain name renewed. No refunds will be granted for domain name renewals under any circumstances.</p>
<h5>New Domain Name Registration:</h5>
<p>Domain Name purchases are subject to refunds under one condition only. If the domain name requested is currently registered by another individual, you will be given the choice of receiving a refund in full for the purchased domain name or a credit to put towards either the purchase of another domain name or some other qwillan.com service.</p>
<h5>Resolution of Disputes:</h5>
<p>Any Disputes / Concerns related to this Refund Policy are subject to be processed under the Honorable High Court, Jabalpur, M.P, India</p>
<h5>Can-spam Act:</h5>
<p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>
<h5>We collect your email address in order to:</h5>
<p>Send information, respond to inquiries, and/or other requests or questions.</p>
<p>To be in accordance with CANSPAM we agree to the following:</p>
<ol>
<li>NOT use false or misleading subjects or email addresses.</li>
<li>Identify the message as an advertisement in some reasonable way.</li>
<li>Include the physical address of our business or site headquarters.</li>
<li>Monitor third-party email marketing services for compliance, if one is used.</li>
<li>Honor opt-out/unsubscribe requests quickly.</li>
<li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
</ol>
<p>At any time you would like to unsubscribe from receiving future emails, you can email us at <a href="mailto:admin&#64;rpatechnologies.in">admin&#64;rpatechnologies.in</a> and we will promptly remove you from ALL correspondence.</p>
<p>If there are any questions regarding this Privacy Policy, you may contact us using the information below.</p>
<p>If you have any concerns or queries regarding this Privacy Policy, kindly mail us at <strong><a href="mailto:admin&#64;rpatechnologies.in">admin&#64;rpatechnologies.in</a></strong></p>
			</div>
		</div>
	</div>
</section>