<!-- desktop -->
<section class="gradientwrap d-none d-md-block">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center py-3 box">
      <div
        class="d-flex align-items-center justify-content-center testimonialImg"
        *ngFor="let image of clientimg"
      >
        <img src="assets/img/servicesdev/{{ image }}" alt="" srcset="" />
      </div>
    </div>
  </div>
</section>

<!-- mobile -->
<section class="gradientwrap d-block d-md-none">
  <div class="container py-3">
    <div
      class="d-flex justify-content-between align-items-center py-3 box"
      *ngIf="currentRoute !== '/native-app-development/dubai'"
    >
      <div
        class="d-flex align-items-center justify-content-center testimonialImg"
        *ngFor="let image of clientmobile"
      >
        <img src="assets/img/servicesdev/{{ image }}" alt="" srcset="" />
      </div>
    </div>
    <div class="text-center singleimg">
      <img
        src="assets/img/servicesdev/{{ clienttarget }}"
        width="100%"
        alt=""
        srcset=""
      />
    </div>
  </div>
</section>

<section class="testimonial">
  <div
    class="container d-flex flex-column justify-content-center align-items-center"
  >
    <div>
      <img src="assets/img/servicesdev/straight-line.svg" alt="" srcset="" />
    </div>
    <div class="d-flex align-items-center">
      <div style="width: 100px">
        <img
          src="assets/img/servicesdev/{{ profile }}"
          width="100%"
          alt=""
          srcset=""
        />
      </div>
      <div class="info-text">
        <h4 class="mb-0">{{ clientname }}</h4>
        <p class="mb-0">{{ designation }}</p>
        <p class="mb-0">{{ brandname }}</p>
        <p class="mb-0">
          <img
            src="assets/img/servicesdev/{{ flag }}"
            class="pe-2"
            alt="flag"
            srcset=""
          />
          {{ location }}
        </p>
      </div>
    </div>
    <div>
      <img src="assets/img/servicesdev/down-line.svg" alt="" srcset="" />
    </div>
    <div class="review-text">
      <h6>{{ clientcomment }}</h6>
    </div>
    <div class="my-3">
      <div class="d-flex">
        <div>
          <img src="assets/img/servicesdev/googleimg.svg" alt="" srcset="" />
        </div>
        <div>
          <p class="mb-0">{{ rating }}</p>
          <img
            src="assets/img/servicesdev/star4.9.svg"
            width="150px"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div
        class="text-center py-2"
        *ngIf="currentRoute == '/seo-company/dubai'"
      >
        <img
          src="assets/img/servicesdev/seo-companyimg.png"
          width="200px"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
</section>

<section class="techstack" *ngIf="currentRoute == '/web-hosting-company/dubai'">
  <div class="container">
    <div class="row gy-4 gy-md-0 align-items-center">
      <div class="col-md">
        <div class="text">
          <h3 class="text-center text-md-start">
            Launch Websites Effortlessly With Your Favorite Web Apps &
            Frameworks
          </h3>

          <div class="d-block d-md-none py-3">
            <img
              src="../../assets/img/servicesdev/webhosting-tech.png"
              alt=""
              srcset=""
            />
          </div>

          <p>
            Launch websites effortlessly with our Dedicated Server hosting,
            tailored for seamless integration with your favorite web
            applications and frameworks like WordPress and Laravel. Enjoy full
            control over your server resources, optimizing performance and
            customization to your specific needs. Say goodbye to limitations and
            hello to limitless possibilities as you swiftly bring your website
            ideas to life on our dedicated servers.
          </p>
          <div class="text-center text-md-start">
            <button class="showbtn">
              <a href="/web-hosting-packages"
                >Choose Your Package
                <img
                  src="../../assets/img/servicesdev/service-arrow.svg"
                  class="px-2 d-none d-md-inline-block"
                  alt="arrow"
                  srcset=""
              /></a>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md d-none d-md-block">
        <div>
          <img
            src="../../assets/img/servicesdev/webhosting-tech.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
  </div>
</section>
