import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-machine-learning-service',
  templateUrl: './machine-learning-service.component.html',
  styleUrls: ['./machine-learning-service.component.css']
})
export class MachineLearningServiceComponent {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    // Meta Title & Description
    this.titleService.setTitle("Machine Learning Development Company in Dubai | Pro Web");

    this.metaTagService.updateTag(
      { name: "keywords", content: "machine learning, machine learning development services, machine learning algorithms" }
    );

    this.metaTagService.updateTag(
      { name: 'description', content: "Experience transformative growth with Pro Web's specialized Machine Learning Development services. Our team crafts advanced AI solutions tailored to your unique business requirements, driving innovation, efficiency, and competitive advantage." }
    );
  }

  taglinearr = ['Deep Learning Applications', 'AI Generative Product Recommendations', 'Automation Capabilities', 'Deep Learning Applications', 'AI Generative Product Recommendations', 'Automation Capabilities',]

  cardsItems = [
    {
      title: "Computer Vision",
      desc: "Empowered by cutting-edge computer vision technologies, our adept team specializes in developing intelligent algorithms for a wide array of applications. From precise object identification to advanced facial and gesture recognition, our solutions encompass sophisticated visual sensing capabilities. We excel in intricate image and video analysis, leveraging state-of-the-art techniques to extract meaningful insights and drive innovation. Our expertise spans across diverse domains including autonomous vehicles, augmented reality, medical imaging, surveillance systems, and industrial automation.",
      img: "assets/img/servicesdev/globe.webp",
      btnname: 'Explore Dedicated Hosting Packages',
      btnurl: '/web-hosting-packages'
    },
    {
      title: "Voice & Speech Recognition",
      desc: "Our team specializes in developing customized AI-driven voice and speech recognition platforms. These cutting-edge solutions are tailored for various sectors like customer service, healthcare, automotive, and legal industries. Leveraging advanced AI and natural language processing, our platforms ensure high accuracy and performance. From optimizing workflows to enhancing user experiences, our solutions drive transformative outcomes across diverse sectors, including finance and education.",
      img: "assets/img/servicesdev/globe.webp",
      btnname: 'Explore more About Cloud Hosting',
      btnurl: '/cloud-hosting-company/dubai'
    },
    {
      title: "Neural Network Development",
      desc: "Leveraging deep learning technology, we engineer neural networks that adeptly acquire mapping functions from input to output data. Our methodology confronts the complexities of non-convex optimization issues within model parameters, refining backpropagation algorithms for smooth updates. With expertise spanning diverse sectors such as computer vision, natural language processing, and predictive analytics, our solutions catalyze innovation and drive tangible results for businesses.",
      img: "assets/img/servicesdev/globe.webp",
      btnname: 'Explore more About Cloud Hosting',
      btnurl: '/cloud-hosting-company/dubai'
    },
    {
      title: "Natural Language Processing",
      desc: "At Pro Web, we excel in providing advanced Natural Language Processing (NLP) software solutions for diverse applications. Our expertise spans statistical machine translation, intent analysis, optical character recognition, precise text parsing, and spoken language pattern analysis. With a commitment to innovation, we continuously enhance our NLP algorithms for superior accuracy and efficiency. From improving customer interactions to automating document processing, our solutions enable businesses to fully leverage the capabilities of NLP technology.",
      img: "assets/img/servicesdev/globe.webp",
      btnname: 'Explore more About Cloud Hosting',
      btnurl: '/cloud-hosting-company/dubai'
    },
    {
      title: "Predictive Alogrithms",
      desc: "At Pro Web, we excel in providing advanced Natural Language Processing (NLP) software solutions for diverse applications. Our expertise spans statistical machine translation, intent analysis, optical character recognition, precise text parsing, and spoken language pattern analysis. With a commitment to innovation, we continuously enhance our NLP algorithms for superior accuracy and efficiency. From improving customer interactions to automating document processing, our solutions enable businesses to fully leverage the capabilities of NLP technology.",
      img: "assets/img/servicesdev/globe.webp",
      btnname: 'Explore more About Cloud Hosting',
      btnurl: '/cloud-hosting-company/dubai'
    },
    {
      title: "MLOps and Deep Learning",
      desc: "At Pro Web, our expertise extends to leveraging MLOps and AutoML methodologies to optimize the deployment of machine learning solutions. We are dedicated to removing obstacles that impede the adoption of machine learning, enabling IT teams to take the lead in implementing production-grade initiatives seamlessly. Our commitment to prioritizing model quality, performance, and interpretability ensures that our solutions provide measurable benefits to our clients. ",
      img: "assets/img/servicesdev/globe.webp",
      btnname: 'Explore more About Cloud Hosting',
      btnurl: '/cloud-hosting-company/dubai'
    },

    {
      title: "Large Language Models (LLM)",
      desc: "Pro Web excels in creating revolutionary AI chatbots that are in high demand in today's market. Our innovative solutions revolutionize customer support by providing 24/7 assistance through intelligent responses to user inquiries. These AI chatbots seamlessly integrate into websites or custom mobile apps, serving as efficient chat messaging assistants customized to meet your specific business requirements. With advanced natural language processing capabilities, our chatbots deliver personalized interactions and drive engagement, leading to improved customer satisfaction and loyalty.",
      img: "assets/img/servicesdev/globe.webp",
      btnname: 'Explore more About Cloud Hosting',
      btnurl: '/cloud-hosting-company/dubai'
    },
    {
      title: "Smart AI Assistants and Chatbot",
      desc: "We leverage Large Language Models (LLMs) such as GPT-3 to fuel our advanced solutions. By harnessing the capabilities of LLMs, we excel in natural language understanding, content generation, and personalized user experiences. Our clients benefit from state-of-the-art AI capabilities, enabling them to develop innovative and engaging applications tailored to their specific needs. Whether it's enhancing chatbots, creating compelling content, or optimizing search functionality, our expertise in LLMs empowers businesses to stay ahead in today's dynamic market landscape.",
      img: "assets/img/servicesdev/globe.webp",
      btnname: 'Explore more About Cloud Hosting',
      btnurl: '/cloud-hosting-company/dubai'
    },

  ]

  dataItems = [
    {
      heading: 'Data Driven IOT', description: 'At Pro Web, we excel in crafting sophisticated ML/DL algorithmic systems tailored for IoT devices. These systems generate vast amounts of data for thorough analysis, uncovering insights into behavior monitoring, pattern identification, data-driven predictions, and beyond.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Data Migration for ML', description: 'We specialize in migrating data from legacy and big data systems, transforming it into actionable datasets. These datasets are then utilized for multi-label classification, regression, clustering, density estimation, and dimensionality reduction analyses, preparing models for deployment across pertinent systems.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Business Intelligence', description: 'We offer expertise in crafting solutions for text recognition, extraction, and generation, tailored to meet your business needs. With our advanced algorithms and customizable approaches, we ensure seamless integration and optimal performance for your unique requirements.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Computer Vision', description: 'Enhance your business operations by leveraging computer vision technology to analyze and extract insights from images and videos. Pro Web, a leading AI and ML development firm, specializes in creating bespoke computer vision solutions to meet the unique needs of businesses.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Data Analytics', description: 'Enhance the effectiveness of your business operations by harnessing the power of data analytics to extract valuable insights. Pro Web, a premier AI and ML development firm, specializes in crafting innovative custom solutions tailored to your specific needs.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Text to Speech', description: 'At Pro Web, we specialize in developing advanced text-to-speech solutions that transform written text into natural-sounding speech. Our expertise in this area allows us to create customized applications that enhance user experiences across various platforms, from websites and mobile apps to assistive technologies. ', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Object Recognition', description: 'Pro Web excels in crafting state-of-the-art object recognition solutions that enable businesses to identify and classify objects within images or videos. Our expertise in computer vision technology allows us to develop highly accurate and efficient algorithms for object detection and recognition.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Data Visualization', description: 'We specialize in creating visually compelling data visualization solutions that transform complex datasets into actionable insights. Our expertise in data visualization techniques enables us to design interactive dashboards, charts, and graphs that facilitate understanding and decision-making.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Sentiment Analysis', description: 'We offer cutting-edge sentiment analysis solutions, leveraging advanced natural language processing techniques to analyze and interpret textual data. Our custom sentiment analysis algorithms enable businesses to gain valuable insights into customer opinions, attitudes, and emotions across various channels.', bgimg: 'hosting-card.png'
    }

  ]

  iconsdata = [
    { icon: 'assets/img/servicesdev/machine-learning/grad-one.png', name: 'Real-Time Decision Support' },
    { icon: 'assets/img/servicesdev/machine-learning/grad-two.png', name: 'Data-Driven Insights' },
    { icon: 'assets/img/servicesdev/machine-learning/grad-three.png', name: 'Enhanced User Experience' },
    { icon: 'assets/img/servicesdev/machine-learning/grad-four.png', name: 'Increased Efficiency' },
    { icon: 'assets/img/servicesdev/machine-learning/grad-five.png', name: 'Improved Customer Insights' }
  ]

  cards = [

    { icon: '../../assets/img/servicesdev/machine-learning/comprehnsive-icon.png', heading: 'Comprehensive Development', description: 'Pro Web offers end-to-end solutions, from data preprocessing to model deployment, ensuring a robust and complete development cycle.' },

    { icon: '../../assets/img/servicesdev/machine-learning/enterprise-icon.png', heading: 'Enterprise Automation Tools', description: ' Leveraging advanced automation tools streamlines processes, enhances accuracy, and reduces manual intervention, boosting productivity and efficiency.' },

    { icon: '../../assets/img/servicesdev/machine-learning/expert-led-icon.png', heading: 'Expert-Led Development Process', description: 'A team of experienced data scientists and ML engineers leads the development, ensuring high-quality results and industry best practices.' },

    { icon: '../../assets/img/servicesdev/machine-learning/safe-secure-icon.png', heading: 'Safe and Secure', description: "Emphasizing data security with robust encryption, access controls, and compliance measures ensures that client's data remains protected throughout the process." },

    { icon: '../../assets/img/servicesdev/machine-learning/customized-icon.png', heading: 'Customized Models & Training', description: 'Tailoring ML models to specific business needs and providing flexible training platforms empower clients with optimized and scalable solutions.' },

    { icon: '../../assets/img/servicesdev/machine-learning/seamless-icon.png', heading: 'Easy Integration & Deployment', description: 'Integrating ML solutions seamlessly into existing systems and facilitating smooth deployment minimizes downtime and optimizes performance.' }

  ]


  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },

    ]
  };


  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          speed: 5000,
        }
      },

    ]
  };


  faq = [
    {
      title: "What is Machine Learning and what are its types?",
      des: "<p>Machine learning is a subset of artificial intelligence (AI) that involves developing algorithms and statistical models that enable computers to learn from and make predictions or decisions based on data without being explicitly programmed.There are three main types of machine learning: supervised learning, unsupervised learning, and reinforcement learning. Supervised learning involves learning from labeled data, unsupervised learning involves finding patterns in unlabeled data, and reinforcement learning involves learning to make decisions by trial and error based on feedback from the environment.</p>",
      isActive: false,
    },
    {
      title: "What are the Benefits of Implementing Machine Learning in my Business?",
      des: "<p>Implementing machine learning can offer several benefits, including improved decision-making through data-driven insights, increased efficiency and productivity through automation of repetitive tasks, better customer engagement through personalized experiences, and the ability to uncover hidden patterns and trends in data for competitive advantage.</p>",
      isActive: false,
    },
    {
      title: "Does Pro Web Provide Custom Machine Learning Model Development?",
      des: "<p>Yes, we offer custom machine learning model development tailored to your specific business needs and goals. Our team of data scientists and machine learning engineers will work closely with you to understand your requirements and deliver solutions that meet your expectations.</p>",
      isActive: false,
    },
    {
      title: "What Industries or Applications can benefit from Machine Learning?",
      des: '<p>Machine learning has applications across various industries, including healthcare (diagnosis, drug discovery), finance (fraud detection, risk assessment), e-commerce (recommendation systems, customer segmentation), manufacturing (predictive maintenance, quality control), and natural language processing (chatbots, sentiment analysis).</p>',
      isActive: false,
    },
    {
      title: "What is the Process for Developing and Deploying Machine Learning Models?",
      des: "<p>The process typically involves several stages, including data collection and preprocessing, feature engineering, model selection and training, evaluation and validation, deployment, and monitoring. Each stage requires careful consideration and expertise to ensure the success of the machine learning project.</p>",
      isActive: false,
    },
    {
      title: "What data Security and Privacy measures do you have in place when handling Sensitive data?",
      des: '<p>We take data security and privacy seriously. Our practices include implementing encryption techniques, access controls, and compliance with data protection regulations to ensure that sensitive data is handled securely and responsibly.</p>',
      isActive: false,
    },

    {
      title: "What ongoing Support and Maintenance do you provide after deploying a Machine Learning Solution?",
      des: "<p>After deploying a machine learning solution, ongoing support and maintenance are essential to ensure its continued performance and effectiveness. This may include monitoring model performance, updating models with new data, retraining models to adapt to changing conditions, and addressing any issues or feedback from users.</p>",
      isActive: false,
    },
    {
      title: "What is the Future of Machine Learning?",
      des: "<p>Machine learning's future is poised to revolutionize multiple domains, including automation, healthcare, natural language processing, transportation, personalized experiences, cybersecurity, and scientific advancements. It promises personalized healthcare, enhanced automation, and cutting-edge solutions across various sectors.</p>",
      isActive: false,
    },

  ];

  toggleAccordian(event: any, index: any) {

    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }



}
