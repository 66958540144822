<app-packagesbanner heading="Cost-Effective PPC Packages in Dubai"
    paragraph="Supercharge your daily leads and sales with the premier PPC marketing agency in Dubai, Pro Web. Our commitment ensures a robust return on your ad spend, delivering results that scale with your business, regardless of its size."
    bannerimg="/assets/img/packages/packagebg.jpg" bannermobile="/assets/img/packages/ppc-mobile.png"
    packageimg="/assets/img/packages/ppc.png" [cards]="logoarr" [cards2]="logoarr2"
    text="Trusted by"></app-packagesbanner>


<section class="ptb-80">
    <div class="container">
        <div class="row">
            <!-- description -->
            <div>
                <h2 class="text-center mainheading">PPC Agency That Converts <span class="prohead">Clicks to Revenue</span></h2>

                <div class="bar"></div>
                <p class="text-center desc">Pro Web PPC campaigns are customized to suit your specific needs, taking
                    into account your brand, industry, goals, unique selling points, and how you stand out from your
                    competitors. Our PPC service packages are designed to give you a competitive advantage and
                    enhance
                    your online visibility. With our PPC marketing packages, we assist you in the setup, management,
                    and
                    optimization of your marketing platforms. Pro Web ensures that you target the right keywords,
                    use
                    compelling calls to action, and attract the right traffic. As a result, our Pay Per Click
                    Packages
                    provide your business with a cost-effective way to ignite its online presence.</p>
            </div>

            <!-- table -->
            <div class="ptb-20 py-3">

                <h2 class="text-center mainheading mb-3">Choose Your <span class="prohead"> PPC
                        Package </span></h2>

                <div class="bar"></div>


                <div class="mobTabpills">

                    <ul>
                        <li (click)="seo(1)" [ngClass]="{'bgtab' : package == 1}">Startup</li>
                        <li (click)="seo(2)" [ngClass]="{'bgtab' : package == 2}">Gold</li>
                        <li (click)="seo(3)" [ngClass]="{'bgtab' : package == 3}">Enterprise</li>
                    </ul>

                </div>


                <!-- *************************************** -->

                <!-- Desktop -->

                <div class="tableContent">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gray">
                                <td>PPC Monthly Budget</td>
                                <td class="text-center">Upto 5000 AED</td>
                                <td class="text-center">5000 - 15000 AED</td>
                                <td class="text-center">15000 AED & Above</td>
                            </tr>

                            <tr class="gray">
                                <td>Initial Analysis and Setup Fees (One Time)</td>
                                <td class="text-center"> <span class="pricecolor">AED 1500</span></td>
                                <td class="text-center"> <span class="pricecolor">AED 1500</span></td>
                                <td class="text-center"> <span class="pricecolor">AED 1500</span></td>
                            </tr>

                            <tr class="white">
                                <td>Monthly Maintenance Fees</td>
                                <td class="text-center">30% of PPC Monthly Budget </td>
                                <td class="text-center"> 25% of PPC Monthly Budget</td>
                                <td class="text-center"> 20% of PPC Monthly Budget</td>
                            </tr>

                            <tr class="white">
                                <td>Google Adwords Network</td>
                                <td class="text-center">
                                     <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Google Search Network (reach people who searches in google search bar)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Google Display Network (reach people to your industry websites, blogs, videos,
                                    etc )
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Google Remarketing (reach people who visited your website previously) </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Google Product Listing Ads PLA’s (Mandatory : Google Merchant Account)</td>
                                <td class="text-center"> If Applicable </td>
                                <td class="text-center"> If Applicable </td>
                                <td class="text-center"> If Applicable </td>
                            </tr>

                            <tr class="white">
                                <td>Website Review & Ongoing Consulting for PPC Success </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="text-center py-3 morefeatbtn">
                    <div (click)="showdata()" class="d-flex align-items-center justify-content-center"> <b>More
                            Features</b>
                            <img src="/assets/img/webp/add3.webp" class="mx-2" *ngIf="addclass == false" alt="" srcset=""> 
                           
                            <img src="/assets/img/webp/minus3.webp" class="mx-2" *ngIf="addclass == true" alt="" srcset=""> 
                      
                    </div>
                </div>

                <div class="tableContent2" [ngClass]="{'myCSSclass' : addclass == true}">
                    <table class="table">
                        <tbody>

                            <tr class="white">
                                <td>A Complete Analysis of Your PPC Advertising History </td>
                                <td class="text-center"> If Applicable </td>
                                <td class="text-center"> If Applicable </td>
                                <td class="text-center"> If Applicable </td>
                            </tr>
                            <tr class="white">
                                <td>Set Initial Expectations and Specific Goals</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Design and Build Proper Campaigns for Search, Display & Remarketing Network
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Entire Campaign Optimization, Highly Structured and Targeted campaigns </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Perform a Comprehensive Keyword Research and Selection </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Build and Implement a Comprehensive Negative Keyword List </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Proper Keyword Categorization, Matching Options and AdGroup Development </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Creative & Effective Ad Copy – Multiple Ads Per AdGroup for Testing </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Landing Page Optimization Suggestion</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Landing Page Creation/Designing*</td>
                                <td class="text-center"> Extra Charge </td>
                                <td class="text-center"> Extra Charge </td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>


                            <tr class="white">
                                <td>Call To Action (CTA) Optimization Suggestion </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Call To Action Creation/Designing*</td>
                                <td class="text-center"> Extra Charge </td>
                                <td class="text-center"> Extra Charge </td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Ad Scheduling and Geographical Targeting</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Creative Banner Creation/Designing* </td>
                                <td class="text-center"> Extra Charge </td>
                                <td class="text-center"> Extra Charge </td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Integrate Location, Social, Call, Sitelinks, etc Extensions if needed </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Mobile Ads & Bid Optimization* (If website is mobile responsive)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Conversion Tracking & Remarketing Code Installation </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>A Dedicated Account Executive for Questions, Concerns and Ideas </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Continual Bid & Position Monitoring to Achieve Desired Results </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Ongoing Keyword Performance Analysis, Additions & Deletions </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Ongoing Ad Copy Analysis and Testing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Continued Conversion Tracking Analysis </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Work to Increase Higher Conversion Rates, Higher CTR, and Quality Scores</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Competitor Tracking, Research and Benchmarking</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>A/B Landing Page & CTA Split Testing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Work to lower CPA (cost per acquisition)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Fortnightly Campaign Report </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Campaign | Adgroup | Keyword | Position | Clicks | Cost | CPC | Impressions |
                                    CTR
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Conversions |Conv. Rate | Cost Per Conv. </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Mobile -->

                <div class="tableContentMobile">

                    <!-- one -->
                    <table class="table" *ngIf="package == 1">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>PPC Monthly Budget</td>
                                <td class="text-center">Upto 5000 AED</td>

                            </tr>

                            <tr class="white">
                                <td>Initial Analysis and Setup Fees (One Time)</td>
                                <td class="text-center"> <span class="pricecolor">AED 1500</span></td>

                            </tr>
                                                                                                                                                                                         
                            <tr class="white">
                                <td>Monthly Maintenance Fees</td>
                                <td class="text-center"> 30% of PPC Monthly Budget </td>

                            </tr>

                            <tr class="white">
                                <td>Google Adwords Network</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Search Network (reach people who searches in google search bar)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Display Network (reach people to your industry websites, blogs, videos, etc )
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Google Remarketing (reach people who visited your website previously)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Product Listing Ads PLA’s (Mandatory : Google Merchant Account)</td>
                                <td class="text-center"> If Applicable</td>

                            </tr>
                            <tr class="white">
                                <td>Website Review & Ongoing Consulting for PPC Success</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>A Complete Analysis of Your PPC Advertising History</td>
                                <td class="text-center"> If Applicable </td>

                            </tr>
                            <tr class="white">
                                <td>Set Initial Expectations and Specific Goals</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Design and Build Proper Campaigns for Search, Display & Remarketing Network</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Entire Campaign Optimization, Highly Structured and Targeted campaigns</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Perform a Comprehensive Keyword Research and Selection</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Build and Implement a Comprehensive Negative Keyword List</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Proper Keyword Categorization, Matching Options and AdGroup Development</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Creative & Effective Ad Copy – Multiple Ads Per AdGroup for Testing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Landing Page Optimization Suggestion</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Landing Page Creation/Designing*</td>
                                <td class="text-center">Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Call To Action (CTA) Optimization Suggestion</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Call To Action Creation/Designing*</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Ad Scheduling and Geographical Targeting</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Creative Banner Creation/Designing*</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Integrate Location, Social, Call, Sitelinks, etc Extensions if needed</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Mobile Ads & Bid Optimization* (If website is mobile responsive)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Conversion Tracking & Remarketing Code Installation</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>A Dedicated Account Executive for Questions, Concerns and Ideas</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Continual Bid & Position Monitoring to Achieve Desired Results</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Ongoing Ad Copy Analysis and Testing</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Continued Conversion Tracking Analysis </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Work to Increase Higher Conversion Rates, Higher CTR, and Quality Scores</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>
                            </tr>

                            <tr class="white">
                                <td>Competitor Tracking, Research and Benchmarking</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>A/B Landing Page & CTA Split Testing </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Work to lower CPA (cost per acquisition)</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Fortnightly Campaign Report </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Campaign | Adgroup | Keyword | Position | Clicks | Cost | CPC | Impressions | CTR
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Conversions |Conv. Rate | Cost Per Conv. </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                        </tbody>
                    </table>

                    <!-- two -->
                    <table class="table" *ngIf="package == 2">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>PPC Monthly Budget</td>
                                <td class="text-center">5000 - 15000 AED</td>

                            </tr>

                            <tr class="white">
                                <td>Initial Analysis and Setup Fees (One Time)</td>
                                <td class="text-center"> <span class="pricecolor">AED 1500</span></td>

                            </tr>

                            <tr class="white">
                                <td>Monthly Maintenance Fees</td>
                                <td class="text-center"> 25% of PPC Monthly Budget </td>

                            </tr>

                            <tr class="white">
                                <td>Google Adwords Network</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Search Network (reach people who searches in google search bar)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Display Network (reach people to your industry websites, blogs, videos, etc )
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Google Remarketing (reach people who visited your website previously)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Product Listing Ads PLA’s (Mandatory : Google Merchant Account)</td>
                                <td class="text-center"> If Applicable</td>

                            </tr>
                            <tr class="white">
                                <td>Website Review & Ongoing Consulting for PPC Success</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>A Complete Analysis of Your PPC Advertising History</td>
                                <td class="text-center"> If Applicable </td>

                            </tr>
                            <tr class="white">
                                <td>Set Initial Expectations and Specific Goals</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Design and Build Proper Campaigns for Search, Display & Remarketing Network</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Entire Campaign Optimization, Highly Structured and Targeted campaigns</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Perform a Comprehensive Keyword Research and Selection</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Build and Implement a Comprehensive Negative Keyword List</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Proper Keyword Categorization, Matching Options and AdGroup Development</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Creative & Effective Ad Copy – Multiple Ads Per AdGroup for Testing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Landing Page Optimization Suggestion</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Landing Page Creation/Designing*</td>
                                <td class="text-center">Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Call To Action (CTA) Optimization Suggestion</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Call To Action Creation/Designing*</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Ad Scheduling and Geographical Targeting</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Creative Banner Creation/Designing*</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Integrate Location, Social, Call, Sitelinks, etc Extensions if needed</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Mobile Ads & Bid Optimization* (If website is mobile responsive)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Conversion Tracking & Remarketing Code Installation</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>A Dedicated Account Executive for Questions, Concerns and Ideas</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Continual Bid & Position Monitoring to Achieve Desired Results</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Ongoing Ad Copy Analysis and Testing</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Continued Conversion Tracking Analysis </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Work to Increase Higher Conversion Rates, Higher CTR, and Quality Scores</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>
                            </tr>

                            <tr class="white">
                                <td>Competitor Tracking, Research and Benchmarking</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>A/B Landing Page & CTA Split Testing </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Work to lower CPA (cost per acquisition)</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Fortnightly Campaign Report </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Campaign | Adgroup | Keyword | Position | Clicks | Cost | CPC | Impressions | CTR
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Conversions |Conv. Rate | Cost Per Conv. </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                        </tbody>
                    </table>

                    <!-- three -->
                    <table class="table" *ngIf="package == 3">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>PPC Monthly Budget</td>
                                <td class="text-center">15000 AED & Above</td>

                            </tr>

                            <tr class="white">
                                <td>Initial Analysis and Setup Fees (One Time)</td>
                                <td class="text-center"> <span class="pricecolor">AED 1500</span></td>

                            </tr>

                            <tr class="white">
                                <td>Monthly Maintenance Fees</td>
                                <td class="text-center"> 20% of PPC Monthly Budget
                                </td>

                            </tr>

                            <tr class="white">
                                <td>Google Adwords Network</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Search Network (reach people who searches in google search bar)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Display Network (reach people to your industry websites, blogs, videos, etc )
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Google Remarketing (reach people who visited your website previously)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Google Product Listing Ads PLA’s (Mandatory : Google Merchant Account)</td>
                                <td class="text-center"> If Applicable</td>

                            </tr>
                            <tr class="white">
                                <td>Website Review & Ongoing Consulting for PPC Success</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>A Complete Analysis of Your PPC Advertising History</td>
                                <td class="text-center"> If Applicable </td>

                            </tr>
                            <tr class="white">
                                <td>Set Initial Expectations and Specific Goals</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Design and Build Proper Campaigns for Search, Display & Remarketing Network</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Entire Campaign Optimization, Highly Structured and Targeted campaigns</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Perform a Comprehensive Keyword Research and Selection</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Build and Implement a Comprehensive Negative Keyword List</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Proper Keyword Categorization, Matching Options and AdGroup Development</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Creative & Effective Ad Copy – Multiple Ads Per AdGroup for Testing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Landing Page Optimization Suggestion</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Landing Page Creation/Designing*</td>
                                <td class="text-center">Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Call To Action (CTA) Optimization Suggestion</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Call To Action Creation/Designing*</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Ad Scheduling and Geographical Targeting</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Creative Banner Creation/Designing*</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>Integrate Location, Social, Call, Sitelinks, etc Extensions if needed</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Mobile Ads & Bid Optimization* (If website is mobile responsive)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Conversion Tracking & Remarketing Code Installation</td>
                                <td class="text-center"> Extra Charge </td>
                            </tr>

                            <tr class="white">
                                <td>A Dedicated Account Executive for Questions, Concerns and Ideas</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Continual Bid & Position Monitoring to Achieve Desired Results</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Ongoing Ad Copy Analysis and Testing</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Continued Conversion Tracking Analysis </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Work to Increase Higher Conversion Rates, Higher CTR, and Quality Scores</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>
                            </tr>

                            <tr class="white">
                                <td>Competitor Tracking, Research and Benchmarking</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>A/B Landing Page & CTA Split Testing </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Work to lower CPA (cost per acquisition)</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Fortnightly Campaign Report </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Campaign | Adgroup | Keyword | Position | Clicks | Cost | CPC | Impressions | CTR
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Conversions |Conv. Rate | Cost Per Conv. </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                        </tbody>
                    </table>

                </div>

                <!-- ************************************* -->

            </div>

        </div>
    </div>

    <app-bottom-bar text="Looking for PPC Package for your Business?"></app-bottom-bar>

</section>