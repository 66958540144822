<app-servicebanner
  heading="Fast & Scalable Cloud Hosting Services in Dubai"
  paragraph="Pro Web 's managed cloud hosting solutions are designed to exceed your expectations and empower your online presence to thrive in today's competitive digital landscape. Trust us for industry-leading performance and reliability."
  bannerimg="assets/img/servicesdev/webdesignbg.webp"
  mobileImg="hosting-mobile.png"
  [cards]="logo"
  [tagline]="taglinearr"
></app-servicebanner>

<app-technology-stack
  (selectedPackage)="onSelectPackage($event)"
  firsttitle="Your Trusted Partner for"
  semititle="Cloud Hosting Solutions"
  description="Unlock the potential of your website with Pro Web's managed cloud hosting solutions. Revolutionize your website management experience with our innovative approach. Benefit from unmatched scalability, reliability, and efficiency through our state-of-the-art infrastructure. Explore cost-effective plans, fortified security protocols, and swift deployment options tailored to your needs. Embrace seamless access to computing resources, heightened availability, and accelerated digital expansion under the care of Pro Web's managed cloud hosting services."
  [cards]="cardsItems"
></app-technology-stack>

<app-dedicated-hosting
  heading="Cloud"
  subheading="Hosting Features"
  [data]="dataItems"
></app-dedicated-hosting>

<div #scrollTarget>
  <app-service-tab [selectedPackage]="selectedPackage"></app-service-tab>
</div>

<!-- <app-deployement-approach></app-deployement-approach> -->

<!-- the proweb way section -->
<section class="spacing">
  <div class="proweb-padding">
    <div class="row justify-content-center processWrap">
      <h2 class="my-2">The <span>Pro Web Way</span></h2>
      <p>
        Being a reputed cloud solution provider, we have a streamlined process
        to develop cloud infrastructure, migration for your business IT
        infrastructure.
      </p>
      <div
        class="d-none d-md-flex align-items-center justify-content-center p-3"
      >
        <div class="row px-5">
          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex align-items-center">
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/awsicon-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/awsicon-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">AWS Server Setup</h5>
                <ul>
                  <li>Choose AWS Service & instance setup</li>
                  <li>Configure Security Groups</li>
                  <li>Install Software and Deploy</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/configicon-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/configicon-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Server Configuration</h5>
                <ul>
                  <li>Configure Server</li>
                  <li>Install Software</li>
                  <li>Test Application and Deploy</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/domainicon-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/domainicon-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Domain Configuration</h5>
                <ul>
                  <li>Create Hosted Zone</li>
                  <li>Register Domain</li>
                  <li>Set Up DNS Records</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/sslicon-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/sslicon-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">SSL Certificate Setup</h5>
                <ul>
                  <li>Install SSL Certificate</li>
                  <li>Configure HTTPS</li>
                  <li>Ensure Secure Connection</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-block d-md-none my-4">
        <ngx-slick-carousel
          class="carousel owl-carousel branflex processSlider"
          [config]="carouselConfig"
        >
          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/cloud-hosting/awsicon-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">AWS Server Setup</h5>
              <ul>
                <li>Choose AWS Service & instance setup</li>
                <li>Configure Security Groups</li>
                <li>Install Software</li>
                <li>Deploy Application</li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/cloud-hosting/configicon-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Server Configuration</h5>
              <ul>
                <li>Configure Server</li>
                <li>Install Software</li>
                <li>Test Application</li>
                <li>Deploy Application</li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/cloud-hosting/domainicon-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Domain Configuration</h5>
              <ul>
                <li>Create Hosted Zone</li>
                <li>Register Domain</li>
                <li>Set Up DNS Records</li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/cloud-hosting/sslicon-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">SSL Certificate Setup</h5>
              <ul>
                <li>Install SSL Certificate</li>
                <li>Configure HTTPS</li>
                <li>Ensure Secure Connection</li>
              </ul>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</section>

<section class="techstack">
  <div class="container">
    <div class="row gy-4 gy-md-0 align-items-center">
      <div class="col-md">
        <div class="text">
          <h3 class="text-center text-md-start">
            Experience Effortless Deployment with Our Cloud Solutions
          </h3>

          <div class="d-block d-md-none py-3">
            <img
              src="../../assets/img/servicesdev/cloud-hosting/cloud-techstack.png"
              alt=""
              srcset=""
            />
          </div>

          <p>
            We seamlessly deploy websites on Cloud Servers, engineered for
            effortless integration with the latest technologies like Node.js,
            React, and serverless architectures. Embrace the versatility of
            cloud infrastructure, enabling you to dynamically adjust resources
            for peak efficiency. With automated upgrades and advanced security
            protocols, your website remains resilient and current. Embrace the
            simplicity of cloud deployment and unlock your innovation potential
            in the ever-evolving tech landscape with our Cloud Hosting Services.
          </p>
          <!-- <div class="text-center text-md-start">
            <button class="showbtn">
              <a href="/web-hosting-packages">
                Choose Your Package
                <img
                  src="../../assets/img/servicesdev/service-arrow.svg"
                  class="px-2 d-none d-md-inline-block"
                  alt="arrow"
                  srcset=""
              /></a>
            </button>
          </div> -->
        </div>
      </div>
      <div class="col-md d-none d-md-block">
        <div>
          <img
            src="../../assets/img/servicesdev/cloud-hosting/cloud-techstack.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- map -->
<section class="spacing">
  <div class="map-padding">
    <div class="row justify-content-center processWrap">
      <h2 class="my-2">Global <span>data centre locations</span></h2>
      <p>
        Our global data centres and CDN nodes deliver lightning-fast speeds
        wherever your traffic is coming from.
      </p>
    </div>
    <div class="mt-5">
      <img
        src="../../assets/img/servicesdev/cloud-hosting/map.png"
        alt="map"
        srcset=""
      />
    </div>
  </div>
</section>

<app-why-choose-proweb
  heading="Why Choose Pro Web For Managed Cloud Hosting?"
  desc="We excel in Cloud Deployment, delivering top-tier solutions with expertise and reliability."
  [obj]="cards"
></app-why-choose-proweb>

<app-testimonial-service
  profile="mr_tshepo.svg"
  clientname="Mr. Tshepo Maubane"
  designation="Managing Directorr"
  location="United Arab Emirates"
  flag="uk-flag.svg"
  clientcomment="I recently collaborated with Pro Web for my real estate website, and the experience was truly exceptional. Rohit led an incredible team that consistently exceeded my expectations. Shrishti was assigned as our dedicated project manager and excelled in demystifying complex tech terms, keeping us well-informed throughout the 5-month project. Manoj is a genius who successfully turned our ambitious CRM and website integration ideas into reality. Sushma's design skills were unparalleled, transforming our concepts into visual masterpieces. I've already recommended Pro Web to my professional network and am looking forward to a long-term relationship to explore their wide range of services."
  rating="Rated 4.9 out of 5.0"
  [clientimg]="data"
  [clientmobile]="datamobile"
  clienttarget="neo_reality_review.png"
></app-testimonial-service>

<app-check-out
  checkoutImg="../../assets/img/servicesdev/checkout-webhosting.png"
  alt="check out"
  checkoutText="Check Out Our"
  checkoutName="Web Hosting Packages"
  checkoutUrl="/web-hosting-packages"
></app-check-out>

<app-bottom-bar
  text="Not sure which is the best Managed Cloud Hosting for you? Talk to our experts"
></app-bottom-bar>

<section class="spacing">
  <div class="container-md">
    <div class="row justify-content-center">
      <h2>Frequently <span>Asked Questions</span></h2>
      <p class="my-3">
        Find the answers you need to know more about web hosting
      </p>

      <div class="row my-2 my-md-4">
        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(0, 4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span>-->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i + 4)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
