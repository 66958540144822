<!-- Start Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <h2>Services</h2>
            </div>
        </div>
    </div>
    
    <div class="shape1"><img src="assets/img/shape1.png" alt="shape"></div>
    <div class="shape2 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.svg" alt="shape"></div>
    <div class="shape4"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape5"><img src="assets/img/shape5.png" alt="shape"></div>
    <div class="shape6 rotateme"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape7"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
</div>
<!-- End Page Title -->


<section class="servp-sec1">
    <div class="container">
        <div class="section-title">
            <h2>Over <span class="secondary-color"><strong>11+ years</strong></span> of business-centric tech expertise.</h2>
            <div class="bar"></div>
            <p>Straightforward solutions to complex business challenges.</p>
        </div>
    </div>
</section>


<section class="servbg-anim-sec" *ngIf="isDesktop">
    <div class="servbg-anim-box servbg-anim-box1"></div>
    <div class="servbg-anim-box servbg-anim-box2"></div>
    <div class="servbg-anim-box servbg-anim-box3"></div>
    <div class="servbg-anim-box servbg-anim-box4"></div>
    <div class="servbg-anim-box servbg-anim-box5"></div>
    <div class="servbg-anim-box servbg-anim-box6"></div>
    <div class="servbg-anim-box servbg-anim-box7"></div>
    <div class="servbg-anim-box servbg-anim-box8"></div>
    <ul>
        <li class="servbg-anim-li1">
            <a routerLink="/dedicated-team-hiring" routerLinkActive="active">
                <div class="servbox servbox1"></div>                
                <div class="servbox-inner">
                    <div class="servbox-icodiv">
                        <img src="assets/img/services-page/collaboration-icon.svg" alt="icon">
                    </div>
                    <h2>Dedicated Team Hiring</h2>
                    <p class="servbox-para">Dedicated teams of various skills and sizes are available for your specific project requirements</p>
                </div> 
            </a>           
        </li>
        <li class="servbg-anim-li2">
            <a routerLink="/app-development" routerLinkActive="active">
                <div class="servbox servbox2"></div>
                <div class="servbox-inner">
                    <div class="servbox-icodiv">
                        <img src="assets/img/services-page/app-dev-icon.svg" alt="icon">
                    </div>
                    <h2>App Development</h2>
                    <p class="servbox-para">Building process-based and powerful iOS or Android apps to solve everyday business problems.</p>
                </div>
            </a>            
        </li>
        <li class="servbg-anim-li3">
            <a routerLink="/web-development" routerLinkActive="active">
                <div class="servbox servbox3"></div>
                <div class="servbox-inner">
                    <div class="servbox-icodiv">
                        <img src="assets/img/services-page/code-icon.svg" alt="icon">
                    </div>
                    <h2>Web Development</h2>
                    <p class="servbox-para">Expertise in creating quality web designs to diversified clients for growing their online businesses.</p>
                </div>
            </a>            
        </li>
        <li class="servbg-anim-li4">
            <a routerLink="/qa-testing" routerLinkActive="active">
                <div class="servbox servbox4"></div>
                <div class="servbox-inner">
                    <div class="servbox-icodiv">
                        <img src="assets/img/services-page/speed-icon.svg" alt="icon">
                    </div>
                    <h2>QA & Testing</h2>
                    <p class="servbox-para">Best practices and strategies to make sure that the project is up to the expectations of the stakeholders.</p>
                </div>
            </a>            
        </li>
    </ul>
    <ul>
        <li class="servbg-anim-li5">
            <a routerLink="/digital-marketing" routerLinkActive="active">
                <div class="servbox servbox5"></div>
                <div class="servbox-inner">
                    <div class="servbox-icodiv">
                        <img src="assets/img/services-page/dm-icon.svg" alt="icon">
                    </div>
                    <h2>Digital Marketing</h2>
                    <p class="servbox-para">Digital Marketing services to let you connect with the audience in the right place and at the right time.</p>
                </div>
            </a>
        </li>
        <li class="servbg-anim-li6">
            <a routerLink="/ecommerce-development" routerLinkActive="active">
                <div class="servbox servbox6"></div>
                <div class="servbox-inner">
                    <div class="servbox-icodiv">
                        <img src="assets/img/services-page/ecom-dev-icon.svg" alt="icon">
                    </div>
                    <h2>Ecommerce Development</h2>
                    <p class="servbox-para">Comprehensive e-commerce development for getting more than just selling online</p>
                </div>
            </a>            
        </li>
        <li class="servbg-anim-li7">
            <a href="javascript:;">
                <div class="servbox servbox7"></div>
                <div class="servbox-inner">
                    <div class="servbox-icodiv">
                        <img src="assets/img/services-page/graphic-design-icon.svg" alt="icon">
                    </div>
                    <h2>Graphic Design</h2>
                    <p class="servbox-para">Establishing visual communication by using elements of typography, photography, and illustration.</p>
                </div>
            </a>            
        </li>
        <li class="servbg-anim-li8">
            <a href="javascript:;">
                <div class="servbox servbox8"></div>
                <div class="servbox-inner">
                    <div class="servbox-icodiv">
                        <img src="assets/img/services-page/iot-icon.svg" alt="icon">
                    </div>
                    <h2>Web Hosting</h2>
                    <p class="servbox-para">Power your business website with cloud-based hosting services providing 99.8% uptime & the 24x7 support.</p>
                </div>
            </a>            
        </li>
    </ul>
</section>


<section class="servmob-sec" *ngIf="isMobile">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <a routerLink="/web-development">
                    <div class="servmob-div">
                        <div class="svm-imgdiv">
                            <img src="assets/img/services-page/web-dev-small.jpg" alt="image"/>
                        </div>
                        <p>Web Development</p>
                    </div>
                </a>
            </div>
            <div class="col-md-12">
                <a routerLink="/ecommerce-development">
                    <div class="servmob-div">
                        <div class="svm-imgdiv">
                            <img src="assets/img/services-page/ecom-dev-small.png" alt="image"/>
                        </div>
                        <p>Ecommerce Development</p>
                    </div>
                </a>
            </div>
            <div class="col-md-12">
                <a routerLink="/app-development">
                    <div class="servmob-div">
                        <div class="svm-imgdiv">
                            <img src="assets/img/services-page/app-dev-small.jpg" alt="image"/>
                        </div>
                        <p>App Development</p>
                    </div>
                </a>
            </div>
            <div class="col-md-12">
                <a routerLink="/digital-marketing">
                    <div class="servmob-div">
                        <div class="svm-imgdiv">
                            <img src="assets/img/services-page/digital-marketing-small.jpg" alt="image"/>
                        </div>
                        <p>Digital Marketing</p>
                    </div>
                </a>
            </div>                                    
            <div class="col-md-12">
                <a routerLink="/qa-testing">
                    <div class="servmob-div">
                        <div class="svm-imgdiv">
                            <img src="assets/img/services-page/qa-testing-small.jpg" alt="image"/>
                        </div>
                        <p>QA & Testing</p>
                    </div>
                </a>
            </div>            
            <div class="col-md-12">
                <a routerLink="/dedicated-team-hiring">
                    <div class="servmob-div">
                        <div class="svm-imgdiv">
                            <img src="assets/img/services-page/dedicated-team-small.jpg" alt="image"/>
                        </div>
                        <p>Dedicated Team Hiring</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

