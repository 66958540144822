import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

// import Jquery
import * as $ from 'jquery';

@Component({
	selector: 'app-services',
	templateUrl: './services.component.html',
	styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

	isMobile = true;
	isDesktop = true;

	@HostListener("window:resize", [])
	onResize() {
		var width = window.innerWidth;
		if (width < 768) {
			this.isMobile = true;
			this.isDesktop = false;
		} else {
			this.isMobile = false;
			this.isDesktop = true;
		}
	}

	// Meta Title
	title = 'Services - Pro Web';

	constructor(
		private titleService: Title,
		private metaTagService: Meta
	) { }

	ngOnInit() {

		// Meta Title & Description
		this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'description', content: 'Top Rated Web Design & App Development Agency with more than 11 years experience in building results driven custom web based solutions.' }
		);
		// Meta Title & Description

		/* Generate code according to resolution open here */

		if (window.screen.width < 768) {
			this.isMobile = true;
			this.isDesktop = false;
		}
		else {
			this.isMobile = false;
			this.isDesktop = true;
		}

		/* Generate code according to resolution close here */

		$(document).ready(function () {

			$(".servbg-anim-li1").hover(function () {
				$(".servbg-anim-box1").addClass("active");
				$(".servbg-anim-li1").addClass("active");

			}, function () {

				$(".servbg-anim-box").removeClass("active");
				$(".servbg-anim-li1").removeClass("active");

			});

			$(".servbg-anim-li2").hover(function () {
				$(".servbg-anim-box2").addClass("active");
				$(".servbg-anim-li2").addClass("active");

			}, function () {

				$(".servbg-anim-box").removeClass("active");
				$(".servbg-anim-li2").removeClass("active");

			});

			$(".servbg-anim-li3").hover(function () {
				$(".servbg-anim-box3").addClass("active");
				$(".servbg-anim-li3").addClass("active");

			}, function () {

				$(".servbg-anim-box3").removeClass("active");
				$(".servbg-anim-li3").removeClass("active");

			});

			$(".servbg-anim-li4").hover(function () {
				$(".servbg-anim-box4").addClass("active");
				$(".servbg-anim-li4").addClass("active");

			}, function () {

				$(".servbg-anim-box4").removeClass("active");
				$(".servbg-anim-li4").removeClass("active");

			});

			$(".servbg-anim-li5").hover(function () {
				$(".servbg-anim-box5").addClass("active");
				$(".servbg-anim-li5").addClass("active");

			}, function () {

				$(".servbg-anim-box5").removeClass("active");
				$(".servbg-anim-li5").removeClass("active");

			});

			$(".servbg-anim-li6").hover(function () {
				$(".servbg-anim-box6").addClass("active");
				$(".servbg-anim-li6").addClass("active");

			}, function () {

				$(".servbg-anim-box6").removeClass("active");
				$(".servbg-anim-li6").removeClass("active");

			});

			$(".servbg-anim-li7").hover(function () {
				$(".servbg-anim-box7").addClass("active");
				$(".servbg-anim-li7").addClass("active");

			}, function () {

				$(".servbg-anim-box7").removeClass("active");
				$(".servbg-anim-li7").removeClass("active");

			});

			$(".servbg-anim-li8").hover(function () {
				$(".servbg-anim-box8").addClass("active");
				$(".servbg-anim-li8").addClass("active");

			}, function () {

				$(".servbg-anim-box8").removeClass("active");
				$(".servbg-anim-li8").removeClass("active");

			});

		});

	}

}
