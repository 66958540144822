<app-packagesbanner heading="Budget-Friendly SMM Packages in Dubai" paragraph="Pro Web SMM packages are one of the most comprehensive and effective social media management
packages in UAE.
Now small businesses can enjoy the benefits of the numerous returns social networks have to
offer to
businesses, with our monthly social media packages." bannerimg="/assets/img/packages/packagebg.jpg"
    bannermobile="/assets/img/packages/smm-mobile.png" packageimg="/assets/img/packages/smm.png" [cards]="logoarr"
    [cards2]="logoarr2" text="Trusted by"></app-packagesbanner>


<section class="ptb-80">

    <div class="container">
        <div class="row">
            <!-- description -->
            <div>
                <h2 class="text-center mainheading">Cost - Effective Social Media  <span class="prohead"> Solutions For All Businesses
                    </span></h2>

                <div class="bar"></div>
                <p class="text-center desc">Pro Web offers some of the most comprehensive and cost-effective social
                    media management packages in the UAE. Our monthly packages are designed for small businesses,
                    startups, and even corporate giants, making it affordable for everyone to harness the power of
                    social networks. We enhance your digital presence, manage your online reputation, and save you time
                    and money, so you can focus on growing your business without breaking the budget. As a leading
                    social media marketing agency in Dubai, we help you to make the most of the benefits that social
                    media brings to businesses.Pro Web has designed various social media marketing and management
                    packages to deliver you best results but at a low cost, so that you break your competitors, not your
                    budget.</p>
            </div>

            <!-- table -->
            <div class="ptb-20 py-3">

                <!-- <h2 class="text-center pb-3"> <span style="color:black !important;"> Our Exclusive</span> <span
                        style="color:#fd7d00 !important;"> SMM Packages </span> </h2> -->


                <h2 class="text-center mainheading mb-3">Choose Your <span class="prohead"> SMM
                        Package </span></h2>

                <!-- <h2 class="text-center mainheading otherHeading mb-3">Unlock the Power of Social Networks with  <span
                        class="prohead">Pro Web's Comprehensive SMM Solutions</span></h2> -->



                <div class="bar"></div>
                <!-- <p class="text-center">ALL IN ONE SOCIAL MEDIA PACKAGE TO BUILD YOUR ONLINE PRESENCE.</p> -->





                <!-- Desktop -->

                <div class="tableContent">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Price</td>
                                <td class="text-center"> <span class="pricecolor">AED 2500 </span> Per Month</td>
                                <td class="text-center"> <span class="pricecolor">AED 4000</span> Per Month</td>
                                <td class="text-center"> <span class="pricecolor"> AED 5000</span> Per Month</td>
                            </tr>
                            <tr class="white">
                                <td>Posts/Reels Per Month</td>
                                <td class="text-center">12 posts/reels and 5 interactive
                                    stories per month.
                                </td>
                                <td class="text-center">20 posts/reels and 12 interactive
                                    stories per month.
                                </td>
                                <td class="text-center">30 posts/reels and 20 interactive
                                    stories per month.</td>
                            </tr>

                            <tr class="white">
                                <td>SMM - for Meta Account (Facebook, Instagram)</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                    <p>Choice of any 2
                                        platforms (X/ Threads/ Snapchat/
                                        Pinterest/ TikTok)
                                    </p>
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                    <p>Choice of any 3
                                        platforms (X/ Threads/ Snapchat/
                                        Pinterest/ TikTok)
                                    </p>
                                </td>

                            </tr>


                            <tr class="white">
                                <td>Social Media Account Setup</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Monthly Progress Report & Recommendations</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>SMM Optimization</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Ad Strategy Plan</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""></td>
                                <td class="text-center"> Purpose-driven social media ad
                                    strategy plan. (Ad budget extra)
                                </td>
                                <td class="text-center"> Purpose-driven social media ad
                                    strategy plan. (Ad budget extra)
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Influencer Outreach (Budget extra)</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center"> <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Community Management</td>
                                <td class="text-center">Community Management including
                                    replying to comments, messages and
                                    moderating follower activities. (to be
                                    reviewed after every 5k increase in
                                    followers base).
                                </td>
                                <td class="text-center">Community Management including
                                    replying to comments, messages and
                                    moderating follower activities. (to be
                                    reviewed after every 5k increase in
                                    followers base).
                                </td>
                                <td class="text-center">Community Management including
                                    replying to comments, messages and
                                    moderating follower activities. (to be
                                    reviewed after every 5k increase in
                                    followers base).
                                </td>
                            </tr>


                        </tbody>
                    </table>
                </div>


                <div class="mobTabpills">

                    <ul>
                        <li (click)="seo(1)" [ngClass]="{'bgtab' : package == 1}">Startup</li>
                        <li (click)="seo(2)" [ngClass]="{'bgtab' : package == 2}">Gold</li>
                        <li (click)="seo(3)" [ngClass]="{'bgtab' : package == 3}">Enterprise</li>
                    </ul>

                </div>


                <!-- *************************************** -->

                <!-- Mobile -->

                <div class="tableContentMobile">

                    <table class="table" *ngIf="package == 1">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr class="white">
                                <td>Price</td>
                                <td class="text-center"> <span class="pricecolor">AED 2500</span> Per Month</td>

                            </tr>

                            <tr class="white">
                                <td>Posts/Reels Per Month</td>
                                <td class="text-center">12 posts/reels and 5 interactive
                                    stories per month. </td>

                            </tr>

                            <tr class="white">
                                <td>SMM - for Meta Account (Facebook, Instagram)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Social Media Account Setup</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Monthly Progress Report & Recommendations</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>SMM Optimisation</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Ad Strategy Plan</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Influencer Outreach (Budget extra)</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Community Management</td>
                                <td class="text-center"> Community Management including
                                    replying to comments, messages and
                                    moderating follower activities. (to be
                                    reviewed after every 5k increase in
                                    followers base). </td>

                            </tr>

                        </tbody>
                    </table>

                    <table class="table" *ngIf="package == 2">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr class="white">
                                <td>Price</td>
                                <td class="text-center"> <span class="pricecolor">AED 4000</span> Per Month</td>

                            </tr>

                            <tr class="white">
                                <td>Posts/Reels Per Month</td>
                                <td class="text-center">20 posts/reels and 12 interactive
                                    stories per month. </td>

                            </tr>

                            <tr class="white">
                                <td>SMM - for Meta Account (Facebook, Instagram)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                    <p>Choice of any 2
                                        platforms (X/ Threads/ Snapchat/
                                        Pinterest/ TikTok)</p>
                                </td>

                            </tr>

                            <tr class="white">
                                <td>Social Media Account Setup</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Monthly Progress Report & Recommendations</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>SMM Optimisation</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Ad Strategy Plan</td>
                                <td class="text-center">
                                    <p>Purpose-driven social media ad
                                        strategy plan. (Ad budget extra)</p>
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Influencer Outreach (Budget extra)</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Community Management</td>
                                <td class="text-center"> Community Management including
                                    replying to comments, messages and
                                    moderating follower activities. (to be
                                    reviewed after every 5k increase in
                                    followers base). </td>

                            </tr>

                        </tbody>
                    </table>

                    <table class="table" *ngIf="package == 3">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr class="white">
                                <td>Price</td>
                                <td class="text-center"> <span class="pricecolor">AED 5000</span> Per Month</td>

                            </tr>

                            <tr class="white">
                                <td>Posts/Reels Per Month</td>
                                <td class="text-center">30 posts/reels and 20 interactive
                                    stories per month. </td>

                            </tr>

                            <tr class="white">
                                <td>SMM - for Meta Account (Facebook, Instagram)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                    <p>Choice of any 3
                                        platforms (X/ Threads/ Snapchat/
                                        Pinterest/ TikTok)</p>
                                </td>

                            </tr>

                            <tr class="white">
                                <td>Social Media Account Setup</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Monthly Progress Report & Recommendations</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>SMM Optimisation</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Ad Strategy Plan</td>
                                <td class="text-center">
                                    <p>Purpose-driven social media ad
                                        strategy plan. (Ad budget extra)</p>
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Influencer Outreach (Budget extra)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Community Management</td>
                                <td class="text-center"> Community Management including
                                    replying to comments, messages and
                                    moderating follower activities. (to be
                                    reviewed after every 5k increase in
                                    followers base). </td>

                            </tr>

                        </tbody>
                    </table>

                </div>

                <!-- ************************************* -->

                <!-- Desktop -->

                <!-- <div class="wrapper">

                    <div class="card">

                        <div class="top">
                            <h2>Basic</h2>
                            <h4>AED 2500</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>12 posts/reels and 5 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram)
                            </li>
                            <li>Social Media account setup.
                                Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Monthly progress report &
                                recommendations.</li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                        </ul>

                    </div>

                    <div class="card">

                        <div class="top">
                            <h2>Advanced</h2>
                            <h4>AED 4000</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>20 posts/reels and 12 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram) + Choice of any 2
                                platforms (X/ Threads/ Snapchat/
                                Pinterest/ TikTok)
                            </li>
                            <li>Social Media account setup.

                            </li>
                            <li>Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Dedicated account manager.
                            </li>
                            <li>Monthly progress report &
                                Recommendations.</li>
                            <li>Social Media Profile optimization
                                (keywords, hashtags)
                            </li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                            <li>Purpose-driven social media ad
                                strategy plan. (Ad budget extra)</li>
                        </ul>

                    </div>

                    <div class="card">

                        <div class="top">
                            <h2>Pro</h2>
                            <h4>AED 4000</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>30 posts/reels and 20 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram) + Choice of any 3
                                platforms (X/ Threads/ Snapchat/
                                Pinterest/ TikTok)
                            </li>
                            <li>Social Media account setup.

                            </li>
                            <li>Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Dedicated account manager.
                            </li>
                            <li>Monthly progress report &
                                Recommendations.
                            </li>
                            <li>Social Media Profile optimization
                                (keywords, hashtags)
                            </li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                            <li>Purpose-driven social media ad
                                strategy plan. (Ad budget extra)
                            </li>
                            <li>Influencer outreach (Budget extra)
                            </li>
                        </ul>

                    </div>
                </div> -->

                <!-- ************************************* -->

            </div>

        </div>
    </div>

    <app-bottom-bar text="Looking for SMM Package for your Business?"></app-bottom-bar>


</section>