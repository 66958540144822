<app-packagesbanner heading="Affordable SEO Packages in Dubai" paragraph="Crafting an effective SEO strategy involves meticulously planning and executing steps to enhance your
website's visibility and Google ranking. We provide a holistic suite of SEO services designed to propel your
website towards achieving its business objectives." bannerimg="/assets/img/packages/packagebg.jpg"
    bannermobile="/assets/img/packages/seo-mobile.png" packageimg="/assets/img/packages/seo-dektop.png" text="Trusted by"
    [cards]="logoarr" [cards2]="logoarr2"></app-packagesbanner>



<section class="ptb-80">

    <div class="container">

        <div class="row">

            <div>
                <h2 class="text-center mainheading">Custom SEO Packages Tailored<span class="prohead"> for Your Business Success</span></h2>

                <div class="bar"></div>
                <p class="text-center desc">We are a leading, full-service SEO firm with an innovative strategy for
                    rapid and
                    desired results. Our experts, well-versed in the industry, recognize the need for adaptable
                    strategies to match evolving algorithms, and our certified professionals are well-prepared for such
                    changes. Choose from our SEO packages that align with your business needs and expected outcomes, as
                    we customize prices based on the uniqueness of each business. With three tailored SEO packages
                    available in Dubai, our goal is to assist you in selecting the perfect fit for your budget and
                    website. Our dedicated team crafts personalized strategies to boost rankings and maximize SEO
                    outcomes. Instead of expecting you to directly navigate package selection, we offer a complimentary
                    SEO Audit, analyzing specific focus keywords and industry strategies to refine your approach amidst
                    your company's competition and sector.</p>
            </div>

            <!-- table -->
            <div class="ptb-20 py-3">

                <h2 class="text-center mainheading mb-3">Choose Your <span class="prohead"> SEO
                        Package </span></h2>

                <!-- <h2 class="text-center mainheading otherHeading mb-3">Tailored SEO Packages by Pro Web <span
                        class="prohead"> for Rapid and Desired Results</span></h2> -->


                <div class="bar"></div>

                <div class="mobTabpills">

                    <ul>
                        <li (click)="seo(1)" [ngClass]="{'bgtab' : package == 1}">Startup</li>
                        <li (click)="seo(2)" [ngClass]="{'bgtab' : package == 2}">Gold</li>
                        <li (click)="seo(3)" [ngClass]="{'bgtab' : package == 3}">Enterprise</li>
                    </ul>

                </div>

                <!-- *************************************** -->

                <!-- Desktop -->

                <div class="tableContent">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gray">
                                <td>Expected Results</td>
                                <td class="text-center">9-12 Months</td>
                                <td class="text-center">6-9 Months</td>
                                <td class="text-center">3-6 Months</td>
                            </tr>

                            <tr class="gray">
                                <td>Price</td>
                                <td class="text-center"> <span class="pricecolor">AED 5500</span> Per Month</td>
                                <td class="text-center"> <span class="pricecolor">AED 7700</span> Per Month</td>
                                <td class="text-center"> <span class="pricecolor">AED 12850</span> Per Month</td>
                            </tr>

                            <tr class="white">
                                <td>Custom Strategy</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Keyword Research</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Error Fixing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Technical SEO Optimisation</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Content Research</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Pieces Per Month</td>
                                <td class="text-center"> 1</td>
                                <td class="text-center"> 2 </td>
                                <td class="text-center"> 4 </td>
                            </tr>
                            <tr class="white">
                                <td>Link Building Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Link Prospecting Per Month</td>
                                <td class="text-center"> 10 Hours </td>
                                <td class="text-center"> 20 Hours </td>
                                <td class="text-center"> 50 Hours </td>
                            </tr>

                            <tr class="white">
                                <td>Email Outreach</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Guest Posts Per Month</td>
                                <td class="text-center"> 2 to 4 </td>
                                <td class="text-center"> 5 to 8 </td>
                                <td class="text-center"> 9 to 16 </td>
                            </tr>

                            <tr class="white">
                                <td>Monthly Performance Reporting</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Mobile -->

                <div class="tableContentMobile">

                    <!-- one -->
                    <table class="table" *ngIf="package == 1">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Expected Results</td>
                                <td class="text-center">9-12 Months</td>

                            </tr>

                            <tr class="white">
                                <td>Price</td>
                                <td class="text-center"> <span class="pricecolor">AED 5500</span> Per Month</td>

                            </tr>

                            <!-- <tr>
                                <th colspan="4" class="heading">
                                    On-Page
                                    Optimization </th>
                            </tr> -->

                            <tr class="white">
                                <td>Custom Strategy</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Keyword Research</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Technical Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Technical Error Fixing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Technical SEO Optimisation</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Content Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Content Research</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Content Pieces Per Month</td>
                                <td class="text-center"> 1</td>

                            </tr>
                            <tr class="white">
                                <td>Link Building Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Link Prospecting Per Month</td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Email Outreach</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Guest Posts Per Month</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Monthly Performance Reporting</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                        </tbody>
                    </table>

                    <!-- two -->
                    <table class="table" *ngIf="package == 2">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Expected Results</td>
                                <td class="text-center">6-9 Months</td>
                            </tr>

                            <tr class="white">
                                <td>Price</td>
                                <td class="text-center"> <span class="pricecolor">AED 7700</span> Per Month</td>
                            </tr>

                            <!-- <tr>
                                <th colspan="4" class="heading">
                                    On-Page
                                    Optimization </th>
                            </tr> -->

                            <tr class="white">
                                <td>Custom Strategy</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Keyword Research</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Error Fixing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Technical SEO Optimisation</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Content Research</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Pieces Per Month</td>
                                <td class="text-center"> 2 </td>
                            </tr>
                            <tr class="white">
                                <td>Link Building Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Link Prospecting Per Month</td>
                                <td class="text-center"> 20 Hours </td>
                            </tr>

                            <tr class="white">
                                <td>Email Outreach</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Guest Posts Per Month</td>
                                <td class="text-center"> 5 to 8 </td>
                            </tr>

                            <tr class="white">
                                <td>Monthly Performance Reporting</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                        </tbody>
                    </table>

                    <!-- three -->
                    <table class="table" *ngIf="package == 3">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gray">
                                <td>Expected Results</td>
                                <td class="text-center">3-6 Months</td>
                            </tr>

                            <tr class="gray">
                                <td>Price</td>
                                <td class="text-center"> <span class="pricecolor">AED 12850</span> Per Month</td>
                            </tr>

                            <!-- <tr>
                                <th colspan="4" class="heading">
                                    On-Page
                                    Optimization </th>
                            </tr> -->

                            <tr class="white">
                                <td>Custom Strategy</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Keyword Research</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Error Fixing</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Technical SEO Optimisation</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Content Research</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Pieces Per Month</td>
                                <td class="text-center"> 4 </td>
                            </tr>
                            <tr class="white">
                                <td>Link Building Audit</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Link Prospecting Per Month</td>
                                <td class="text-center"> 50 Hours </td>
                            </tr>

                            <tr class="white">
                                <td>Email Outreach</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Guest Posts Per Month</td>
                                <td class="text-center"> 9 to 16 </td>
                            </tr>

                            <tr class="white">
                                <td>Monthly Performance Reporting</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                        </tbody>
                    </table>

                </div>

                <!-- ************************************* -->

            </div>

        </div>


    </div>


    <app-check-out checkoutImg="../../assets/img/servicesdev/checkout_seopackage.png" alt="check out"
        checkoutText="Check Out Our" checkoutName="SEO Service" checkoutUrl=""></app-check-out>

    <app-bottom-bar text="Looking for SEO Package for your Business?"></app-bottom-bar>


    <!-- faq's section -->
    <!-- <section class="spacing">
        <div class="container-md">
            <div class="row justify-content-center">
                <h2>Frequently <span>Asked Questions</span> </h2>
                <p class="my-3">Our industry expertise and research capabilities drive our leadership in developing advanced
                    engagement models.</p>
    
    
                <div class="row my-4">
                    <div class="col-sm-6">
                        <div class="faq-list" *ngFor="let item of faq.slice(0, 4); let i = index">
                            <div class="faq-title d-flex justify-content-between" (click)="toggleAccordian($event, i)">
                                <h6>{{item.title}}</h6>
    
                                <h4 *ngIf="item.isActive == false"> + </h4>
                                <h4 *ngIf="item.isActive == true"> - </h4>
                            </div>
                            <div class="faq-des" *ngIf="item.isActive">
                                <div [innerHTML]="item.des"></div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-sm-6">
                        <div class="faq-list" *ngFor="let item of faq.slice(4); let i = index">
                            <div class="faq-title d-flex justify-content-between" (click)="toggleAccordian($event, i+4)">
                                <h6>{{item.title}}</h6>
    
    
                                <h4 *ngIf="item.isActive == false"> + </h4>
                                <h4 *ngIf="item.isActive == true"> - </h4>
                            </div>
                            <div class="faq-des" *ngIf="item.isActive">
                                <div [innerHTML]="item.des"></div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    
    </section> -->

</section>