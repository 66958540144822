import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-why-choose-proweb',
  templateUrl: './why-choose-proweb.component.html',
  styleUrls: ['./why-choose-proweb.component.css']
})
export class WhyChooseProwebComponent {
  @Input() heading: any;
  @Input() desc: any;
  @Input() obj: any;


}
