import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-quality-assurance',
  templateUrl: './quality-assurance.component.html',
  styleUrls: ['./quality-assurance.component.css'],
})
export class QualityAssuranceComponent {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle(
      'Software Quality Assurance Company in Dubai | Pro Web'
    );
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Guarantee the quality of your software with our professional QA services. We offer thorough testing to detect and resolve issues, ensuring optimal performance and user experience. Rely on us for dependable and efficient software quality assurance solutions.',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'qa, qa testing, qa services, qa testing services, software qa, software testing company',
    });
  }

  taglinearr = [
    'Fast Creative & Reliable Websites',
    'Best-Rated UI/UX Websites Design',
    'Award Winning Agency',
    'Fast Creative & Reliable Websites',
    'Best-Rated UI/UX Websites Design',
    'Award Winning Agency',
    'Fast Creative & Reliable Websites',
    'Best-Rated UI/UX Websites Design',
    'Award Winning Agency',
  ];

  logo = [
    '/assets/img/servicesdev/web-development/book_my_pet_logo.webp',
    '/assets/img/servicesdev/web-development/smartdoor.svg',
    '/assets/img/servicesdev/ecom-dev/organic_mantra_logo.webp',
    '/assets/img/servicesdev/quality-assurance/classic_education.webp',
  ];

  logo2 = [
    '/assets/img/servicesdev/web-development/book_my_pet_logo.webp',
    '/assets/img/servicesdev/web-development/smartdoor.svg',
    '/assets/img/servicesdev/ecom-dev/organic_mantra_logo.webp',
  ];

  cardsItems = [
    {
      title: 'Web Testing',
      desc: "In the dynamic world of software development, Pro Web understands the critical importance of flawless web applications. Our rigorous testing methodologies ensure that every website meets the highest standards of functionality and reliability. From comprehensive testing across multiple devices and platforms to meticulous bug identification and resolution, Pro Web's approach guarantees a seamless user experience. By optimizing performance and maintaining application integrity, we empower businesses to thrive in today's competitive digital landscape.",
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'User Acceptance Testing',
      desc: 'At Pro Web, we recognize the critical role of User Acceptance Testing (UAT) in delivering superior software solutions. UAT involves end-users validating application functionality to ensure it meets expectations and business objectives. By simulating real-world scenarios, Pro Web identifies and addresses any discrepancies early on, refining the user experience and maximizing satisfaction. This meticulous approach guarantees that our clients receive robust, user-friendly applications that align perfectly with their operational needs and exceed expectations.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Performance Testing',
      desc: 'At Pro Web, we prioritize performance testing to ensure our software solutions operate seamlessly under diverse conditions. By evaluating responsiveness, stability, scalability, and speed, we guarantee that our applications meet stringent performance standards and exceed user expectations. Through rigorous stress testing and simulation of varying user loads, Pro Web identifies and resolves potential bottlenecks early, optimizing overall performance and ensuring reliable operation in every digital scenario.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Load Testing',
      desc: 'At Pro Web, we understand the pivotal role of load testing in assessing and optimizing system performance. Our rigorous approach involves simulating realistic user loads to evaluate how our applications respond under stress. By identifying and addressing performance bottlenecks early, Pro Web ensures that our solutions maintain stability and reliability even during peak usage. This proactive testing strategy not only enhances scalability but also guarantees a seamless user experience, reinforcing our commitment to delivering robust digital solutions.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Quality Assessment/Audits',
      desc: "Pro Web's Quality Assessment and Audit services are designed to meticulously evaluate and enhance organizational processes and systems. By conducting thorough assessments, we identify areas for improvement, ensuring adherence to industry standards and regulatory requirements. Our audits provide valuable insights into operational efficiency, risk management practices, and overall quality assurance. With Pro Web's expertise, businesses can confidently enhance their performance, maintain compliance, and elevate their standards to meet the demands of a competitive marketplace.",
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'VAPT Assessment',
      desc: "Pro Web offers comprehensive Vulnerability Assessment and Penetration Testing (VAPT) services to safeguard your digital infrastructure. VAPT involves systematic testing to identify weaknesses in networks, applications, and systems. Our experts simulate real-world attacks to assess vulnerabilities and provide actionable insights for remediation. With Pro Web's VAPT Assessment, businesses can strengthen their cybersecurity posture, mitigate risks proactively, and ensure the confidentiality, integrity, and availability of their critical assets in an increasingly complex threat landscape.",
      img: 'assets/img/servicesdev/globe.webp',
    },
  ];

  iconsdata = [
    {
      icon: 'assets/img/servicesdev/grad-one.png',
      name: 'Host In Dubai, UAE Data Center',
    },
    { icon: 'assets/img/servicesdev/grad-two.png', name: '20x Faster Speed' },
    {
      icon: 'assets/img/servicesdev/grad-three.png',
      name: 'Dedicated Support',
    },
    {
      icon: 'assets/img/servicesdev/firewall-icon.png',
      name: 'Rank Higher In Search Engine',
    },
    {
      icon: 'assets/img/servicesdev/country-data.png',
      name: 'In Country Data Compliance',
    },
  ];

  dataItems = [
    {
      heading: 'Functional Testing',
      description:
        'At Pro Web, our functional testing focuses on user needs, building QA processes around your business context and specific use cases. By understanding your niche, we ensure effective test coverage and eliminate unnecessary tests, delivering precise and efficient results.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Performance Testing',
      description:
        "Pro Web's QA engineers evaluate your solution for performance issues, risks, and vulnerabilities. Using the latest tools, we identify heavy load modules and bottlenecks. Our data-driven analysis informs testing scenarios to proactively address future performance challenges.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Security Testing',
      description:
        "Pro Web's security testing ensures your applications are protected against threats and vulnerabilities. Our experts use advanced tools to identify security gaps, simulate attacks, and provide actionable insights, ensuring robust protection and compliance with industry standards.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Usability Testing',
      description:
        "Pro Web's usability testing ensures intuitive, user-friendly applications. We conduct evaluations with real users to identify pain points and optimize the user experience. Our insights enhance functionality, boost satisfaction, and drive user engagement.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Compatibility Testing',
      description:
        "Pro Web's compatibility testing ensures your application performs seamlessly across various devices, browsers, and operating systems. We identify and resolve compatibility issues, ensuring a consistent user experience. Our comprehensive testing boosts reliability and user satisfaction.",
      bgimg: 'hosting-card.png',
    },

    {
      heading: 'Mobile App Testing',
      description:
        "Pro Web's mobile app testing ensures your application runs flawlessly across various devices and operating systems. Our experts identify bugs, optimize performance, and enhance user experience. With comprehensive testing, we guarantee reliability, security, and high user satisfaction.",
      bgimg: 'hosting-card.png',
    },

    {
      heading: 'Prioritized Local Support',
      description:
        "Pro Web's Prioritized Local Support ensures swift, tailored assistance for your business needs. Our dedicated local teams offer rapid response times, personalized solutions, and in-depth market knowledge. We prioritize your success, ensuring smooth operations and enhanced performance.",
      bgimg: 'hosting-card.png',
    },

    {
      heading: 'Bandwidth Brilliance',
      description:
        "Pro Web's Bandwidth Brilliance optimizes your network performance to ensure seamless connectivity and high-speed data transfer. Our advanced solutions maximize bandwidth efficiency, reduce latency, and enhance user experience. Achieve superior network efficiency with Pro Web.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Disk Space Upgrade',
      description:
        "Pro Web's Disk Space Upgrade ensures your systems have the storage capacity they need to handle growing data demands. Our solutions offer seamless expansion, optimized performance, and improved data management. Empower your business with Pro Web's enhanced storage solutions.",
      bgimg: 'hosting-card.png',
    },
  ];

  data = [
    'book_my_pet_logo.webp',
    'smart_door_logo.webp',
    'classic_centerlogo.png',
    'om_logo.webp',
    'niccs_logo.png',
  ];

  datamobile = [
    'book_my_pet_logo.webp',
    'smart_door_logo.webp',
    'om_logo.webp',
    'niccs_logo.png',
  ];

  cards = [
    {
      icon: '../../assets/img/servicesdev/quality-assurance/value_driven.svg',
      heading: 'Values Driven Approach',
      description:
        'We pride ourselves on embodying a values-driven approach in all our endeavors. This means taking full ownership of our processes, maintaining transparent communication, and continuously evolving through learning.',
    },
    {
      icon: '../../assets/img/servicesdev/quality-assurance/long_term_partnership.svg',
      heading: 'Long Term Partnership',
      description:
        'We prioritize building enduring partnerships based on trust, collaboration, and mutual success. We are committed to understanding your long-term goals and providing innovative solutions that evolve with your business needs.',
    },
    {
      icon: '../../assets/img/servicesdev/quality-assurance/agile_methodology.svg',
      heading: 'Agile Methodology',
      description:
        'Pro Web utilizes Agile methodology to deliver flexible, efficient solutions. Our iterative approach ensures rapid adaptation to changing requirements, continuous improvement, and timely delivery of high-quality products.',
    },
    {
      icon: '../../assets/img/servicesdev/quality-assurance/demonstrated.svg',
      heading: 'Demonstrated Competency',
      description:
        'Pro Web showcases proven expertise in consistently delivering excellent results. Our track record includes successful projects across diverse industries, highlighting our capability to meet and exceed client expectations.',
    },
    {
      icon: '../../assets/img/servicesdev/quality-assurance/technology.svg',
      heading: 'Technology Agnostic',
      description:
        'Pro Web excels in leveraging diverse tools and platforms to craft solutions perfectly aligned with your business needs. Our approach ensures scalability and future readiness for your digital endeavors.',
    },
    {
      icon: '../../assets/img/servicesdev/quality-assurance/commitment_quality.svg',
      heading: 'Commitment to Quality',
      description:
        'We uphold unwavering standards of excellence in every aspect of our service delivery. From meticulous planning to rigorous testing, we ensure our solutions meet and exceed industry benchmarks.',
    },
  ];

  faq = [
    {
      title: 'What is Quality Assurance (QA)?',
      des: '<p> Quality Assurance (QA) is a systematic process aimed at ensuring that products and services meet specified requirements and standards. It involves various testing methods to identify and fix defects before the final release.</p>',
      isActive: false,
    },
    {
      title: 'Why does my business need software testing services?',
      des: '<p>Your business needs software testing services to ensure your applications are reliable, secure, and user-friendly. Testing identifies and fixes bugs early, improves software quality, enhances user satisfaction, and reduces the risk of costly errors post-deployment.</p>',
      isActive: false,
    },
    {
      title: 'What types of testing does Pro Web offer?',
      des: '<p>Pro Web offers a comprehensive range of testing services, including functional testing, performance testing, security testing, usability testing, compatibility testing, and mobile app testing.</p>',
      isActive: false,
    },
    {
      title: 'How does Pro Web ensure the quality of its testing processes?',
      des: "<p> Pro Web ensures quality through rigorous testing methodologies, continuous training of QA engineers, use of advanced testing tools, and adherence to industry best practices. We also customize our approach based on each client's unique needs.</p>",
      isActive: false,
    },
    {
      title: 'When do I need manual and automation QA services?',
      des: '<p>You need manual QA for exploratory testing and scenarios requiring human judgment. Automation QA is ideal for repetitive tests to ensure consistency, efficiency, and broader test coverage in your software development lifecycle.</p>',
      isActive: false,
    },
    {
      title: 'Can Pro Web integrate QA into our existing development workflow?',
      des: "<p>Yes, ProWeb seamlessly integrates QA into your existing development process, whether Agile, Waterfall, or another methodology. We adapt our approach to fit your team's workflow for minimal disruption and maximum efficiency.</p>",
      isActive: false,
    },
    {
      title: 'How does Pro Web measure the success of QA efforts?',
      des: '<p>Pro Web measures QA success through metrics such as defect density, test coverage, bug fix turnaround time, and user acceptance rates. These metrics help gauge the effectiveness of QA processes and ensure continuous improvement.</p>',
      isActive: false,
    },
    {
      title: "What are the typical deliverables from ProWeb's QA services?",
      des: '<p>Typical deliverables include comprehensive test plans, detailed test cases, bug reports with severity and priority ratings, test automation scripts, and QA documentation ensuring transparency and traceability.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }

  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
