import { Component , Input } from '@angular/core';

@Component({
  selector: 'app-services-offer',
  templateUrl: './services-offer.component.html',
  styleUrls: ['./services-offer.component.css']
})
export class ServicesOfferComponent {
  @Input() firsttitle: any;
  @Input() semititle: any;
  @Input() description: any;
  @Input() cards: any;
}
