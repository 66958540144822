<section>
  <!-- banner section -->
  <app-anim-banner
    heading="Hire Expert Node.js Developers at Pro Web for Innovative Projects"
    description="Master the digital landscape with our Node.js development services. We build high-performance, scalable web applications tailored to your business needs. Combining speed and efficiency, our solutions ensure seamless user experiences and robust backend support.
    "
    image="nodejs/nodejs-anim.png"
  ></app-anim-banner>

  <!-- popular brands -->
  <!-- <app-brands [brands]="data3"></app-brands> -->

  <!-- services -->
  <app-services-offer
    [cards]="cardsItems"
    firsttitle="Our Node.js Development"
    semititle="Services"
    description="Unlock innovative possibilities with our exceptional Node.js Development Services, offering bespoke, scalable solutions. Our proficient team harnesses Node.js to create dynamic, real-time applications tailored to your business requirements. We guarantee seamless integration, enhanced security, and an intuitive user experience, fostering innovation and growth. Transform your digital presence with our Node.js mastery."
  ></app-services-offer>

  <!-- empower business -->
  <div class="learn_business">
    <div class="container fullwidth">
      <div class="row align-items-center gy-3">
        <div class="col-md-5">
          <div>
            <h2>
              Benefits of Leveraging Node.js for Web Application Development
            </h2>
            <p>
              Choosing our Node.js development services means embracing visually
              compelling brand identities that harmonize flawlessly with your
              business objectives. Our seasoned Node.js developers specialize in
              crafting bespoke web applications that mirror your brand's
              essence, ensuring a lasting impression.
            </p>
          </div>
        </div>
        <div class="col-md-7">
          <div class="empower_wrapper">
            <div>
              <img
                src="../../assets/img/servicesdev/nodejs/steps_img.svg"
                alt=""
                srcset=""
              />
            </div>

            <div class="emplower_gif">
              <img
                src="../../assets/img/servicesdev/react-native/cost-saving.gif"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- engagement model -->
  <div class="engagement">
    <app-engagement-modal></app-engagement-modal>
  </div>

  <!-- flutter app idea -->

  <app-steps-choose
    heading="Streamlining Node.js Development for Seamless Success"
    description="From inception to execution, our Node.js expertise ensures tailored solutions that harmonize with each client's distinct vision and objectives. Our focus on personalized experiences ensures applications that enhance user engagement and operational efficiency, driving innovation in technology solutions."
    [steps]="data"
  ></app-steps-choose>

  <!-- why choose -->

  <div class="why-choose">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6">
          <div>
            <h2>Why Choose Pro Web as Your Node.js Development Company?</h2>
            <p>
              Embrace Pro Web as your Node.js development partner and harness
              our expertise to propel your digital ambitions. Our seasoned team
              specializes in crafting scalable solutions tailored to your
              specific needs, ensuring seamless integration and optimal
              performance. From innovative web applications to robust mobile
              solutions, we empower your business with cutting-edge technology
              and unwavering support, driving sustained growth and success.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mt-3 mt-md-0">
            <img
              src="../../assets/img/servicesdev/native/native-img.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- review -->
  <app-testimonial-service
    profile="gtc_profile.svg"
    clientname="Talib Sadiq Janwari"
    designation="Finance Officer GTC"
    location="Dubai, United Arab Emirates"
    flag="uk-flag.svg"
    clientcomment="We have now had 2 sites built with Unisys and can confirm that the team is extremely efficient, always available and make the process to building a site considerably stress free when comparing to past developers we have engaged with. We highly recommend to use their services, we genuinely cannot point at any dissatisfaction."
    rating="Rated 4.9 out of 5.0"
    [clientimg]="data2"
    [clientmobile]="datamobile"
    clienttarget="gtc_logo.png"
  ></app-testimonial-service>

  <!-- contact -->
  <app-bottom-bar
    text="Get in touch with Pro Web and redefine your digital future with Node.js"
  ></app-bottom-bar>

  <!-- faq's -->
  <section class="spacing">
    <div class="container-md">
      <div class="row justify-content-center justify-content-md-start">
        <h2>Frequently <span>Asked Questions </span></h2>
        <p class="my-3">
          Discover insights to deepen your grasp of Flutter fundamentals
        </p>

        <div class="row my-4">
          <div class="col-sm-6">
            <div
              class="faq-list"
              *ngFor="let item of faq.slice(0, 4); let i = index"
            >
              <div
                class="faq-title d-flex justify-content-between"
                (click)="toggleAccordian($event, i)"
              >
                <h6>{{ item.title }}</h6>

                <h4 *ngIf="item.isActive == false">+</h4>
                <h4 *ngIf="item.isActive == true">-</h4>
              </div>
              <div class="faq-des" *ngIf="item.isActive">
                <div [innerHTML]="item.des"></div>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div
              class="faq-list"
              *ngFor="let item of faq.slice(4); let i = index"
            >
              <div
                class="faq-title d-flex justify-content-between"
                (click)="toggleAccordian($event, i + 4)"
              >
                <h6>{{ item.title }}</h6>

                <h4 *ngIf="item.isActive == false">+</h4>
                <h4 *ngIf="item.isActive == true">-</h4>
              </div>
              <div class="faq-des" *ngIf="item.isActive">
                <div [innerHTML]="item.des"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
