import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';


@Component({
  selector: 'app-web-maintenance-package',
  templateUrl: './web-maintenance-package.component.html',
  styleUrls: ['./web-maintenance-package.component.css']
})
export class WebMaintenancePackageComponent {
  title = 'Web Maintenance Packages for Peak Performance | Pro Web Dubai';



  addclass = false


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private loadingService: LoadingService,

  ) { }


  logoarr = ['/assets/img/packages/maison-logo.svg', '/assets/img/packages/audi-logo.svg', '/assets/img/packages/cerruti-logo.svg', '/assets/img/packages/neo-logo.svg'];
  logoarr2 = ['/assets/img/packages/neo-mobile.png', '/assets/img/packages/audi-mobile.png', '/assets/img/packages/maison-mobile.png'];

  data = [{ btnName: 'Web Design', checkoutUrl: '/web-design-company/dubai' }, { btnName: 'Web Development', checkoutUrl: 'web-development-company/dubai' } , { btnName: 'E-Commerce Development', checkoutUrl: '/ecommerce-development-company/dubai' } , { btnName: 'Mobile App Development', checkoutUrl: '/mobile-app-development-company/dubai' }]

  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000)

    this.titleService.setTitle("Web Maintenance Packages in Dubai | Pro Web");

    this.metaTagService.updateTag(
      { name: "keywords", content: "web maintenance packages" }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: "Maximize your website investment with Pro Web's Website Maintenance Packages. Tailored solutions for eCommerce, applications, and major CMS platforms." }
    );

  }

  package: any = 1;
  toggle = false

  // seo package click function
  web(a: any) {
    this.package = a;
  }

  budgetChange() {
    this.toggle = !this.toggle
  }

  //  collapse table
  showdata() {
    this.addclass = !this.addclass
  }

}
