import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoadingService } from '../utils/services/loading.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	animations: [
		trigger('fade',
			[
				state('void', style({ opacity: 0 })),
				transition(':enter', [animate(300)]),
				transition(':leave', [animate(500)])
			]
		)
	],
	providers: [
		Location, {
			provide: LocationStrategy,
			useClass: PathLocationStrategy
		}
	]
})

export class HeaderComponent implements OnInit {
	isMobile:  boolean | undefined;
	isMenuOpen: boolean = false;
	isLoading: boolean = false;
	public show: boolean = false;
	public buttonName: any = 'Show';
  	hideHeader: boolean = false;
	serviceVisible: boolean = true;
	packageVisible: boolean = true;
	public navbarOpen = false;
	public clicked = false;
	_el: any;
	headerShow: boolean = false;
	isElementVisible = false;

	toggleNavbar = () => {
		this.navbarOpen = !this.navbarOpen;
	}

	constructor(
		private router: Router,
		private loadingService: LoadingService,
		private cdr: ChangeDetectorRef,
		private platform: Platform
	) {
		this.isMobile = this.platform.ANDROID || this.platform.IOS;
	}

  	ngOnInit() {
		this.loadingService.loading$.subscribe((isLoading) => {
			this.isLoading = isLoading;
			this.cdr.detectChanges(); // Manually trigger change detection
		});

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.hideHeader = event.url === '/thankyou'; // Adjust path as needed
			}
		});
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
		this.updateBodyOverflow(this.isMenuOpen);
	}
	openMenu() {
		this.isMenuOpen = true;
		this.updateBodyOverflow(true);
	}
	closeMenu() {
		this.isMenuOpen = false;
		this.updateBodyOverflow(false);
	}
	updateBodyOverflow(isOverflowHidden: boolean) {
		document.body.classList.toggle('overflow-hidden', isOverflowHidden);
		document.documentElement.classList.toggle('overflow-hidden', isOverflowHidden);
		document.documentElement.style.overflowY = isOverflowHidden ? 'hidden' : 'scroll';
	}
	onMenuItemClick() {
		this.closeMenu();
	}
	hideService() {
		this.serviceVisible = !this.serviceVisible;
	}
	serviceVisibility() {
		this.serviceVisible = !this.serviceVisible;
	}
	packageVisibility() {
		this.packageVisible = !this.packageVisible;
	}
	hidePackage() {
		this.packageVisible = !this.packageVisible;
	}

	toggle() {
		this.show = !this.show;
		if (this.show)
			this.buttonName = "Hide";
		else
			this.buttonName = "Show";
	}

  	// mobile menu dropdown
	onClick(event: FocusEvent): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this.clicked = true;
	}

	@HostListener('window:click', ['$event'])
	clickedOutside(): void {
		if (this.clicked) {
			this._el.nativeElement.querySelector('dropdown-menu').classList.toogle('show')
		}
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll() {
		if (window.pageYOffset > 100) {
			let element = document.getElementById('header');
			element && element.classList.add('is-sticky');
		} else {
			let element = document.getElementById('header');
			element && element.classList.remove('is-sticky');
		}
	}

	toggleNav() {
		this.headerShow = !this.headerShow
	}

	// toggleElement() {
	// 	this.isElementVisible = !this.isElementVisible;
	// }
}
