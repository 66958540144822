import { Component, OnInit,ViewChild  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { environment } from '../../environments/environment';
import { AlertService } from '../_alert';


@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.css']
})

export class FormComponent implements OnInit {
	currentStep: number = 1;
	totalSteps: number = 4;
	progressBarValue: number = 20; 

	mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

	apiurl = environment.apiUrl;
	blogUrl = environment.blogUrl

	seoForm: any = FormGroup;
	submitted  = false;
	isDisabled = false;
	country_code = '';
	showLoader   = false;

	websiteType = "";
	keywordCount = "";
	targetCountry = "";
	countries: any = [];

	constructor(
		private formBuilder: FormBuilder,
		private http: HttpClient,
		private router: Router,
		private alertService: AlertService
	) { }

	ngOnInit() {

		this.http.get(this.blogUrl + 'countries').subscribe(async response => {
			let data: any = response;
			this.countries = data.response;
			// console.log("this.countries", this.countries)
		});

		this.seoForm = this.formBuilder.group({
			site_url: ['', Validators.required],
			name: ['', Validators.required],
			country_code: ['', Validators.required],
			phone_number: ['', Validators.required],
			company_name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]]
		});

		this.http.get('https://ipapi.co/json').subscribe(async data => {
			let ClientsData: any = data;
			var countryCode = ClientsData.country_calling_code.replace('+', '');
			this.seoForm.controls.country_code.setValue(countryCode);
		});
	}

	get fn() { return this.seoForm.controls; }

	onSubmit(event: any) {
		this.submitted = true;
		if (this.seoForm.invalid) {
			return;
		}
		this.showLoader = true;
		const formData = new FormData(event.target);
		formData.append("website_type", this.websiteType);
		formData.append("no_of_keywords", this.keywordCount);
		formData.append("target_country", this.targetCountry);
		this.http.post(this.blogUrl + 'save_seo_enquiry', formData).subscribe(data => {
			this.isDisabled = false;
			this.showLoader = false;
			let ClientsData: any = data;
			if (ClientsData.success == true) {
				this.alertService.clear();
				this.alertService.success(ClientsData.message);
				this.submitted = false;
				this.seoForm.reset();
				this.router.navigate(['/thankyou']);
			} else {
				this.alertService.clear();
				this.alertService.error(ClientsData.message);
			}
		});
	}

	telInputObject(obj: any) {
		obj.setCountry('in');
	}

	countryChange(country: any) {
		this.seoForm.controls.country_code.setValue(country.dialCode);
	}

	numberOnly(event: any): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	goToNextStep(type: any, value: any) {
		if(type == '1') {
			this.websiteType = value;
		}
		if(type == '2') {
			this.keywordCount = value;
		}
		if(type == '3') {
			this.targetCountry = value;
		}


		if (this.currentStep < this.totalSteps) {
			this.currentStep++;
			this.updateProgressBar();
		}
	}
	
	goToPreviousStep() {
		if (this.currentStep > 1) {
			this.currentStep--;
			this.updateProgressBar();
		}
	}
	
	updateProgressBar() {
		this.progressBarValue = (this.currentStep - 1) * (100 / this.totalSteps);
	}
}