import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-artificial-intelligence',
  templateUrl: './artificial-intelligence.component.html',
  styleUrls: ['./artificial-intelligence.component.css'],
})
export class ArtificialIntelligenceComponent {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle('AI Development Company in Dubai | Pro Web');
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Unlock the potential of your website with our AI development services. From advanced analytics to personalized user experiences, we craft cutting-edge solutions tailored to propel your web presence forward. Elevate your digital strategy with AI expertise.',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'AI, AI Company, AI Development Company , AI services, AI development services, AI development company in dubai',
    });
  }

  taglinearr = [
    'Futuristic AI',
    'Intelligent Automation',
    'Holistic Analytics',
    'Futuristic AI',
    'Intelligent Automation',
    'Holistic Analytics',
  ];

  cardsItems2 = [
    {
      title: 'Data Annotation',
      desc: 'Pro Web specializes in data annotation, employing generative AI to streamline the process. Our advanced techniques ensure precise labeling of datasets, enhancing model accuracy and efficiency. Leveraging generative AI, we automate annotation tasks, reducing manual effort and accelerating project timelines. Trust Pro Web to deliver meticulous data annotation services, empowering your AI initiatives with unparalleled precision and speed.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Computer Vision',
      desc: "Revolutionize your business's engagement with the visual realm with Pro Web's Computer Vision offerings. Our expertise lies in object identification, image categorization, facial detection, and automated video assessment. Our tailor-made solutions facilitate automated quality assessments, bolstered security measures, and in-depth data scrutiny derived from visual inputs. By incorporating our state-of-the-art Computer Vision capabilities, we empower you to enhance operational efficacy and pioneer groundbreaking user interactions.",
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Sports Analytics',
      desc: 'Pro Web leads the charge in sports analytics, harnessing the power of AI. We delve deep into data troves, revealing nuanced trends for informed decision-making by teams. With advanced AI, we forecast player prowess, fine-tune strategies, and foresee outcomes with remarkable precision. Count on Pro Web to transform sports analytics, propelling your team towards peak performance through data-backed insights. Our solutions also boost fan engagement, offering personalized experiences and deeper connections with the game.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'AI Surveillance Systems',
      desc: "Pro Web revolutionizes surveillance with AI-powered cameras. Our advanced systems leverage AI & cutting-edge technology to enhance security. They detect anomalies, analyze behaviors, and automate threat response, ensuring proactive monitoring. With intelligent features like facial recognition and predictive analytics, Pro Web's AI cameras provide real-time insights, enabling swift action and informed decision-making. Trust us for cutting-edge surveillance solutions that prioritize safety and efficiency.",
      img: 'assets/img/servicesdev/globe.webp',
    },

    {
      title: 'AI Governance',
      desc: 'Pro Web prioritizes AI governance, regulating AI development, deployment, and use for ethical, transparent, and accountable practices. Our integrated approach ensures accessibility, security, and reliability in online AI applications, fostering responsible innovation and user trust. We uphold ethical standards, protect privacy, and promote transparency and accountability in AI-driven decisions. Pro Web collaborates with experts and policymakers to refine AI governance frameworks, staying ahead of emerging challenges and opportunities.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Large Language Models (LLM)',
      desc: "Pro Web's proficiency in Large Language Models (LLM) equips your enterprise with unparalleled AI-driven text generation and understanding capabilities. Whether it's automating content generation or enhancing decision-making workflows, our LLM solutions are engineered to disseminate knowledge efficiently throughout your organization. We offer tailored LLM training, refinement, and incorporation services, guaranteeing that your business leverages state-of-the-art technology to maintain a competitive edge.",
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Data Labelling',
      desc: 'Pro Web employs advanced AI techniques, including cutting-edge AI, for efficient data labeling. Our approach leverages machine learning algorithms to automate and streamline the data labeling process, ensuring accuracy and scalability. Innovative AI models generate synthetic data to augment labeled datasets, enhancing AI model training and performance. This combination of AI technologies enables Pro Web to deliver high-quality labeled datasets for various applications, from computer vision to natural language processing, promoting innovation and reliability in AI systems.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Custom Chatbot',
      desc: 'Pro Web excels in creating bespoke chatbots driven by AI and state-of-the-art technology. These chatbots are customized to suit businesses, delivering personalized user interactions. Powered by advanced AI, our chatbots comprehend intents, engage naturally, and offer timely responses. They evolve continuously, adapting to user preferences and market dynamics. Count on Pro Web for cutting-edge chatbot solutions that elevate customer engagement, optimize operations, and fuel business expansion.',
      img: 'assets/img/servicesdev/globe.webp',
    },
  ];

  iconsdata = [
    {
      icon: 'assets/img/servicesdev/artificial-intelligence/grad-one.png',
      name: 'Advanced algorithm understanding',
    },
    {
      icon: 'assets/img/servicesdev/artificial-intelligence/grad-two.png',
      name: 'Cross-industry experience',
    },
    {
      icon: 'assets/img/servicesdev/artificial-intelligence/grad-three.png',
      name: 'Conversational AI Model',
    },
    {
      icon: 'assets/img/servicesdev/artificial-intelligence/grad-four.png',
      name: 'In-depth Industry Knowledge',
    },
    {
      icon: 'assets/img/servicesdev/artificial-intelligence/grad-five.png',
      name: 'Innovative Solutions',
    },
  ];

  dataItems = [
    {
      heading: 'Custom AI Solutions',
      description:
        'We focus on personalized treatment recommendations, dynamic pricing models, and adaptive learning platforms and harness AI to enhance efficiency and productivity. Our collaborative efforts refine and fine-tune specialized models and algorithms, seamlessly integrating them into existing systems to drive optimal performance.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Machine Learning',
      description:
        'We specialize in integrating machine learning, deep learning, and other AI-powered network operating systems into your IT infrastructure. This enables your systems to analyze business data, uncover patterns, and automate critical processes. Our machine learning software development expertise spans cybersecurity, healthcare, marketing automation, finance, and banking.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Deep Learning',
      description:
        'We specialize in implementing deep learning-based solutions for advanced speech and facial recognition, automation, and business intelligence. Our deep learning AI software expertise enables us to deliver advanced solutions that drive innovation and efficiency in various industries.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Natural Language Processing',
      description:
        'We specialize in developing NLP programming languages customized for analysts to analyze structured and semi-structured content, including search queries, web-mined data, company information repositories, and audio sources. Our NLP development tools identify emerging trends, provide operational insights, and facilitate the development of predictive models.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Robotic Process Automation',
      description:
        'Our AI developers specialize in creating and integrating robotic process automation (RPA) programs to streamline workflows. These programs excel in extracting structured and semi-structured data from documents, copying and pasting data, moving files and folders, scraping browsers, making calculations, and more.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Large Language Models',
      description:
        "Our proficiency in large language models (LLM) equips your business with advanced AI-driven text generation and comprehension capabilities. Our LLM solutions scale knowledge across your organization's functions, from automating content creation to enhancing decision-making processes. We offer custom LLM training, fine-tuning, and integration services.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Data Annotation',
      description:
        'With a meticulous focus on precision and quality, our experienced annotators add metadata, tags, bounding boxes, key points, and other annotations to your raw data. Whether you need AI data annotation or specialized labeling services, we ensure your data is accurately labeled and primed for high-performance machine learning model training.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Object Detection',
      description:
        "Our advanced object detection and recognition solutions are designed to empower our clients to revolutionize their operations with unparalleled precision and enhanced accuracy. We specialize in creating bespoke solutions customized to each client's requirements, leveraging advanced AI models for spatial and object recognition.",
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'AI Pipeline Development',
      description:
        'Our expertise lies in integrating data from various sources into a unified view. We extract data from systems like CRM, ERP, and databases using advanced methods and tools, transforming it into a standardized format before loading it into a target system. Our data processing capabilities ensure immediate insights and operational agility as data is processed and enters the system.',
      bgimg: 'hosting-card.png',
    },
  ];

  cards = [
    {
      icon: '../../assets/img/servicesdev/artificial-intelligence/data-centric-icon.png',
      heading: 'Data-Centric Approach',
      description:
        'Our approach involves placing data at the core of every decision and solution. By meticulously analyzing and leveraging data insights, we ensure that our strategies and implementations are precisely tailored to meet your business objectives.',
    },

    {
      icon: '../../assets/img/servicesdev/artificial-intelligence/outcome-driven-icon.png',
      heading: 'Outcome-Driven',
      description:
        'We are dedicated to delivering tangible results that align with your desired outcomes. Our focus remains steadfast on driving measurable success and creating value for your organization through our AI and machine learning solutions.',
    },

    {
      icon: '../../assets/img/servicesdev/artificial-intelligence/tranparent-icon.png',
      heading: 'Transparent & Secure',
      description:
        'Transparency and security are paramount in our operations. We maintain open communication channels to inform you at every step of the process and prioritize robust security measures to safeguard your data and assets.',
    },

    {
      icon: '../../assets/img/servicesdev/artificial-intelligence/demonstrated-icon.png',
      heading: 'Demonstrated Competency',
      description:
        'Our team brings unparalleled expertise to the table. With a proven track record of successfully implementing AI and machine learning solutions across various industries, our past achievements speak volumes about our competency and capabilities.',
    },

    {
      icon: '../../assets/img/servicesdev/artificial-intelligence/technology-icon.png',
      heading: 'Technology Agnostic',
      description:
        'We believe in selecting the most suitable technologies tailored to your needs rather than a one-size-fits-all approach. Our agnostic stance ensures that we utilize the latest innovations to deliver optimal outcomes for your business.',
    },

    {
      icon: '../../assets/img/servicesdev/artificial-intelligence/commitment-icon.png',
      heading: 'Commitment to Quality',
      description:
        'Quality is ingrained in everything we do. From initial consultation to post-deployment support, we maintain rigorous quality standards to ensure that our solutions consistently exceed expectations and deliver long-term value.',
    },
  ];

  logo = [
    '/assets/img/servicesdev/web-design/whites.webp',
    '/assets/img/servicesdev/web-design/x-cloud.svg',
    '/assets/img/servicesdev/web-design/neo_realty_logo.svg',
    '/assets/img/servicesdev/web-design/the_bezel_club_logo.svg',
  ];

  logo2 = [
    '/assets/img/servicesdev/web-design/whites.webp',
    '/assets/img/servicesdev/web-design/x-cloud.svg',
    '/assets/img/servicesdev/web-design/neo_realty_logo.svg',
  ];

  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  faq = [
    {
      title: 'What is Artificial Intelligence ?',
      des: '<p>Artificial intelligence (AI) refers to the simulation of human intelligence in machines programmed to mimic tasks such as learning, problem-solving, and decision-making. It encompasses various techniques like machine learning, natural language processing, and computer vision.</p>',
      isActive: false,
    },
    {
      title: ' How do AI Models Work?',
      des: '<p>AI models process data using algorithms to learn patterns and relationships. Through training, they adjust parameters to minimize errors. Tailored architectures enable tasks like image recognition or language processing. After validation, they make predictions and learn from new data, improving over time.</p>',
      isActive: false,
    },
    {
      title: 'Why Should I Integrate AI into my Business?',
      des: '<p>Integrating AI drives efficiency, innovation, and informed decisions by analyzing data, automating tasks, and enhancing customer experiences. It keeps you agile in a competitive landscape, unlocking growth and innovation opportunities at the forefront of technology.</p>',
      isActive: false,
    },
    {
      title: 'Can you Integrate AI into Existing Systems?',
      des: '<p>We are adept at seamlessly integrating AI into your systems. Our approach includes thorough analysis, customization for compatibility, and rigorous testing for efficiency. We assess current setups, identify use cases, ensure authentic data, and tailor solutions for enhanced functionality without disruption, providing optimal performance and tangible value.</p>',
      isActive: false,
    },
    {
      title: 'What are the Types of AI Solutions you Build?',
      des: "<p>We specialize in building diverse AI solutions tailored to your needs, including natural language processing, computer vision, predictive analytics, and recommendation systems. Whether it's automating processes or enhancing decision-making, we have you covered with our expertise.</p>",
      isActive: false,
    },
    {
      title:
        "What are the Benefits of Using Pro Web's AI-Powered Surveillance Systems?",
      des: "<p>Pro Web's AI-powered surveillance systems enhance security by detecting anomalies, analyzing behaviors, and automating threat response. With features like facial recognition and predictive analytics, our AI cameras provide real-time insights, enabling swift action and informed decision-making.</p>",
      isActive: false,
    },

    {
      title: ' How do you Ensure the Security of an AI Project?',
      des: '<p>For AI project security, we employ robust measures including data encryption, access controls, and secure architecture. Prioritizing user authentication and regular audits, we utilize advanced intrusion detection for real-time threat detection. Adhering to compliance standards ensures confidentiality and integrity.</p>',
      isActive: false,
    },
    {
      title:
        ' What is the Typical Duration to Develop and Implement AI Solutions?',
      des: '<p>The timeline for developing and implementing AI solutions varies widely. Basic models may take weeks, while complex, large-scale implementations can span months or longer. Factors like data availability, model complexity, and integration level influence the timeline. For a precise estimate, consult with our AI experts!</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }
}
