import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: ['./refund-policy.component.css']
})
export class RefundPolicyComponent implements OnInit {

  // Meta Title
	title = 'Refund Policy - RPA Technology PVT LTD';	

	constructor(
		private titleService: Title,
		private metaTagService: Meta
	) { }

	ngOnInit() {

		// Meta Title & Description
	  this.titleService.setTitle(this.title);
	  this.metaTagService.updateTag(
	    { name: 'description', content: 'Top Rated Web Design & App Development Agency with more than 11 years experience in building results driven custom web based solutions.' }
	  );
	// Meta Title & Description
  }

}
