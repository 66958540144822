import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';


@Component({
	selector: 'app-terms-of-services',
	templateUrl: './terms-of-services.component.html',
	styleUrls: ['./terms-of-services.component.css']
})
export class TermsOfServicesComponent implements OnInit {

	// Meta Title
	title = 'Terms and Conditions - Pro Web';

	constructor(
		private titleService: Title,
		private metaTagService: Meta,
		private loadingService: LoadingService,

	) { }

	ngOnInit() {
		this.loadingService.setLoadingState(true);

		setTimeout(() => {
			this.loadingService.setLoadingState(false);
		}, 2000)
		// Meta Title & Description
		this.titleService.setTitle("Pro Web Terms : Understanding Our Terms and Policies");
		this.metaTagService.updateTag(
			{ name: 'description', content: "Explore Pro Web's policies, ensuring clarity and understanding in our terms and conditions for seamless collaborations." }
		);
		this.metaTagService.updateTag(
			{ name: "keywords", content: "pro web's terms and conditions, pro web's terms of use" }
		  );
		// Meta Title & Description
	}

}
