import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-customized-solutions',
  templateUrl: './customized-solutions.component.html',
  styleUrls: ['./customized-solutions.component.css']
})
export class CustomizedSolutionsComponent {
  @Input() htmlStr: any;
}
