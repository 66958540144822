import { Component, Input , ViewChild, ElementRef} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.css']
})
export class CheckOutComponent {
  @Input() checkoutImg: any;
  @Input() checkoutText: any;
  @Input() checkoutName: any;
  @Input() checkoutUrl: any;
  @Input() checkout_arr: any;
  currentRoute = '';

  @ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

}
