import { Component } from '@angular/core';

@Component({
  selector: 'app-engagement-modal',
  templateUrl: './engagement-modal.component.html',
  styleUrls: ['./engagement-modal.component.css']
})
export class EngagementModalComponent {

}
