import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';

@Component({
	selector: 'app-sitemap',
	templateUrl: './sitemap.component.html',
	styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {

	// Meta Title
	title = 'Sitemap - RPA Technology PVT LTD';

	constructor(
		private titleService: Title,
		private metaTagService: Meta,
		private loadingService: LoadingService,

	) { }

	ngOnInit() {
		this.loadingService.setLoadingState(true);

		setTimeout(() => {
			this.loadingService.setLoadingState(false);
		}, 2000)
		// Meta Title & Description
		this.titleService.setTitle("Pro Web Sitemap: Easy Navigation for SEO");
		this.metaTagService.updateTag(
			{ name: 'description', content: "Explore Pro Web's intuitive sitemap for effortless navigation and elevate your online presence with our innovative web solutions." }
		);
		this.metaTagService.updateTag(
			{ name: "keywords", content: "sitemap" }
		  );
		// Meta Title & Description
	}

}
