import { Component, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-dedicated-hosting',
  templateUrl: './dedicated-hosting.component.html',
  styleUrls: ['./dedicated-hosting.component.css']
})
export class DedicatedHostingComponent {

  @Input() heading: any;
  @Input() subheading: any;
  @Input() data: any;
  currentRoute = '';


  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        console.log('cureent', this.currentRoute)
      }
    });
  }

}
