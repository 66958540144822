import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  isMobile = true;
  isDesktop = true;

  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    if (width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
    }
  }

  // Meta Title
  title = 'Privacy Policy - Pro Web';

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private loadingService: LoadingService,

  ) { }

  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000)
    // Meta Title & Description
    this.titleService.setTitle("Pro Web's Policy Guidelines : Privacy Assisstance");
    this.metaTagService.updateTag(
      { name: 'description', content: "Discover how we responsibly collect, protect, and utilize your information for a secure online experience. Read more about our responsible practices." }
    );
    this.metaTagService.updateTag(
      { name: "keywords", content: "privacy policy at pro web" }
    );
    // Meta Title & Description

    /* Generate code according to resolution open here */

    if (window.screen.width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    }
    else {
      this.isMobile = false;
      this.isDesktop = true;
    }

    /* Generate code according to resolution close here */
  }

}
