import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-testimonial-service',
  templateUrl: './testimonial-service.component.html',
  styleUrls: ['./testimonial-service.component.css']
})
export class TestimonialServiceComponent {

  @Input() clientimg: any;
  @Input() clientmobile: any;
  @Input() clienttarget: any;
  @Input() clientname: any;
  @Input() brandname: any;
  @Input() profile: any;
  @Input() designation: any;
  @Input() flag: any;
  @Input() location: any;
  @Input() clientcomment: any;
  @Input() rating: any;


  currentRoute: any = '';

  @ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }


}