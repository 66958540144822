<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <h2>Features</h2>
            </div>
        </div>
    </div>
    
    <div class="shape1"><img src="assets/img/shape1.png" alt="shape"></div>
    <div class="shape2 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.svg" alt="shape"></div>
    <div class="shape4"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape5"><img src="assets/img/shape5.png" alt="shape"></div>
    <div class="shape6 rotateme"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape7"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
</div>

<section class="features-area ptb-80 bg-f9f6f6">
    <div class="container">
        <div class="section-title">
            <h2>Our Features</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3><a href="#">Incredible Infrastructure</a></h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3><a href="#">Email Notifications</a></h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3><a href="#">Simple Dashboard</a></h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3><a href="#">Information Retrieval</a></h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3><a href="#">Drag and Drop Functionality</a></h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3><a href="#">Deadline Reminders</a></h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-area-two ptb-80 bg-f7fafd">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12 services-content">
                <div class="section-title">
                    <h2>Experience The Best Features Ever</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                           Secure & Fast
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Rich experience
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                             Support Stuffs
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Forex trading
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                           File backups
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Remote desktop
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                           Email servers
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                           Hybrid cloud
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 services-right-image">
                <img src="assets/img/services-right-image/book-self.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="book-self">
                <img src="assets/img/services-right-image/box.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="box">
                <img src="assets/img/services-right-image/chair.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="chair">
                <img src="assets/img/services-right-image/cloud.png" class="wow zoomIn" data-wow-delay="0.6s" alt="cloud">
                <img src="assets/img/services-right-image/cup.png" class="wow bounceIn" data-wow-delay="0.6s" alt="cup">
                <img src="assets/img/services-right-image/flower-top.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="flower">
                <img src="assets/img/services-right-image/head-phone.png" class="wow zoomIn" data-wow-delay="0.6s" alt="head-phone">
                <img src="assets/img/services-right-image/monitor.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="monitor">
                <img src="assets/img/services-right-image/mug.png" class="wow rotateIn" data-wow-delay="0.6s" alt="mug">
                <img src="assets/img/services-right-image/table.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="table">
                <img src="assets/img/services-right-image/tissue.png" class="wow zoomIn" data-wow-delay="0.6s" alt="tissue">
                <img src="assets/img/services-right-image/water-bottle.png" class="wow zoomIn" data-wow-delay="0.6s" alt="water-bottle">
                <img src="assets/img/services-right-image/wifi.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="wifi">
                <img src="assets/img/services-right-image/cercle-shape.png" class="bg-image rotateme" alt="shape">
                
                <img src="assets/img/services-right-image/main-pic.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic">
            </div>
        </div>
    </div>
</section>

<section class="features-area-two ptb-80">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12 services-left-image">
                <img src="assets/img/services-left-image/big-monitor.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="big-monitor">
                <img src="assets/img/services-left-image/creative.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="creative">
                <img src="assets/img/services-left-image/developer.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="developer">
                <img src="assets/img/services-left-image/flower-top.png" class="wow zoomIn" data-wow-delay="0.6s" alt="flower-top">
                <img src="assets/img/services-left-image/small-monitor.png" class="wow bounceIn" data-wow-delay="0.6s" alt="small-monitor">
                <img src="assets/img/services-left-image/small-top.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="small-top">
                <img src="assets/img/services-left-image/table.png" class="wow zoomIn" data-wow-delay="0.6s" alt="table">
                <img src="assets/img/services-left-image/target.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="target">
                <img src="assets/img/services-left-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

                <img src="assets/img/services-left-image/main-pic.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic">
            </div>

            <div class="col-lg-6 col-md-12 services-content">
                <div class="section-title">
                    <h2>What Our Software Can Do For You</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Responsive design
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            React web development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Android apps development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Laravel web development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            iOS apps development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            UX/UI design
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Ecommerce development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Print ready design
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>