import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-flutter',
  templateUrl: './flutter.component.html',
  styleUrls: ['./flutter.component.css'],
})
export class FlutterComponent {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle(
      'Flutter App Development Company in Dubai | Pro Web'
    );
    this.metaTagService.updateTag({
      name: 'description',
      content:
        " Empower your web projects with Flutter app development expertise. Craft polished, high-performance applications for seamless cross-platform experiences. Boost engagement and growth with Pro Web's top-notch Flutter apps, driven by our commitment to excellence.",
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'flutter, flutter app development, flutter app development services, flutter app development company',
    });
  }

  cardsItems = [
    {
      heading: 'Flutter App Ideation',
      description:
        "Unlock innovative possibilities with Flutter app ideation. Our experts brainstorm, design, and prototype ideas that align with your business goals. From concept to execution, we ensure your app stands out with a seamless user experience and cutting-edge functionalities, leveraging the power of Flutter's cross-platform capabilities.",
    },

    {
      heading: 'Flutter App Development',
      description:
        "Pro Web specializes in Flutter app development for your business's cross-platform presence. Our developers guide you through each stage, from pinpointing the perfect product-market match to seamlessly deploying your app across various platforms. As a Flutter development firm, we assist in crafting, building, testing, and launching fully operational Flutter apps.",
    },

    {
      heading: 'Flutter App Migration',
      description:
        "Leverage Pro Web's expertise in Flutter app development to effortlessly migrate your Android and iOS apps. Our dedicated team specializes in cross-platform integration, ensuring your applications transcend operating systems seamlessly. Entrust your project to us, the trusted choice for crafting versatile Flutter apps.",
    },
  ];

  data3 = [
    'flutter/brands/upside.svg',
    'flutter/brands/uae-driving.svg',
    'flutter/brands/smart-door.svg',
    'flutter/brands/vista.svg',
  ];

  data = [
    {
      count: '1',
      heading: 'Requirement Analysis',
      desc: 'Our process begins with in-depth discussions with clients to understand their goals, target audience, and business objectives. We meticulously gather and analyze requirements, considering functionality, user experience, and platform compatibility. Utilizing our expertise in Flutter development, we translate these requirements into actionable plans, outlining project scope, timelines, and milestones.',
    },
    {
      count: '2',
      heading: 'Data-Informed Design',
      desc: 'By understanding how users interact with apps, we identify pain points and opportunities for improvement. Utilizing tools such as analytics and user testing, we gather insights to inform the design process. Our team then employs iterative design techniques, continuously refining and optimizing app interfaces based on data-driven insights. This data-informed design approach ensures that our Flutter applications are visually appealing but also intuitive and user-centric, resulting in enhanced user engagement and satisfaction.',
    },
    {
      count: '3',
      heading: 'Future-Ready Development',
      desc: "Our experienced developers utilize Flutter's cross-platform capabilities to build high-quality applications that deliver exceptional performance across iOS, Android, and web platforms. We prioritize collaboration and communication throughout the development lifecycle, providing regular updates and incorporating client feedback to ensure project alignment and satisfaction. With our streamlined Flutter-ready development process, we empower businesses to accelerate their digital transformation and achieve their app goals effectively and efficiently.",
    },
    {
      count: '4',
      heading: 'Rigorous Quality Assurance',
      desc: 'Leveraging manual testing methodologies, our dedicated QA team conducts comprehensive testing across multiple devices and platforms to identify and address any issues or inconsistencies. We also prioritize continuous integration and deployment practices, allowing for early detection and resolution of bugs throughout the development lifecycle. Our commitment to rigorous quality assurance ensures that every Flutter app we deliver meets the highest standards of excellence, reliability, and user satisfaction.',
    },
    {
      count: '5',
      heading: 'Launch & Maintenance',
      desc: 'Our dedicated team guides you through the entire launch process, ensuring a smooth transition from development to deployment. We handle all aspects of app store submissions, ensuring compliance with platform guidelines and optimization for maximum visibility. Once your app is live, our maintenance services ensure its continuous performance and stability. We proactively monitor app performance, promptly addressing issues or bugs to minimize downtime and ensure a seamless user experience.',
    },
  ];

  iconsdata = [
    { icon: 'assets/img/servicesdev/icon-one.png', name: 'Enhanced Security' },
    {
      icon: 'assets/img/servicesdev/icon-two.png',
      name: 'Lasting Customer Loyalty',
    },
    {
      icon: 'assets/img/servicesdev/icon-three.png',
      name: 'Two-Tier Authentication',
    },
    {
      icon: 'assets/img/servicesdev/icon-four.png',
      name: 'Session Out Feature',
    },
    {
      icon: 'assets/img/servicesdev/icon-five.png',
      name: 'Nullified Frauds & Risk Factors',
    },
  ];

  data2 = [
    'UAE-Driving-review.png',
    'upside-review.png',
    'ttt-review.png',
    'smartdoor-review.png',
    'vista-review.png',
  ];

  datamobile = [
    'UAE-Driving-review.png',
    'upside-review.png',
    'smartdoor-review.png',
    'vista-review.png',
  ];

  faq = [
    {
      title: 'Why choose Flutter for app development?',
      des: "<p> You can choose Flutter for app development because of its single codebase across platforms, fast growth, expressive UI, and native performance. It's ideal for efficient cross-platform app building, saving time and resources</p>",
      isActive: false,
    },
    {
      title: 'Can Flutter apps access native device features?',
      des: '<p>Yes, Flutter apps can access native device features like camera, GPS, sensors, and more through plugins. This enables developers to create feature-rich applications with seamless integration of device functionalities across platforms.</p>',
      isActive: false,
    },
    {
      title: 'Is it possible to integrate Flutter into my existing native app?',
      des: "<p>Yes, it's possible to integrate Flutter into your existing native app. Flutter offers seamless integration capabilities, allowing you to leverage its cross-platform features while maintaining your app's native functionalities.</p>",
      isActive: false,
    },
    {
      title:
        'How can I stay updated on the progress of my Flutter app development?',
      des: '<p> Pro Web utilizes project management tools like Trello or Jira to keep you updated on the progress of your Flutter app development. Scheduled status meetings or reports ensure continuous alignment with project goals, ensuring transparency and effective communication throughout the development process.</p>',
      isActive: false,
    },
    {
      title: 'Is Flutter suitable for building complex applications?',
      des: "<p>Absolutely, Flutter is well-suited for building complex applications. Its modular architecture, rich widget library, and strong community support enable developers to create sophisticated apps with ease. Flutter's scalability and performance make it an excellent choice for projects of any complexity.</p>",
      isActive: false,
    },
    {
      title: 'What types of apps can be developed on Flutter?',
      des: '<p>Flutter empowers diverse app development, spanning business solutions like CRM, social networking platforms, e-commerce stores, entertainment hubs, utility tools, educational resources, healthcare services, and travel planners. Its adaptability allows developers to craft tailored, efficient solutions for varied industries, ensuring robust and high-quality applications.</p>',
      isActive: false,
    },
    {
      title: 'How does Flutter handle app updates and maintenance?',
      des: "<p>Flutter simplifies app updates and maintenance through its hot reload feature, allowing developers to instantly view changes during development. Additionally, Flutter's robust community ensures ongoing support and resources for seamless maintenance, ensuring your app remains up-to-date and optimized.</p>",
      isActive: false,
    },
    {
      title: 'What is the scope of Flutter app development?',
      des: '<p>Compared to other cross-platform frameworks, Flutter is reactive and robust and comes with a range of tools and widgets that enable quick coding and deployment. It leverages a single codebase that works for Android and iOS platforms. This minimizes the app turnaround time to a significant extent. Further, it offers features such as hot reload and customizable widgets too.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }

  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
}
