import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {
	// Meta Title
	title = 'Thank You - Pro Web';		

	constructor(
		private titleService: Title,
		private metaTagService: Meta,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location
	) {  }

	ngOnInit() {
		// Meta Title & Description
		this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'description', content: 'Top Rated Web Design & App Development Agency with more than 11 years experience in building results driven custom web based solutions.' }
		);
		// Meta Title & Description
	}

	back(): void {
		this.location.back()
	}
}
