import { Component, EventEmitter,OnInit, Input, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-technology-stack',
  templateUrl: './technology-stack.component.html',
  styleUrls: ['./technology-stack.component.css']
})
export class TechnologyStackComponent  implements OnInit {
  @Input() firsttitle: any;
  @Input() semititle: any;
  @Input() description: any;
  @Input() image!: string;
  @Input() cards: any;
  @Output() selectedPackage: EventEmitter<any> = new EventEmitter();

  currentRoute: any = '';


  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }
  ngOnInit() {
    this.selectedPackage.emit('Amazon Web Servers');
  }

  onPackageChange(package_name: any){
    console.log(package_name,"package_name")
    this.selectedPackage.emit(package_name);
  }
  

}
