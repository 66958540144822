import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-vapt',
  templateUrl: './vapt.component.html',
  styleUrls: ['./vapt.component.css'],
})
export class VaptComponent {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle(
      `Vulnerability and Penetration Testing Company in Dubai | Pro Web`
    );
    this.metaTagService.updateTag({
      name: 'description',
      content: `Strengthen your system's security with our Vulnerability Assessment and Penetration Testing (VAPT) services. Identify and address potential threats, ensuring robust protection against cyber attacks and data breaches.`,
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: `vapt, vapt testing, vulnerability assessment and penetration testing, vulnerability assessment and penetration testing services, vulnerability assessment and penetration testing, penetration testing services`,
    });
  }

  cardsItems = [
    {
      heading: 'Network Audit',
      description:
        'A network audit provides tailored support for businesses seeking to assess the security and stability of their IT networks. We conduct rigorous scans to detect various security vulnerabilities. Our detailed report classifies risks to your cybersecurity network and offers mitigation strategies to safeguard your operations effectively.',
    },

    {
      heading: 'External Penetration Testing',
      description:
        'External penetration testing assesses servers, infrastructure, and software of a target system. This method can be conducted with no prior site knowledge (black box) or with full disclosure of the environment (crystal box). Its goal is to identify vulnerabilities and evaluate security measures thoroughly and effectively.',
    },

    {
      heading: 'Internal Security Assessment',
      description:
        'Internal security assessment employs a methodology akin to external testing but provides a holistic view of site security. Tests span multiple network access points, including tiers, DMZs, corporate networks, and partner connections. This ensures comprehensive evaluation and identification of vulnerabilities across diverse network entry points.',
    },
  ];

  data = [
    {
      count: '1',
      heading: 'Sign-Up Instantly',
      desc: 'Register for a security audit directly through our website. Our streamlined sign-up process is quick and easy, allowing you to start securing your systems immediately. Simply provide your details and select the audit type you need, and our team will promptly begin the assessment, ensuring a hassle-free experience.',
    },
    {
      count: '2',
      heading: 'Plan Scope',
      desc: `Define the audit scope based on your application's or website's technology stack. Work with our experts to pinpoint critical areas, data flows, and entry points. This detailed planning ensures a thorough assessment tailored to your specific security needs and vulnerabilities, creating a clear roadmap for effective risk mitigation and enhanced protection`,
    },
    {
      count: '3',
      heading: 'Our Assessment',
      desc: 'We initiate a comprehensive vulnerability assessment, identifying potential risks across your systems. Gain real-time insights through dashboard access, allowing you to track assessment progress and view findings as our experts work to secure your digital infrastructure effectively.',
    },
    {
      count: '4',
      heading: 'Resolve Issues with Developers',
      desc: 'Collaborate closely with your development team to address identified vulnerabilities swiftly and effectively. Our experts provide detailed reports and guidance, ensuring seamless integration of security fixes into your systems to bolster resilience against potential cyber threats.',
    },
    {
      count: '5',
      heading: 'Confirmation Test',
      desc: ' Conduct a meticulous final test to validate the stability and security of your systems post-security audit. This crucial step ensures that all identified vulnerabilities have been effectively mitigated, providing assurance that your infrastructure is robust and resilient against potential cyber threats.',
    },
  ];

  faq = [
    {
      title: 'What is VAPT?',
      des: "<p> Vulnerability Assessment (VA) and Penetration Testing (PT) identify vulnerabilities in your website's network, server, and system infrastructure. PT tests external threats, simulating attacks, while VA focuses on internal security risks. This dual approach ensures robust protection against cyber threats.</p>",
      isActive: false,
    },
    {
      title: 'What does the VAPT process involve?',
      des: '<p>The VAPT process involves conducting a thorough assessment of IT systems to identify vulnerabilities. It includes vulnerability scanning, penetration testing, and analysis of findings to provide actionable recommendations for enhancing cybersecurity defenses and mitigating risks effectively.</p>',
      isActive: false,
    },
    {
      title: 'Why is VAPT important?',
      des: '<p>VAPT is crucial for cybersecurity. It helps organizations identify vulnerabilities before exploitation. By assessing defenses, VAPT enhances security posture, mitigates data breach risks, and ensures compliance, safeguarding sensitive information and maintaining stakeholder trust.</p>',
      isActive: false,
    },
    {
      title: 'How often should VAPT be conducted?',
      des: '<p> VAPT should be conducted regularly, ideally annually or after significant system changes, to ensure ongoing protection against evolving cyber threats. Regular assessments help maintain robust cybersecurity measures and compliance with industry standards.</p>',
      isActive: false,
    },
    {
      title: 'How long does a typical VAPT assessment take?',
      des: '<p>The duration of a VAPT assessment depends on the size and complexity of the target systems or networks. Smaller applications or networks may take a few days, while larger and more complex infrastructures could require several weeks. Scheduling occurs during the planning and scoping stage of the assessment.</p>',
      isActive: false,
    },
    {
      title: 'What types of vulnerabilities can VAPT identify?',
      des: '<p>VAPT comprehensively identifies vulnerabilities such as software bugs, misconfigurations, weak passwords, insecure network protocols, and other weaknesses that could compromise your systems. This helps organizations prioritize security measures and mitigate potential risks proactively.</p>',
      isActive: false,
    },
    {
      title:
        'Can VAPT services be customized based on specific business needs?',
      des: `<p>Yes, VAPT services can be customized to meet specific business requirements, allowing for tailored assessments based on the organization's unique security challenges, compliance needs, and IT infrastructure complexities. This ensures comprehensive and effective cybersecurity evaluations.</p>`,
      isActive: false,
    },
    {
      title: 'How can I get started with VAPT services at Pro Web?',
      des: '<p>To start with VAPT services at Pro Web, visit our website and sign up for a security audit. Our team will guide you through the process, from planning and scoping to the assessment and remediation phases, ensuring a smooth and effective experience.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }
}
