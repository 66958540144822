import { Component, Input } from '@angular/core';
import { LoadingService } from '../utils/services/loading.service';


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent {
  @Input() firsttitle: any;
  @Input() semititle: any;
  @Input() desc: any;
  @Input() portfolio: any;

  constructor(
    private loadingService: LoadingService,
  ) { }

  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000)
  }

}
