<section>
  <!-- banner section -->
  <app-anim-banner
    heading="Hire React Native App Developers for Mobile Innovation"
    description="Advance your Mobile App Innovation with Our Premier React Native Development Solutions. We Create High-Performance, Cross-Platform Apps with Seamless user Experiences. Tap into our Expertise to Swiftly Materialize your Ideas and Maintain Competitiveness."
    image="react-native/react-native-anim.png"
  ></app-anim-banner>

  <!-- popular brands -->
  <app-brands [brands]="data3"></app-brands>

  <!-- services -->
  <app-services-offer
    [cards]="cardsItems"
    firsttitle="React Native App Development"
    semititle="Services We Offer"
    description="Pro Web provides React Native development services to create high-performance cross-platform solutions for web, Android, and iOS, saving money and time for our clients up to 50% (compared to developing native solutions for each of the platforms). Our portfolio continues to grow with React Native flagship solutions in the areas of e-learning, fintech, and blockchain, and we are constantly improving our experience."
  ></app-services-offer>

  <app-services-offer
    [cards]="cardsItems2"
    firsttitle=""
    semititle=""
    description=""
  ></app-services-offer>

  <!-- empower business -->
  <div class="learn_business">
    <div class="container">
      <div class="row align-items-center gy-3">
        <div class="col-md-5">
          <div>
            <h2>Advantages of Using React Native for Mobile App Development</h2>
            <p>
              Our React Native app development services are designed to go
              beyond just meeting budget requirements. We ensure that our React
              Native cross-platform app development delivers high-quality,
              effective communication and transparency, all in a cost-efficient
              manner.
            </p>
          </div>
        </div>
        <div class="col-md-7">
          <div class="empower_wrapper">
            <div>
              <img
                src="../../assets/img/servicesdev/react-native/cost-saving-img.png"
                alt=""
                srcset=""
              />
            </div>

            <div class="emplower_gif">
              <img
                src="../../assets/img/servicesdev/react-native/cost-saving.gif"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- engagement model -->
  <div class="engagement">
    <app-engagement-modal></app-engagement-modal>
  </div>

  <!-- flutter app idea -->
  <app-steps-choose
    heading="Our Process For Result-Oriented React Native App Development"
    description="Our React Native software app development process focuses on innovation and user experience, establishing us as a respected and reliable React Native app development company worldwide."
    [steps]="data"
  ></app-steps-choose>

  <!-- why choose -->

  <div class="why-choose">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6">
          <div>
            <h2>
              Why Choose Pro Web As Your React Native App Development Company?
            </h2>
            <p>
              As a dedicated React Native company, we create structured apps
              with native-like UX, swiftly and efficiently. Our skilled
              developers understand the importance of visually captivating brand
              identities, crafting iOS, Android, and web apps that align
              seamlessly with your business goals.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mt-3 mt-md-0">
            <img
              src="../../assets/img/servicesdev/react-native/why-choose.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- review -->
  <app-testimonial-service
    profile="barry-profile.svg"
    clientname="Mr. Basem Barry"
    designation="CEO"
    location="Dubai, United Arab Emirates"
    brandname="Geohoney"
    flag="uk-flag.svg"
    clientcomment="I personally dealt with many web developers of all languages and specialities. In one word I can say Rohit Sadani is the best of all!. I specially noticed how smart he was since I first met with him, and how dedicated, resourceful and over deliver he was when we started working on several online projects."
    rating="Rated 4.9 out of 5.0"
    [clientimg]="data2"
    [clientmobile]="datamobile"
    clienttarget="geohoney-react.png"
  ></app-testimonial-service>

  <!-- contact -->
  <app-bottom-bar
    text="Connect Today for Custom React Native Excellence!"
  ></app-bottom-bar>

  <!-- FAQ's -->
  <section class="spacing">
    <div class="container-md">
      <div class="row justify-content-center">
        <h2>Frequently <span>Asked Questions</span></h2>
        <p class="my-3">
          Find the answers you need to know more about React Native Development.
        </p>

        <div class="row my-4">
          <div class="col-sm-6">
            <div
              class="faq-list"
              *ngFor="let item of faq.slice(0, 4); let i = index"
            >
              <div
                class="faq-title d-flex justify-content-between"
                (click)="toggleAccordian($event, i)"
              >
                <h6>{{ item.title }}</h6>

                <h4 *ngIf="item.isActive == false">+</h4>
                <h4 *ngIf="item.isActive == true">-</h4>
              </div>
              <div class="faq-des" *ngIf="item.isActive">
                <div [innerHTML]="item.des"></div>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div
              class="faq-list"
              *ngFor="let item of faq.slice(4); let i = index"
            >
              <div
                class="faq-title d-flex justify-content-between"
                (click)="toggleAccordian($event, i + 4)"
              >
                <h6>{{ item.title }}</h6>

                <h4 *ngIf="item.isActive == false">+</h4>
                <h4 *ngIf="item.isActive == true">-</h4>
              </div>
              <div class="faq-des" *ngIf="item.isActive">
                <div [innerHTML]="item.des"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
