<app-packagesbanner heading="Economical Website Maintenance Packages in Dubai" paragraph="Streamline your web maintenance with our hassle-free services for eCommerce, web apps, and CMS platforms.
Choose a tailored package for content updates, speed enhancements, and backlinking. Optimize your website
investment—select your package today!" bannerimg="/assets/img/packages/packagebg.jpg"
    bannermobile="/assets/img/packages/web-maintennace-mobile.png" packageimg="/assets/img/packages/web-maintenance.png"
    [cards]="logoarr" [cards2]="logoarr2" text="Trusted by"></app-packagesbanner>




<section class="ptb-80">

    <div class="container">

        <div class="row">
            <div>
                <h2 class="text-center mainheading"> Choose Pro Web For Professional <span class="prohead">Web Maintenance Services</span></h2>


                <div class="bar"></div>
                <p class="text-center desc">Many websites become inactive or ineffective due to neglect and outdated
                    technology. We know that businesses require consistent maintenance to engage customers, maintain SEO
                    rankings, and offer fresh content. Our hassle-free maintenance services cover eCommerce stores, web
                    applications, and major CMS platforms. Choose a package to suit your needs, including content
                    updates, speed enhancements, and effective backlinking. We also offer customized packages for your
                    specific requirements. Let us optimize your website investment. Select your package today!</p>
            </div>


            <!-- table -->
            <div class="ptb-20 py-3">

                <h2 class="text-center mainheading mb-3">Choose Your <span class="prohead"> Web Maintenance Package
                    </span></h2>

                <!-- <h2 class="text-center mainheading otherHeading mb-3">Comprehensive Website Maintenance Packages  <span
                        class="prohead">for Optimal Performance from Pro Web </span></h2> -->




                <div class="bar"></div>

                <div class="tabletagline">
                    <h3><span style="color:#2e70a6 !important;"> Monthly </span></h3>
                    <label class="switch">
                        <input type="checkbox" (click)="budgetChange()">
                        <span class="slider round"></span>
                    </label>
                    <h3><span style="color:#2e70a6 !important;"> Yearly </span></h3>

                </div>


                <!-- Desktop -->

                <div class="tableContent">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center">
                                    <div *ngIf="toggle == false"> <span class="pricecolor">1499 AED</span> Per Month
                                    </div>
                                    <div *ngIf="toggle == true"> <del>18000</del> <span class="pricecolor">16200 AED
                                        </span> Per
                                        Year</div>
                                </td>
                                <td class="text-center">
                                    <div *ngIf="toggle == false"> <span class="pricecolor">3499 AED </span> Per Month
                                    </div>
                                    <div *ngIf="toggle == true"> <del>42000</del> <span class="pricecolor">37800
                                            AED</span> Per
                                        Year</div>
                                </td>
                                <td class="text-center"> <span class="pricecolor"> Custom Pricing </span> Per Month</td>
                            </tr>
                            <tr class="white">
                                <td>Pro Web Enhancement Hours Per Month</td>
                                <td class="text-center">10 hrs
                                </td>
                                <td class="text-center">25 hrs
                                </td>
                                <td class="text-center">To Be Discussed
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Response Time</td>
                                <td class="text-center">48 hrs
                                </td>
                                <td class="text-center">48 hrs

                                </td>
                                <td class="text-center">24 hrs

                                </td>

                            </tr>


                            <tr class="white">
                                <td>Bug Fixing</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Speed Optimization</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Database Optimization</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Solving Server Issues</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Improving UX/UI</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Spam Cleanup</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Banners & Graphic Designing</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>


                        </tbody>
                    </table>
                </div>


                <div class="text-center py-3 morefeatbtn"
                    style="border: 1px solid #e3d8d8; border-top: none; cursor: pointer;">
                    <div (click)="showdata()"> <b>More Features</b>
                        <img src="/assets/img/webp/add3.webp" class="mx-2" *ngIf="addclass == false" alt="" srcset=""> 
                           
                            <img src="/assets/img/webp/minus3.webp" class="mx-2" *ngIf="addclass == true" alt="" srcset=""> 

                       
                    </div>
                </div>

                <div class="tableContent2" [ngClass]="{'myCSSclass' : addclass == true}">
                    <table class="table">
                        <tbody>

                            <tr class="white">
                                <td>Broken Link Scanning</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>


                            <tr class="white">
                                <td>Versions & Plugins Upgrades</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Adding New Pages/Blog Post</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>



                            <tr class="white">
                                <td>Security Enhancement</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>



                            <tr class="white">
                                <td>Malware Scan</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Forms Submissions Testing</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Fixing 404, 500 etc.</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Phone/Skype Support</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Performance Scan</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Website Backup</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Uptime Monitoring</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Social Media Integration</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Preventing Hack Attempts</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Database Backup </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>



                <!-- *************************************** -->

                <!-- Mobile -->




                <div class="mobTabpills">

                    <div class="tabletagline d-flex align-items-center">
                        <h5><span style="color:#2e70a6 !important;"> Monthly </span></h5>
                        <label class="switch">
                            <input type="checkbox" (click)="budgetChange()">
                            <span class="slider round"></span>
                        </label>
                        <h5><span style="color:#2e70a6 !important;"> Yearly </span></h5>

                    </div>

                    <ul>
                        <li (click)="web(1)" [ngClass]="{'bgtab' : package == 1}">Startup</li>
                        <li (click)="web(2)" [ngClass]="{'bgtab' : package == 2}">Gold</li>
                        <li (click)="web(3)" [ngClass]="{'bgtab' : package == 3}">Enterprise</li>
                    </ul>

                </div>


                <div class="tableContentMobile">

                    <table class="table" *ngIf="package == 1">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center">
                                    <p *ngIf="toggle == false" class="mb-0"> <span class="pricecolor"> 1499 AED </span>
                                        Per
                                        Month </p>
                                    <p *ngIf="toggle == true"> <del>18000</del> <span class="pricecolor"> 16200 AED
                                        </span> Per
                                        Year </p>
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Pro Web Enhancement Hours Per Month </td>
                                <td class="text-center"> 10 hrs </td>

                            </tr>

                            <tr class="white">
                                <td>Response Time</td>
                                <td class="text-center"> 48 hrs </td>

                            </tr>

                            <tr class="white">
                                <td>Bug Fixing </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Speed Optimization </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Database Optimization</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Solving Server Issues</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Improving UX/UI</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Spam Cleanup</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Banners & Graphic Designing </td>
                                <td class="text-center"> 1</td>

                            </tr>
                            <tr class="white">
                                <td>Broken Link Scanning</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Versions & Plugins Upgrades </td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Adding New Pages/Blog Post</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Security Enhancement</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Malware Scan</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>



                            <tr class="white">
                                <td>Forms Submissions Testing</td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Fixing 404, 500 etc.</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Phone/Skype Support</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Performance Scan</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Website Backup</td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Uptime Monitoring</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Social Media Integration</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Preventing Hack Attempts</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Database Backup </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>


                        </tbody>
                    </table>

                    <table class="table" *ngIf="package == 2">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center">
                                    <p *ngIf="toggle == false" class="mb-0"> <span class="pricecolor"> 3499 AED </span>
                                        Per Month
                                    </p>
                                    <p *ngIf="toggle == true"> <del>42000</del> <span class="pricecolor">37800 AED
                                        </span> Per Year </p>
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Pro Web Enhancement Hours Per Month </td>
                                <td class="text-center"> 25 hrs </td>

                            </tr>

                            <tr class="white">
                                <td>Response Time</td>
                                <td class="text-center"> 48 hrs </td>

                            </tr>

                            <tr class="white">
                                <td>Bug Fixing </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Speed Optimization </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Database Optimization</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Solving Server Issues</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Improving UX/UI</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Spam Cleanup</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Banners & Graphic Designing </td>
                                <td class="text-center"> 1</td>

                            </tr>
                            <tr class="white">
                                <td>Broken Link Scanning</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Versions & Plugins Upgrades </td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Adding New Pages/Blog Post</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Security Enhancement</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Malware Scan</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>



                            <tr class="white">
                                <td>Forms Submissions Testing</td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Fixing 404, 500 etc.</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Phone/Skype Support</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Performance Scan</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Website Backup</td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Uptime Monitoring</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Social Media Integration</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Preventing Hack Attempts</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Database Backup </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>


                        </tbody>
                    </table>

                    <table class="table" *ngIf="package == 3">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center">
                                    Custom Pricing Per Month
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Pro Web Enhancement Hours Per Month </td>
                                <td class="text-center"> To Be Discussed
                                </td>

                            </tr>

                            <tr class="white">
                                <td>Response Time</td>
                                <td class="text-center"> 24 hrs
                                </td>

                            </tr>

                            <tr class="white">
                                <td>Bug Fixing </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Speed Optimization </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Database Optimization</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Solving Server Issues</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Improving UX/UI</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>Spam Cleanup</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Banners & Graphic Designing </td>
                                <td class="text-center"> 1</td>

                            </tr>
                            <tr class="white">
                                <td>Broken Link Scanning</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Versions & Plugins Upgrades </td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Adding New Pages/Blog Post</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Security Enhancement</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Malware Scan</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>



                            <tr class="white">
                                <td>Forms Submissions Testing</td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Fixing 404, 500 etc.</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Phone/Skype Support</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Performance Scan</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Website Backup</td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Uptime Monitoring</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Social Media Integration</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Preventing Hack Attempts</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Database Backup </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>


                        </tbody>
                    </table>

                </div>

                <!-- ************************************* -->


            </div>

        </div>
    </div>

</section>

<app-check-out checkoutImg="../../assets/img/servicesdev/checkout_webmaintenance.png" alt="check out" checkoutText="Check Out Our"
    checkoutName="Services" checkoutUrl="" [checkout_arr]="data"></app-check-out>


<app-bottom-bar text="Looking for Web Maintenance Package for your Business?"></app-bottom-bar>