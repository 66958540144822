import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-blockchain-service',
  templateUrl: './blockchain-service.component.html',
  styleUrls: ['./blockchain-service.component.css']
})
export class BlockchainServiceComponent {


  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle("Blockchain Development Company in Dubai | Pro Web");
    this.metaTagService.updateTag(
      { name: 'description', content: "Opt for Pro Web Dubai's forefront expertise in cutting-edge blockchain development. Our solutions ensure optimal performance, promising utmost security and efficiency for your business applications." }
    );
    this.metaTagService.updateTag(
      { name: "keywords", content: "" }
    );
  }


  faq = [
    {
      title: "What is Blockchain Development?",
      des: "<p> Blockchain development involves creating decentralized applications (dApps), smart contracts, and blockchain networks using distributed ledger technology. It encompasses coding, designing, and implementing secure and transparent systems for various industries, from finance to supply chain management.</p>",
      isActive: false,
    },
    {
      title: "What tools does Pro Web use to create blockchain applications?",
      des: "<p>At Pro Web, our toolkit for blockchain development is comprehensive, featuring Solidity as our primary language for smart contract development. We utilize Ethereum and Polygon mainnets for our blockchain solutions. Furthermore, our team constantly explores and adopts new tools to enhance our blockchain development processes.</p>",
      isActive: false,
    },
    {
      title: "Do you offer ongoing support and maintenance for blockchain applications post-deployment?",
      des: "<p> Yes, we provide comprehensive support and maintenance services to ensure the continued success and optimal performance of blockchain applications post-deployment. Our team is available to address any issues, implement updates, and provide technical assistance as needed.</p>",
      isActive: false,
    },
    {
      title: "What steps are involved in the blockchain development process, and how long does it typically take?",
      des: '<p>The blockchain development process typically involves requirements gathering, design, development, testing, deployment, and maintenance. The duration varies based on project complexity, scope, and requirements, but we aim to deliver timely and high-quality solutions.</p>',
      isActive: false,
    },
    {
      title: "How do you handle data privacy and confidentiality in blockchain solutions?",
      des: "<p> We employ encryption techniques, access controls, and privacy-enhancing technologies to ensure data privacy and confidentiality in blockchain solutions. Additionally, we adhere to relevant data protection regulations and industry standards to safeguard sensitive information.</p>",
      isActive: false,
    },
    {
      title: "Can you integrate blockchain solutions with existing systems and technologies in my business?",
      des: "<p>Yes, we specialize in integrating blockchain solutions with existing systems, databases, and applications in your business. Our team ensures seamless interoperability and data exchange between blockchain-based solutions and legacy systems, maximizing efficiency and effectiveness.</p>",
      isActive: false,
    },
    {
      title: "What measures do you take to ensure the scalability of blockchain solutions as my business grows?",
      des: "<p> We design blockchain solutions with scalability in mind, employing techniques such as sharding, sidechains, and off-chain processing to accommodate increasing transaction volumes and user activity. Our solutions are architected to scale horizontally and vertically as your business needs evolve.</p>",
      isActive: false,
    },
    {
      title: "How can I assess the progress of my blockchain project? ",
      des: '<p>Pro Web follows an agile development technology which keeps communication between the client and the development team always open and transparent. Our team not only updates the progress of blockchain solutions development at regular intervals to clients, but is also always motivated to work on any last minute iterations to ensure 100% client satisfaction.</p>',
      isActive: false,
    },


  ];

  toggleAccordian(event: any, index: any) {

    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }


  processdata = [
    {
      icon: '/assets/img/servicesdev/consultation-icon.png',
      heading: 'Requirement Analysis',
      description: 'Understanding project requirements , Scale assessment of the project ,Setting project milestones'

    },

    {
      icon: '/assets/img/servicesdev/data-informed-icon.png',
      heading: 'Data-Informed Design',
      description: 'Customized platform architecture , Interactive UI/UX Design , Creating a prototype'

    },
    {
      icon: '/assets/img/servicesdev/future-ready-icon.png',
      heading: 'Future-Ready Development',
      description: 'Blockchain software conceptualization , Integrating the blockchain system into ecosystem , Back end development'

    },
    {
      icon: '/assets/img/servicesdev/quality-assurance-icon.png',
      heading: 'Rigorous Quality Assurance',
      description: 'Testing & Auditing , Performance Optimization , Big Fixing For Function, Quality & Accuracy'

    },
    {
      icon: '/assets/img/servicesdev/launch-icon.png',
      heading: 'Launch & Maintenance',
      description: 'Blockchain software support and evolution , Periodic Maintenance & Updates , UI/UX Improvements'

    }
  ]

  cardsItems = [
    {
      title: "Non-Fungible Token (NFT)",
      desc: "Unlock the potential for global accessibility and seamless transactions, creating a borderless marketplace for enthusiasts to engage in transparent and authenticated digital collectible exchanges. Embrace the future of collecting by blending tradition with innovation on a blockchain that ensures authenticity and scarcity. Elevate the collector's experience by enabling fractional ownership, allowing enthusiasts to co-own rare pieces and participate in the vibrant world of digital collectibles with unprecedented ease.",
      img: "assets/img/servicesdev/globe.webp"
    },
    {
      title: "DeFi Solutions",
      desc: "Revamp your financial applications into decentralized ecosystems, eliminating the need for intermediaries and fostering transparency. Within the realm of decentralized finance (DeFi), the self-executing functionalities thrive in a peer-to-peer setting, offering a level of transparency and efficiency that redefines traditional financial systems. Embrace a future where financial operations are streamlined, intermediaries are minimized, and users experience heightened trust through the seamless execution of transactions in DeFi.",
      img: "assets/img/servicesdev/globe.webp"
    },
    {
      title: "Smart Contracts",
      desc: "Smart contracts represent self-executing and unchangeable codes that trigger predefined actions upon the fulfillment of specific conditions. These automated protocols empower seamless execution of tasks such as processing payments, transferring ownership, and facilitating the exchange of information seamlessly across diverse systems. Embrace the efficiency and transparency offered by smart contracts, revolutionizing how transactions are conducted and ensuring precision in the execution of contractual obligations.",
      img: "assets/img/servicesdev/globe.webp"
    },
    {
      title: "DApps Solutions",
      desc: "Dive into the world of Decentralized Applications (DApps), revolutionary software programs built on blockchain networks like Ethereum or Binance Smart Chain, diverging from the conventional reliance on centralized servers. These innovative applications leverage the inherent benefits of decentralized networks, enhancing security, transparency, and resilience. By operating on distributed ledgers, DApps facilitate a new era of digital interactions where users can engage in activities with increased autonomy and reduced dependency on a central authority.",
      img: "assets/img/servicesdev/globe.webp"
    },
    {
      title: "Integration of Crypto & Fiat Currencies",
      desc: "Seamlessly embed automated payment processes, including payables, collections, and receivables, into your workflows by leveraging a specialized API payments infrastructure. This strategic integration spans both crypto and fiat currencies, offering a versatile financial solution that not only optimizes your operational efficiency but also unveils untapped revenue streams for your business. Enhance your business model by effortlessly incorporating cutting-edge payment technologies, ensuring adaptability to evolve",
      img: "assets/img/servicesdev/globe.webp"
    },
    {
      title: "Integration of Crypto Wallet",
      desc: "Elevate your digital experience with a state-of-the-art token wallet, providing users with secure blockchain storage for their digital assets, FDIC-compliant asset insurance, and instantaneous capabilities for purchases and currency exchanges. Empower users to navigate the evolving landscape of digital finance with confidence, backed by robust security measures. As you adopt this advanced digital wallet, you not only fortify the protection of digital assets but also position yourself at the forefront of a rapidly evolving financial ecosystem.",
      img: "assets/img/servicesdev/globe.webp"
    }
  ]


  dataItems = [
    {
      heading: 'Decentralization', description: 'Blockchain operates on a decentralized network, meaning there is no central authority or control. This ensures that no single entity has the power to manipulate the system, providing transparency and trust among participants. Furthermore, decentralization fosters a more resilient system, as there is no single point of failure.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Immutable Ledger', description: 'Once data is added to the blockchain, it cannot be altered or deleted. This immutability ensures the integrity of the transaction history, making it resistant to fraud and tampering. The permanence of the ledger also facilitates a reliable and auditable record, valuable for compliance and regulatory purposes.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Security through Cryptography', description: 'Blockchain uses cryptographic techniques to secure transactions and control the creation of new units. This cryptographic security ensures the confidentiality and integrity of data, protecting it from unauthorized access and manipulation. Additionally, the public and private key infrastructure employed in blockchain transactions ensures secure and verifiable ownership.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Transparency', description: 'All transactions on the blockchain are visible to all participants in the network. This transparency fosters trust among users, as they can independently verify and validate transactions without relying on a central authority. This feature not only reduces the potential for disputes but also encourages responsible behavior within the network, as all actions are subject to scrutiny.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Consensus Mechanism', description: 'Blockchain networks use consensus algorithms to achieve agreement on the validity of transactions. Popular mechanisms include Proof of Work (PoW) and Proof of Stake (PoS), ensuring that participants collectively agree on the state of the blockchain; consensus mechanisms play a vital role in the evolution of blockchain technology, with ongoing research.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Interoperability', description: 'Blockchain systems can be designed to interoperate with each other, allowing seamless communication and transaction flow between different blockchain networks. As blockchain technology continues to evolve, it plays a crucial role in ensuring a cohesive and interconnected landscape, allowing users and businesses to benefit with different blockchain networks.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Distributed & Redundant Storage', description: 'Blockchain data is distributed across multiple nodes in the network. This distributed storage not only enhances security by reducing the risk of a single point of failure but also contributes to the overall resilience and availability of the system. The combination of distributed and redundant storage mechanisms makes blockchain networks highly fault-tolerant.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Permissioned Access', description: 'Some blockchain networks have permissioned access, where participants need authorization to join the network. This feature allows organizations to maintain control over who can participate, enhancing privacy and security for sensitive business applications fostering trust among participants.', bgimg: 'hosting-card.png'
    },
    {
      heading: 'Cross-Chain Compatibility', description: 'Blockchain services can exhibit cross-chain compatibility, allowing them to seamlessly interact and share information with other blockchain networks. Cross-chain compatibility is crucial for creating a more interconnected blockchain ecosystem, enabling users to leverage the strengths of multiple blockchains simultaneously.', bgimg: 'hosting-card.png'
    }

  ]

  logo = ['/assets/img/servicesdev/blockchain/travictionary_logo.webp', '/assets/img/servicesdev/blockchain/jbbc_logo.webp', '/assets/img/servicesdev/blockchain/kalachain_logo.webp', '/assets/img/servicesdev/blockchain/agomic_labs_logo.webp']

  logo2 = ['/assets/img/servicesdev/blockchain/travictionary_logo.webp', '/assets/img/servicesdev/blockchain/jbbc_logo.webp', '/assets/img/servicesdev/blockchain/kalachain_logo.webp']

  taglinearr = ['Custom Decentralized Solutions', 'Blockchain Technology Integrations', '360-Degree Security', 'Custom Decentralized Solutions', 'Blockchain Technology Integrations','Custom Decentralized Solutions', 'Blockchain Technology Integrations', '360-Degree Security', 'Custom Decentralized Solutions', 'Blockchain Technology Integrations']

  portfolioItems = [
    {
      heading: "Kalachain",
      smalltitle: "A NFT Development Portal",
      desc: "Kalachain is a NFT collection of Nature Clicks. Many artists upload their clicks as NFT with us to get wider audience. With us you will get 10+ Traits in 3 Categories which opens a vertical and adds to Kalachain NFTs USP. Kalachain NFTs are unique cryptographic tokens that exist on a blockchain and cannot be replicated.",
      bgimg: "../../assets/img/servicesdev/kalachain.png",
      icon: "../../assets/img/servicesdev/polygon-matic-seeklogo.svg",
      casestudy: "case-study/the-bezel-club",
      heading1: 'Specialists Worked',
      heading2: 'Retention Rate',
      heading3: 'Years of Business',
      specialist: "3",
      download: "84%",
      rating: "15+"
    },
    {
      heading: "Jocky Boa Boxer Club",
      smalltitle: "Transformer NFT Avatars",
      desc: "Jocky Boa Boxer Club (JBBC) is a collection of 10,000 Jocky Boa NFTs of which 3500 NFTs are in Polygon and 6500 NFTs are in Ethereum blockchain.Your JBBC NFT is your ticket to creating your own personalised Jocky Boa Transformer NFT Avatar.",
      bgimg: "../../assets/img/servicesdev/jocky-boxer.png",
      icon: "../../assets/img/servicesdev/polygon-matic-seeklogo.svg",
      casestudy: "",
      heading1: 'Specialists Worked',
      heading2: 'Retention Rate',
      heading3: 'Years of Business',
      specialist: "4",
      download: "87%",
      rating: "10+"
    },
    {
      heading: "Travictionary",
      smalltitle: "NFT Minting on Polygon ERC 1155",
      desc: "Travictionary offers an exclusive community for the seeker who loves to explore our mother nature - EARTH. Both artists and collectors are assured the best user experience possible. Here at Travicitionary, NFTs are all about “Traveling and Exploring” because nothing is perfect and everything is perfect.",
      bgimg: "../../assets/img/servicesdev/travictionary.png",
      icon: "../../assets/img/servicesdev/polygon-matic-seeklogo.svg",
      casestudy: "",
      heading1: 'Specialists Worked',
      heading2: 'Retention Rate',
      heading3: 'Years of Business',
      specialist: "3",
      download: "89%",
      rating: "10+"
    },

  ]

  cards = [
    { icon: '../../assets/img/servicesdev/flag.png', heading: 'Experts in Blockchain', description: 'Certified experts to navigate the complexities of blockchain, ensuring optimal and secure solutions for your business.' },
    { icon: '../../assets/img/servicesdev/focus-approach.png', heading: 'Client Focused Approach', description: 'Tailored solutions that prioritize your unique needs, fostering a collaborative and successful journey in the decentralized landscape.' },
    { icon: '../../assets/img/servicesdev/dedicated-support.png', heading: 'Dedicated Support', description: 'Experience unparalleled peace of mind with our dedicated support in blockchain services, where our expert team stands committed to providing continuous assistance.' },
    { icon: '../../assets/img/servicesdev/lock.png', heading: 'Compliance-Assured Products', description: 'Designed to meet regulatory standards and ensure a secure, trustworthy, and legally compliant environment for your business operations.' },
    { icon: '../../assets/img/servicesdev/enhance-data.png', heading: 'Enhanced Data Security', description: 'Enhanced protection through decentralized consensus, cryptographic techniques, and immutable ledgers, ensuring a fortified defense against unauthorized access.' },
    { icon: '../../assets/img/servicesdev/setting.png', heading: 'Transparent Environment', description: 'Transparency in your operations with our blockchain solutions, an environment where every transaction is visible, traceable, and verifiable.' }
  ]

  iconsdata = [
    { icon: 'assets/img/servicesdev/icon-one.png', name: 'Enhanced Security' },
    { icon: 'assets/img/servicesdev/icon-two.png', name: 'Lasting Customer Loyalty' },
    { icon: 'assets/img/servicesdev/icon-three.png', name: 'Two-Tier Authentication' },
    { icon: 'assets/img/servicesdev/icon-four.png', name: 'Session Out Feature' },
    { icon: 'assets/img/servicesdev/icon-five.png', name: 'Nullified Frauds & Risk Factors' }
  ]

  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },

    ]
  };

  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },

    ]
  };


}
