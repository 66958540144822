  <!-- services -->
  <div class="flutter_services">
    <div class="text-center service_content m-auto">
      <h2>{{firsttitle}}<span> {{semititle}}</span></h2>
      <p class="my-3">
       {{description}}
      </p>
    </div>
    <div class="shape_bg">
      <div class="bgimg">
        <img
          src="../../assets/img/servicesdev/flutter/flutter_servicebg.png"
          alt=""
          srcset=""
        />
      </div>

      <div class="container serviceWrap">
        <div class="row gy-3">
          <div class="col-md-4" *ngFor="let item of cards">
            <div class="flutter_card">
              <h4>{{ item.heading }}</h4>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>