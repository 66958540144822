<section class="spacing">
    <!-- <div class="container-md"> -->
    <div class="row align-items-center">

        <div class="col-md-9">


            <div class="bgimg">

                <div class="contactImg">
                    <img src="../../assets/img/servicesdev/bottombg.webp" alt="contact" srcset="">
                </div>

                <div class="textwrap">
                    <!-- <h5 *ngIf="currentRoute == '/seo-company/dubai'">Need something beyond our standard SEO packages?
                    </h5> -->
                    <h3>{{text}}</h3>

                    <!-- <a href="/smm-packages"> <h5 style="color: #F7993B;" *ngIf="currentRoute == '/social-media-marketing-company/dubai'">Explore our SMM Package <img src="../../assets/img/servicesdev/orange-arrow.svg" alt="" srcset=""></h5></a> -->

                   
                </div>

            </div>

        </div>
        <div class="col-md-3">
            <div class="mobilecontact">
                <a routerLink="/contact-us" *ngIf="currentRoute != '/social-media-marketing-company/dubai' && currentRoute != '/web-hosting-company/dubai' && currentRoute != '/adwords-agency/dubai' && currentRoute != '/seo-company/dubai' ">
                    <div class="circlewrap">
                        <img src="../../assets/img/servicesdev/circleimg.webp" alt="" srcset="">
                        <div class="textcircle">
                            <p>Contact Us</p>
                        </div>
                    </div>
                </a>
                

                <a routerLink={{contact_url}} *ngIf="currentRoute == '/social-media-marketing-company/dubai' || currentRoute == '/web-hosting-company/dubai' || currentRoute == '/adwords-agency/dubai' || currentRoute == '/seo-company/dubai'">
                    <div class="circlewrap">
                        <img src="../../assets/img/servicesdev/circleimg.webp" alt="" srcset="">
                        <div class="textcircle">
                           <p class="d-flex justify-content-center align-items-center"><img style="animation: none;" src="../../assets/img/servicesdev/arrow_symbol.svg" alt="" srcset=""></p> 
                        </div>
                    </div>
                </a>
            </div>

        </div>

    </div>
    <!-- </div> -->
</section>

<hr>