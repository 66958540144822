import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../_alert';
import { Router } from "@angular/router";

@Component({
	selector: 'app-blog-sidebar',
	templateUrl: './blog-sidebar.component.html',
	styleUrls: ['./blog-sidebar.component.css']
})

export class BlogSidebarComponent {
	blogList: any = [];
	blogCategory: any = [];
	pressRelease: any = [];
	featuredBlogs: any = [];
	totalBlogCount: number = 0;

	constructor(
		private http: HttpClient,
		private formBuilder: FormBuilder,
		private alertService: AlertService,
		private router: Router
	) { }

	blogUrl = environment.blogUrl

	blogForm: any = FormGroup;
	submitted  = false;
	isDisabled = false;
	country_code = '';
	showLoader   = false;
	countries: any = [];
	services: any = [];

	ngOnInit() {
	
		this.http.get(this.blogUrl + 'countries').subscribe(async response => {
			let data: any = response;
			this.countries = data.response;
		});
		this.http.get(this.blogUrl + 'services').subscribe(async response => {
			let data: any = response;
			this.services = data.response;
		});

		// blog categories
		this.http.get(this.blogUrl + 'blog_categories').subscribe(data => {
			let category: any = data;
			this.blogCategory = category.response;
			this.totalBlogCount = this.blogCategory.reduce((acc: any, category: any) => acc + category.blog_count, 0);
		});

		// blog categories
		this.http.get(this.blogUrl + 'latest_press_release').subscribe(data => {
			let clientData: any = data;
			this.pressRelease = clientData.response;
		});

		// blog categories
		this.http.get(this.blogUrl + 'featured_blog_list').subscribe(data => {
			let clientData: any = data;
			this.featuredBlogs = clientData.response;
		});

		this.blogForm = this.formBuilder.group({
			name: ['', Validators.required],
			country_code: ['', Validators.required],
			phone_number: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			business_url: [],
			service: ['', Validators.required],
			message: []
		});

		this.http.get('https://ipapi.co/json').subscribe(async data => {
			let ClientsData: any = data;
			var countryCode = ClientsData.country_calling_code.replace('+', '');
			this.blogForm.controls.country_code.setValue(countryCode);
		});
	}

	get fn() { return this.blogForm.controls; }

	onSubmit(event: any) {
		this.submitted = true;
		if (this.blogForm.invalid) {
			return;
		}
		this.showLoader = true;
		const formData = new FormData(event.target);
		this.http.post(this.blogUrl + 'save_blog_enquiry', formData).subscribe(data => {
			this.isDisabled = false;
			this.showLoader = false;
			let ClientsData: any = data;
			if (ClientsData.success == true) {
				this.alertService.clear();
				this.alertService.success(ClientsData.message);
				this.submitted = false;
				this.blogForm.reset();
				this.router.navigate(['/thankyou']);
			} else {
				this.alertService.clear();
				this.alertService.error(ClientsData.message);
			}
		});
	}

	telInputObject(obj: any) {
		console.log(obj);
		obj.setCountry('in');
	}

	countryChange(country: any) {
		this.blogForm.controls.country_code.setValue(country.dialCode);
	}

	numberOnly(event: any): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	changeBlogCategory(slug: any) {
		this.http.get(this.blogUrl + 'category_blog_list/' + slug).subscribe(data => {
			let blogData: any = data;
			this.blogList = blogData.response;
			this.router.navigateByUrl('/CategoryBlogsComponent', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/blog/category', slug]);
			});
		});
	}
}
  