import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';

@Component({
  selector: 'app-seo',
  templateUrl: './seo-packages.component.html',
  styleUrls: ['./seo-packages.component.css']
})

export class SeoComponent implements OnInit {

  @ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;

  goToBottom() {
    // window.scrollTo(0, 6000);
    this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  // Meta Title
  title = 'Tailored SEO Packages for Business Success | Pro Web Dubai';


  package: any = 1;    //define variable - bydefault it's 1

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private loadingService: LoadingService,


  ) { }


  logoarr = ['/assets/img/packages/whites-logo.svg', '/assets/img/packages/uaedriving-logo.svg', '/assets/img/packages/luxurygallery-logo.svg', '/assets/img/packages/nicccs-logo.svg'];
  logoarr2 = ['/assets/img/servicesdev/niccs-mobile.png', '/assets/img/packages/luxurygallery-logo.svg', '/assets/img/servicesdev/whites-mobile.png'];




  // seo package click function
  seo(a: any) {
    this.package = a;
  }

  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000)
    // Meta Title & Description
    this.titleService.setTitle("Affordable SEO Packages in Dubai | Pro web");

    this.metaTagService.updateTag(
      { name: "keywords", content: "seo packages" }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: "Discover Pro Web's leading SEO services! Tailored packages, adaptable strategies, and a complimentary SEO audit to refine your approach in Dubai." }
    );

  }


  // faq = [
  //   {
  //     title: "What is web design?",
  //     des: "<p> Web design is the process of creating and arranging elements on a website, including layout, graphics, colors, fonts, and interactive features. It aims to enhance user experience and deliver visually appealing, effective, and functional websites.</p>",
  //     isActive: false,
  //   },
  //   {
  //     title: "Why is responsive design important and how does it contribute to mobile optimization?",
  //     des: "<p>Responsive design is vital for a positive user experience on various devices. It ensures mobile optimization by adapting layouts, enhancing accessibility, and accommodating different screen sizes, improving overall usability and satisfaction.</p>",
  //     isActive: false,
  //   },
  //   {
  //     title: "Do you provide UI/UX design services only from scratch? Can you do an existing app/web redesign?",
  //     des: "<p> Absolutely! We offer both UI/UX design for new projects and redesign services for existing app/web. Whether it's a fresh concept or enhancing an established platform, our team is adept at transforming user experiences to meet your specific needs and goals.</p>",
  //     isActive: false,
  //   },
  //   {
  //     title: "Do you redesign websites along with making them responsive ?",
  //     des: '<p>Yes, we offer comprehensive website redesign services, ensuring responsiveness for optimal user experience. Our approach focuses on modernizing aesthetics and functionality to align with evolving design trends and user expectations.</p>',
  //     isActive: false,
  //   },
  //   {
  //     title: "Can you redesign my website to align with my brand guidelines and incorporate my logo?",
  //     des: "<p> Upon receiving your logo and brand guidelines, we're ready to redesign your websites. We'll create mobile/desktop mockups, and the implementation will commence once you approve the proposed design.</p>",
  //     isActive: false,
  //   },
  //   {
  //     title: "What web technologies do you utilize for web design?",
  //     des: '<p>We leverage a range of web technologies for design, including Vue.js, Angular, HTML, WordPress,  Drupal etc. These tools empower us to create dynamic, responsive, and visually appealing websites tailored to your requirements.</p>',
  //     isActive: false,
  //   },



  //   {
  //     title: "Which applications/software are part of your toolkit for creating mockups?",
  //     des: "<p> The tools we use for creating mockups include industry-standard software such as Figma, Sketch, Photoshop, Adobe Illustrator etc. These tools enable us to design and visualize user interfaces effectively during the early stages of the web design process.</p>",
  //     isActive: false,
  //   },
  //   {
  //     title: "How much does a custom web design project typically cost?",
  //     des: '<p>Custom web design projects generally range from 10,000 to 30,000 AED, reflecting the complexity of pages and design mockups. Theme-based options offer a more affordable choice, ranging from 4,000 to 10,000 AED, based on the number of pages involved.</p>',
  //     isActive: false,
  //   },


  // ];

  // toggleAccordian(event: any, index: any) {

  //   this.faq.forEach((item, i) => {
  //     if (i !== index) {
  //       item.isActive = false;
  //     }
  //   });

  //   this.faq[index].isActive = !this.faq[index].isActive;
  // }





}