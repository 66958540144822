<!-- banner section -->
<app-anim-banner
  heading="Hire Native App Developers For IOS And Android In UAE"
  description="Create impactful native apps with essential features and functionalities using our top-notch development services. Our expertise ensures your business stands out in the digital market, delivering high-quality, performance-driven solutions efficiently and effectively."
  image="native/native-anim.png"
></app-anim-banner>

<!-- services -->
<app-services-offer
  [cards]="cardsItems"
  firsttitle="Our Native App Development"
  semititle=" Services"
  description="We excel in delivering high-quality native app development for both iOS and Android. Our dedicated team of experts focuses on crafting custom apps that provide outstanding user experiences and leverage the latest technology. With a comprehensive range of features and functionalities, our apps are designed to help your business stand out in the digital market. Trust Pro Web for innovative and scalable app solutions that drive growth and success."
></app-services-offer>

<!-- empower business -->
<div class="learn_business">
  <div class="container fullwidth">
    <div class="row align-items-center gy-3">
      <div class="col-md-5">
        <div>
          <h2>Benefits Of Native App Development Services</h2>
          <p>
            Our team of skilled developers leverages the latest technologies and
            industry best practices to deliver dynamic and innovative solutions
            across various platforms, including iOS and Android. From concept to
            deployment, we work closely with our clients to ensure that their
            vision is realized, delivering seamless user experiences and robust
            functionality that drive success.
          </p>
        </div>
      </div>
      <div class="col-md-7">
        <div class="empower_wrapper">
          <div>
            <img
              src="../../assets/img/servicesdev/native/cost-saving2.png"
              alt=""
              srcset=""
            />
          </div>

          <div class="emplower_gif">
            <img
              src="../../assets/img/servicesdev/react-native/cost-saving.gif"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- engagement model -->
<div class="engagement">
  <app-engagement-modal></app-engagement-modal>
</div>

<!-- flutter app idea -->
<app-steps-choose
  heading="Our Native App Development Process"
  description="We understand the importance of customizing our approach to each client's unique project, ensuring that every aspect is carefully considered and personalized. From the initial concept to the final product, our focus is on creating a one-of-a-kind experience that perfectly aligns with our client's vision and goals."
  [steps]="data"
></app-steps-choose>

<!-- choose native app -->
<div class="choose-native">
  <div class="container">
    <div class="row align-items-center gy-3">
      <div class="col-md-6">
        <div>
          <h2>Why Choose Pro Web As Your Native App Development Company?</h2>
          <p>
            With a deep understanding of industry trends and cutting-edge
            technologies, our skilled professionals ensure that each app we
            develop is robust, reliable, and strategically aligned with our
            clients' business objectives. We prioritize open communication,
            transparency, and responsiveness throughout the development process.
            Our clients can trust us to deliver results that meet and exceed
            their expectations, leaving a lasting impression and fostering
            long-term partnerships.
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="">
          <div>
            <img
              src="../../assets/img/servicesdev/native/native-img.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- review -->
<app-testimonial-service
  profile="rashwan.svg"
  clientname="Mr. Abdelrahman Rashwan"
  designation="Founder & CEO"
  location="Dubai, United Arab Emirates"
  brandname="The Discounter App"
  flag="uk-flag.svg"
  clientcomment="Rohit was always supportive through out our 2 years of operations, would constantly devote him self to the delivery of the project and support to the team."
  rating="Rated 4.9 out of 5.0"
  [clientimg]="data2"
  [clientmobile]="datamobile"
  clienttarget="discounter-native.png"
></app-testimonial-service>

<!-- contact -->
<app-bottom-bar
  text="Contact us Today to Start your Journey Toward Innovative App Solutions."
></app-bottom-bar>

<!-- FAQ's -->
<section class="spacing">
  <div class="container-md">
    <div class="row justify-content-center">
      <h2>Frequently <span>Asked Questions</span></h2>
      <p class="my-3">
        Discover Insights to Deepen your Grasp of Flutter Fundamentals
      </p>

      <div class="row my-4">
        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(0, 4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i)"
            >
              <h6>{{ item.title }}</h6>

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i + 4)"
            >
              <h6>{{ item.title }}</h6>

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
