<app-packagesbanner heading="Powerful, Secure & Value-Driven Web Hosting Services"
    paragraph="Experience top-notch hosting with uptime, fast loading, and responsive chat support for your business needs. Our Web Hosting is crafted to prioritize your specific requirements.Tailor your package for secure communication, mailbox support, and accelerated website speed."
    bannerimg="/assets/img/packages/packagebg.jpg" bannermobile="/assets/img/packages/web-hosting-mobile.png"
    packageimg="/assets/img/packages/web-hosting.png" [cards]="logoarr" [cards2]="logoarr2"
    text="We Offer"></app-packagesbanner>


<section class="package-content">

    <div class="container">
        <div class="row">
            <div>
                <h2 class="text-center mainheading">Tailored Hosting Excellence  <span class="prohead"> with 24/7 Support </span></h2>

                <div class="bar"></div>
                <p class="text-center desc">Pro Web provides dedicated hosting solutions, which means we handle all
                    aspects of web hosting for you. We understand that your business requires more than just server
                    space and a domain. Our hosting provides uptime, fast loading, unlimited bandwidth, and chat support
                    to efficiently meet your business hosting needs. You may have specific needs like secure
                    communication, mailbox support, enhanced website speed, and more. With a decade of industry
                    experience, we have designed our Web Hosting packages to address these essential requirements. We
                    prioritize your needs and not just ours.</p>
            </div>

            <!-- table -->
            <div class="ptb-20 py-3">

                <h2 class="text-center mainheading mb-3"> Choose Your <span class="prohead"> Web Hosting
                        Package </span></h2>

                <div class="bar"></div>

                <!-- Desktop -->

                <div class="tableContent" [ngClass]="{'myCSSclass' : addclass == true}">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Info</td>
                                <td class="text-center"> A great startup plan! Hosting with an entry-level price for new
                                    sites, including WordPress and a few emails. </td>
                                <td class="text-center"> Host your website with more space and flexibility. Includes a
                                    free SSL. Loaded with free tools. </td>
                                <td class="text-center"> Best hosting experience, more power for complex sites and heavy
                                    traffic. Includes free SSL & IP.
                                </td>
                            </tr>
                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center"> <span class="pricecolor">999 AED</span> Per Year
                                </td>
                                <td class="text-center"><span class="pricecolor">2999 AED</span> Per Year
                                </td>
                                <td class="text-center"><span class="pricecolor">4499 AED </span>Per Year</td>
                            </tr>

                            <tr class="white">
                                <td>Hosting Server</td>
                                <td class="text-center"> <img src="./assets/img/icons/linux-hosting.png" alt=""
                                        srcset="">
                                </td>
                                <td class="text-center"> <img src="./assets/img/icons/linux-hosting.png" alt=""
                                        srcset="">
                                </td>
                                <td class="text-center"> <img src="./assets/img/icons/linux-hosting.png" alt=""
                                        srcset="">
                                </td>
                            </tr>


                            <tr class="white">
                                <td>Intel(R) Xeon(R) CPU E3-1230 v5 3.40GHz</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset="">
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Website</td>
                                <td class="text-center">1
                                </td>
                                <td class="text-center">1
                                </td>
                                <td class="text-center">1
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Dedicated IP</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>SSL Included</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""></td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>RAM </td>
                                <td class="text-center"> 8 GB </td>
                                <td class="text-center">16 GB</td>
                                <td class="text-center"> 16 GB</td>
                            </tr>

                            <tr class="white">
                                <td>Storage / Webspace</td>
                                <td class="text-center">20 GB
                                </td>
                                <td class="text-center">50 GB
                                </td>
                                <td class="text-center">100 GB
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Emails Address</td>
                                <td class="text-center">10
                                </td>
                                <td class="text-center">25
                                </td>
                                <td class="text-center">Unlimited
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="text-center py-3 morefeatbtn"
                    style="border: 1px solid #e3d8d8; border-top: none; cursor: pointer;">
                    <div (click)="showdata()"> <b>More Features</b>
                        <img src="/assets/img/webp/add3.webp" class="mx-2" *ngIf="addclass == false" alt="" srcset=""> 
                           
                        <img src="/assets/img/webp/minus3.webp" class="mx-2" *ngIf="addclass == true" alt="" srcset=""> 
                      
                    </div>
                </div>

                <div class="tableContent2" [ngClass]="{'myCSSclass' : addclass == true}">
                    <table class="table">
                        <tbody>

                            <tr class="white">
                                <td>Monthly Bandwidth</td>
                                <td class="text-center">15 GB
                                </td>
                                <td class="text-center">50 GB
                                </td>
                                <td class="text-center">Unlimited
                                </td>
                            </tr>
                            <tr class="white">
                                <td>CPU Cores</td>
                                <td class="text-center">4
                                </td>
                                <td class="text-center">4
                                </td>
                                <td class="text-center">4
                                </td>
                            </tr>

                            <tr class="white">
                                <td>SSD Drives</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>My SQL 5.7 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>PHP 7.3 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Licensed cPanel </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>


                            <tr class="white">
                                <td>Apache 2.4.25 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>


                            <tr class="white">
                                <td>Firewall </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>FTP Access </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Sub Domains Unlimited</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>POP3 / IMAP Support</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Web Mail Access </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Spam Assasin</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Auto Reply Forwarders</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Full DNS Management ( A, C Name, MX Records)</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>
                            <tr class="white">
                                <td>Customizable Error Pages</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""></td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""></td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>

                        </tbody>
                    </table>
                </div>



                <div class="mobTabpills">

                    <ul>
                        <li (click)="seo(1)" [ngClass]="{'bgtab' : package == 1}">Startup</li>
                        <li (click)="seo(2)" [ngClass]="{'bgtab' : package == 2}">Gold</li>
                        <li (click)="seo(3)" [ngClass]="{'bgtab' : package == 3}">Enterprise</li>
                    </ul>

                </div>


                <!-- *************************************** -->

                <!-- Mobile -->

                <div class="tableContentMobile">

                    <table class="table" *ngIf="package == 1">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Info</td>
                                <td class="text-center">A great startup plan! Hosting with an entry-level price for new
                                    sites, including
                                    WordPress and a few emails.</td>

                            </tr>

                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center"> <span class="pricecolor">999 AED </span> Per Year</td>
                            </tr>


                            <tr class="white">
                                <td>Hosting Server</td>
                                <td class="text-center"> <img src="./assets/img/icons/linux-hosting.png" alt=""
                                        srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Intel(R) Xeon(R) CPU E3-1230 v5 3.40GHz </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Website </td>
                                <td class="text-center">1 </td>

                            </tr>

                            <tr class="white">
                                <td>Dedicated IP</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>SSL Included</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>RAM </td>
                                <td class="text-center">8 GB</td>

                            </tr>
                            <tr class="white">
                                <td>Storage / Webspace </td>
                                <td class="text-center"> 20 GB </td>

                            </tr>

                            <tr class="white">
                                <td>Emails Address </td>
                                <td class="text-center"> 10 </td>

                            </tr>
                            <tr class="white">
                                <td>Monthly Bandwidth </td>
                                <td class="text-center"> 15 GB </td>

                            </tr>

                            <tr class="white">
                                <td>CPU Cores </td>
                                <td class="text-center"> 4 </td>

                            </tr>

                            <tr class="white">
                                <td>SSD Drives </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>My SQL 5.7 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>PHP 7.3 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td>Licensed cPanel </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Apache 2.4.25 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Firewall</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>FTP Access </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td>Sub Domains Unlimited </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>POP3 / IMAP Support </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Web Mail Access </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>Spam Assasin</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td> Auto Reply Forwarders
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Full DNS Management ( A, C Name, MX Records) </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Customizable Error Pages </td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""></td>

                            </tr>



                        </tbody>
                    </table>

                    <table class="table" *ngIf="package == 2">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png" class="goldbadge" alt=""> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Info</td>
                                <td class="text-center">Host your website with more space and flexibility. Includes a
                                    free SSL. Loaded with
                                    free tools.</td>

                            </tr>

                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center"> <span class="pricecolor">2999 AED </span> Per Year</td>
                            </tr>


                            <tr class="white">
                                <td>Hosting Server</td>
                                <td class="text-center"> <img src="./assets/img/icons/linux-hosting.png" alt=""
                                        srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Intel(R) Xeon(R) CPU E3-1230 v5 3.40GHz </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Website </td>
                                <td class="text-center">1 </td>

                            </tr>

                            <tr class="white">
                                <td>Dedicated IP</td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>SSL Included</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>RAM </td>
                                <td class="text-center">16 GB</td>

                            </tr>
                            <tr class="white">
                                <td>Storage / Webspace </td>
                                <td class="text-center"> 50 GB </td>

                            </tr>

                            <tr class="white">
                                <td>Emails Address </td>
                                <td class="text-center"> 25 </td>

                            </tr>
                            <tr class="white">
                                <td>Monthly Bandwidth </td>
                                <td class="text-center"> 50 GB </td>

                            </tr>

                            <tr class="white">
                                <td>CPU Cores </td>
                                <td class="text-center"> 4 </td>

                            </tr>

                            <tr class="white">
                                <td>SSD Drives </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>My SQL 5.7 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>PHP 7.3 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td>Licensed cPanel </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Apache 2.4.25 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Firewall</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>FTP Access </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td>Sub Domains Unlimited </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>POP3 / IMAP Support </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Web Mail Access </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>Spam Assasin</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td> Auto Reply Forwarders
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Full DNS Management ( A, C Name, MX Records) </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Customizable Error Pages </td>
                                <td class="text-center">  <img src="/assets/img/webp/red_tick.webp" alt="" srcset=""></td>

                            </tr>



                        </tbody>
                    </table>

                    <table class="table" *ngIf="package == 3">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Info</td>
                                <td class="text-center">Best hosting experience, more power for complex sites and heavy
                                    traffic. Includes
                                    free SSL & IP.</td>

                            </tr>

                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center"> <span class="pricecolor">4499 AED
                                    </span> Per Year</td>
                            </tr>


                            <tr class="white">
                                <td>Hosting Server</td>
                                <td class="text-center"> <img src="./assets/img/icons/linux-hosting.png" alt=""
                                        srcset=""> </td>
                            </tr>

                            <tr class="white">
                                <td>Intel(R) Xeon(R) CPU E3-1230 v5 3.40GHz </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Website </td>
                                <td class="text-center">1 </td>

                            </tr>

                            <tr class="white">
                                <td>Dedicated IP</td>
                                <td class="text-center"> <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>
                            <tr class="white">
                                <td>SSL Included</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>RAM </td>
                                <td class="text-center">16 GB
                                </td>

                            </tr>
                            <tr class="white">
                                <td>Storage / Webspace </td>
                                <td class="text-center"> 100 GB
                                </td>

                            </tr>

                            <tr class="white">
                                <td>Emails Address </td>
                                <td class="text-center"> Unlimited
                                </td>

                            </tr>
                            <tr class="white">
                                <td>Monthly Bandwidth </td>
                                <td class="text-center"> Unlimited
                                </td>

                            </tr>

                            <tr class="white">
                                <td>CPU Cores </td>
                                <td class="text-center"> 4 </td>

                            </tr>

                            <tr class="white">
                                <td>SSD Drives </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>My SQL 5.7 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>PHP 7.3 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td>Licensed cPanel </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Apache 2.4.25 </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Firewall</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>FTP Access </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td>Sub Domains Unlimited </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>POP3 / IMAP Support </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Web Mail Access </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>

                            <tr class="white">
                                <td>Spam Assasin</td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>
                            </tr>



                            <tr class="white">
                                <td> Auto Reply Forwarders
                                </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Full DNS Management ( A, C Name, MX Records) </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""> </td>

                            </tr>

                            <tr class="white">
                                <td>Customizable Error Pages </td>
                                <td class="text-center">  <img src="/assets/img/webp/green_tick.webp" alt="" srcset=""></td>

                            </tr>



                        </tbody>
                    </table>

                </div>

                <!-- ************************************* -->



                <!-- Desktop -->

                <!-- <div class="wrapper">

                    <div class="card">

                        <div class="top">
                            <h2>Basic</h2>
                            <h4>AED 2500</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>12 posts/reels and 5 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram)
                            </li>
                            <li>Social Media account setup.
                                Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Monthly progress report &
                                recommendations.</li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                        </ul>

                    </div>

                    <div class="card">

                        <div class="top">
                            <h2>Advanced</h2>
                            <h4>AED 4000</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>20 posts/reels and 12 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram) + Choice of any 2
                                platforms (X/ Threads/ Snapchat/
                                Pinterest/ TikTok)
                            </li>
                            <li>Social Media account setup.

                            </li>
                            <li>Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Dedicated account manager.
                            </li>
                            <li>Monthly progress report &
                                Recommendations.</li>
                            <li>Social Media Profile optimization
                                (keywords, hashtags)
                            </li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                            <li>Purpose-driven social media ad
                                strategy plan. (Ad budget extra)</li>
                        </ul>

                    </div>

                    <div class="card">

                        <div class="top">
                            <h2>Pro</h2>
                            <h4>AED 4000</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>30 posts/reels and 20 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram) + Choice of any 3
                                platforms (X/ Threads/ Snapchat/
                                Pinterest/ TikTok)
                            </li>
                            <li>Social Media account setup.

                            </li>
                            <li>Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Dedicated account manager.
                            </li>
                            <li>Monthly progress report &
                                Recommendations.
                            </li>
                            <li>Social Media Profile optimization
                                (keywords, hashtags)
                            </li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                            <li>Purpose-driven social media ad
                                strategy plan. (Ad budget extra)
                            </li>
                            <li>Influencer outreach (Budget extra)
                            </li>
                        </ul>

                    </div>
                </div> -->

                <!-- ************************************* -->

            </div>

        </div>
    </div>

</section>
<app-check-out checkoutImg="../../assets/img/servicesdev/checkout_webhosting.png" alt="check Out"
    checkoutText="Check Out Our" checkoutName="Web Hosting Sevices" checkoutUrl=""
    [checkout_arr]="data"></app-check-out>

<app-bottom-bar text="Looking for Web Hosting Package for your Business?"></app-bottom-bar>