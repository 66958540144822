<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-6">
                        <div class="hero-content">
                            <h1>Built for Developers</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                            <a href="#" class="btn btn-primary">Get Started</a>
                        </div>
                    </div>

                    <div class="col-xl-5 offset-xl-1 col-lg-6">
                        <div class="banner-form ml-3">
                            <form>
                                <div class="form-group">
                                    <label>Username</label>
                                    <input type="text" class="form-control" placeholder="Enter username">
                                </div>

                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control" placeholder="Enter your email">
                                </div>

                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class="form-control" placeholder="Create a password">
                                </div>

                                <button type="submit" class="btn btn-primary">Register Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="shape"></div>
    <div class="shape2 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.svg" alt="shape"></div>
    <div class="shape4"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape5"><img src="assets/img/shape5.png" alt="shape"></div>
    <div class="shape6 rotateme"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape7"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
</div>

<section class="boxes-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-box">
                   
                    <h3>Zero Configuration</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-box bg-f78acb">
                    
                    <h3>Code Security</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-box bg-c679e3">
                    
                    <h3>Team Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-box bg-eb6b3d">
                   
                    <h3>Access Controlled</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-80 bg-f7fafd">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12 services-content">
                <div class="section-title">
                    <h2>Experience The Best Features Ever</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Cloud databases
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                             Website hosting
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                             File storage
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                             Forex trading
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            File backups
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                             Remote desktop
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Email servers
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                             Hybrid cloud
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 services-right-image">
                <img src="assets/img/services-right-image/book-self.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="book-self">
                <img src="assets/img/services-right-image/box.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="box">
                <img src="assets/img/services-right-image/chair.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="chair">
                <img src="assets/img/services-right-image/cloud.png" class="wow zoomIn" data-wow-delay="0.6s" alt="cloud">
                <img src="assets/img/services-right-image/cup.png" class="wow bounceIn" data-wow-delay="0.6s" alt="cup">
                <img src="assets/img/services-right-image/flower-top.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="flower">
                <img src="assets/img/services-right-image/head-phone.png" class="wow zoomIn" data-wow-delay="0.6s" alt="head-phone">
                <img src="assets/img/services-right-image/monitor.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="monitor">
                <img src="assets/img/services-right-image/mug.png" class="wow rotateIn" data-wow-delay="0.6s" alt="mug">
                <img src="assets/img/services-right-image/table.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="table">
                <img src="assets/img/services-right-image/tissue.png" class="wow zoomIn" data-wow-delay="0.6s" alt="tissue">
                <img src="assets/img/services-right-image/water-bottle.png" class="wow zoomIn" data-wow-delay="0.6s" alt="water-bottle">
                <img src="assets/img/services-right-image/wifi.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="wifi">
                <img src="assets/img/services-right-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

                <img src="assets/img/services-right-image/main-pic.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic">
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-80">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12 services-left-image">
                <img src="assets/img/services-left-image/big-monitor.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="big-monitor">
                <img src="assets/img/services-left-image/creative.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="creative">
                <img src="assets/img/services-left-image/developer.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="developer">
                <img src="assets/img/services-left-image/flower-top.png" class="wow zoomIn" data-wow-delay="0.6s" alt="flower-top">
                <img src="assets/img/services-left-image/small-monitor.png" class="wow bounceIn" data-wow-delay="0.6s" alt="small-monitor">
                <img src="assets/img/services-left-image/small-top.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="small-top">
                <img src="assets/img/services-left-image/table.png" class="wow zoomIn" data-wow-delay="0.6s" alt="table">
                <img src="assets/img/services-left-image/target.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="target">
                <img src="assets/img/services-left-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

                <img src="assets/img/services-left-image/main-pic.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic">
            </div>

            <div class="col-lg-6 col-md-12 services-content">
                <div class="section-title">
                    <h2>Design & Development</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Responsive design
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                           React web development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Android apps development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                             Laravel web development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                           iOS apps development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            UX/UI design
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Ecommerce development
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="box">
                            Print ready design
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-area ptb-80 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-features">

                    <h3>Incredible Infrastructure</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                   

                    <h3>Email Notifications</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3>Simple Dashboard</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3>Information Retrieval</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3>Drag and Drop Functionality</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-features">
                    <h3>Deadline Reminders</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="pricing-area ptb-80 bg-f9f6f6">
    <div class="container">
        <div class="section-title">
            <h2>Pricing Plans</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="pricing-header">
                        <h3>Basic Plan</h3>
                    </div>

                    <div class="price">
                        <span><sup>$</sup>15.00 <span>/ Mon</span></span>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li class="active">5 GB Bandwidth</li>
                            <li class="active">Highest Speed</li>
                            <li class="active">1 GB Storage</li>
                            <li class="active">Unlimited Website</li>
                            <li class="active">Unlimited Users</li>
                            <li class="active">24x7 Great Support</li>
                            <li>Data Security and Backups</li>
                            <li>Monthly Reports and Analytics</li>
                        </ul>
                    </div>

                    <div class="pricing-footer">
                        <a href="#" class="btn btn-primary">Select Plan</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table active-plan">
                    <div class="pricing-header">
                        <h3>Advanced Plan</h3>
                    </div>

                    <div class="price">
                        <span><sup>$</sup>35.00 <span>/ Mon</span></span>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li class="active">10 GB Bandwidth</li>
                            <li class="active">Highest Speed</li>
                            <li class="active">3 GB Storage</li>
                            <li class="active">Unlimited Website</li>
                            <li class="active">Unlimited Users</li>
                            <li class="active">24x7 Great Support</li>
                            <li class="active">Data Security and Backups</li>
                            <li>Monthly Reports and Analytics</li>
                        </ul>
                    </div>

                    <div class="pricing-footer">
                        <a href="#" class="btn btn-primary">Select Plan</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table">
                    <div class="pricing-header">
                        <h3>Expert Plan</h3>
                    </div>

                    <div class="price">
                        <span><sup>$</sup>65.00 <span>/ Mon</span></span>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li class="active">15 GB Bandwidth</li>
                            <li class="active">Highest Speed</li>
                            <li class="active">5 GB Storage</li>
                            <li class="active">Unlimited Website</li>
                            <li class="active">Unlimited Users</li>
                            <li class="active">24x7 Great Support</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="active">Monthly Reports and Analytics</li>
                        </ul>
                    </div>

                    <div class="pricing-footer">
                        <a href="#" class="btn btn-primary">Select Plan</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
    <div class="shape2 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
    <div class="shape7"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape4"><img src="assets/img/shape4.svg" alt="shape"></div>
</section>


<section class="blog-area ptb-80">
    <div class="container">
        <div class="section-title">
            <h2>The News from Our Blog</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/1.jpg" alt="image">
                        </a>

                        <div class="date">
                           March 15, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">The security risks of changing package owners</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">
                          Read More 
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/2.jpg" alt="image">
                        </a>

                        <div class="date">
                           March 17, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Tips to Protecting Your Business and Family</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/3.jpg" alt="image">
                        </a>

                        <div class="date">
                             March 19, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
