<!-- Start Page Title -->
<div class="page-title-area">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<h2>{{careerJobsList.title}}</h2>
				<a href="javascript:;" class="btn btn-primary bop-btn"
					(click)=selectjob(careerJobsList.title,target)>Apply Now</a>
			</div>
		</div>
	</div>
</div>
<!-- End Page Title -->

<section class="vacancy-sec">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="section-title">
					<h6 class="career-foo-head">Job Description</h6>
					<div class="bar"></div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="jobdetails">

					<p *ngIf="careerJobsList.title"><b>Role :</b> {{careerJobsList.title}}</p>
					<p *ngIf="careerJobsList.profile_id"><b>Profile Id :</b> {{careerJobsList.profile_id}}</p>
					<p *ngIf="careerJobsList.experience"><b>Experience :</b> {{careerJobsList.experience}}</p>
					<p *ngIf="careerJobsList.skills"><b>Skills :</b> {{careerJobsList.skills}}</p>
					<p *ngIf="careerJobsList.location"><b>Location :</b> {{careerJobsList.location}}</p>
					<p *ngIf="careerJobsList.job_description"><b>Job Description:</b></p>
					<p *ngIf="careerJobsList.job_description" [innerHTML]="careerJobsList.job_description"></p>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="vacancy-form-sec" #target id="targetElement">
	<div class="container">
		<div class="row">
			<div class="col-md-5">
				<h2 class="vfc-left-head">
					Looking for work? <span>Feel free</span>
					to connect with us, we are available
				</h2>
			</div>
			<div class="col-md-7">
				<h4 class="vacform-head">Drop your Resume</h4>
				<div class="vacform-div">
					<app-alert></app-alert>
					<div class="formLoader" *ngIf="showLoader">Please Wait...</div>
					<form [formGroup]="careerForm" (ngSubmit)="onSubmit($event)" enctype="multipart/form-data"
						id="careerForm" ngNativeValidate>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<input type="text" class="form-control" name="name" formControlName="name"
										placeholder="Name*" required>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<input type="email" class="form-control" name="email" formControlName="email"
										placeholder="Email*" required>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group form-tel-box">
									<select name="country_code" formControlName="country_code" class="ctry-select">
										<option *ngFor="let country of countries"
											[attr.data-countryCode]="country.country_code"
											value="{{country.phone_code}}">+{{ country.phone_code }}</option>
									</select>

									<input type="text" class="form-control ps-3" formControlName="phone_number"
										placeholder="Mobile No.*" name="phone_number" (keypress)="numberOnly($event)"
										(countryChange)="countryChange($event)" required>
								</div>
							</div>
							<div class="col-md-6 d-flex align-items-center">

								<select name="interested_in" class="form-select px-0 ddvalue"
									aria-label="Default select example"
									style="border: none; border-bottom: 1px solid #e5eaf0; border-radius: 0%; font-size: 14px; color: gray;">
									<option selected>Interested In*</option>
									<option value="Marketing Manager"> Marketing Manager</option>
									<option value="Customer Support Executive">Customer Support Executive
								</select>


							</div>



							<div class="col-md-12">
								<div class="form-group">
									<input type="url" class="form-control" name="linkedin_url"
										formControlName="linkedin_url" placeholder="LinkedIn Profile URL*">
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group upload_outer">
									<label class="blank">Upload Resume (docx, pdf only)*</label>
									<span class="wpcf7-form-control-wrap file-376">
										<input type="file" name="resume" size="40"
											class="wpcf7-form-control wpcf7-file input-file file_brws" id="resume"
											accept=".docx,.pdf,.DOCX,.PDF" aria-invalid="false"
											(change)="getFiles($event)" required formControlName="resume">
										<input type="hidden" name="filedata" id="filedata" formControlName="filedata">
									</span>
									<label control-label="" class="file_btn" for="filebutton"></label>
									<p style="color: #000;">{{filename}}</p>
								</div>
							</div>
						</div>
						<button type="submit" class="btn btn btn-primary" [disabled]="isDisabled">Submit</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>