import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-anim-banner',
  templateUrl: './anim-banner.component.html',
  styleUrls: ['./anim-banner.component.css'],
})
export class AnimBannerComponent {
  @Input() heading: any;
  @Input() description: any;
  @Input() image: any;
}
