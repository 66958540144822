<div class="formMain">
    <div class="text-center">
        <h4 class="bg-white p-2">Grab a FREE SEO Proposal Today!</h4>
    </div>
    <div class="formspacing">
        <!-- first -->
        <div *ngIf="currentStep === 1">
            <div class="text-center">
                <h6 class="py-1 py-md-3 text-white"> What Type Of A Site Do You Own?</h6>
                <hr>
            </div>
            <div class="d-flex justify-content-around py-4">
                <div class="py-3 p-md-3 bg-white text-center border-3 rounded-bottom boxForm"
                    (click)="goToNextStep('1','Portfolio')">
                    <div class="iconwrap">
                        <img src="../../assets/img/servicesdev/seo-service/portfolio-gray.svg" width="50" alt="">
                    </div>
                    <p class="pt-2 fw-400 heading">PORTFOLIO</p>
                </div>
                <div class="py-3 p-md-3 bg-white text-center rounded-bottom boxForm"
                    (click)="goToNextStep('1','E-Commerce')">
                    <div class="iconwrap">
                        <img src="../../assets/img/servicesdev/seo-service/ecommerce-gray.svg" width="50" alt="">
                    </div>
                    <p class="pt-2 heading">E-COMMERCE</p>
                </div>
                <div class="py-3 p-md-3 bg-white text-center rounded-bottom boxForm" (click)="goToNextStep('1','App')">
                    <div class="iconwrap">
                        <img src="../../assets/img/servicesdev/seo-service/app-gray.svg" width="30" alt="">
                    </div>
                    <p class="pt-2 heading">APP</p>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" [style.width]="progressBarValue + '%'"
                    aria-valuenow="progressBarValue + '%'" aria-valuemin="0" aria-valuemax="100">{{progressBarValue +
                    '%'}}
                </div>
            </div>
        </div>
        <!-- second -->
        <div *ngIf="currentStep === 2">
            <div class="text-center">
                <h6 class="py-md-3 text-white">
                    <span class="icon" (click)="goToPreviousStep()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-caret-left" viewBox="0 0 16 16">
                            <path
                                d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                    </span> How Many Keywords Are You Planning To <br> Target Through SEO?
                </h6>
            </div>
            <div class="row justify-content-between g-2 g-md-0 py-4">
                <div class="col-6 col-md">
                    <div class="p-3 bg-white text-center border-3 rounded-bottom boxForm2"
                        (click)="goToNextStep('2','10 Keywords')">
                        <!-- <img src="https://www.proweb.ae/assets/img/seo-page/keyword-icon.svg" width="50" alt=""> -->
                        <p class="py-md-4 fw-400 heading">10 KEYWORDS</p>
                    </div>
                </div>
                <div class="col-6 col-md">
                    <div class="p-3 bg-white text-center rounded-bottom boxForm2"
                        (click)="goToNextStep('2','20 Keywords')">
                        <!-- <img src="https://www.proweb.ae/assets/img/seo-page/keyword-icon.svg" width="50" alt=""> -->
                        <p class="py-md-4 heading">20 KEYWORDS</p>
                    </div>
                </div>
                <div class="col-6 col-md">
                    <div class="p-3 bg-white text-center rounded-bottom boxForm2"
                        (click)="goToNextStep('2', '40 Keywords')">
                        <!-- <img src="https://www.proweb.ae/assets/img/seo-page/keyword-icon.svg" width="50" alt=""> -->
                        <p class="py-md-4 heading">40 KEYWORDS</p>
                    </div>
                </div>
                <div class="col-6 col-md">
                    <div class="p-3 bg-white text-center rounded-bottom boxForm2"
                        (click)="goToNextStep('2','Not Sure')">
                        <!-- <img src="https://www.proweb.ae/assets/img/seo-page/keyword-grey-icon.svg" width="50" alt=""> -->
                        <p class="py-md-4 mb-0 mb-md-3 heading">NOT SURE</p>
                        <p class="d-none d-md-block"></p>
                    </div>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" [style.width]="progressBarValue + '%'"
                    aria-valuenow="progressBarValue + '%'" aria-valuemin="0" aria-valuemax="100">{{progressBarValue +
                    '%'}}
                </div>
            </div>
        </div>
        <!-- third -->
        <div *ngIf="currentStep === 3">
            <div class="text-center">
                <h6 class="py-1 py-md-3 text-white">
                    <span class="icon" (click)="goToPreviousStep()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-caret-left" viewBox="0 0 16 16">
                            <path
                                d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                    </span> Which Country Do You Wish To Target For <br /> Your Business
                </h6>
            </div>
            <div class="row row-cols-6 gy-3 justify-content-between py-2">
                <div class="col-6">
                    <div class="p-3 bg-white text-center border-3 rounded-bottom boxForm3"
                        (click)="goToNextStep('3','United Arab Emirates')">
                        <img src="../../assets/img/servicesdev/seo-service/uae.svg" width="50" alt="">
                        <p class="py-2 heading">UNITED ARAB EMIRATES</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="p-3 bg-white text-center rounded-bottom boxForm3"
                        (click)="goToNextStep('3','Gulf Cooperation Council')">
                        <img src="../../assets/img/servicesdev/seo-service/gcc.svg" width="50" alt="">
                        <p class="py-2 heading">GULF COOPERATION COUNCIL</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="p-3 bg-white text-center rounded-bottom boxForm3"
                        (click)="goToNextStep('3','Worldwide')">
                        <img src="../../assets/img/servicesdev/seo-service/worldwide.svg" width="50" alt="">
                        <p class="py-2 heading">WORLDWIDE</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="p-3 bg-white text-center rounded-bottom boxForm3"
                        (click)="goToNextStep('3','Not Sure')">
                        <img src="../../assets/img/servicesdev/seo-service/not-sure.svg" width="50" alt="">
                        <p class="py-2 heading">NOT SURE</p>
                    </div>
                </div>
            </div>
            <div class="progress mt-3">
                <div class="progress-bar" role="progressbar" [style.width]="progressBarValue + '%'"
                    aria-valuenow="progressBarValue + '%'" aria-valuemin="0" aria-valuemax="100">{{progressBarValue +
                    '%'}}
                </div>
            </div>
        </div>
        <!-- four -->
        <div *ngIf="currentStep === 4">
            <div class="text-center">
                <h6 class="py-1 py-md-3 text-white">
                    <span class="icon" (click)="goToPreviousStep()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-caret-left" viewBox="0 0 16 16">
                            <path
                                d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                    </span>
                    How Can We Contact You?
                </h6>
            </div>
            <div class="py-2">
                <app-alert></app-alert>
                <div class="formLoader" *ngIf="showLoader">Please Wait...</div>
                <form action="" method="POST" novalidate="novalidate" [formGroup]="seoForm"
                    (ngSubmit)="onSubmit($event)" #seoFormNew="ngForm" id="seoForm">
                    <div class="mb-3">
                        <input type="text" class="form-control bg-white" id="exampleInput1" aria-describedby=""
                            placeholder="Site URL*" name="site_url" formControlName="site_url"
                            [ngClass]="{ 'is-invalid': submitted && fn.site_url.errors }">

                        <div *ngIf="submitted && fn.site_url.errors" class="invalid-feedback">
                            <div *ngIf="fn.site_url.errors.required">Please, enter your website url</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <input type="text" class="form-control bg-white" id="exampleInput2" aria-describedby=""
                            placeholder="Name*" name="name" formControlName="name"
                            [ngClass]="{ 'is-invalid': submitted && fn.name.errors }">

                        <div *ngIf="submitted && fn.name.errors" class="invalid-feedback">
                            <div *ngIf="fn.name.errors.required">Please, enter your name</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <input type="email" class="form-control bg-white" id="exampleInput3" aria-describedby=""
                            placeholder="Email*" name="email" formControlName="email"
                            [ngClass]="{ 'is-invalid': submitted && fn.email.errors }">

                        <div *ngIf="submitted && fn.email.errors" class="invalid-feedback">
                            <div *ngIf="fn.email.errors.required">Please, enter a valid email
                            </div>
                            <div *ngIf="fn.email.errors.email">Please, enter a valid email</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex mb-2 countrycontact">
                            <select name="country_code" formControlName="country_code" class="ctry-select"
                                style="background-position: 98%;">
                                <option *ngFor="let country of countries"
                                                    [attr.data-countryCode]="country.country_code"
                                                    value="{{country.phone_code}}">+{{ country.phone_code }}</option>
                            </select>

                            <input type="number" class="form-control bg-white" id="exampleInput4" aria-describedby=""
                                placeholder="Contact No.*" name="phone_number" formControlName="phone_number"
                                (keypress)="numberOnly($event)"
                                [ngClass]="{ 'is-invalid': submitted && fn.phone_number.errors }"
                                (countryChange)="countryChange($event)">
                        </div>
                        <div *ngIf="submitted && fn.phone_number.errors" class="invalid-feedback">
                            <div *ngIf="fn.phone_number.errors.required">Please, enter Phone
                                number</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <input type="text" class="form-control bg-white" id="exampleInput4" aria-describedby=""
                            placeholder="Company Name*" name="company_name" formControlName="company_name"
                            [ngClass]="{ 'is-invalid': submitted && fn.company_name.errors }">

                        <div *ngIf="submitted && fn.company_name.errors" class="invalid-feedback">
                            <div *ngIf="fn.company_name.errors.required">Please, enter company name</div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <button type="submit" class="btn btn-primary w-100 py-2">Request
                            Proposal</button>
                        <small class="text-white">*We will get back to you within 24 hours.</small>
                    </div>
                </form>
            </div>
            <div class="progress mt-3">
                <div class="progress-bar" role="progressbar" [style.width]="progressBarValue + '%'"
                    aria-valuenow="progressBarValue + '%'" aria-valuemin="0" aria-valuemax="100">{{progressBarValue +
                    '%'}}
                </div>
            </div>
        </div>


    </div>


</div>