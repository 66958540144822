import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CanonicalResolver implements Resolve<void> {
	constructor(private meta: Meta) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
		const canonicalUrl = window.location.origin + state.url;
		this.setCanonicalUrl(canonicalUrl);
	}

	private setCanonicalUrl(url: string): void {
		// Remove any existing canonical link
		const existingLink: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');
		if (existingLink) {
			existingLink.setAttribute('href', url);
		} else {
			// Add new canonical link
			const link: HTMLLinkElement = document.createElement('link');
			link.setAttribute('rel', 'canonical');
			link.setAttribute('href', url);
			document.head.appendChild(link);
		}
	}
}
