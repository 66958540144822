<section class="spacing" #scrollTarget>
  <div class="container">
    <h2 class="mb-4 mb-md-0">
      Managed Cloud Hosting <span> Built to Scale</span>
    </h2>
    <p class="my-4 d-none d-md-block">
      Build, deploy and manage all your sites and apps with lightning-fast,
      multi-platform cloud hosting. Perfect for agencies, online stores,
      developers, multi-site hosting and high traffic sites.
    </p>
    <div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            [ngClass]="
              selectedPackage === 'Amazon Web Servers'
                ? 'nav-link  active'
                : 'nav-link '
            "
            id="pills-home-tab"
            class="btn-tabs"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          ></button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            [ngClass]="
              selectedPackage === 'Microsoft Azure'
                ? 'nav-link  active'
                : 'nav-link'
            "
            id="pills-profile-tab"
            class="btn-tabs"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          ></button>
        </li>
        <!-- <li class="nav-item" role="presentation">
               <button [ngClass]="selectedPackage === 'Google Cloud' ? 'nav-link  active' : 'nav-link'"
                   id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button"
                   role="tab" aria-controls="pills-contact" aria-selected="false">Cloud</button>
               Google Cloud</li> -->
        <li class="nav-item" role="presentation">
          <button
            [ngClass]="
              selectedPackage === 'Digital Ocean'
                ? 'nav-link  active'
                : 'nav-link'
            "
            id="pills-four-tab"
            data-bs-toggle="pill"
            class="btn-tabs"
            data-bs-target="#pills-four"
            type="button"
            role="tab"
            aria-controls="pills-four"
            aria-selected="false"
          ></button>
        </li>
      </ul>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div
        [ngClass]="
          selectedPackage === 'Amazon Web Servers'
            ? 'tab-pane fade show active'
            : 'tab-pane  '
        "
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div
          class="table-hidden"
          [ngClass]="{ 'badge-secondary': isExpanded == true }"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="featurehead">Features</th>
                <th scope="col">
                  <img
                    src="../../assets/img/servicesdev/aws-logo.webp"
                    width="40px"
                    alt=""
                    srcset=""
                  />
                </th>
                <th
                  scope="col"
                  class="d-flex justify-content-center align-items-center border-0"
                >
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/AWS_proweb.svg"
                    width="150px"
                    alt=""
                    srcset=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Subscription Management</td>
                <td>Managed by Customer</td>
                <td>Managed by Pro Web</td>
              </tr>
              <tr>
                <td>Security Hardening</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Installations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Migration (Websites/Applications)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>1 Included</td>
              </tr>
              <tr>
                <td>Initial Security Scanning & Handover</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Dedicated Support</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Server Uptime Monitering</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Server Level Vulnerability Assessment(Optional)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                  Quarterly (Paid Purchace)
                </td>
              </tr>
              <tr>
                <td>
                  Automated Server Performance Reporting & Capacity Planning
                </td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Alerts & Mitigations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>SSL Certificates installations(Included with AWS)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>1 SSL Certificate</td>
              </tr>
              <tr>
                <td>Firewall Management</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Log Management</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Optimization</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Backup & Cyber Protection</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Malware Protection</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Port Attack Mitigation and IP Blocking</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="benefits-wrap">
            <h3><span>Benefits of</span> AWS Hosting:</h3>
            <div class="py-3">
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Scalability
              </h5>
              <p>
                AWS effortlessly adjusts to your requirements, much like a
                flexible resource that expands or contracts based on your
                immediate needs.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Reliability
              </h5>
              <p>
                Your data enjoys global redundancy, ensuring safety in multiple
                locations, similar to safeguarding essential files in diverse
                places for added security.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Security
              </h5>
              <p>
                AWS functions as a highly secure vault, permitting access solely
                to authorized individuals.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Cost Efficiency
              </h5>
              <p>
                You pay only for the resources you utilize, akin to paying for
                the specific amount of electricity consumed in your household.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Flexibility
              </h5>
              <p>
                AWS accommodates various tools and technologies, resembling a
                versatile toolbox with options for various tasks.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Global Reach
              </h5>
              <p>
                With its worldwide data centers, AWS guarantees swift loading of
                your website or app for users across the globe, akin to
                strategically placed stores serving customers efficiently in
                different areas.
              </p>
            </div>
          </div>
        </div>

        <div class="mt-4 expand" (click)="toggleHeight()">
          <p>
            {{ text }} <img src="{{ icon }}" width="30px" alt="" srcset="" />
          </p>
        </div>
      </div>

      <div
        [ngClass]="
          selectedPackage === 'Microsoft Azure'
            ? 'tab-pane fade show active'
            : 'tab-pane fade'
        "
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <div
          class="table-hidden"
          [ngClass]="{ 'badge-secondary': isExpanded == true }"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="featurehead">Features</th>
                <th>
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/azure.png"
                    width="70px"
                    alt=""
                    srcset=""
                  />
                </th>

                <th
                  scope="col"
                  class="d-flex justify-content-center align-items-center border-0"
                >
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/azure_proweb.svg"
                    width="150px"
                    alt=""
                    srcset=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Subscription Management</td>
                <td>Managed by Customer</td>
                <td>Managed by Pro Web</td>
              </tr>
              <tr>
                <td>Security Hardening</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Installations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Migration (Websites/Applications)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>1 Included</td>
              </tr>
              <tr>
                <td>Initial Security Scanning & Handover</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Dedicated Support</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Server Uptime Monitering</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Server Level Vulnerability Assessment(Optional)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                  Quarterly (Paid Purchace)
                </td>
              </tr>
              <tr>
                <td>
                  Automated Server Performance Reporting & Capacity Planning
                </td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Alerts & Mitigations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>SSL Certificates installations(Included with AWS)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>1 SSL Certificate</td>
              </tr>
              <tr>
                <td>Firewall Management</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Log Management</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Optimization</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Backup & Cyber Protection</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Malware Protection</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Port Attack Mitigation and IP Blocking</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="benefits-wrap">
            <h3><span>Benefits of</span> Microsoft Azure:</h3>
            <div class="py-3">
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Scalability
              </h5>
              <p>
                Azure allows you to scale resources up or down based on demand,
                ensuring you have the capacity you need without
                over-provisioning.
              </p>

              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Security and Compliance:
              </h5>
              <p>
                Azure provides robust security features and compliance
                certifications, helping you protect your data and meet
                regulatory requirements.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Cost Efficiency
              </h5>
              <p>
                Azure offers pay-as-you-go pricing and flexible pricing models,
                allowing you to optimize costs based on your usage patterns and
                business needs.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Flexibility
              </h5>
              <p>
                With Azure, you can choose from a variety of programming
                languages, frameworks, and operating systems, allowing you to
                build applications using the tools and technologies you're
                familiar with.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Hybrid Capabilities:
              </h5>
              <p>
                Azure offers hybrid cloud solutions that allow you to seamlessly
                integrate on-premises infrastructure with the cloud, providing
                flexibility and control over where your data and applications
                are hosted.
              </p>

              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Developer Productivity:
              </h5>
              <p>
                Azure offers a range of developer tools and services, such as
                Azure DevOps and Visual Studio integration, that streamline the
                development and deployment process.
              </p>

              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Global Presence:
              </h5>
              <p>
                Azure has data centers located in regions around the world,
                allowing you to deploy your applications close to your customers
                for better performance and compliance with data residency
                requirements.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-4 expand" (click)="toggleHeight()">
          <p>
            {{ text }}
            <img src="{{ icon }}" width="30px" alt="" srcset="" />
          </p>
        </div>
      </div>

      <div
        [ngClass]="
          selectedPackage === 'Google Cloud'
            ? 'tab-pane fade show active'
            : 'tab-pane fade'
        "
        id="pills-contact"
        role="tabpanel"
        aria-labelledby="pills-contact-tab"
      >
        <div
          class="table-hidden"
          [ngClass]="{ 'badge-secondary': isExpanded == true }"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="featurehead">Features</th>
                <th scope="col">Cloud</th>
                <th scope="col">
                  Cloud
                  <span class="mx-2"> + </span>
                  Pro Web
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Subscription Management</td>
                <td>Managed by Customer</td>
                <td>Managed by Pro Web</td>
              </tr>
              <tr>
                <td>Security Hardening</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Installations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Migration (Websites/Applications)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>2 Included</td>
              </tr>
              <tr>
                <td>Initial Security Scanning & Handover</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Dedicated Support</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Server Uptime</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Server Level Vulnerability Assessment</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                  Quarterly (Subscription of Acronis Cyber protect Cloud Plan
                  required)
                </td>
              </tr>
              <tr>
                <td>
                  Automated Server Performance Reporting & Capacity Planning
                </td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Alerts & Mitigations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>SSL Certificates installations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>1 SSL Certificate</td>
              </tr>
              <tr>
                <td>Firewall Management</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Log Management</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Optimization</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Backup & Cyber Protection</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Malware Protection</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Port Attack Mitigation and IP Blocking</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-4 expand" (click)="toggleHeight()">
          <p>
            {{ text }}
            <img
              src="../../assets/img/servicesdev/top-bottom.svg"
              alt=""
              width="30px"
              srcset=""
            />
          </p>
        </div>
      </div>

      <div
        [ngClass]="
          selectedPackage === 'Digital Ocean'
            ? 'tab-pane fade show active'
            : 'tab-pane fade'
        "
        id="pills-four"
        role="tabpanel"
        aria-labelledby="pills-four-tab"
      >
        <div
          class="table-hidden"
          [ngClass]="{ 'badge-secondary': isExpanded == true }"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="featurehead">Features</th>
                <th scope="col">
                  <img
                    src="../../assets/img/servicesdev/digitalOcean-logo.webp"
                    width="120px"
                    alt=""
                    srcset=""
                  />
                </th>
                <th scope="col">
                  <img
                    src="../../assets/img/servicesdev/cloud-hosting/Digital_proweb.svg"
                    width="170px"
                    alt=""
                    srcset=""
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Subscription Management</td>
                <td>Managed by Customer</td>
                <td>Managed by Pro Web</td>
              </tr>
              <tr>
                <td>Security Hardening</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Installations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Migration (Websites/Applications)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>1 Included</td>
              </tr>
              <tr>
                <td>Initial Security Scanning & Handover</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Dedicated Support</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Server Uptime Monitering</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Server Level Vulnerability Assessment(Optional)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                  Quarterly (Paid Purchace)
                </td>
              </tr>
              <tr>
                <td>
                  Automated Server Performance Reporting & Capacity Planning
                </td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Alerts & Mitigations</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>SSL Certificates installations(Included with AWS)</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>1 SSL Certificate</td>
              </tr>
              <tr>
                <td>Firewall Management</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Log Management</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Optimization</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Backup & Cyber Protection</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Malware Protection</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
              <tr>
                <td>Port Attack Mitigation and IP Blocking</td>
                <td>
                  <img
                    src="../../assets/img/servicesdev/red-line.svg"
                    width="20px"
                    alt=""
                    srcset=""
                  />
                </td>
                <td>
                  <i
                    class="fa fa-check text-success"
                    style="font-size: 20px"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="benefits-wrap">
            <h3><span>Benefits of</span> Digital Ocean Hosting:</h3>
            <div class="py-3">
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Customization
              </h5>
              <p>
                Easily configure and tailor your servers to meet your specific
                requirements.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Fast Deployment
              </h5>
              <p>
                Quick provisioning of resources, allowing you to launch projects
                rapidly.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Networking Features
              </h5>
              <p>
                Advanced networking features for optimizing performance and
                security.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Automated Backups
              </h5>
              <p>
                Automated backup solutions to ensure data integrity and disaster
                recovery.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                API Integration
              </h5>
              <p>
                Robust API for seamless integration with third-party tools and
                automation.
              </p>
              <h5>
                <img
                  src="../../assets/img/servicesdev/diomond-icon.svg"
                  width="20"
                  alt=""
                  srcset=""
                />
                Global Reach
              </h5>
              <p>
                Data centers strategically located around the world for optimal
                performance.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-4 expand" (click)="toggleHeight()">
          <p>
            {{ text }} <img src="{{ icon }}" alt="" width="30px" srcset="" />
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
