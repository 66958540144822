import { Component } from '@angular/core';

@Component({
  selector: 'app-ppc-service-slider',
  templateUrl: './ppc-service-slider.component.html',
  styleUrls: ['./ppc-service-slider.component.css']
})
export class PpcServiceSliderComponent {

}
