import { Component, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';

 
@Component({
  selector: 'app-dedicated-team',
  templateUrl: './dedicated-team.component.html',
  styleUrls: ['./dedicated-team.component.css']
})
export class DedicatedTeamComponent {
  activeIndex: number = 0; // Default active index is 0
  isDesktop: boolean | undefined;
  isMobile: boolean | undefined;
  currentTab: any = '';
  public images: any = [];
  nestedSliderChanged: boolean = false;
  tabbing: number = 1;
  showDve = 1
  mobileAccordion: boolean = false
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private platform: Platform
  ) {
    this.isMobile = this.platform.ANDROID || this.platform.IOS;
    if (!this.isMobile) {
      this.isDesktop = true;
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  ngOnInit() {
    this.titleService.setTitle("Hire Software Developers in Dubai | Pro Web");
    this.metaTagService.updateTag(
      { name: 'description', content: "Hire Website & App Developers from Most Trusted Software Development Company of UAE. Get expert tech solutions for your business growth with Pro Web. " }
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: "hire dedicated developers, dedicated software developers, hire app developers, hire web developers" }
    );
  }

  slickSlideChange(slickModal: any, index: number) {
    console.log("working");

    slickModal.slickGoTo(index)
    this.activeIndex = index;
  }

  onAfterChange(event: any, sliderIndex: number): void {
    if (!this.nestedSliderChanged) {
      this.activeIndex = event.currentSlide;
    }
    this.nestedSliderChanged = false; // Reset the flag
  }

  onAfterChangeNested(event: any, sliderIndex: number): void {
    this.nestedSliderChanged = true;
    this.activeIndex = 2;
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.pageYOffset > 570) {
      let element = document.getElementById('webdevelopment');
      element && element.classList.add('is-sticky');
    }
    else {
      let element = document.getElementById('webdevelopment');
      element && element.classList.remove('is-sticky');
    }

    if (window.pageYOffset > 570) {
      this.currentTab = 1;
    }
    if (window.pageYOffset > 1489) {
      this.currentTab = 2;
    }
    if (window.pageYOffset > 2489) {
      this.currentTab = 3;
    }
    if (window.pageYOffset > 3489) {
      this.currentTab = 4;
    }
    if (window.pageYOffset > 5089) {
      this.currentTab = 5;
    }
    if (window.pageYOffset > 6189) {
      this.currentTab = 6;
    }
  }


  handleClick(e: any) {
    this.tabbing = e
  }



  pmtechs = [
    {
      techicon: "assets/img/team-page/pmtech-icons/dropbox.png"
    },
    {
      techicon: "assets/img/team-page/pmtech-icons/github.png"
    },
    {
      techicon: "assets/img/team-page/pmtech-icons/trello.png"
    },
    {
      techicon: "assets/img/team-page/pmtech-icons/slack.png"
    },
    {
      techicon: "assets/img/team-page/pmtech-icons/jira.png"
    },
    {
      techicon: "assets/img/team-page/pmtech-icons/bugzilla.png"
    }
  ];


  dataItems = [
    {
      heading: 'Rapid Scalability On Demand', description: 'By outsourcing your development requirements, you are liberated from managing in-house teams and gain flexibility, wider scope, and technology options. Our rapid hiring process usually completes within 7 business days, ensuring expert onboardings.', bgimg: 'dedicated-first.png'
    },
    {
      heading: 'Time And Cost Effectiveness', description: 'Utilizing our dedicated hiring service not only reduces time and expenses but also minimizes typical development challenges and uncertainties. Our skilled developers guarantee a sturdy, easy-to-manage product, as we take care of recruitment, financials, compliance, and infrastructure, optimizing your workflow.', bgimg: 'dedicated-second.png'
    },
    {
      heading: 'Fast-Tracked Development', description: 'Partnering with skilled dedicated resources accelerates project kick-off, ensuring a prompt and effective start. Their expertise and dedication lead to expedited project launches, enabling you to reach your objectives swiftly and efficiently.', bgimg: 'dedicated-third.png'
    },
    {
      heading: 'Strong Foundation', description: 'Our developers excel in cutting-edge technologies and programming prowess. With our dedicated team, we ensure transparent communication through regular reports, software releases, and updates, allowing you to concentrate solely on product management tasks requiring your input.', bgimg: 'dedicated-four.png'
    },
    {
      heading: 'Top-Notch Quality', description: 'We closely monitor developer’s performance, regularly soliciting client input to swiftly adjust and enhance our methods as required. Our ongoing feedback loop promotes a collaborative partnership, where your insights fuel continual improvements for enduring project excellence.', bgimg: 'dedicated-five.png'
    },
    {
      heading: 'Complete Command', description: 'Our dedicated teams and resources seamlessly follow your lead, granting you total control and safeguarding your project data within your organization. This cooperative method assures that your project stays internal, ensuring data security and alignment with your overarching goals.', bgimg: 'dedicated-six.png'
    },

  ]


  logo = ['/assets/img/servicesdev/dedicated-team/audi_logo.webp', '/assets/img/servicesdev/dedicated-team/emiqtech.webp', '/assets/img/servicesdev/dedicated-team/Smartdoor2.webp', '/assets/img/servicesdev/dedicated-team/Springboard2.webp']

  logo2 = ['/assets/img/servicesdev/dedicated-team/audi_logo.webp', '/assets/img/servicesdev/dedicated-team/emiqtech.webp', '/assets/img/servicesdev/dedicated-team/springboard.webp']

  diverce_project = [
    {
      title: "Backend Developers",
      image_name: "assets/img/servicesdev/dedicated-team/back-end.svg"
    },
    {
      title: "Frontend Developers",
      image_name: "assets/img/servicesdev/dedicated-team/front-end.svg"
    },
    {
      title: "Mobile App Developers",
      image_name: "assets/img/servicesdev/dedicated-team/mobile-app.svg"
    },
    {
      title: "Data Engineers",
      image_name: "assets/img/servicesdev/dedicated-team/data-engineer.svg"
    },
    {
      title: "AI/ML Experts",
      image_name: "assets/img/servicesdev/dedicated-team/ai-ml.svg"
    },
    {
      title: "Quality Analysts",
      image_name: "assets/img/servicesdev/dedicated-team/quality-analysis.svg"
    },


  ]

  projects = [
    {
      img: 'assets/img/webp-image/dedicated-team/dp1.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/dp2.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/dp3.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/dp4.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/dp5.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/dp6.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/dp7.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/discounter.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/dp9.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/redddot.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/neo.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/dp12.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/align.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/emiq.webp',
    },
    {
      img: 'assets/img/webp-image/dedicated-team/growtomation.webp',
    },
  ]


  steps = [
    {
      s_no: "1",
      title: "Tell Us About Your Project Needs",
      des: "Share project specifics to assemble a skilled team, ensuring successful execution. Your input is vital for tailored solutions and optimal results.",
    },
    {
      s_no: "2",
      title: "Assess Expertise & Background",
      des: "We provide the best-suited specialists to meet your needs. You'll receive multiple resumes for each role, allowing you to review technical proficiency and past project achievements.",
    },
    {
      s_no: "3",
      title: "Engage in Live Interviews",
      des: "We facilitate video or voice interviews with our pre-screened specialists, allowing you to familiarize yourself with them and determine the best match for your project.",
    },
    {
      s_no: "4",
      title: "Finalize and Sign",
      des: "We negotiate partnership terms, including engagement model, involvement level, rates, and pricing. Upon agreement, we formalize the arrangement with a contract and NDA.",
    },

    {
      s_no: "5",
      title: "Start Within 48 Hours",
      des: "Once you've made your choice, kick off your project with a strong focus on transparent and ongoing communication, ensuring a seamless and efficient project experience.",
    },
  ]


  hiring_partner: any = [
    {
      isAnotherSlider: false,
      hiringImg: '../../assets/img/servicesdev/dedicated-team/slider-one.png',
      hiringName: 'Contract Model',
      hiringDesc: "Engage a dedicated software programmer to collaborate with your team on a full-time contractual basis. We manage the entire hiring process and post-hiring formalities, ensuring a smooth onboarding experience. Benefit from our comprehensive support to seamlessly integrate expert talent into your projects and advance your business goals.",
    },
    {
      isAnotherSlider: false,
      hiringImg: '../../assets/img/servicesdev/dedicated-team/slider-two.png',
      hiringName: 'Remote Hiring',
      hiringDesc: "Engage skilled remote developers to work half or full-time with your team. We handle the entire recruitment process, from sourcing and screening to onboarding, ensuring you find the right talent seamlessly. Embrace the benefits of a global talent pool and drive your business forward with our remote developer hiring solutions.",
    },
    {
      isAnotherSlider: true,
      anotherSlider: [
        {
          hiringImg: '../../assets/img/servicesdev/dedicated-team/slider-three.png',
          hiringName: 'Pre-Hiring',
          hiringDesc: "We meticulously source and screen candidates, presenting you with only the most qualified and vetted profiles tailored to your specific requirements. Our commitment to excellence ensures that you only hire a resource who not only meets but exceeds your expectations. With our approach, you can save time and resources, letting you focus on selecting the best.",
        },
        {
          hiringImg: '../../assets/img/servicesdev/dedicated-team/slider-four.png',
          hiringName: 'Post-Hiring',
          hiringDesc: "After securing your ideal candidate, we manage all aspects of the onboarding process, ensuring seamless integration into your team. With our attentive support, you can rest assured that administrative tasks are efficiently managed, allowing you to focus on fostering a productive and collaborative work environment. ",
        }
      ]
    },
  ]




  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,

    arrows: true,
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      }
    ]
  };

  carouselConfig2 = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    pauseOnHover: true,

  };

  carouselConfig3 = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    pauseOnHover: true,
    fade: true

  };


  carouselConfig4 = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },

    ]
  };


  // toggleAccordian(event: any, index: any) {

  //   this.faq.forEach((item, i) => {
  //     if (i !== index) {
  //       item.isActive = false;
  //     }
  //   });

  //   // Toggle the clicked item
  //   this.faq[index].isActive = !this.faq[index].isActive;
  // }

  taglinearr = ['Cost Effective', 'Faster Development', 'Top-Class Developers', 'Cost Effective', 'Faster Development', 'Top-Class Developers']


  data = ['audi.png', 'smartdoor.png', 'springboard.png', 'quillwill.png', 'emitech.png']

  datamobile = ['whites2-logo.webp', 'X_CLOUD_LOGO_white.svg', 'alliance_international_logo.svg', 'neo_realty_logo.svg']

  handleDeve(e: any) {
    if (e > 0 && this.showDve == e && this.isMobile) {
      this.showDve = e - e;
    }
    else {
      this.showDve = e;
    }

    //this.mobileAccordion = !this.mobileAccordion;
  }

  faq = [
    {
      title: "What is dedicated hiring?",
      des: "Dedicated resource or dedicated team hiring involves exclusively hiring professionals to work solely on a client's project. This model offers flexibility, access to specialized talent, and direct client management, ensuring focused attention and tailored expertise for project success.",
      isActive: false,
    },
    {
      title: "What are the benefits of hiring dedicated developers or individuals?",
      des: "<p>Hiring dedicated developers offers access to specialized talent, focused attention on your project, scalability, better communication, and cost-efficiency. It ensures expertise tailored to your needs and fosters collaboration for successful project outcomes.</p>",
      isActive: false,
    },
    {
      title: "How is billing handled for dedicated hiring services?",
      des: "<p>Billing occurs monthly and can be tailored to your preference upon contract signing: either a fixed cost for a specific developer set or based on time and material - billed per hour. Typically, the latter option is favored for dedicated developer billing, offering flexibility and transparency.</p>",
      isActive: false,
    },
    {
      title: "How to hire dedicated software developers?",
      des: '<p>To engage Pro Web is dedicated developers, begin by detailing your project needs or challenges. Reach out to us. Our process typically includes an initial consultation to understand your requirements, followed by providing CVs showcasing suitable team members. You will be able to interview and select the specialists who best fit your project. Once terms are agreed upon and contracts signed, we facilitate a smooth onboarding process for your selected dedicated developers</p>',
      isActive: false,
    },
    {
      title: "What is the process of dedicated hiring at Pro Web?",
      des: '<p>At Pro Web, our dedicated hiring process is streamlined and efficient, consisting of three key steps:</p><p>Requirement Analysis: Initially, we engage in a comprehensive discussion to understand your project needs, complexities, and specific requirements. This enables us to efficiently match you with pre-vetted developers who align with your project is goals and culture.</p><p>Talent Selection: Within a short timeframe, typically two days, we present you with a carefully curated selection of 2-3 developer profiles that best match your requirements. You can interview these candidates and assess their suitability based on technical skills, cultural fit, and team dynamics. Seamless Onboarding: Once you have selected your preferred developer, we facilitate a smooth onboarding process. Our dedicated Account Manager handles all contractual and payment procedures, ensuring a hassle-free experience as you begin working directly with the chosen developer.</p>',
      isActive: false,
    },
    {
      title: "How do you guarantee hired developers stay focused on their tasks and avoid distractions?",
      des: "At Pro Web, we instill a culture of accountability and professionalism among our developers to ensure they prioritize your tasks. Our robust project management systems track progress along with time tracking and provide daily work updates. Furthermore, our hired developers are dedicated to delivering quality results and meeting project deadlines, minimizing distractions from unrelated tasks.",
      isActive: false,
    },
    {
      title: "How do you ensure transparency and communication throughout the project?",
      des: '<p>Pro Web ensures transparency and communication by utilizing regular progress updates, status reports, and dedicated project management tools like Jira, Zoho Project , Trello , Clickup etc. Clients have full visibility into project developments, fostering trust and collaboration every step of the way.',
      isActive: false,
    },

    {
      title: "What is the typical duration for hiring dedicated developers?",
      des: '<p>The typical duration for hiring developers at Pro Web varies based on project scope tech stack and requirements. We work closely with clients to determine the most suitable timeline for assembling and onboarding the developers, ensuring efficiency without compromising quality.</p>',
      isActive: false,
    },

  ];




  toggleAccordian(event: any, index: any) {

    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }


}
