import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-web-development',
  templateUrl: './web-development.component.html',
  styleUrls: ['./web-development.component.css'],
})
export class WebDevelopmentComponent {
  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit() {
    // Meta Title & Description
    this.titleService.setTitle('Web Development Company in Dubai | Pro Web');

    this.metaTagService.updateTag({
      name: 'keywords',
      content: 'web development company, web development company in dubai',
    });

    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Pro Web Dubai is your reliable partner for web innovation. We turn digital dreams into reality, delivering excellence, affordability, and seamless user experiences that stand out from the competition.',
    });
  }

  data = [
    'quill_at_will_logo.webp',
    'geohoney_review_logo.svg',
    'the-travel-hub.webp',
    'book_my_pet_logo.webp',
    'smart_door_logo.webp',
  ];

  datamobile = [
    'quill_at_will_logo.webp',
    'geohoney_review_logo.svg',
    'book_my_pet_logo.webp',
    'smart_door_logo.webp',
  ];


  cardsItems = [
    {
      title: 'Node.js',
      desc: 'Node.js equips developers with the capabilities to craft real-time, high-performance applications and services. With its broad acceptance and versatility, Node.js has become the top preference for businesses aiming to elevate their online operations and user experiences. At Pro Web, our team of Node.js experts stands ready to unlock the full potential of this robust runtime. We enable you to create websites, applications, and services that not only captivate users but also provide exceptional performance and real-time functionality.',
      img: 'assets/img/servicesdev/globe.webp',
      btnurl: 'node-js-development/dubai',
    },
    {
      title: 'Core PHP',
      desc: 'PHP, a powerful and widely utilized server-side scripting language, is the backbone of countless web applications and services. Its robust features and flexibility make it the go-to choice for businesses aiming to optimize their digital presence and user experiences. At Pro Web, our team of CORE PHP specialists is committed to leveraging the full capabilities of this dynamic language. We enable you to create websites, applications, and services that not only engage users but also provide outstanding performance and reliability. With CORE PHP as our cornerstone, we help you achieve cutting-edge and user-focused digital solutions.',
      img: 'assets/img/servicesdev/globe.webp',
      btnurl: 'web-development-company/dubai',
    },
    {
      title: 'Laravel',
      desc: 'Laravel, a robust and widely acclaimed PHP framework, is the cornerstone of modern web development. Its rich feature set and elegant syntax make it the preferred choice for businesses aiming to enhance their online presence and user experiences. At Pro Web, our team of Laravel experts is committed to maximize the potential of this powerful framework. We enable you to create websites and applications that captivate users while delivering exceptional functionality. With Laravel as our foundation, we empower you to achieve cutting-edge digital solutions. ',
      img: 'assets/img/servicesdev/globe.webp',
      btnurl: 'web-development-company/dubai',
    },
    {
      title: 'Java Spring Boot',
      desc: 'Java Spring Boot, a dynamic and widely acclaimed framework, serves as a cornerstone in modern web application development. Its comprehensive suite of tools and streamlined design make it the preferred solution for businesses aiming to enhance their digital presence and user experience. At Pro Web, our skilled team of Spring Boot experts is committed to harnessing the complete capabilities of this powerful framework. We enable you to create web applications and services that engage users effectively while providing unmatched performance and scalability.',
      img: 'assets/img/servicesdev/globe.webp',
      btnurl: 'web-development-company/dubai',
    },
  ];

  cards = [
    {
      icon: '../../assets/img/servicesdev/creativity.svg',
      heading: 'Creativity',
      description:
        'Unparalleled creativity, where innovation & imagination drive extraordinary results.',
    },
    {
      icon: '../../assets/img/servicesdev/teamwork.svg',
      heading: 'Teamwork',
      description:
        'Exceptional teamwork, where collaboration and synergy form the foundation of success',
    },
    {
      icon: '../../assets/img/servicesdev/innovation.svg',
      heading: 'Realtime monitoring',
      description:
        'Urge to innovate, where we consistently push boundaries, fostering creative thinking.',
    },
    {
      icon: '../../assets/img/servicesdev/quality.svg',
      heading: 'Quality',
      description:
        'Commitment to quality, where excellence is the standard, ensuring every deliverable meets the benchmark',
    },
    {
      icon: '../../assets/img/servicesdev/security.svg',
      heading: 'Security',
      description:
        'Security, where safeguarding the assets and data is our utmost priority, backed by advanced technologies.',
    },
    {
      icon: '../../assets/img/servicesdev/trust.svg',
      heading: 'Trust',
      description:
        'We inspire, built on transparency, honesty, and a track record of delivering on our commitments',
    },
  ];

  logo = [
    '/assets/img/servicesdev/web-development/the_travel_hub_logo.webp',
    '/assets/img/servicesdev/web-development/book_my_pet_logo.webp',
    '/assets/img/servicesdev/web-development/smartdoor.svg',
    '/assets/img/servicesdev/web-development/geohoney_logo.svg',
  ];

  logo2 = [
    '/assets/img/servicesdev/web-development/the_travel_hub_logo.webp',
    '/assets/img/servicesdev/web-development/book_my_pet_logo.webp',
    '/assets/img/servicesdev/web-development/smartdoor.svg',
  ];

  taglinearr = [
    'Dynamic Web Development',
    'Web App Development',
    'Tech Savvy Developers',
    'Web App Development',
    'Dynamic Web Development',
    'Web App Development',
    'Tech Savvy Developers',
    'Web App Development',
  ];

  portfolioItems = [
    {
      heading: 'Book My Pet',
      smalltitle: 'Pet Care Website',
      desc: 'Book My Pet has revolutionized the pet care industry by offering a comprehensive platform that seamlessly connects pet owners with a range of services by enabling easy booking of grooming, boarding, and veterinary services. Book My Pet has elevated the well-being of pets and provided peace of mind to owners, fostering a more convenient and compassionate approach to pet care.',
      bgimg: '../../assets/img/servicesdev/bookmypet2.webp',
      icon: '../../assets/img/servicesdev/nodejs.svg',
      casestudy: '/case-study/bookmypet',
      heading1: 'Specialists Worked',
      heading2: 'Retention Rate',
      heading3: 'Years of Business',
      specialist: '4',
      download: '96%',
      rating: '2+',
    },
    {
      heading: 'Smartdoor',
      smalltitle: 'Property & Real Estate',
      desc: 'SmartDoor Provides a  convenient way to buy, rent or list your properties with the ability to access properties using digital keys and schedule visits at preferred time slots. It shows the property to prospective buyers without the need for physical visits to the site, using our smart solution to let technology do the work for you. We also provide flexible Access management where there is no need to change the lock with every change of tenant.',
      bgimg: '../../assets/img/servicesdev/smartdoor-portfolio.png',
      icon: '../../assets/img/servicesdev/web-development/next-js.svg',
      casestudy: '/case-study/quillatwill',
      heading1: 'Specialists Worked',
      heading2: 'Retention Rate',
      heading3: 'Years of Business',
      specialist: '3',
      download: '84%',
      rating: '5+',
    },
    {
      heading: 'The Travel Hub',
      smalltitle: 'Travel Blogging',
      desc: 'The Travel Hub stands for a lasting immersive travel experience that brings different cultures together. With the travel landscape changing at a fast pace, they wanted to create a common space for travelers and explorers to interact via Articles, Share Reviews, and discuss travel-related topics over forums.',
      bgimg: '../../assets/img/servicesdev/travelhub.webp',
      icon: '../../assets/img/servicesdev/web-development/laravel2.svg',
      casestudy: '/case-study/travelhub',
      heading1: 'Specialists Worked',
      heading2: 'Retention Rate',
      heading3: 'Years of Business',
      specialist: '4',
      download: '87%',
      rating: '3+',
    },
    {
      heading: 'Geohoney',
      smalltitle: 'Organic Honey Website',
      desc: "GeoHoney, where modern design meets nature's gateway to the world's finest nectar. As an exclusive eCommerce site, we embark on a captivating journey to uncover pure, unfiltered honey sourced from diverse landscapes. Elevate your culinary experience, connecting with nature's hidden wonders.",
      bgimg: '../../assets/img/servicesdev/geohoney.webp',
      icon: '../../assets/img/servicesdev/web-development/laravel2.svg',
      heading1: 'Specialists Worked',
      heading2: 'Retention Rate',
      heading3: 'Years of Business',
      specialist: '4',
      download: '81%',
      rating: '6+',
    },
    // {
    // 	heading: "Neo Reality",
    // 	smalltitle: "Real Estate",
    // 	desc: " Neo Realty is a distinctive brokerage firm that goes beyond expectations, offering an extraordinary journey while assisting clients in exploring Dubai's finest commercial and residential properties. With a commitment to delivering exceptional service and expert guidance, Neo Realty aims to support individuals on their unique ventures within the dynamic realm of Dubai's real estate market.",
    // 	bgimg: "../../assets/img/servicesdev/neoreality.webp",
    // 	icon: "../../assets/img/servicesdev/wordpress.svg",
    // 	casestudy: "",
    // 	heading1: 'Specialists Worked',
    // 	heading2: 'Retention Rate',
    // 	heading3: 'Years of Business',
    // 	specialist: "3",
    // 	download: "89%",
    // 	rating: "4+"
    // },
  ];

  processdata = [
    {
      icon: '/assets/img/servicesdev/process-way/analysis-orange.png',
      heading: 'Analysis and Planning',
      description:
        'Through thorough research and analysis, we meticulously gather comprehensive information on market trends and target audiences. This information forms the foundation for our precise planning, where we strategically determine the technologies and tools required for project execution. Additionally, we define the website layout and modules, ensuring a well-thought-out framework for the development of a feasible website.',
    },

    {
      icon: '/assets/img/servicesdev/process-way/design-orange.png',
      heading: 'Design & Development',
      description:
        "During the design phase, our focus is on wireframing and creating user interfaces that align with the specific requirements of our clients. Our development process is guided by a keen awareness of online market trends, aiming to captivate the audience's attention through thoughtful design and functionality. We strive to create websites that not only meet but exceed customer expectations.",
    },

    {
      icon: '/assets/img/servicesdev/process-way/quality-orange.png',
      heading: 'Quality Analysis ',
      description:
        'Our dedicated Quality Assurance (QA) team conducts continuous testing to guarantee that the websites are devoid of technical errors, critical bugs, and other code-related issues. This rigorous testing process ensures that the developed applications seamlessly serve their intended purpose, delivering a reliable and user-friendly experience.',
    },
    {
      icon: '/assets/img/servicesdev/process-way/deploy-orange.png',
      heading: 'Deployment',
      description:
        'Upon successful resolution of all technical issues identified during the QA phase, we proceed to launch the websites on online platforms. This deployment phase marks the transition from development to the live environment. Throughout this stage, we actively monitor the websites, gaining insights into user interactions and making any necessary adjustments to optimize performance and user experience.',
    },
  ];

  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  faq = [
    {
      title: 'What is web development?',
      des: '<p>Web development refers to the process of creating websites or web applications. It involves designing, building, and maintaining websites using various programming languages, frameworks, and tools to deliver content and functionality to users over the internet.</p>',
      isActive: false,
    },
    {
      title: 'Which web development technologies does Pro Web work on?',
      des: '<p>Pro Web adapts technologies such as HTML, CSS, and JavaScript for front-end design. Frameworks like React and Angular enhance functionality. Server-side technologies like Node.js, Laravel, PHP and databases such as MySQL, PostgreSQL & MongoDB handle dynamic content, ensuring interactive websites.</p>',
      isActive: false,
    },
    {
      title: 'How long does it take to develop a website?',
      des: '<p>  The process of designing and deploying a professionally built website could range from 2 months upto 6-12 months. However, there are several factors such as project complexity, scope adjustments, client responsiveness, and unforeseen challenges that can impact the timeline of the project.</p>',
      isActive: false,
    },
    {
      title: 'What is your support plan, how do you provide technical support?',
      des: '<p>We provide a selection of maintenance packages tailored to your needs. Explore them on our web maintenance packages page. Moreover, enjoy a complimentary 30-day technical support period after launching your website or application. </p>',
      isActive: false,
    },
    {
      title: 'What are web development models that Pro Web offers?',
      des: '<p>Pro Web offers two primary web development models: Fixed Price and Dedicated/Hourly. For Fixed Price, clients share requirements, we draft scope of work, then offer estimates. Dedicated/Hourly provides flexibility, with dedicated resources for about 176 hours monthly, and a developer assigned based on client needs.</p>',
      isActive: false,
    },
    {
      title: 'What is the standard price range for web development at Pro Web?',
      des: '<p>Web development services at Pro Web cover a range of offerings, including portfolio websites, blogs, e-commerce, web applications, software, custom sites, ERPs, CMS, and more. Prices typically start at 75 AED/hour. For instance, a Portfolio Website may range from 5000-10,000 AED, while a Custom or E-Commerce Website can exceed 20,000 AED, depending on specific requirements.</p>',
      isActive: false,
    },

    {
      title:
        'Can social media features be seamlessly integrated into the website?',
      des: '<p>Yes, we specialize in integrating social media functionalities, fostering engagement and connectivity by incorporating social sharing, login options, and live feed embedding.</p>',
      isActive: false,
    },
    {
      title: 'What measures do you take to optimize website loading speed?',
      des: '<p>We optimize website loading speed and user experience through various techniques such as code minification, image optimization, caching strategies, and server optimization. Users can verify the improvements in load time using tools like Google PageSpeed Insights.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }
}
