import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-native',
  templateUrl: './native.component.html',
  styleUrls: ['./native.component.css'],
})
export class NativeComponent {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle(
      'Native App Development Company in Dubai | Pro Web'
    );
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Pro Web offers expert native app development for iOS and Android transforming your business with bespoke solutions, smooth user experiences and state-of-the-art technology. Our dedicated team ensures innovative and scalable app solutions tailored to your unique needs.',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'native app development, native app development services, native app development company',
    });
  }

  cardsItems = [
    {
      heading: 'Full-Cycle Native App Development',
      description:
        'Our expert developers deliver comprehensive native app development services, managing every stage from initial design and development to rigorous testing and deployment. We create innovative, user-centric apps by incorporating feedback and market trends.Our approach ensures your app stands out, offering a seamless and engaging user experience.',
    },

    {
      heading: 'Custom Native App Development',
      description:
        "With Pro Web's dedicated native app development services, we create customized applications tailored to your business needs and requirements.Our expert team uses the latest technology and best practices to ensure your app is functional, scalable, and secure. We create engaging user experiences for your business to thrive in the digital market.",
    },

    {
      heading: 'UI/UX Design for Native Apps',
      description:
        'We prioritize exceptional UI/UX design for native apps, ensuring a seamless and intuitive user experience. Our design process involves thorough user research, prototyping, and usability testing to create visually appealing and highly functional apps. By focusing on user needs and behavior, we craft interfaces that enhance satisfaction and engagement.',
    },
  ];

  data = [
    {
      count: '1',
      heading: 'Requirement Analysis',
      desc: "In this phase, we start by identifying the project's requirements. This involves understanding the client's vision, objectives, and target audience for the app. We then define the team structure, ensuring that the right mix of skills and expertise is available to meet the project's needs.",
    },
    {
      count: '2',
      heading: 'Wireframing & Designing',
      desc: "We begin with wireframing to outline the app's structure and flow. This step ensures that the user journey is intuitive and logical. Once the wireframes are approved, our design team creates a visually appealing interface that aligns with the client's brand identity.",
    },
    {
      count: '3',
      heading: 'Future-Ready Development',
      desc: 'Our comprehensive development phase follows an agile process to ensure flexibility and adaptability. We start with backend development, building a robust foundation for the app. This includes setting up databases and servers and integrating necessary APIs.',
    },
    {
      count: '4',
      heading: 'Rigorous Quality Assurance',
      desc: 'We begin with unit tests to ensure that individual components function as expected, followed by integration tests to confirm seamless interaction between all parts of the app. User acceptance testing (UAT) is then conducted to gather feedback from real users.',
    },
    {
      count: '5',
      heading: 'Deployment & Support',
      desc: 'After the app successfully passes all testing phases, we prepare it for launch. We manage the submission process for the Google Play and Apple App Stores, ensuring compliance with all guidelines and requirements. We provide ongoing support to address issues and implement updates.',
    },
  ];

  data2 = ['', '', 'discounter-native.png', '', ''];

  datamobile = [];

  faq = [
    {
      title: 'What is Native App Development?',
      des: "<p>Native App Development involves creating applications specifically for a particular mobile operating system, like iOS or Android, using the platform's native programming languages and tools. This ensures optimal performance, seamless user experiences, and full use of device features.</p>",
      isActive: false,
    },
    {
      title: 'What Programming Languages are used in Native App Development?',
      des: '<p>Native app development uses Swift/Objective-C for iOS and Java/Kotlin for Android. Swift offers modernity and safety, while Kotlin brings concise syntax and interoperability. These languages enable optimal utilization of platform features, ensuring seamless user experiences.</p>',
      isActive: false,
    },
    {
      title:
        'How does Native App Development Improve Performance and User Experience?',
      des: "<p>Native app development directly leverages the device's hardware and software, ensuring optimal performance and a smoother, more responsive user experience. These apps are faster and more efficient, with improved load times, graphics, and functionality. Designed specifically for the platform, they align closely with user expectations and device capabilities.</p>",
      isActive: false,
    },
    {
      title:
        'Can Native Apps be Developed for both iOS and Android Simultaneously?',
      des: '<p>Yes, native apps can be developed for both iOS and Android simultaneously, but this requires separate efforts due to different programming languages and tools (Swift/Objective-C for iOS, Java/Kotlin for Android). This ensures optimal performance and user experience but may increase development time and cost. </p>',
      isActive: false,
    },
    {
      title:
        'What Advanced Features Enhance User Experience in Native App Development?',
      des: '<p>Native mobile applications, developed with specific programming languages and tools, offer enhanced responsiveness and speed. This performance is crucial for integrating advanced features like augmented reality, animations, and real-time processing, ensuring a seamless, engaging user experience.</p>',
      isActive: false,
    },
    {
      title: 'How does Pro Web ensure the Security of the Apps you Develop?',
      des: '<p>At Pro Web, we ensure app security through rigorous practices including secure coding standards, regular vulnerability assessments, and thorough testing. We implement encryption, authentication protocols, and adhere to industry best practices to protect data and maintain user trust.</p>',
      isActive: false,
    },
    {
      title:
        'Can Pro Web Integrate Existing Systems or Databases with the New App?',
      des: '<p>Yes, Pro Web can integrate existing systems or databases with your new app. Our team specializes in creating seamless connections between new applications and legacy systems, ensuring data consistency and improved functionality. We use robust APIs and middleware to facilitate smooth integration.</p>',
      isActive: false,
    },
    {
      title:
        'Does Pro Web Offers Maintenance and Support After the App is Launched?',
      des: '<p>Yes, Pro Web offers comprehensive maintenance and support after your app is launched. We ensure your app remains updated, secure, and runs smoothly, addressing any issues promptly and incorporating necessary enhancements to keep your app performing at its best.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }
}
