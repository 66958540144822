import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-packagesbanner',
	templateUrl: './packagesbanner.component.html',
	styleUrls: ['./packagesbanner.component.css']
})
export class PackagesbannerComponent {

	@Input() heading: any;
	@Input() paragraph: any;
	@Input() text: any;
	@Input() bannerimg: any;
	@Input() packageimg: any;
	@Input() bannermobile: any;
	@Input() cards: any;
	@Input() cards2: any;


}
