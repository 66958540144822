export class Alert {
    type: any;
    message: any;
    alertId: any;
    keepAfterRouteChange: any;

    constructor(init?:Partial<Alert>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}