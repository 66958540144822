
<!-- Start Page Title -->
<div class="page-title-area">


<!-- feature blogs -->
<div class="container my-5">
    <h1 class="text-start heading"> <span>FEATURED</span>  BLOG</h1>
    <div class="row align-items-center my-4">
        <div class="col-lg">
             <div>
                <img [src]="this.featuredBlog.image" alt="blog image" srcset="">
             </div>
        </div>
        <div class="col-lg">
           <div class="text-start">
            <span>Date : {{this.featuredBlog.post_date}}</span>
            <h4  class="blogtitle my-2">{{this.featuredBlog.title}}</h4>
            <p>{{this.featuredBlog.short_description}}......</p>
                <a [routerLink]="['/blog', this.featuredBlog.url]" class="btn btn-small btn-primary">
                    <span class="mx-2">Read More</span>  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 7.76429 0.0827751 7.53822 0.230116 7.37155C0.377456 7.20487 0.577293 7.11124 0.785664 7.11124L19.3163 7.11124L14.3714 1.51913C14.2238 1.35225 14.141 1.1259 14.141 0.889886C14.141 0.653873 14.2238 0.427527 14.3714 0.260641C14.5189 0.0937548 14.719 0 14.9276 0C15.1363 0 15.3363 0.0937548 15.4839 0.260641L21.7692 7.37076C21.8423 7.45331 21.9004 7.55139 21.94 7.65937C21.9796 7.76734 22 7.8831 22 8C22 8.1169 21.9796 8.23266 21.94 8.34063C21.9004 8.44861 21.8423 8.54669 21.7692 8.62925L15.4839 15.7394C15.3363 15.9062 15.1363 16 14.9276 16C14.719 16 14.5189 15.9062 14.3714 15.7394C14.2238 15.5725 14.141 15.3461 14.141 15.1101C14.141 14.8741 14.2238 14.6478 14.3714 14.4809L19.3163 8.88876H0.785664C0.577293 8.88876 0.377456 8.79513 0.230116 8.62845C0.0827751 8.46177 0 8.23571 0 8Z" fill="white"/>
                    </svg>
                </a>
           </div>
        </div>
    </div>
</div>
 

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-8">
                        <h4 class="text-start heading"> <span>LATEST</span>  BLOGS</h4>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Blog Area -->
<section class="blog-area">
    <div class="container" #target>
        <div class="row align-items-start">
            <div class="col-md-8">
                <div class="row row-cols-1 row-cols-md-2 g-4">
                    <div class="col" *ngFor="let item of getPaginatedData(currentPage, pageSize)">
                        <a [routerLink]="['/blog', item.url]">
                            <div class="card border-0 blogwrap">
                                <div class="blogimg">
                                    <img src={{item.image}} class="card-img-top" alt="...">
                                </div>
                                <div class="blogtxt card-body">
                                    <div class="d-flex justify-content-between my-2">
                                        <div class="text-secondary">
                                            <img src="assets/img/webp/blog/user.webp" alt="" srcset="">
                                            <span class="mx-1">Pro Web</span>
                                        </div>
                                        <div class="text-secondary">
                                            <img src="assets/img/webp/blog/calendar.webp" alt="" srcset="">
                                            <span class="mx-1">{{item.post_date}}</span>
                                        </div>
                                    </div>
                                    <h5 class="card-title">{{item.title}}</h5>
                                   
                                    <p class="card-text mt-2 text-secondary descrip">
                                        {{item.short_description}}
                                    </p>
                                    <div>
                                        <a [routerLink]="['/blog', item.url]" class="btn btn-small btn-primary"> <span class="mx-2">Read
                                            More </span>  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 7.76429 0.0827751 7.53822 0.230116 7.37155C0.377456 7.20487 0.577293 7.11124 0.785664 7.11124L19.3163 7.11124L14.3714 1.51913C14.2238 1.35225 14.141 1.1259 14.141 0.889886C14.141 0.653873 14.2238 0.427527 14.3714 0.260641C14.5189 0.0937548 14.719 0 14.9276 0C15.1363 0 15.3363 0.0937548 15.4839 0.260641L21.7692 7.37076C21.8423 7.45331 21.9004 7.55139 21.94 7.65937C21.9796 7.76734 22 7.8831 22 8C22 8.1169 21.9796 8.23266 21.94 8.34063C21.9004 8.44861 21.8423 8.54669 21.7692 8.62925L15.4839 15.7394C15.3363 15.9062 15.1363 16 14.9276 16C14.719 16 14.5189 15.9062 14.3714 15.7394C14.2238 15.5725 14.141 15.3461 14.141 15.1101C14.141 14.8741 14.2238 14.6478 14.3714 14.4809L19.3163 8.88876H0.785664C0.577293 8.88876 0.377456 8.79513 0.230116 8.62845C0.0827751 8.46177 0 8.23571 0 8Z" fill="white"/>
                                                </svg> </a>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <!-- <div class="my-5 text-center">
                    <a href="/" class="btn btn-small btn-primary"> <span class="mx-2"> View All </span>  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 7.76429 0.0827751 7.53822 0.230116 7.37155C0.377456 7.20487 0.577293 7.11124 0.785664 7.11124L19.3163 7.11124L14.3714 1.51913C14.2238 1.35225 14.141 1.1259 14.141 0.889886C14.141 0.653873 14.2238 0.427527 14.3714 0.260641C14.5189 0.0937548 14.719 0 14.9276 0C15.1363 0 15.3363 0.0937548 15.4839 0.260641L21.7692 7.37076C21.8423 7.45331 21.9004 7.55139 21.94 7.65937C21.9796 7.76734 22 7.8831 22 8C22 8.1169 21.9796 8.23266 21.94 8.34063C21.9004 8.44861 21.8423 8.54669 21.7692 8.62925L15.4839 15.7394C15.3363 15.9062 15.1363 16 14.9276 16C14.719 16 14.5189 15.9062 14.3714 15.7394C14.2238 15.5725 14.141 15.3461 14.141 15.1101C14.141 14.8741 14.2238 14.6478 14.3714 14.4809L19.3163 8.88876H0.785664C0.577293 8.88876 0.377456 8.79513 0.230116 8.62845C0.0827751 8.46177 0 8.23571 0 8Z" fill="white"/></svg> 
                    </a>
                </div> -->

                <div class="pagination-area">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" *ngIf="currentPage > 1">
                                <a class="page-link"
                                    (click)="changePage(currentPage - 1, target)">Prev</a>
                            </li>
                            <li class="page-item" *ngIf="currentPage === 1"><a
                                    class="page-link">Prev</a></li>

                            <li *ngFor="let pageNumber of getPageNumbers()" class="page-item"
                                [class.active]="pageNumber === currentPage"
                                (click)="changePage(pageNumber, target)">
                                <a class="page-link">{{ pageNumber }}</a>
                            </li>

                            <li class="page-item" *ngIf="currentPage < totalPages">
                                <a class="page-link"
                                    (click)="changePage(currentPage + 1, target)">Next</a>
                            </li>
                            <li class="page-item" *ngIf="currentPage === totalPages"><a
                                    class="page-link">Next</a></li>
                        </ul>
                    </nav>
                </div>

                <div class="row justify-content-between my-4">
                    <div class="col-md-8">
                        <h4 class="text-start fw-500 heading"><span>PRESS</span> RELEASES</h4>
                    </div>
                    <div class="col-md-4">
                        <!-- <div class="text-end">
                            <a href="#" class="btn btn-small btn-primary">View All</a>
                        </div> -->
                    </div>
                </div>
                <hr>
                <div id="carouselExampleIndicators" class="carousel slide blogslider" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button *ngFor="let pressItem of pressRelease; let i = index" type="button"
                            data-bs-target="#carouselExampleIndicators" [attr.data-bs-slide-to]="i" aria-current="true"
                            [attr.aria-label]="i" [class.active]="i === 0"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let pressItem of pressRelease; let i = index"
                            [class.active]="i === 0">
                            <a [routerLink]="['/blog', pressItem.url]">
                                <img src="{{pressItem.image}}" class="d-block w-100" alt="...">
                                <div class="carousel-caption d-none d-md-block my-3">
                                    <div class="d-flex justify-content-between my-3">
                                        <div class="text-secondary">
                                            <img src="assets/img/webp/blog/user.webp" alt="" srcset="">
                                            <span class="mx-1">Pro Web</span>
                                        </div>

                                    </div>
                                    <h5 class="text-start">{{pressItem.title}}</h5>
                                  
                                    <p class="text-start">{{pressItem.short_description}}</p>

                                    <div class="text-start">
                                        <a href="/" class="btn btn-small btn-primary"> <span class="mx-2">Read
                                            More </span>  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 7.76429 0.0827751 7.53822 0.230116 7.37155C0.377456 7.20487 0.577293 7.11124 0.785664 7.11124L19.3163 7.11124L14.3714 1.51913C14.2238 1.35225 14.141 1.1259 14.141 0.889886C14.141 0.653873 14.2238 0.427527 14.3714 0.260641C14.5189 0.0937548 14.719 0 14.9276 0C15.1363 0 15.3363 0.0937548 15.4839 0.260641L21.7692 7.37076C21.8423 7.45331 21.9004 7.55139 21.94 7.65937C21.9796 7.76734 22 7.8831 22 8C22 8.1169 21.9796 8.23266 21.94 8.34063C21.9004 8.44861 21.8423 8.54669 21.7692 8.62925L15.4839 15.7394C15.3363 15.9062 15.1363 16 14.9276 16C14.719 16 14.5189 15.9062 14.3714 15.7394C14.2238 15.5725 14.141 15.3461 14.141 15.1101C14.141 14.8741 14.2238 14.6478 14.3714 14.4809L19.3163 8.88876H0.785664C0.577293 8.88876 0.377456 8.79513 0.230116 8.62845C0.0827751 8.46177 0 8.23571 0 8Z" fill="white"/>
                                                </svg> </a>
                                    </div>

                                </div>
                            </a>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev" *ngIf="pressRelease.length > 1">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next" *ngIf="pressRelease.length > 1">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div class="col-md-4 my-3 my-md-0">
                <app-blog-sidebar></app-blog-sidebar>
            </div>
        </div>
    </div>
</section>