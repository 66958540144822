import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';


@Component({
  selector: 'app-web-hosting-package',
  templateUrl: './web-hosting-package.component.html',
  styleUrls: ['./web-hosting-package.component.css']
})
export class WebHostingPackageComponent {
  title = 'Web Hosting Packages for Business Excellence | Pro Web Dubai';
  addclass = false


  package: any = 1;    //define variable - bydefault it's 1

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private loadingService: LoadingService,

  ) { }

  logoarr = ['/assets/img/packages/dedicated-logo.svg', '/assets/img/packages/azure-logo.svg', '/assets/img/packages/aws-logo.svg', '/assets/img/packages/google-cloude-logo.svg'];
  logoarr2 = ['/assets/img/packages/google-mobile.png', '/assets/img/packages/aws-mobile.png', '/assets/img/packages/azure-mobile.png'];


  data = [{ btnName: 'Dedicated Hosting', checkoutUrl: '/web-hosting-packages' }, { btnName: 'Cloud Hosting', checkoutUrl: '/' }]

  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000)

    this.titleService.setTitle("Web Hosting Packages in dubai | Pro Web");

    this.metaTagService.updateTag(
      { name: "keywords", content: "web hosting packages" }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: "Explore Pro Web's Hosting Packages for unmatched business performance. Uptime, speed, and unlimited bandwidth tailored to your needs. Secure, reliable, and efficient hosting solutions." }
    );

  }

  // seo package click function
  seo(a: any) {
    this.package = a;
  }

  //  collapse table
  showdata() {
    this.addclass = !this.addclass
  }

}
