<section class="spacing">
  <div class="container-md">
    <div class="row justify-content-center text-center">
      <h2 class="mb-3 mb-md-0">{{ heading }}</h2>
      <p>{{ desc }}</p>

      <div class="row justify-content-center my-2 my-md-5 g-2 g-md-5">
        <div class="col-6 col-md-4 my-1 my-md-3" *ngFor="let item of obj">
          <div class="choosecontent">
            <div class="imgwrap">
              <img src="{{ item.icon }}" alt="creativity" srcset="" />
            </div>
            <h4 class="mt-3">{{ item.heading }}</h4>
            <p class="my-2 d-none d-lg-block">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
