import { Component, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
	selector: 'app-category-blogs',
	templateUrl: './category-blogs.component.html',
	styleUrls: ['./category-blogs.component.css']
})
 
export class CategoryBlogsComponent implements OnInit {

	id : string;
	category: any = [];
	blogCategory: any = [];
	pressRelease: any = [];
	featuredBlogs: any = [];

	currentPage = 1;
	pageSize = 6;
	totalPages: any;

	currentTab = 1;
	
	constructor(
		private http: HttpClient,
		private titleService: Title,
		private metaTagService: Meta,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.id = this.route.snapshot.params['id'];
	}

	baseUrl = environment.baseUrl;
	apiUrl = environment.apiUrl;
	uploadUrl = environment.uploadUrl;
	blogUrl= environment.blogUrl

	ngOnInit() {
		// Meta Title & Description
		this.titleService.setTitle("Category - Pro Web");
		this.metaTagService.updateTag({ name: 'description', content: 'Top Rated Web Design & App Development Agency with more than 11 years experience in building results driven custom web based solutions.' });
		// Meta Title & Description

		this.http.get(this.blogUrl + 'category_blog_list/' + this.id).subscribe(data => {
			let res: any = data;
			this.category = res.response;
		});

		this.http.get(this.blogUrl + 'blog_categories').subscribe(data => {
			let category: any = data;
			this.blogCategory = category.response;
		});

		this.http.get(this.blogUrl + 'featured_blog_list').subscribe(data => {
			let clientData: any = data;
			this.featuredBlogs = clientData.response;
		});

		// this.router.navigateByUrl('/CategoryBlogsComponent', { skipLocationChange: true }).then(() => {
		// 	this.router.navigate(['/blog/category', this.id]);
		// });
	}

	getPaginatedData(pageNumber: any, pageSize: any) {
		const startIndex = (pageNumber - 1) * pageSize;
		const endIndex = startIndex + pageSize;
		const pageData = this.category.blogs.slice(startIndex, endIndex);
		this.totalPages = Math.ceil(this.category.blogs.length / pageSize);
		return pageData;
	}

	changePage(page: any, el: HTMLElement) {
		this.currentPage = page;
		el.scrollIntoView({behavior: 'smooth'});
	}

	getPageNumbers(): number[] {
		const pageNumbers: number[] = [];
		for (let i = 1; i <= this.totalPages; i++) {
			pageNumbers.push(i);
		}
		return pageNumbers;
	}
}
