import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LoadingService } from '../utils/services/loading.service';


@Component({
  selector: 'app-ppc-package',
  templateUrl: './ppc-package.component.html',
  styleUrls: ['./ppc-package.component.css']
})
export class PpcPackageComponent {
  // Meta Title
  title = 'Customized PPC Packages for Online Success | Pro Web Dubai';


  addclass = false

  package: any = 1;    //define variable - bydefault it's 1

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private loadingService: LoadingService,


  ) { }

  logoarr = ['/assets/img/packages/millenium-logo.svg', '/assets/img/packages/sea7-logo.svg', '/assets/img/packages/tax-logo.svg', '/assets/img/packages/whites-logo.svg'];
  logoarr2 = ['/assets/img/packages/sea7-mobile.png', '/assets/img/packages/tax-mobile.png', '/assets/img/packages/whites-mobile.png'];

  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000)

    this.titleService.setTitle("PPC Packages | Google Ads Management in Dubai | Pro Web");

    this.metaTagService.updateTag(
      { name: "keywords", content: "ppc packages" }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: "Enhance online presence with Pro Web's PPC Packages. Tailored campaigns, strategic optimization, and a cost-effective approach for success." }
    );

  }

  // seo package click function
  seo(a: any) {
    this.package = a;
  }

  //  collapse table
  showdata() {
    this.addclass = !this.addclass
  }




}
