<div class="brand_wrap p-3 p-md-0">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-4 text-center text-md-start">
        <h5>
          Popular Brands Who
          <img
            src="../../assets/img/servicesdev/flutter/brands/hearticonhome.png"
            alt="heart"
            width="20"
            srcset=""
          />
          Pro Web
        </h5>
      </div>
      <div class="col-12 col-md-8">
        <div
          class="row justify-content-between align-items-center py-4 g-5 g-md-0"
        >
          <div
            class="col-6 col-md-3 text-center text-md-start"
            *ngFor="let item of brands"
          >
            <img
              src="../../assets/img/servicesdev/{{ item }}"
              alt="upside"
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
