
<div class="banner_img">
    <div class="banner_content">
      <h1>
        {{ heading }}
      </h1>
      <p>
        {{ description }}
      </p>
      <div class="banner_anim">
        <img src="../../assets/img/servicesdev/{{ image }}" alt="" srcset="" />
      </div>
    </div>
  </div>