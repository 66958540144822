<!-- desktop banner -->
<div class="banner-wrap">

    <img src={{bannerimg}} class="banner-img" alt="banner" srcset="">



    <div class="banner-content_wrap">

    <div class="banner-content">
        <div class="row align-items-center">
            <!-- <div class="bigcircle"></div> -->

            <div class="col-md-5">
                <h1 class="text-capitalize">{{heading}}</h1>
                <p>{{paragraph}}</p>
                <h5>
                    Popular Brands Who ️<img src="/assets/img/awardslogo/hearticonhome.png" width="20px" alt="heart"
                        srcset="">
                    Pro Web
                </h5>

                <div class="trusted-logo py-3">
                    <table>
                        <tr>
                            <td *ngFor="let logoimg of cards">
                                <img src={{logoimg}} alt="" srcset="">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="col-md-7">
                <div class="text-end">
                    <img src={{packageimg}} alt="" srcset="">
                </div>
            </div>

        </div>



    </div>
    </div>

</div>

<!-- mobile banner -->

<div class="mobilebanner">

    <div style="position: relative;">

        <div>
            <img src="/assets/img/packages/banner-mobile.png" class="mobImage" width="100%" alt="" srcset="">
        </div>

        <div style="position: absolute; top: 0px; left: 0%;">

            <div class="bannermob-content">
                <h2>{{heading}}</h2>
                <p>{{paragraph}}</p>
                <h6>
                    Popular Brands Who ️<img src="/assets/img/awardslogo/hearticonhome.png" width="20px" alt="heart"
                        srcset="">
                    Pro Web
                </h6>

                <div class="trusted-logo py-3">
                    <table>
                        <tr>
                            <td *ngFor="let logoimg of cards2">
                                <img src={{logoimg}} alt="" srcset="">
                            </td>
                        </tr>
                    </table>
                </div>

                <div>
                  <img src={{bannermobile}} alt="" srcset="">
                </div>

            </div>
        </div>


    </div>



</div>


<div #scrollTarget></div>