import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-react-native',
  templateUrl: './react-native.component.html',
  styleUrls: ['./react-native.component.css'],
})
export class ReactNativeComponent {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle(
      'React Native App Development Company in Dubai | Pro Web'
    );
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Dive into next-level React Native app development with Pro Web. Our adept team crafts customized mobile solutions, merging innovation effortlessly with expertise. Redesign your digital panorama with our pioneering techniques.',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'react native, react native app development, react native app development services, react native app development company',
    });
  }

  data3 = [
    'react-native/brands/bookmypet.svg',
    'react-native/brands/logo2.png',
    'react-native/brands/geo-honey.svg',
    'react-native/brands/acai.svg',
  ];

  cardsItems = [
    {
      heading: 'React Native App Development',
      description:
        "Pro Web specializes in React Native app migration, seamlessly transitioning existing mobile apps to harness its framework's benefits. From enhanced performance to cross-platform compatibility, we optimize functionality, leveraging React Native's extensive ecosystem. Through thorough audits, we ensure swift, secure migrations tailored to modernize your app's functionality.",
    },

    {
      heading: 'React Native App Migration',
      description:
        "Pro Web specializes in React Native migration, seamlessly transitioning existing mobile apps to harness its framework's benefits. From enhanced performance to cross-platform compatibility, we optimize functionality, leveraging React Native's extensive ecosystem. Through thorough audits, we ensure swift, secure migrations tailored to modernize your app's functionality.",
    },

    {
      heading: 'React Native App Maintenance',
      description:
        'Pro Web ensures top-notch React Native app maintenance, optimizing performance, stability, and security. Our proactive approach enhances user experience, swiftly resolving issues and keeping your app up-to-date. With ongoing support and timely updates, we provide continuous assistance beyond the initial launch, ensuring your app remains competitive.',
    },
  ];

  cardsItems2 = [
    {
      heading: 'React Native Audit and Consulting',
      description:
        'Pro Web offers thorough React Native audit and consulting services, analyzing projects to pinpoint areas for enhancement and providing strategic advice. Our expert assessment and personalized recommendations optimize your projects for success. Unsure about your business idea? Our specialists guide you to the most efficient technology stack.',
    },

    {
      heading: 'React Native Plugin Development',
      description:
        "Pro Web specializes in React Native plugin development, creating custom modules to extend functionality and integrate native code seamlessly. Our expert services enhance app capabilities, ensuring seamless integration with native features. We also excel in crafting plugins and widgets for existing digital solutions, leveraging React Native's full potential for maximum impact.",
    },

    {
      heading: 'Dedicated React Native Developers',
      description:
        'Pro Web provides dedicated React Native developers, focused on crafting high-quality mobile apps. Our specialists tailor solutions to your needs, ensuring efficiency and excellence. For clients with in-house teams, we offer collaboration with dedicated RN developers, seamlessly integrating with established workflows and implementing best practices.',
    },
  ];

  data = [
    {
      count: '1',
      heading: 'Requirement Analysis',
      desc: 'We begin by collecting both technical and non-technical requirements from the product owner. Our development team compiles a comprehensive list of specifications to guide the project. This ensures that we clearly understand the goals and expectations for the React Native app, laying a solid foundation for the subsequent phases.',
    },
    {
      count: '2',
      heading: 'Data-Informed Design',
      desc: "Our designers then prototype the solution, crafting detailed designs and user interfaces. We perform final edits and reviews with the product owner to prevent costly rework later. This collaborative approach helps us refine the design, ensuring it aligns perfectly with the product owner's vision and requirements",
    },
    {
      count: '3',
      heading: 'Future Ready Development',
      desc: 'Our specialists commence app development, focusing on coding for the frontend, backend, and databases. Development typically progresses in stages, concentrating on small, manageable modules. By breaking the project into smaller parts, we can maintain high quality and address issues promptly, ensuring a smooth development process.',
    },
    {
      count: '4',
      heading: 'Quality Assurance',
      desc: 'Our QA specialists test the implemented modules against the specification list. If discrepancies are identified, our developers revise and refine the code accordingly. This rigorous testing phase ensures that the React Native app meets all specified requirements and functions flawlessly.',
    },
    {
      count: '5',
      heading: 'Deployment',
      desc: 'After final testing is completed, we deploy the product to its intended environment, such as Google Play or the App Store. We grant access to the target audience, enabling them to download and use the mobile app. This phase marks the culmination of our development efforts, making the app available to users.',
    },
  ];

  data2 = [
    'sg-hired-react.png',
    'bookmypet-react.png',
    'geohoney-react.png',
    'saver-grocery-react.png',
    'acai-react.png',
  ];

  datamobile = [
    'sg-hired-react.png',
    'bookmypet-react.png',
    'saver-grocery-react.png',
    'acai-react.png',
  ];

  faq = [
    {
      title: 'What is React Native App Development?',
      des: '<p>React Native app development is the process of creating mobile applications using the React Native framework. It allows developers to build cross-platform apps with a single codebase, utilizing JavaScript and React, while delivering native-like performance and user experience on both iOS and Android platforms.</p>',
      isActive: false,
    },
    {
      title: 'What are the benefits of a React Native application?',
      des: '<p>React Native applications offer several benefits, including faster development times and reduced costs by using a single codebase for both iOS and Android platforms. They deliver a native-like user experience, provide easy integration with third-party plugins, and facilitate hot reloading for instant updates, ensuring efficient and high-performing mobile apps.</p>',
      isActive: false,
    },
    {
      title: 'Can React Native be used for both web and mobile?',
      des: '<p>Yes, React Native can be used for both web and mobile development. By leveraging the React Native for Web library, developers can create a single codebase that runs across iOS, Android, and web platforms. This approach ensures consistent user experience and streamlines the development process, saving time and resources.</p>',
      isActive: false,
    },
    {
      title:
        'How does Pro Web ensure seamless integration of React Native into existing apps?',
      des: '<p>Pro Web ensures seamless integration of React Native into existing apps by conducting thorough analysis, carefully planning the integration process, and utilizing industry best practices. Our experienced developers handle the transition with precision, ensuring enhanced functionality and a smooth user experience.</p>',
      isActive: false,
    },
    {
      title:
        'What types of apps are suitable for development using React Native?',
      des: '<p>React Native is suitable for various types of apps, including but not limited to e-commerce, social networking, productivity tools, and entertainment apps. Its flexibility and cross-platform compatibility make it a versatile choice for many app categories.</p>',
      isActive: false,
    },
    {
      title: ' Is React Native a good choice for long-term projects?',
      des: '<p>Yes, React Native is a good choice for long-term projects. Its strong community support, regular updates, and ability to share a single codebase across multiple platforms make it reliable and cost-effective. It ensures consistent performance and adaptability to new technologies, making it suitable for sustained development.</p>',
      isActive: false,
    },
    {
      title:
        'How does Pro Web handle maintenance and updates for React Native apps after launch?',
      des: '<p>Pro Web ensures seamless maintenance and updates for React Native apps post-launch through regular monitoring, timely bug fixes, and feature enhancements. Our dedicated team prioritizes ongoing support to ensure app functionality, security, and user satisfaction, keeping your app up-to-date with evolving technologies and market trends.</p>',
      isActive: false,
    },
    {
      title:
        'Can Pro Web assist with cross-platform compatibility and testing for React Native apps?',
      des: '<p>Yes, Pro Web specializes in ensuring cross-platform compatibility and rigorous testing for React Native apps. Our team employs comprehensive testing strategies to identify and resolve any compatibility issues, ensuring seamless functionality across different devices and platforms.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }
}
