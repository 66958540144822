import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-ppc-service',
  templateUrl: './ppc-service.component.html',
  styleUrls: ['./ppc-service.component.css']
})
export class PpcServiceComponent {

  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle('Google Adwords Agency In Dubai, UAE | Google Certified Partner');
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Certified PPC (Pay-Per-Click) Agency, with an expert team and over 10 years of experience in Search , Display , Remarketing & Shopping Ads. Maximize your ROI with Paid Media.',
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'pay per click, pay per click advertising, pay per click marketing, pay per click company',
    });
  }

  logoarr = ['/assets/img/packages/whites-logo.svg', '/assets/img/packages/tax-logo.svg', '/assets/img/packages/niccs-logo.svg', '/assets/img/packages/6skins-logo.svg'];
  logoarr2 = ['/assets/img/packages/whites-mobile.png', '/assets/img/packages/tax-mobile.png', '/assets/img/packages/niccs-logo.svg'];

  cardsItems = [
    {
      title: 'Google Ads Management',
      desc: 'We recognize the importance of Google Ads in a well-rounded marketing strategy. Our Google Ads management services boost your online visibility and enhance the success of your organic search results. By targeting specific keywords and demographics, we help you reach a broader audience and drive more traffic to your website. Our team optimizes your campaigns for maximum ROI, leveraging data-driven insights and industry best practices. From crafting compelling ad copy to continuous monitoring and adjustments, Pro Web ensures your business achieves its marketing goals efficiently and effectively.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Google Display Ads',
      desc: `Google Display Ads are an essential part of an effective marketing strategy, appearing on websites, apps, and videos within the Google Display Network (GDN). While Search Ads target customers actively looking for your products or services, Display Ads help introduce potential customers to your brand as they browse online. At Pro Web, we utilize Google Display Ads to expand your brand's reach and visibility, capturing the attention of a wider audience. Our team strategically places and optimizes your ads for maximum impact, driving awareness and engagement before customers search for your offerings.`,
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Remarketing Ads',
      desc: 'Remarketing Ads are crucial for keeping your brand top-of-mind for potential customers who have visited your website without making a purchase. These ads enable you to target users who have previously clicked on an ad and visited your site. At Pro Web, we leverage remarketing ads to re-engage these visitors, encouraging them to return and complete their purchase. By strategically placing these ads across various platforms, we ensure your brand remains visible to interested prospects, increasing the chances of conversion and enhancing your overall marketing effectiveness.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Google Shopping Ads',
      desc: 'Google Shopping Ads enable you to showcase product listings with direct links to your online store, driving e-commerce sales. These ads feature high-quality images and pricing information, capturing the attention of potential buyers. Leveraging Google Shopping Ads, Pro Web enhances your online retail presence and boosts visibility. Our team crafts compelling product listings that stand out, ensuring your products reach the right audience. By optimizing these ads for maximum visibility and engagement, Pro Web helps you convert browsers into buyers, enhancing your sales and boosting your e-commerce success.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Landing Page Creation',
      desc: 'Effective landing page creation is crucial for converting visitors into valuable leads. Pro Web specializes in designing and developing high-converting landing pages tailored to your specific goals. Our team focuses on clear, compelling content and intuitive design to capture visitor interest and drive action. By integrating persuasive calls-to-action, engaging visuals, and strategic layout, we ensure your landing page maximizes conversions and supports your overall marketing objectives. Trust Pro Web to create landing pages that not only attract but also retain and convert your target audience.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Paid Search',
      desc: 'Paid search is a powerful tool for driving targeted traffic to your website and achieving immediate results. Pro Web excels in managing and optimizing paid search campaigns to enhance your online visibility and attract qualified leads. Our team meticulously crafts compelling ad copy and strategically selects keywords to maximize your return on investment. By continually analyzing and refining your campaigns, we ensure they remain effective and aligned with your business objectives. Trust Pro Web to leverage paid search for increased visibility, higher click-through rates, and better overall performance.',
      img: 'assets/img/servicesdev/globe.webp',
    },
  ];

  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  iconsdata = [
    {
      icon: 'assets/img/servicesdev/package-service/grad-one.svg',
      name: 'Ad Campaigns',
    },
    { icon: 'assets/img/servicesdev/package-service/grad-two.svg', name: 'Click-Through Rate' },
    {
      icon: 'assets/img/servicesdev/package-service/grad-three.svg',
      name: 'ROI Maximization',
    },
    {
      icon: 'assets/img/servicesdev/package-service/grad-four.svg',
      name: 'Lead Generation',
    },
    {
      icon: 'assets/img/servicesdev/package-service/grad-five.svg',
      name: 'Keyword Bidding',
    },
  ];

  dataItems = [
    {
      heading: 'Ad Spend Insight',
      description:
        'Ad Spend Insight provides a detailed analysis of your advertising expenditures, helping you understand where your budget is going and how it impacts performance. Pro Web uses data insights to optimize ad spend, ensuring effective allocation and maximizing ROI.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Conversion Tracking',
      description:
        'Conversion tracking measures the effectiveness of your marketing efforts by monitoring user actions, such as purchases or sign-ups. Pro Web’s tracking solutions offer clear insights into campaign performance, driving data-based decisions to boost conversions and ROI.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Keyword Research',
      description:
        'Keyword targeting helps you reach your desired audience by selecting relevant search terms for your ads. By focusing on specific keywords, your ads appear when potential customers search for products or services like yours, maximizing engagement and conversions.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Ad Copywriting',
      description:
        'Ad copywriting involves crafting persuasive text for ads to engage your target audience. Pro Web creates copy that highlights benefits, grabs attention, and drives conversions. Our approach ensures messages resonate with customers, boosting campaign effectiveness.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Ad Design',
      description:
        'Ad design focuses on creating visually appealing and effective ads that capture attention and convey your message. Pro Web designs striking ads with compelling visuals and clear calls-to-action, enhancing engagement and driving conversions while aligning with your brand identity.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Budget Control',
      description: 'With Pro Web’s budget control, you can set daily or monthly spending limits for PPC advertising, ensuring effective cost management. Define a budget to allocate resources strategically, avoid overspending, and optimize your ad spend for the best return on investment.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Quality Score Monitoring',
      description:
        `Quality score monitoring with Pro Web optimizes your PPC ads for relevance and performance. Tracking and improving your quality score enhances ad relevance, boosts landing page quality, and reduces costs per click, leading to better campaign effectiveness and ROI.`,
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Campaign Analysis',
      description:
        'Campaign analysis involves evaluating the performance of your marketing campaigns to measure effectiveness and ROI. Pro Web meticulously examines key metrics, identifies trends, and provides actionable insights to optimize strategies, improve results, and maximize your investment.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Landing Page Optimisation',
      description:
        `Landing page optimization focuses on enhancing your page's design and content to boost conversions. Pro Web refines elements such as headlines, calls-to-action, and user experience to maximize engagement, reduce bounce rates, and improve overall campaign performance.`,
      bgimg: 'hosting-card.png',
    },
  ];

  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  data = [
    'levantiques-logo.webp',
    '6skins-logo.webp',
    'landmark-logo.png',
    'whites-logo-ppc.webp',
    'sea7-logo.webp',
  ];

  datamobile = [
    'levantiques-logo.webp',
    '6skins-logo.webp',
    'whites-logo-ppc.webp',
    'sea7-logo.webp',
  ];


  faq = [
    {
      title: 'What is PPC Advertising?',
      des: '<p> PPC (Pay-Per-Click) advertising is an online marketing model where you pay a fee each time someone clicks on your ad. It helps drive targeted traffic to your website by purchasing visits through ads placed on search engines or social media, rather than relying solely on organic search results.</p>',
      isActive: false,
    },
    {
      title:
        'How do Pay per click ads work?',
      des: '<p>Pay-Per-Click (PPC) ads work by targeting specific keywords relevant to your business. You bid on these keywords, and your ad appears when users search for them. You only pay when someone clicks on your ad, driving targeted traffic to your website. Performance is tracked and optimized based on metrics like clicks and conversions.</p>',
      isActive: false,
    },
    {
      title:
        'What platforms do you use for PPC advertising?',
      des: "<p> We use several platforms for PPC advertising, including Google Ads for search and display networks, Bing Ads for additional search traffic, and social media platforms like Facebook, Instagram, LinkedIn, and Twitter. This multi-platform approach ensures we reach your target audience effectively.</p>",
      isActive: false,
    },
    {
      title: 'What PPC strategy do you use for your clients?',
      des: '<p>Our PPC strategy for clients includes thorough keyword research, targeted ad creation, and continuous optimization. We focus on bidding strategies, ad copy testing, and landing page improvements to maximize ROI. Regular performance analysis ensures our approach is always refined for optimal results.</p>',
      isActive: false,
    },
    {
      title:
        'How do you track the success of a PPC campaign?',
      des: "<p> We track the success of a PPC campaign using advanced analytics tools to monitor key metrics like click-through rates, conversions, and return on investment (ROI). Regular performance reports provide insights into campaign effectiveness, helping us optimize strategies for better results.</p>",
      isActive: false,
    },
    {
      title: 'Why is PPC advertising crucial for businesses, and is it a good fit for my company?',
      des: '<p>PPC advertising is important because it drives targeted traffic to your website, offers immediate visibility, and provides measurable results. It’s ideal for businesses looking to boost online presence quickly and efficiently. If you want to reach specific audiences and see quick returns, PPC is a valuable tool.</p>',
      isActive: false,
    },
    {
      title:
        'How long does it take to see results from a PPC campaign?',
      des: '<p> You can see initial results from a PPC campaign within a few days to weeks, as ads start driving traffic almost immediately. However, optimizing for optimal performance and achieving the best ROI may take a few months of ongoing adjustments and refinements</p>',
      isActive: false,
    },
    {
      title: `Can I make changes to my PPC campaign once it's live?`,
      des: '<p>Yes, you can make changes to your PPC campaign once it’s live. We regularly review performance data and adjust keywords, ad copy, and bidding strategies as needed. This ongoing optimization helps improve results and adapt to any changes in your business or market conditions.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }


}
